import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./accountDetails.css";
import Button from "@mui/material/Button";
import camera from "../../assets/camera.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  clinicPic,
  clinicDetails,
  updateDentistNameFromOfficeDetails,
  getLatestClinicDetails,
  getBinaryDataOfClinicImage
} from "../../Services/AuthService";
import ImagePreview from "./imagePreview";
import { useNavigate } from "react-router-dom";
import NavbarPatientEnquiry from "./navbar";
import PacmanLoader from "react-spinners/PacmanLoader";
import SnackFire from "../../UI/Snackbar/Snackbar";

const AccountDetails = () => {
  const navigate = useNavigate();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const [clinicDetailsData, setClinicDetailsData] = useState();
  const [loading, setloading] = useState(true);
  const [imgs, setimgs] = useState();
  const [photo, setphoto] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snacktype, setsnacktype] = useState("error");
  const [snackbarmessage, setsnackbarmessage] = useState("");

  const user = JSON.parse(localStorage.getItem("admin"));
  const handleclose = () => {
    setsnackbar(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("admin"));
    getLatestClinicDetails({ user_id: user.admin._id })
      .then((res) => {
        if (res.status === 200) {
          setClinicDetailsData(res.data.data[0]);
          if (!res.data.data.length == 0) {
            if (res.data.data[0].clinic_image_name) {
              const imageName = res.data.data[0].clinic_image_name.split("/")[1]
              getBinaryDataOfClinicImage(imageName).then((res) => {
                if (res.status == 200) {
                  const byteArray = new Uint8Array(res.data.data.binaryData.data); // Convert the array of integers to a byte array
                  const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
                  const imageUrl = URL.createObjectURL(blob);
                  setimgs([imageUrl]);
                  setphoto(true);
                } 
              }).catch((err) => {
                setphoto(false);
              })
            } 
            // else if (res.data.data[0].fullUrl.length != 0) {
            //   setimgs([`${res.data.data[0].fullUrl}`]);
            //   setphoto(true);
            // }
          }
          // if(imgs.length)
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        return err;
      });
  }, []);


  
  if (loading) {
    return (
      <div>
   <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              {/* <img
              alt=""
              src={logo}
              width="40px"
              height="40px"
              //   width="0"
              //   height="30"
              className="d-inline-block align-top"
            />{" "} */}
            </div>

            <div className="navbar_text">Dental Office</div>
          </div>
        </Container>
      </Navbar>         <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  if (!loading) {
    return (
      <Container fluid>
      {
        app?       <NavbarPatientEnquiry/>:
        <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              {/* <img
              alt=""
              src={logo}
              width="40px"
              height="40px"
              //   width="0"
              //   height="30"
              className="d-inline-block align-top"
            />{" "} */}
            </div>

            <div className="navbar_text">Dental Office</div>
          </div>
        </Container>
      </Navbar> 
      }   
        {
          <Formik
            validate={(values) => {
              let errors = {};

              if (!values.clinic_name) {
                errors.clinic_name = "Clinic name is required";
              }
              if (!values.name) {
                errors.first_name = "Name is required";
              }
              if (!values.phone_number) {
                errors.phone_number = "Phone Number is required";
              }
              if (!values.address) {
                errors.address = "Address is required";
              }
              if (!values.city) {
                errors.city = "City is required";
              }
              if (!values.state) {
                errors.state = "State is required";
              }
              if (!values.preferred_url) {
                errors.preferred_url = " URL is required";
              }
              if (!values.country) {
                errors.country = "Country is required";
              }
              if (!values.zip_code) {
                errors.zip_code = "Zip Code is required";
              }
              // if (!values.file) {
              //   errors.file = "Office Image is required";
              // }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              // last_name: Yup.string().required("Last Name is required"),
              clinic_name: Yup.string().required("Office name is required"),
              address: Yup.string().required(" Address is Required"),
              phone_number: Yup.string().required(" Address is Required"),
              city: Yup.string().required("City is Required"),
              state: Yup.string().required("State is Required"),
              preferred_url: Yup.string().required("URL is Required"),
              country: Yup.string().required(" Country is Required"),
              zip_code: Yup.string().required(" Zipcode is Required"),
              // file: Yup.string().required("Office Image is Required"),
            })}
            initialValues={{
              clinic_name: clinicDetailsData
                ? clinicDetailsData.clinic_name
                : "",
              name: clinicDetailsData
                ? clinicDetailsData.chief_dental_officer_name
                : "",
              prefix_name: clinicDetailsData
                ? clinicDetailsData.chief_dental_officer_prefix_name
                : "",
              address: clinicDetailsData
                ? clinicDetailsData.clinic_address
                : "",
              city: clinicDetailsData ? clinicDetailsData.clinic_city : "",
              phone_number: clinicDetailsData
                ? clinicDetailsData.phone_number
                : "",
              state: clinicDetailsData ? clinicDetailsData.clinic_state : "",
              country: clinicDetailsData
                ? clinicDetailsData.clinic_country
                : "",
              zip_code: clinicDetailsData
                ? clinicDetailsData.clinic_zipcode
                : "",
              preferred_url: clinicDetailsData
                ? clinicDetailsData.preferred_url
                : "",
              file: null,
            }}
            onSubmit={(values) => {
              // clinicDetails(values).then(res=>{
              //   console.log(res)
              // }).catch(err=>console.log(err))
              if(app && values.name){
                updateDentistNameFromOfficeDetails({app,name:values.name})
              }
              if (values.file) {
                const reader = new FileReader();
                reader.readAsDataURL(values.file);
                reader.onload = () => {
                  clinicPic(reader.result)
                    .then((response) => {
                      if (response.data) {
                        const data = {
                          ...values,
                          BaseUrl: response.data.BaseUrl,
                          fullUrl: response.data.fullUrl,
                          imageName: response.data.imageName,
                        };
                        clinicDetails(data)
                          .then((res) => {
                      if(user.admin.company.length != 0){
                        setsnackbar(true)
                        setsnackbarmessage(res.data.message)
                        setsnacktype("success");
                      }else{
                          navigate("/signupsucess");
                      }  
                          })
                          .catch((err) => {
                            return err;
                          });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                };
              } else {
                clinicDetails(values)
                  .then((res) => {
                    if (res) {
                      if(user.admin.company.length != 0){
        
                        setsnackbar(true)
                        setsnackbarmessage(res.data.message)
                        setsnacktype("success");
                      }else{
                          navigate("/signupsucess");
                      }                    }
                  })
                  .catch((err) => {
                    setsnackbar(true)
                    setsnackbarmessage(err.data.message)
                    setsnacktype("error");
                    return err;
                  });
              }
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Row className="office-details-container">
                    <Col md={5} className="pic_content">
                      <div className="apollo_image mt-5  positions">
                        {!values.file && photo && (
                          <img
                            disable={true}
                            className="apollo_image  positions"
                            src={imgs[0]}
                            alt="Upload logo(It will be published in the user app)"
                          />
                        )}
                        {!values.file && !photo && (
                          <div className="d-flex flex-column align-items-center justify-content-center upload_logo_text">
                            <div className="upload_logo_font">Upload logo </div>
                            <div className="mt-2">
                              (It will be published in the user app){" "}
                            </div>
                            <div className="mt-2">
                              Dimension: 500px x 500px{" "}
                            </div>
                          </div>
                        )}

                        {values.file && <ImagePreview file={values.file} />}

                        <div className="absolute">
                          <label htmlFor="upload-button">
                            <img
                              src={camera}
                              className="camera-icon"
                              alt="upload image"
                            />
                          </label>

                          <input
                            id="upload-button"
                            className="my-3 custom-file-input"
                            placeholder="Upload your Card"
                            type="file"
                            style={{ display: "none" }}
                            value={""}
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={7} className="overflow-auto office-form-container">
                      <Col md={8} className="">
                        <Col className="mx-5 mt-5 mb-4 positions ">
                          {/* <div className="dental_office_text "> Clinic Name</div> */}
                          <Col md={2} className="mt-1">
                            {" "}
                            {/* <div className="underline"></div> */}
                          </Col>
                        </Col>

                        <Row className="mx-5">
                          <Col md={12} className="">
                            <span className="field-texts mx-2">
                              Chief Dental Officer
                            </span>{" "}
                          </Col>
                        </Row>

                        <div className=" d-flex mx-5 mt-2">
                          <Col md={2} className="">
                            <input
                              id="prefix_name"
                              className="form-input-chief mb-3 grey-bg-color"
                              placeholder="Dr."
                              type="text"
                              value={values.prefix_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                          </Col>
                          <Col md={10} className="mx-3">
                            <input
                              id="name"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="FirstName  LastName"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                          </Col>
                          {/* <Col md={5} className="">
                            <input
                              id="last_name"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="Last Name"
                              type="text"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col> */}
                          {errors.name && touched.name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.name}
                            </div>
                          )}
                        </div>

                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              Dental Office name
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5 ">
                          {" "}
                          <input
                            id="clinic_name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Smile Dental Center"
                            type="text"
                            value={values.clinic_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.clinic_name && touched.clinic_name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.clinic_name}
                            </div>
                          )}
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              Preferred URL
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mx-5">
                          <Row>
                            <Col md={7} className="">
                              <input
                                id="preferred_url"
                                className="form-input-account mb-1 grey-bg-color "
                                placeholder="Smile"
                                type="text"
                                value={values.preferred_url}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />{" "}
                            </Col>
                            <Col md={5} className="">
                              <input
                                id="last_name"
                                className="form-input-url mb-3 grey-bg-color"
                                placeholder=".toothlens.com"
                                type="text"
                                disabled={true}
                                // value={values.last_name}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                            </Col>
                            {errors.preferred_url && touched.preferred_url && (
                              <div className="text-danger mb-2 ml-2">
                                {errors.preferred_url}
                              </div>
                            )}
                            <div className="text-success mb-2 mx-2">
                              You will be able to use, once your URL and app are
                              enabled.
                            </div>
                          </Row>
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              Phone Number
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="phone_number"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Phone number"
                            type="text"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.phone_number && touched.phone_number && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.phone_number}
                            </div>
                          )}
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">Address</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="address"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="address"
                            type="text"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.address && touched.address && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.address}
                            </div>
                          )}
                        </Col>

                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">City</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="city"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="city"
                            type="text"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.city && touched.city && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.city}
                            </div>
                          )}
                        </Col>

                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">State</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="state"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="State"
                            type="text"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.state && touched.state && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.state}
                            </div>
                          )}
                        </Col>

                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">Country</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="country"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="country"
                            type="text"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.country && touched.country && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.country}
                            </div>
                          )}
                        </Col>

                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              ZipCode/Pincode
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="zip_code"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Zip code/Pin code"
                            type="text"
                            value={values.zip_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.zip_code && touched.zip_code && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.zip_code}
                            </div>
                          )}
                        </Col>

                        <Col className="mx-5 mt-3 mb-5">
                          <Button className="form-btns-office-save-and-update" type="submit">
                            Save and Update
                          </Button>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
            
        }
         {
              snackbar &&  <SnackFire
               open={snackbar}
               onClose={handleclose}
               position="top-right"
               timing={5000}
               type={snacktype}
               message={snackbarmessage}
             />
             }
      </Container>
    );
  }
};

export default AccountDetails;
