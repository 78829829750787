import React,{useEffect} from "react";
import "./landingPage.css";
import Logo from "../../assets/Logo.png";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import {
  rememberMe
} from "../../Services/AuthService";


const LandingPage = () => {

  const navigate = useNavigate();

    useEffect(()=>{
    rememberMe().then(res=>{
    if(res.status == 200){
      const chiefAdmin=JSON.parse(localStorage.getItem("admin")).admin.is_chief_admin;
      const dentist=JSON.parse(localStorage.getItem("admin")).admin.dentist_id
      const isAlignerAdmin = JSON.parse(localStorage.getItem("admin")).admin.is_aligner_admin;
      if (isAlignerAdmin) {
        navigate("/aligner-dashboard")
      } else {
        chiefAdmin?navigate("/dashboard-dental"):dentist?navigate("/dashboard-dentist"):navigate("/dashboard-location")
      }
    }
    }).catch(err=>navigate("/"))},[])


  return (
    <Container fluid className="dashboard-bg ">
      <Col>
        <div className="dashboard-content">
          <div className="dashboard-circle">
            <div className="dashboard-image">
              <img src={Logo} alt="Logo" />
            </div>
          </div>
        </div>

        <div className="dashboard-content">
          <div className="dashboard-text">Toothlens Dental Dashboard</div>
        </div>
        <div className="dashboard-content">
          <Link to="login">
            <button type="submit" className="landing-btn">
              Login
            </button>
          </Link>
        </div>
        <div className="dashboard-content">
          <Link to="signup/64233094abcc9054c50ae0e8">
            <button type="submit" className="landing-btn">
              Sign up
            </button>
          </Link>
        </div>

        <div className="dashboard-content">
          {/* <div className="dashboard-footer">Powered by Toothlens</div> */}
        </div>
      </Col>
    </Container>
  );
};

export default LandingPage;
