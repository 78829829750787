import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assets/navbarLogo.svg";
import dashboard from "../../assets/backToDashboard.svg";
import { Link, useNavigate } from "react-router-dom";
import logout from "../../assets/logout.svg";
import Col from "react-bootstrap/Col";
import MyClinic from "../../assets/MyClinic.svg";
import {FaMailBulk} from "react-icons/fa";
import {FaUserMd} from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";

function NavbarPatientEnquiry() {
  const usa_admin=JSON.parse(localStorage.getItem("admin")).admin.is_usa_type_admin;
  const verified = JSON.parse(localStorage.getItem("flag"))
  const isAlignerAdmin = JSON.parse(localStorage.getItem("admin")).admin.is_aligner_admin

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
          {verified ? <Link
            to="/dashboard-internal"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="d-flex mx-2">
              <img
                alt=""
                src={dashboard}
                //   width="0"
                //   height="30"
                width="35px"
                height="35px"
                className="d-inline-block align-top"
              />
              <div className=" d-flex justify-content-center align-items-center mx-1 text_backtodashboard">
                Back to Dashboard
              </div>
            </div>
          </Link> : 
            usa_admin? <Link
            to={isAlignerAdmin ?  "/aligner-dashboard" :  "/dashboard-dental"}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="d-flex mx-2">
              <img
                alt=""
                src={dashboard}
                //   width="0"
                //   height="30"
                width="35px"
                height="35px"
                className="d-inline-block align-top"
              />
              <div className=" d-flex justify-content-center align-items-center mx-1 text_backtodashboard">
                Back to Dashboard
              </div>
            </div>
          </Link> :
           <Link
           to="/dashboard"
           style={{ textDecoration: "none", color: "inherit" }}
         >
           <div className="d-flex mx-2">
             <img
               alt=""
               src={dashboard}
               //   width="0"
               //   height="30"
               width="35px"
               height="35px"
               className="d-inline-block align-top"
             />
             <div className=" d-flex justify-content-center align-items-center mx-1 text_backtodashboard">
               Back to Dashboard
             </div>
           </div>
         </Link>
          } 


            <div className="navbar_text_dashbord">Office Details</div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <div className="d-flex w-5">
                    <div>
                      {" "}
                      <img
                        alt=""
                        src={logo}
                        //   width="0"
                        //   height="30"
                        width="40px"
                        height="40px"
                      />
                    </div>
                    <div className="navbar_link_text_dashbord">Toothlens</div>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center w-5 h-80 align-items-center flex-grow-1 pe-3">
                  <Col md={12} className="px-3">
                  {verified ? <Link
                      to="/dashboard-internal"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={dashboard}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dashboard Internal
                        </Col>
                      </div>
                    </Link> : usa_admin?<Link
                      to={isAlignerAdmin ?  "/aligner-dashboard" :  "/dashboard-dental"}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={dashboard}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dashboard
                        </Col>
                      </div>
                    </Link> : 
                    <Link
                      to="/dashboard"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={dashboard}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dashboard
                        </Col>
                      </div>
                    </Link> }
                  </Col>

                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/dentist"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <FaUserMd size="40px" color='#0090FF'  />

                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Team Details
                        </Col>
                      </div>
                    </Link>
                  </Col>
             {!isAlignerAdmin && 
                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/location-details"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <FaBuilding size="40px" color='#0090FF'  />

                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Location Details
                        </Col>
                      </div>
                    </Link>
                  </Col>  }

                   <Col md={12} className="px-3 mt-3">
                   <a href="mailto:support@toothlens.com"                  
                        style={{ textDecoration: "none", color: "inherit" }}
>

                    
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center "
                        >
                          {" "}
                          {/* <img
                            alt=""
                            src={MyClinic}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          /> */}
                          {/* <i className="fas fa-envelope bg-primary" ></i> */}
                          {/* <i class="fa-lg fa-envelope" aria-hidden="true"></i> */}
                          <FaMailBulk size="50px" color='#0090FF'  />

                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support d-flex w-5 justify-content-start"
                        >
                        Support@toothlens.com
                        </Col>
                      </div>
                    </a>
                  </Col> 
                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/login"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={logout}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Logout
                        </Col>
                      </div>
                    </Link>
                  </Col>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavbarPatientEnquiry;
