import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import Button from "@mui/material/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {uploadVideoTos3Bucket, updateAlignerUserFlow } from '../../Services/AuthService';
import SnackFire from '../../UI/Snackbar/Snackbar';

const RestartPlan = ({ back, userData, noOfReports, onClose }) => {
    const { _id } = userData
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Update New Aligner")
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const newRestart = {restartsAt: noOfReports , alignerNo: 0}
    
   

    // const restartData = [
    //     {restartsAt : 3, alignerNo:5},
    //     {restartsAt : 8, alignerNo:0},
    // ]


    const updateRestartStages = (array, alignerNo = 0) => {
        const isExist = array.find(each => each.restartsAt == newRestart.restartsAt)
        let updatedArray
        if (!isExist) {
             updatedArray = [...array, newRestart].map((each) => {
                if (each.restartsAt == noOfReports ) {
                    return { ...each,  restartsAt: noOfReports , alignerNo }
                }
                return each
            })
            
        } else {
             updatedArray = array.map((each) => {
                if (each.restartsAt == noOfReports ) {
                    return { ...each,  restartsAt: noOfReports , alignerNo }
                }
                return each
            })

        }

        return updatedArray

    }


    const initialValues = {
        fileOption: "",
        file: "",
        webUrl: "",
        aligner_change_days: 15,
        total_aligners: "",
        compare_aligner: 0,
        next_date_to_take_test: "",
        aligner_restarts_at: userData.aligner_restarts_at ? updateRestartStages([...userData.aligner_restarts_at]) : [newRestart]
    }

    const validationSchema = Yup.object().shape({
        fileOption: Yup.string().required('Please select one of the options.'),
        file: Yup.mixed().when('fileOption', {
            is: 'upload',
            then: Yup.mixed().required('Please upload a file.').test('is-video', 'Invalid file format. Please upload a mp4 file.', (value) =>
                value && ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'].includes(value.type)
            ).test('fileSize', 'Video size is too large, please select a video of below 200 mb', (value) => !value || value.size <= 150 * 1024 * 1024),
        }),
        webUrl: Yup.mixed().when('fileOption', {
            is: 'webUrl',
            then: Yup.string().required('URL is required').url('Invalid URL. Please enter a valid URL.'),
        }),
        next_date_to_take_test: Yup
            .date()
            .required('Next check date is required')
            .test('is-future-or-present', 'Date must be in the present or future', function (value) {
                if (!value) return true; // Allow empty values
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Remove time component for accurate comparison
                return value >= currentDate;
            }),
        aligner_change_days: Yup.number().required("Required aligner change in days"),
        total_aligners: Yup.number().required("Total number of aligner is Required for user"),
        compare_aligner: Yup.number().required("Required aligner change in days"),
    })


    const UploadFileInput = () => {
        const { values, setFieldValue } = useFormikContext();
        return (
            <>
                {values.fileOption === 'upload' && (
                    <div className='my-2 ms-5'>
                        <label htmlFor="file">Select a mp4 file:</label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
                        />
                        <ErrorMessage name="file" component="div" className="text-danger" />
                    </div>
                )
                }

                {values.fileOption === "webUrl" && (
                    <div className='w-75'>
                        <label htmlFor="webUrl" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                                Web URL*
                            </span>
                        </label>
                        <Field type="text" id="webUrl" name="webUrl" className="form-input mb-3 grey-bg-color" placeholder="add your URL here" />
                        <ErrorMessage name="webUrl" component="div" className='text-danger' />
                    </div>
                )
                }
            </>
        );
    };


    const AlignerNumber = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            const alignerNo = values.compare_aligner
            const updatedArray = updateRestartStages(values.aligner_restarts_at, alignerNo)
            setFieldValue('aligner_restarts_at', updatedArray)

        }, [values.compare_aligner])

        return (<Col className='d-flex justify-content-center'>
            <div className='w-75'>
                <label htmlFor="compare_aligner" className="pt-2 font-weight-500 text-dark field-text mb-1">
                    <span className="field-texts  mx-3">
                        Current Aligner(number)
                    </span>
                </label>
                <Field type="number" id="compare_aligner" name="compare_aligner" className="form-input mb-3 grey-bg-color" placeholder="Current Aligner Number" />
                <ErrorMessage name="compare_aligner" component="div" className='text-danger' />
            </div>
        </Col>

        )
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        const { fileOption, file } = values
        setLoader(true)
        setSubmitting(true);
        if (fileOption === "upload" && file) {
            // converting video file to required file format to store in s3Bucket

            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            const formData = new FormData();
            formData.append('video', newFile);
            uploadVideoTos3Bucket({ formData, newFlow: true }).then((res) => {
                if (JSON.stringify(res.data.data) === '{}') {
                    setLoader(false)
                    setsnackbarmessage(res.data.message);
                    settype("error");
                    setsnackbar(true);

                } else {
                    const videoDetails = res.data.data
                    updateAlignerUserFlow({ ...values, ...videoDetails, user_id: _id }).then((res) => {
                        setsnackbarmessage(res.data.message);
                        if (JSON.stringify(res.data.data) === '{}') {
                            settype("error")

                        } else {
                            setsnackbarmessage("User Updated Successfully");
                            settype("success");
                            setSubmitButtonText("User Updated Successfully")
                            setTimeout(() => {
                                onClose();
                            }, 2000);

                        }
                        setLoader(false)
                        setsnackbar(true);
                    }).catch((err) => {
                        setLoader(false)
                        setsnackbarmessage(err.data.message);
                        setsnackbar(true);

                    })
                }
            }).catch((err) => {
                setLoader(false)
                console.log("error")

            })
        } else {
            setLoader(true)
            updateAlignerUserFlow({ ...values, user_id: _id }).then((res) => {
                setsnackbarmessage(res.data.message);
                if (JSON.stringify(res.data.data) === '{}') {
                    settype("error")

                } else {
                    setsnackbarmessage("User Updated Successfully");
                    settype("success");
                    setSubmitButtonText("User Updated Successfully")
                    setTimeout(() => {
                        onClose();
                    }, 2000);
                }
                setLoader(false)
                setsnackbar(true);
            }).catch((err) => {
                setLoader(false)
                setsnackbarmessage(err.data.message);
                setsnackbar(true);

            })

        }



    }

    
    

    return (
        <>

            <div>
                <button className="btn btn-primary ms-5" onClick={() => { back() }}>
                    <ArrowBackIcon />
                </button>
            </div>
            {snackbar && (
                    <SnackFire
                        open={snackbar}
                        onClose={() => { setsnackbar(false) }}
                        position="top-right"
                        timing={4000}
                        type={type}
                        message={snackbarmessage}
                    />
                )}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {
                    ({ isSubmitting }) =>
                    (
                        <Form>
                            <Container>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="total_aligners" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Total number of aligners*
                                                </span>
                                            </label>
                                            <Field type="number" id="total_aligners" name="total_aligners" className="form-input mb-3 grey-bg-color" placeholder="Total number of aligners" />
                                            <ErrorMessage name="total_aligners" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="aligner_change_days" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Aligner change period(days)*
                                                </span>
                                            </label>
                                            <Field type="number" id="aligner_change_days" name="aligner_change_days" className="form-input mb-3 grey-bg-color" placeholder="Number of days" />
                                            <ErrorMessage name="aligner_change_days" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <AlignerNumber />
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="next_date_to_take_test" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Next check Date*
                                                </span>
                                            </label>
                                            <Field type="date" id="next_date_to_take_test" name="next_date_to_take_test" className="form-input mb-3 grey-bg-color" placeholder="Next check date" />
                                            <ErrorMessage name="next_date_to_take_test" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="fileOption" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Select a plan file* :
                                                    </span>
                                                </label>
                                                <Field as="select" id="fileOption" name="fileOption" placeholder="Select a plan file" className="form-input mb-3 grey-bg-color" >
                                                    <option value=""> Select a plan file</option>
                                                    <option value="upload">Upload a mp4 File</option>
                                                    <option value="webUrl">Web URL</option>
                                                </Field>
                                                <ErrorMessage name="fileOption" component="div" className='text-danger' />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className='d-flex justify-content-center'>
                                        <UploadFileInput />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-center mt-4'>
                                            <Button type="submit" className="form-btn mx-3 mt-5" disabled={isSubmitting}>
                                                {loader ? <div className="text-center">
                                                    <div className="spinner-border text-white" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div> : <span> {submitButtonText} </span>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )
                }
            </Formik>

        </>

    )
}


export default RestartPlan