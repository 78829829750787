import React, { useState, useEffect } from "react"
import {
    countOfMarkettingTypeUser,
    countOfAiCheckProspectUser
} from "../../Services/AuthService";
import PieCharts from "../Dashboard_USA/pieChart"
import StackedBarChart from "../Dashboard_USA/stackedBarChart"
import PacmanLoader from "react-spinners/PacmanLoader";


const Analytics = (props) => {
    const {app} = props
    const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(true)

    const [pieChartData, setPieChartData] = useState()
    const [barChartData, setBarChartData] = useState()

    useEffect(() => {
        setIsAnalyticsLoading(true)
        countOfAiCheckProspectUser({ app })
            .then(res => {
                setPieChartData(res.data.data)
                countOfMarkettingTypeUser({ app })
                    .then(res => {
                        setBarChartData(res.data.data)
                        setIsAnalyticsLoading(false)
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => {
                console.log(err)
            })
    }, [app]);
   
    const Loader = () => {
        return (
            <div className="mt-5 mx-5">
            <div className=" d-flex align-items-center justify-content-center">
              <PacmanLoader color={"#0090FF"}  size={150} />
              <span className="mt-3 navbar_text_dashbord">Loading...</span>
            </div>
          </div>
        )

    }

    const GraphicalPresentation = () => {
        return (
            <div className="row mt-3">
            <div className="col-12 col-xl-5">
                <div className="d-flex justify-content-center align-items-center w-5   pt-3 font-32">
                    User Details
                </div>
                <div className="d-flex justify-content-center align-items-center w-5 m-3 mt-2">
                    <PieCharts data={pieChartData} />

                </div>
            </div>
            <div className="col-12 col-xl-7">
                <div className="d-flex justify-content-center align-items-center w-5   pt-4 font-32">
                    Advertising URL/Office URL
                </div>
                <div className="d-flex justify-content-center align-items-center w-5 mt-5">
                    <StackedBarChart data={barChartData} />
                </div>
            </div>
        </div>
        )
    }

    return (
        isAnalyticsLoading ? <Loader /> : <GraphicalPresentation />
    )

}

export default Analytics