import { Link } from 'react-router-dom'

const NotFound = () => (
    <div className="container">
        <div className='row'>
            <div className="col-12 d-flex flex-column align-items-center justify-content-end ">
                <img
                    src="https://res.cloudinary.com/dh38irai9/image/upload/v1675059254/Group_7484_eujdmo.png"
                    alt="not found"
                    className="not-found-image m-3"
                />
                <h1 className=" m-2">Page Not Found</h1>
                <p className=" m-2">
                    We are sorry, the page you requested could not be found, Please go back to
                    the dashboard.
                </p>
                <Link to="/dashboard-dental" className=" m-3" >
                    <button type="button" className="btn btn-primary rounded">
                        Go Back to Dashboard
                    </button>
                </Link>
            </div>
        </div>
    </div>

)

export default NotFound