import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import "./accountDetails.css";
import Button from "@mui/material/Button";
import camera from "../../assets/camera.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
//   clinicPic,
//   clinicDetails,
//   updateDentistNameFromOfficeDetails,
//   getLatestClinicDetails,
//   getBinaryDataOfClinicImage,
  getClinicDetailsUsingAdminMail,
  getAdminDetailsByMail
} from "../../Services/AuthDeploymentService";
import ImagePreview from "./imagePreview";
import { useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import SnackFire from "../../UI/Snackbar/Snackbar";
// import { Link, useNavigate } from "react-router-dom";

const AdminDeployment = () => {
  const navigate = useNavigate();
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const [clinicDetailsData, setClinicDetailsData] = useState();
  const [loading, setloading] = useState(true);
  const [imgs, setimgs] = useState();
  const [photo, setphoto] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snacktype, setsnacktype] = useState("error");
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const handleclose = () => {
    setsnackbar(false);
  };

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              {/* <img
              alt=""
              src={logo}
              width="40px"
              height="40px"
              //   width="0"
              //   height="30"
              className="d-inline-block align-top"
            />{" "} */}
            </div>

            <div className="navbar_text">Deployment Details</div>
          </div>
        </Container>
      </Navbar>

      {
        <Formik
          validate={(values) => {
            let errors = {};

            if (!values.email) {
              errors.email= "Mail Id is required";
            }
           
            return errors;
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(" Email is Required")
          })}
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
           getAdminDetailsByMail(values).then((res)=>{
            setsnackbar(true)
            setsnackbarmessage(res.data.message)
            setsnacktype("success");
            navigate("/deployment", { state: res.data.data});

           }).catch(err=>
            {
              setsnackbar(true)
              setsnackbarmessage(err.data.message)
              setsnacktype("error");
            console.log(err)
           })
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                           {
              snackbar &&  <SnackFire
               open={snackbar}
               onClose={handleclose}
               position="top-right"
               timing={5000}
               type={snacktype}
               message={snackbarmessage}
             />
             }
                <Row className=" d-flex w-5 justify-content-center office-details-container">
              

                  <Col md={9} className="overflow-auto office-form-container">
                    {/* ------------------------------------------admin schema----------------------------- */}
                    <Col md={8} className="">
                      <div className="text-success d-flex w-5 justify-content-center mt-5 mx-2">
                        ADMIN SCHEMA RELATED DETAILS
                      </div>
                      <Row className="mx-5">
                        <Col md={12} className="">
                          <span className="field-texts mx-2">
                            CHIEF ADMIN MAIL (This should be known while filling
                            this form to update the app id in admin schema and
                            using this admin_id, update the "app id" in clinics
                            schema)
                          </span>{" "}
                        </Col>
                      </Row>

                      <div className=" d-flex mx-5 mt-2">
                        <Col md={12} className="mx-3">
                          <input
                            id="email"
                            className="form-input-account mb-3 grey-bg-color"
                            placeholder="chief admin mail id"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />{" "}
                        </Col>
                      
                        {errors.email && touched.email && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </Col>
                    {/* ----------------------APP SCHEMA------------ */}

                

                    <Col className="mx-5 mt-3 mb-5">
                      <Button
                        className="form-btns-office-save-and-update"
                        type="submit"
                      >
                        check for admin mail
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default AdminDeployment;
