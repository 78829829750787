import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  postReportViewStatus,
  getPatientDetailsById,
  getPatientDetailsByIdInitially,
  ReportsBasedOnUserId,
  updateReportById,
  getCavityTartarContents,
  questionereBasedOnUserId,
  updateReportRemarksBasedOnReportId,
  doctorListBasedOnCompany,
  sendToDentistBasedOnId,
  getUserDetails,
  sendToDentistListBasedOnUserId,
  postUserViewedStatus,
  getGraphDetialsForAi,
} from "../../Services/AuthService";
import Container from "react-bootstrap/Container";
import NavbarPatientEnquiry from "../PatientReportDental/navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import profile from "../../assets/userIcon.svg";
import SliderCavityTartarCard from "../PatientReportDental/slider";
import {
  CAVITY_IMG_URL,
  TARTAR_IMG_URL,
  REPORT_IMG_URL,
} from "../../Services/BaseUrl";
import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import riskAnalysisimg from "../../assets/riskAnalysisImg.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";
import Update from "../../assets/update.svg";
import { Link, useNavigate } from "react-router-dom";
import Example from "../practice/practice";
import TartarPractice from "../practice/tartarPractice";
import SnackFire from "../../UI/Snackbar/Snackbar";
import ActiveImage from "./activeImage";


const PatientReport3D = () => {
  localStorage.setItem("consultingUsersTabNav", true);
  localStorage.setItem("propspectTabNav", false);
  localStorage.setItem("analyticsTabNav", false);
  const verified = JSON.parse(localStorage.getItem("flag"))
  const navigate = useNavigate();
  let { user_id } = useParams();
  const [usereditpopup, setusereditpopup] = useState(false);
  const [sendtodentistid, setsendtodentistid] = useState();
  const location = useLocation();
  const [cavityContents, setCavityContents] = useState("");
  const [tartarContents, setTartarContents] = useState("");
  const [overallReportContents, setOverallReportContents] = useState("");
  const [userId, setUserId] = useState("");
  const [reportImages, setReportImages] = useState([]);
  const [reportId, setReportId] = useState();
  const [loading, setloading] = useState(true);
  const [patientdata, setpatientdata] = useState();
  const [status, setstatus] = useState("");
  const [doctor, setdoctor] = useState("");
  const [reportstatusId, setreportstatusId] = useState();
  const [dentalAnalysis, setdentalAnalysis] = useState(true);
  const [riskAnalysis, setriskAnalysis] = useState(false);
  const [questionere, setquestioneredata] = useState();
  const [sendtodentistdata, setsendtodentistdata] = useState("");
  const [temp, settemp] = useState("");
  const [prospectTab, setProspectTab] = useState(false);
  const [doctorList, setdoctorList] = useState("");
  const [userData, setuserData] = useState();
  const [reportList, setReportList] = useState();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [kids, setKids] = useState(false);
  const [cavityGraph, setCavityGraph] = useState();
  const [tartarGraph, setTartarGraph] = useState();
  const [quickScan, setQuickScan] = useState(false);
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const [annotation, setAnnotation] = useState(JSON.parse(localStorage.getItem("admin")).admin.app === "63808e24a3be9e7f00c5c3b3"?true:JSON.parse(localStorage.getItem("admin")).admin.app === "6475e4410a2d20e8cc17f626"?true:JSON.parse(localStorage.getItem("admin")).admin.app === "6451dad9455c36ab4c36df96"?true:false
  );
    const [annotationImage,setAnnotationImage]=useState();
  const [annotationActiveImage,setAnnotationActiveImage]=useState()
  const [showQuickScanAppAi, setShowQuickScanAppAi] = useState(true);
  const [showQuestionereTab, setShowQuestionereTab] = useState(false);
  const [annotationStatus,setAnnotationStatus]=useState({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})
  const [reports, setReports] = useState(false)
  const [show, setShow] = useState(false);

  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  // app=="63d0f71290a9cdf5c42bdc8f"?setShowQuickScanAppAi(false):setShowQuickScanAppAi(true)
  // const [usereditpopup,setusereditpopup]=useState(false);
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  // const [popup,setpopup]=useState(id)
  const onCloseUserPopup = () => {
    setusereditpopup(false);
    call();
  };
  const handleclose = () => {
    setsnackbar(false);
  };
  const handleReportRemark = (event) => {
    settemp(event.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const call = async () => {
    setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})
    if (!verified) {
      await postUserViewedStatus({ user_id });

    } 
    await getUserDetails({ user_id })
      .then((res) => {
        if (res.status === 200) {
          setuserData(res.data.data.userDetails[0]);
        }
      })
      .catch((err) => {
        return err;
      });
    await sendToDentistListBasedOnUserId({ app, user_id })
      .then(async (res) => {
        setReportList(res.data.data.sendToDentistBasedOnUserId);
        setsendtodentistid(res.data.data.sendToDentistBasedOnUserId[0]._id);
        if (!verified) {
          await postReportViewStatus({
          _id: res.data.data.sendToDentistBasedOnUserId[0]._id,
          });
       }
        
        await getPatientDetailsByIdInitially({
          id: res.data.data.sendToDentistBasedOnUserId[0].report,
        }).then(async (res) => {
          const resObj = res.data.data[0];
          setAnnotationActiveImage(res.data.data[0].front_teeth)
          setAnnotationImage(res.data.data[0])
          resObj.is_quick_scan
            ? setQuickScan(resObj.is_quick_scan)
            : setQuickScan(false);
          setReportId(resObj._id);
          setUserId(resObj.user_id);
          const reportScore = Object.keys(resObj.report_score[0]);
          reportScore.splice(reportScore.length - 1, 1);
          setReportImages(reportScore);
          const cavityScore = resObj.report_score[0].overall_score[1];
          const tartarScore = resObj.report_score[0].overall_score[0];

          await getGraphDetialsForAi(resObj._id)
            .then((res) => {
              setCavityGraph(res.data.data[0]);
              setTartarGraph(res.data.data[1]);
            })
            .catch((err) => console.log(err));
          await getCavityTartarContents(resObj._id, cavityScore, tartarScore)
            .then((res1) => {
              if (
                res1 &&
                res1?.data?.code === 200 &&
                res1?.data?.data?.cavityContent &&
                res1?.data?.data?.tartarContent
              ) {
                setCavityContents(res1.data.data.cavityContent);
                setTartarContents(res1.data.data.tartarContent);
                setOverallReportContents(res1.data.data);
              }
            })
            .catch((err) => {});
        });
        await questionereBasedOnUserId({ user_id: user_id })
          .then((res) => {
            setquestioneredata(res.data.data.patientDetails[0]);
            res.data.data.patientDetails[0].questions.length == 5
              ? setKids(true)
              : setKids(false);
            setShowQuestionereTab(true);
          })
          .catch((err) => setShowQuestionereTab(false));
        await sendToDentistBasedOnId({
          sendtodentistid: res.data.data.sendToDentistBasedOnUserId[0]._id,
        })
          .then((res) => {
            setsendtodentistdata(res.data.data.sendtodentistdetails[0]);
            settemp(res.data.data.sendtodentistdetails[0].report_remarks);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        navigate("/");
      });
  };

  //   await ReportsBasedOnUserId({ user_id: resObj.user_id, sendtodentist })
  //       .then((res) => {
  //         setpatientdata(res.data.data.patientDetails);
  //         // setloading(false);
  //       })
  //       .catch((err) => console.log(err));
  //     await sendToDentistBasedOnId({ sendtodentistid: sendtodentist })
  //       .then((res) => {
  //         setsendtodentistdata(res.data.data.sendtodentistdetails[0]);

  //         settemp(res.data.data.sendtodentistdetails[0].report_remarks);
  //       })
  //       .catch((err) => console.log(err));

  //   })
  //   .catch((err) => console.log(err));
  useEffect(() => {
    call();
  }, []);
  const dateFormat = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  if (loading) {
    return (
      <div>
        <NavbarPatientEnquiry />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container fluid>
        <NavbarPatientEnquiry />
          <div>
              <button type="btn" className="btn btn-primary" onClick={handleShow}>Open Reports</button>
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>User Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Col md={12} className="mt-1 ">
            <div className="patient_filter mt-2">
              <Row>
                <Col md={10} className="px-5 pt-3">
                  <div className="patient_name">{userData.first_name}</div>
                  <div className="patient_email mt-2">{userData.email}</div>

                  <div className="patient_id mt-2">{userData.phone_number}</div>
                  <div className="patient_gender mt-2">
                    {userData.gender}, {calculateAge(userData.dob)}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="d-flex w-5  justify-content-end mt-3 mx-3">
                    <Button
                      onClick={() => {
                        setusereditpopup(true);
                        handleClose()
                      }}
                    >
                      <img
                        alt=""
                        src={Update}
                        width="60px"
                        height="60px"
                        className="m-2"
                      />
                    </Button>
                  </div>
                </Col>

                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-2"></Col>
                </Col>
                <div className="dental_analysis_text d-flex w-5 justify-content-center mt-3 pt-1">
                  Chief Complaint
                </div>
                <div className="mx-3 mt-3">
                  {userData.user_looking_for.map((note) => {
                    if (note.length != 0) {
                      return <li>{note}</li>;
                    }
                  })}
                </div>
                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </Col>

                <Col md={12} className="">
                  <span className="patient_status m-2 mx-4 "> Reports</span>

                  <Container className=" ">
                    {reportList.map((value, index) => {
                      return (
                        <Row key={index}>
                          <Button
                            onClick={async () => {
                              setsendtodentistid(value._id);
                              setReportId(value.report);
                              handleClose()
                              await postReportViewStatus({ _id: value._id });

                              setreportstatusId(value.report);

                              await getPatientDetailsById({
                                id: value.report,
                              })
                                .then(async (res) => {
                                  const resObj = res.data.data[0];
                                  setAnnotationActiveImage(res.data.data[0].front_teeth)
                                  setAnnotationImage(res.data.data[0])
                                  setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})
                                  resObj.is_quick_scan
                                    ? setQuickScan(resObj.is_quick_scan)
                                    : setQuickScan(false);
                                  setReportId(resObj._id);
                                  setUserId(resObj.user_id);
                                  const reportScore = Object.keys(
                                    resObj.report_score[0]
                                  );
                                  reportScore.splice(reportScore.length - 1, 1);
                                  setReportImages(reportScore);
                                  const cavityScore =
                                    resObj.report_score[0].overall_score[1];
                                  const tartarScore =
                                    resObj.report_score[0].overall_score[0];
                                  await getGraphDetialsForAi(resObj._id)
                                    .then((res) => {
                                      setCavityGraph(res.data.data[0]);
                                      setTartarGraph(res.data.data[1]);
                                    })
                                    .catch((err) => console.log(err));
                                  await getCavityTartarContents(
                                    resObj._id,
                                    cavityScore,
                                    tartarScore
                                  )
                                    .then((res1) => {
                                      if (
                                        res1 &&
                                        res1?.data?.code === 200 &&
                                        res1?.data?.data?.cavityContent &&
                                        res1?.data?.data?.tartarContent
                                      ) {
                                        setCavityContents(
                                          res1.data.data.cavityContent
                                        );
                                        setTartarContents(
                                          res1.data.data.tartarContent
                                        );
                                        setOverallReportContents(
                                          res1.data.data
                                        );
                                      }
                                    })
                                    .catch((err) => {});
                                })
                                .catch((err) => navigate("/"));

                              await sendToDentistBasedOnId({
                                sendtodentistid: value._id,
                              })
                                .then((res) => {
                                  settemp(
                                    res.data.data.sendtodentistdetails[0]
                                      .report_remarks
                                  );
                                  setsendtodentistdata(
                                    res.data.data.sendtodentistdetails[0]
                                  );
                                })
                                .catch((err) => navigate("/"));
                            }}
                          >
                            <Col
                              className={
                                value.is_report_viewed
                                  ? "d-flex justify-content-start test_text_style active_report mx-4"
                                  : "d-flex justify-content-start test_text_style mx-4"
                              }
                            >
                              <Row>
                                <Col md={3} className="">
                                  {" "}
                                  {value._id == sendtodentistid && (
                                    <img
                                      src={dentalAnalysisimg}
                                      width="25px"
                                      height="25px"
                                      alt="filter"
                                    ></img>
                                  )}{" "}
                                </Col>
                                <Col md={4} className="">
                                   {/* {dateFormat(value.createdAt)}  */}
                                   <span className="text-nowrap" > Week {reportList.length - (index + 1)} </span>
                                </Col>
                              </Row>
                            </Col>
                          </Button>
                        </Row>
                      );
                    })}
                  </Container>
                </Col>

                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </Col>
                <div>
                  <div className="dental_analysis_text d-flex w-5 justify-content-center mt-4 ">
                    Remarks
                  </div>
                  <div>
                    <textarea
                      className="form-control px-3 py-2 form-input-remarks mt-2 mb-3 grey-bg-color"
                      type="text"
                      value={temp}
                      onChange={handleReportRemark}
                    >
                      {" "}
                      {temp}{" "}
                    </textarea>
                    {/* <input
                      className="form-input-remarks mt-2 mb-3 grey-bg-color"
                      type="text"
                      value={temp}
                      onChange={handleReportRemark}
                    /> */}
                  </div>
                  <div className="d-flex w-5 justify-content-center">
                    <Col md={12} className="my-3">
                      <Button
                        className="form-btn-remarks"
                        onClick={() => {
                          updateReportRemarksBasedOnReportId({
                            _id: sendtodentistid,
                            reportRemarks: temp,
                          })
                            .then((res) => {
                              setsnackbarmessage(res.data.message);
                              JSON.stringify(res.data.data) === "{}"
                                ? settype("error")
                                : settype("success");
                              setsnackbar(true);
                            })
                            .catch((err) => {
                              setsnackbarmessage(err.data.message);
                              setsnackbar(true);
                            });
                        }}
                      >
                        SAVE AND UPDATE
                      </Button>
                    </Col>
                  </div>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </div>
              </Row>
            </div>
          </Col>

                </Offcanvas.Body>
              </Offcanvas>
          </div>
        {snackbar && (
          <SnackFire
            open={snackbar}
            onClose={handleclose}
            position="top-right"
            timing={5000}
            type={type}
            message={snackbarmessage}
          />
        )}
        <Row className="">

          <Col md={reports ? 10 : 12} className="mt-2">
           {
            !annotation && 
            <div className="d-flex   analysis-background">
              <Button
                onClick={() => {
                  setdentalAnalysis(true);
                  setriskAnalysis(false);
                }}
              >
                <div className="d-flex my-1 mx-5 align-items-center ">
                  {dentalAnalysis && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      dentalAnalysis
                        ? "dental_analysis_text px-2"
                        : "dental_analysis_text_lite px-2"
                    }
                  >
                    Dental Analysis
                  </div>
                </div>
              </Button>
             {
              showQuestionereTab &&  <Button
              onClick={() => {
                setdentalAnalysis(false);
                setriskAnalysis(true);
              }}
            >
              <div className="d-flex my-1 mx-5 align-items-center ">
                {riskAnalysis && (
                  <img
                    src={riskAnalysisimg}
                    width="25px"
                    height="25px"
                    alt="filter"
                  ></img>
                )}
                <div
                  className={
                    riskAnalysis
                      ? "  risk_analysis_text px-2"
                      : " dental_analysis_text_lite px-2"
                  }
                >
                  Questionnaire
                </div>
              </div>
            </Button>
             }
            </div>
           } 
             {
            annotation && 
            <div className="d-flex   analysis-background">
              <Button
                onClick={() => {
                  setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})

                    setAnnotationActiveImage(annotationImage.front_teeth)
                }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.front_teeth && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.front_teeth
                          ? "dental_analysis_text px-2"
                          : "dental_analysis_text_lite px-2"
                      }
                    >
                      Front Teeth
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:true,lower_jaw:false,right_side:false,left_side:false})

                    setAnnotationActiveImage(annotationImage.upper_jaw)

                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.upper_jaw && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.upper_jaw? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Upper Jaw
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:true,right_side:false,left_side:false})
                    setAnnotationActiveImage(annotationImage.lower_jaw)

                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.lower_jaw && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.lower_jaw ? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Lower Jaw
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:false,right_side:true,left_side:false})

                    setAnnotationActiveImage(annotationImage.right_side)
                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.right_side && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.right_side
                          ? "  dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Right side
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:false,right_side:false,left_side:true})

                    setAnnotationActiveImage(annotationImage.left_side)
                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.left_side && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.left_side ? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Left side
                    </div>
                  </div>
                </Button>
              </div>
            }
            {usereditpopup && (
              <EditUserDetailsPopUp
                open={usereditpopup}
                onClose={onCloseUserPopup}
                userId={user_id}
              />
            )}
            {dentalAnalysis && (
              <div>
                <Row className="">
               {
                !annotation && <Col md={8} className="px-5">
                {reportImages.length > 0 && (
                  <>
                    <div className="shadow bg-white m-3  border-radius-15px">
                      <SliderCavityTartarCard
                        reportId={reportId}
                        imgUrl={!quickScan?TARTAR_IMG_URL:REPORT_IMG_URL}
                        reportScoreImg={reportImages}
                        tartarcontents={tartarContents}
                        cavitycontents={cavityContents}
                      />
                    </div>
                  </>
                )}
              </Col>
               }   
                {
                  annotation && <Row fluid className="w-100 ">
                      <Col md={12}>
                        <ActiveImage annotation={annotationActiveImage} />
                       </Col>
                  
                  </Row>
                } 
               
                  {!annotation && (
                    <Col md={4}>
                      {!quickScan && (
                        <div>
                          <div className="dental_analysis_text d-flex w-5 justify-content-center mt-3 pt-2">
                            AI Generated Results
                          </div>
                          <div className=" d-flex w-5 justify-content-center mt-2 ">
                            (Results may vary based on photo quality)
                          </div>
                          <div className="dental_analysis_text d-flex w-5 justify-content-start mt-3 pt-2">
                            Cavity or Potential Cavity
                          </div>
                          <div>
                            {cavityGraph.map((res) => {
                              return (
                                <Example
                                  name={res.name}
                                  cavity={res.cavity}
                                  shade="json"
                                />
                              );
                            })}
                          </div>
                          <Col md={12} className=" line-horizontal mt-1"></Col>

                          <div className="dental_analysis_text d-flex w-5 justify-content-start mt-3 pt-2">
                            Stains or Tartar
                          </div>
                          <div>
                            {tartarGraph.map((res) => {
                              return (
                                <Example
                                  name={res.name}
                                  cavity={res.tartar}
                                  shade="tartar"
                                />
                              );
                            })}
                          </div>
                          <Col md={12} className=" line-horizontal mt-1"></Col>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            )}

            {riskAnalysis && userData.gender == "male" && !kids && (
              <div>
                <div className=" dental_risk_score_background mt-2 py-5">
                  <Col md={12}>
                    <Row>
                      <Col md={4} className="px-5 border-right">
                        <div>
                          1. {questionere.questions[0].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[0].options.map((fil) => {
                                if (fil.value == questionere.questions[0].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          2. {questionere.questions[2].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[2].options.map((fil) => {
                                if (fil.value == questionere.questions[2].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          3. {questionere.questions[1].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[1].options.map((fil) => {
                                if (fil.value == questionere.questions[1].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          4. {questionere.questions[3].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[3].options.map((fil) => {
                                if (fil.value == questionere.questions[3].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          5. {questionere.questions[4].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[4].options.map((fil) => {
                                if (fil.value == questionere.questions[4].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          6. {questionere.questions[6].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[6].options.map((fil) => {
                                if (fil.value == questionere.questions[6].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          7. {questionere.questions[5].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[5].options.map((fil) => {
                                if (fil.value == questionere.questions[5].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          8. {questionere.questions[7].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[7].options.map((fil) => {
                                if (fil.value == questionere.questions[7].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          9. {questionere.questions[8].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>{questionere.questions[8].answer}</div>
                          </div>
                        </div>

                        <Col md={12} className=" line-horizontal mt-5"></Col>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            )}
            {riskAnalysis && userData.gender == "female" && !kids && (
              <div>
                <div className=" dental_risk_score_background mt-2 py-5">
                  <Col md={12}>
                    <Row>
                      <Col md={4} className="px-5 border-right">
                        <div>
                          1. {questionere.questions[0].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[0].options.map((fil) => {
                                if (fil.value == questionere.questions[0].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-3">
                          2. {questionere.questions[2].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[2].options.map((fil) => {
                                if (fil.value == questionere.questions[2].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-3">
                          3. {questionere.questions[1].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[1].options.map((fil) => {
                                if (fil.value == questionere.questions[1].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          4. {questionere.questions[3].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[3].options.map((fil) => {
                                if (fil.value == questionere.questions[3].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-3">
                          5. {questionere.questions[9].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>{questionere.questions[9].answer}</div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-3">
                          6. {questionere.questions[6].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[6].options.map((fil) => {
                                if (fil.value == questionere.questions[6].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          7. {questionere.questions[5].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[5].options.map((fil) => {
                                if (fil.value == questionere.questions[5].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-3">
                          8. {questionere.questions[7].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[7].options.map((fil) => {
                                if (fil.value == questionere.questions[7].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-3">
                          9. {questionere.questions[4].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[4].options.map((fil) => {
                                if (fil.value == questionere.questions[4].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-4"></Col>
                        </div>
                        <div className="mt-1">
                          10. {questionere.questions[8].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[8].options.map((fil) => {
                                if (fil.value == questionere.questions[8].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-4"></Col>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            )}

            {riskAnalysis && kids && (
              <div>
                <div className=" dental_risk_score_background mt-2 py-5">
                  <Col md={12}>
                    <Row>
                      <Col md={4} className="px-5 border-right">
                        <div className="mt-3">
                          1. {questionere.questions[0].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[0].score == "2"
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-3">
                          4. {questionere.questions[3].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[3].options.map((fil) => {
                                if (fil.value == questionere.questions[3].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div className="mt-3">
                          2. {questionere.questions[2].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[2].options.map((fil) => {
                                if (fil.value == questionere.questions[2].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-3">
                          5. {questionere.questions[1].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[1].question ==
                              "Does your child take gummy vitamins?"
                                ? questionere.questions[1].score == "1"
                                  ? "No"
                                  : "Yes"
                                : questionere.questions[1].answer}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>
                      <Col md={4} className="px-5 border-right">
                        <div className="mt-3">
                          3. {questionere.questions[4].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[4].score == "1"
                                ? "No"
                                : "Yes"}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
};

export default PatientReport3D;
