import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import profile from "../../assets/userIcon.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import {
  getProspectCount,
  getProspectUserLists,
  getProspectUserListsForCsv,
} from "../../Services/AuthService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";
import moment from "moment";
import Update from "../../assets/update.svg";
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import { FaEnvelopeOpenText } from "react-icons/fa";
import MailingProspectUser from "./mailingProspectUser";
const ProspectUsers = ({ callUsersTab, prospect,analytics}) => {
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const navigate = useNavigate();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [change, setChange] = useState(true);
  const [userEditPopUp, setUserEditPopup] = useState(false);
  const [prospectMailPopUp, setProspectMailPopup] = useState(false);
  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(0);
  const [csvdata, setcsvdata] = useState("");
  const [data, setData] = useState("");
  const [userId, setUserId] = useState();
  const [currentpage, setcurrentpage] = useState(0);
  const [totalpage, settotalpage] = useState(0);
  const [sorting, setsorting] = useState("");
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");

  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setChange(!change);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setChange(!change);
  };
  const onCloseUserPopup = () => {
    setUserEditPopup(false);
    callChange();
  };
  const onCloseProspectUserPopup = () => {
    setProspectMailPopup(false);
    // callChange();
  };

  const callChange = async () => {
    let object = {
      currentpage,
      app,
      sorting,
    };
    await getProspectCount(object)
      .then((res) => {
        setcount(res.data?.data);
        settotalpage(Math.ceil(res.data.data[0]?.prospectusers / 8));
      })
      .catch((err) =>{ 
        console.log(err)
        // navigate("/")
    });

    await getProspectUserLists(object)
      .then((res) => {
        setData(res.data.length!=0?res.data?.data[0]:"");
      })
      .catch((err) =>{
        console.log(err)
        // navigate("/")
      } );

    await getProspectUserListsForCsv(object)
      .then((res) => {
        if (res.status === 200) {
          setcsvdata(res.data?.data?.prospectuser);
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err)

        // setloading(false)
        return err;
      });
  };
  useEffect(() => {
    callChange();
  }, [!change]);

  const handlesorting = (e) => {
    setsorting(e.target.value);
    setChange(!change);
  };
  // ----------------------------------CSV headers for prospect users---------------------

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_number" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    // {label:"Registered Date",key:"createdAt"}
  ];
  const csvReport = {
    data: csvdata,
    headers: headers,
    filename: "Prospect_Users.csv",
  };

  if (loading) {
    return (
      <div>
        {" "}
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <div>
        <Row>
          <Col className="my-1 " md={3}>
            <div className="filter m-2 ">
              <div className="pt-2 font-doctor mx-2">SUMMARY</div>

              <div className="d-flex  w-5 justify-content-start">
                {/* <div className="summary-box mt-2 mr-1 summary-text-prospect px-3 py-2">
                    <span>Total Users</span>{" "}
                    <span className="font-60">{count[1].users}</span>
                  </div> */}

                <div className="summary-box mt-2  m-1 summary-text-prospect  px-2 py-2">
                  <span>Total Prospects</span>{" "}
                  <span className="font-32">{count[0].prospectusers}</span>
                </div>

                {/* <div className="summary-box mt-2 summary-text-prospect px-2 py-2">
                    existing Users
                    <span className="font-12">
                      {" "}
                      {count[0].unProspectUserdata.Unprospectusers}
                    </span>
                  </div> */}
              </div>
            </div>
          </Col>

          {/* -------------------------------Prospects body display------------------------ */}

          <Col className="  my-2 " md={9}>
            <div className="lists m-2">
              <div className="d-flex justify-content-between  w-5 ">
                <div>
                
                  <Button
                    onClick={() => {
                      callUsersTab();
                    }}
                  >
                    <div className="unselected_report_text_lite">
                      {" "}
                      Shared Users
                    </div>
                  </Button>

                  <Button
                    className="mx-2"
                    onClick={() => {
                      prospect();
                    }}
                  >
                    <div className="d-flex align-items-center w-5 h-5">
                      <div className="d-flex align-items-center w-5 h-5">
                        <div>
                          <img
                            src={dentalAnalysisimg}
                            width="25px"
                            height="25px"
                            alt="filter"
                          ></img>
                        </div>
                        <div className="shared_report_text mx-1">Prospects</div>
                      </div>
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      analytics();
                    }}
                  >
                    <div className="unselected_report_text_lite">
                      {" "}
                      Analytics
                    </div>
                  </Button>
                </div>
                <div className="d-flex flex-row-reverse p-1 ">
                  <Button
                    disabled={
                      currentpage ==
                      (totalpage == 0 ? currentpage : totalpage - 1)
                    }
                    onClick={handlenextpage}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                  <Button
                    disabled={currentpage == 0}
                    onClick={handlepreviouspage}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                  <div className="p-1 ">
                    {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}-
                    {currentpage == totalpage - 1
                      ? `${count[0].prospectusers}`
                      : (currentpage + 1) * 8}{" "}
                    <span className="mx-1">of</span> {count[0].prospectusers}
                  </div>

                  <select
                    className="form-btns-dashboard text-sans-bold mx-3"
                    value={sorting}
                    onChange={handlesorting}
                  >
                    <option value="" className="text-sans-bold ">
                      Sort By{" "}
                    </option>
                    <option value="Newest on top">Newest on top </option>
                    <option value="Oldest on top">Oldest on top</option>
                    <option value="First Name">First Name (A-Z)</option>
                    <option value="First Name Des">First Name (Z-A)</option>
                  </select>

                  <CSVLink {...csvReport}>
                    <Button>
                      <FaDownload size="22px" color="#0090FF" />
                    </Button>
                  </CSVLink>
                </div>
              </div>

              {
                data.length!=0?
              data.map((res) => {
                return (
                  <Row className={"patients-row m-1 mx-3"}>
                    <div className=" d-flex width-100 ">
                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={3}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-1">Name</div>
                                <div className="text-sans mx-1">Email</div>
                              </Col>

                              <Col
                                md={9}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ellipsis">
                                  {res.first_name} {res.last_name}
                                </div>
                                <div className="text-sans-bold ellipsis">
                                  {res.email}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                        {/* </Link> */}
                      </Col>
                      <Col md={2} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={5}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-1">Ph.no</div>
                                <div className="text-sans mx-1">Age</div>
                              </Col>

                              <Col
                                md={7}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ">
                                  {" "}
                                  {res.phone_number}
                                </div>
                                <div className="text-sans-bold">
                                  {calculateAge(res.dob)}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3"> Reg.date </div>

                                <div className="text-sans mx-3">Gender </div>
                              </Col>

                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold">
                                  {moment(res.createdAt).format("MM/DD/YYYY")}
                                </div>

                                <div className="text-sans-bold ellipsis_state">
                                  {res.gender}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                        {/* </Link> */}
                      </Col>

                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={12} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3 ellipsis_state">
                                  State
                                </div>

                                <div className="text-sans mx-3 ellipsis_state">
                                  Country
                                </div>
                              </Col>

                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ellipsis_state">
                                  {res.state}
                                </div>

                                <div className="text-sans-bold ellipsis_state">
                                  {res.country}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>

                      <Col md={1}>
                        <Row>
                          <Col
                            md={6}
                            // className="d-flex height-100  align-items-center  bg-primary justify-content-start"
                          >
                            <div className="d-flex height-100  align-items-center   justify-content-start">
                              <Button
                                onClick={() => {
                                  setUserId(res._id);
                                  setUserEditPopup(true);
                                }}
                              >
                                {" "}
                                <img
                                  src={Update}
                                  alt="Delete"
                                  style={{ cursor: "pointer" }}
                                  height="70px"
                                  width="50px"
                                />
                              </Button>
                            </div>
                          </Col>
                          <Col
                            md={6}
                            className=" d-flex w-5 justify-content-center"
                          >
                            <Button
                              onClick={() => {
                                setUserId(res._id);
                                setProspectMailPopup(true);
                                setUserMail(res.email)
                                setUserName(res.first_name +" "+ res.last_name)
                              }}
                            >
                              {" "}
                              <div>
                                <FaEnvelopeOpenText
                                  size="28px"
                                  color="#0090FF"
                                />
                              </div>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </div>
                  </Row>
                );
              }):""
              }
            </div>
          </Col>
        </Row>

        {userEditPopUp && (
          <EditUserDetailsPopUp onClose={onCloseUserPopup} userId={userId} />
        )}
          {prospectMailPopUp && ( 
          <MailingProspectUser onClose={onCloseProspectUserPopup} userId={userId} userMail={userMail} userName={userName}/>
        )}
      </div>
    );
  }
};

export default ProspectUsers;
