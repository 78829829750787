import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import moment from "moment";
import * as Yup from 'yup';
import close from "../../assets/Close.svg";
import { FaUserPlus } from "react-icons/fa";
import Button from "@mui/material/Button";
import { signUpNewUser, uploadVideoTos3Bucket } from '../../Services/AuthService';
import SnackFire from '../../UI/Snackbar/Snackbar';
import { doctorListBasedOnCompany } from '../../Services/AuthService';


import "./alignerDashboard.css"

const SignUpForm = (props) => {
    const { popupclose, reload } = props
    const navigate = useNavigate();
    const app = JSON.parse(localStorage.getItem("admin")).admin.app;
    const [doctorsList, setDoctorsList] = useState([])
    const [doctorsLoading, setDoctorsLoading] = useState(true)
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Add User")


    useEffect(() => {

        const apiCalls = async () => {
            await doctorListBasedOnCompany({ app })
                .then((res) => {
                    setDoctorsList(res.data.data.doctorList);
                    setDoctorsLoading(false);
                })
                .catch((err) => navigate("/aligner-dashboard"));

        }

        apiCalls();

    }, [])

    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const date = currentDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${date}`
    }


    const initialValues = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        treatmentDate: getCurrentDate(),
        assignedDentist: "",
        dob: '',
        zipcode: '',
        gender: '',
        fileOption: "",
        file: "",
        webUrl: "",
        newUser: true,
        compare_aligner: 0,
        next_date_to_take_test: new Date(),
        report_start_at: 0,
        aligner_change_days: 15,
        total_aligners: "",
        aligner_restarts_at: []
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        mobileNumber: Yup.string().required('Mobile Number is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        treatmentDate: Yup.string().required('Treatment Date is required'),
        assignedDentist: Yup.string().required('Assign dentist is required'),
        dob: Yup.string().required('Date of Birth is required'),
        zipcode: Yup.string().required('Zipcode is required'),
        gender: Yup.string().required('Gender is required'),
        fileOption: Yup.string().required('Please select one of the options.'),
        file: Yup.mixed().when('fileOption', {
            is: 'upload',
            then: Yup.mixed().required('Please upload a file.').test('is-video', 'Invalid file format. Please upload a mp4 file.', (value) =>
                value && ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'].includes(value.type)
            ).test('fileSize', 'Video size is too large, please select a video of below 200 mb', (value) => !value || value.size <= 150 * 1024 * 1024),
        }),
        webUrl: Yup.mixed().when('fileOption', {
            is: 'webUrl',
            then: Yup.string().required('URL is required').url('Invalid URL. Please enter a valid URL.'),
        }),
        next_date_to_take_test: Yup.mixed().when('newUser', {
            is: false,
            then: Yup.string()
        }),
        aligner_change_days: Yup.number().required("Required aligner change in days"),
        total_aligners: Yup.number().required("Total number of aligner is Required for user"),
        compare_aligner : Yup.number().required("Current aligner number is required")
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        const { fileOption, file } = values
        setLoader(true)
        setSubmitting(true);
        if (fileOption == "upload" && file) {
            // converting video file to required file format to store in s3Bucket

            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            const formData = new FormData();
            formData.append('video', newFile);
            formData.append("userEmail", values.email)
            const userEmail = values.email
            uploadVideoTos3Bucket({ formData, app, userEmail }).then((res) => {
                if (JSON.stringify(res.data.data) === '{}') {
                    setLoader(false)
                    setsnackbarmessage(res.data.message);
                    settype("error");
                    setsnackbar(true);

                } else {
                    const videoDetails = res.data.data
                    signUpNewUser({ ...values, ...videoDetails, app }).then((res) => {
                        setsnackbarmessage(res.data.message);
                        if (JSON.stringify(res.data.data) === '{}') {
                            settype("error")

                        } else {
                            settype("success");
                            setSubmitButtonText("User Added Successfully")
                            setTimeout(() => {
                                popupclose();
                                reload()
                            }, 5000);

                        }
                        setLoader(false)
                        setsnackbar(true);
                    }).catch((err) => {
                        setLoader(false)
                        setsnackbarmessage(err.data.message);
                        setsnackbar(true);

                    })
                }
            }).catch((err) => {
                setLoader(false)
                console.log("error")

            })
        } else {
            setLoader(true)
            signUpNewUser({ ...values, app }).then((res) => {
                setsnackbarmessage(res.data.message);
                if (JSON.stringify(res.data.data) === '{}') {
                    settype("error")

                } else {
                    settype("success");
                    setSubmitButtonText("User Added Successfully")
                    setTimeout(() => {
                        popupclose();
                        reload()
                    }, 5000);
                }
                setLoader(false)
                setsnackbar(true);
            }).catch((err) => {
                setLoader(false)
                setsnackbarmessage(err.data.message);
                setsnackbar(true);

            })
        }



    }

    const handleclose = () => {
        popupclose();
        reload();
    }


    const updateForm = (values, setFieldValue) => {
        const alignerDays = values.aligner_change_days ? values.aligner_change_days : 1
            const startDate = moment(new Date(values.treatmentDate))
            const signUpDate = moment(new Date())
            const noOfPastDays = signUpDate.diff(startDate, 'days')

            if (noOfPastDays > 0) {
                
                const reportsCompleted = Math.floor(noOfPastDays / alignerDays)
                const lastReport = (reportsCompleted * alignerDays) + alignerDays
                const today = new Date(values.treatmentDate)
                today.setDate(today.getDate() + (lastReport - alignerDays))
                const sameDay = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(today)).format('MM/DD/YYYY')

                let nextReportDate = new Date(values.treatmentDate)
                nextReportDate.setDate(nextReportDate.getDate() + lastReport)

                if (sameDay) {
                    nextReportDate = today
                }

                setFieldValue('newUser', false)
                setFieldValue('next_date_to_take_test', nextReportDate)
                setFieldValue('report_start_at', (reportsCompleted + 1))
            } else {
                setFieldValue('newUser', true)
                setFieldValue('next_date_to_take_test', values.treatmentDate)
                setFieldValue('report_start_at', 0)
            }
        
    }

    const TreatmentDate = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            updateForm(values, setFieldValue)

        }, [values.treatmentDate])

        return (
            <Col className='d-flex justify-content-center'>
                <div className='w-75'>
                    <label htmlFor="treatmentDate" className="pt-2 font-weight-500 text-dark field-text mb-1">
                        <span className="field-texts  mx-3">
                            Treatment Start Date*
                        </span>
                    </label>
                    <Field type="date" id="treatmentDate" name="treatmentDate" className="form-input mb-3 grey-bg-color" placeholder="Treatment Start Date" />
                    <ErrorMessage name="treatmentDate" component="div" className='text-danger' />
                </div>
            </Col>

        )
    }

    const AlignerDays = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            updateForm(values, setFieldValue)

        }, [values.aligner_change_days])


        return (
            <Col className='d-flex justify-content-center'>
                <div className='w-75'>
                    <label htmlFor="aligner_change_days" className="pt-2 font-weight-500 text-dark field-text mb-1">
                        <span className="field-texts  mx-3">
                            Aligner change period(days)*
                        </span>
                    </label>
                    <Field type="number" id="aligner_change_days" name="aligner_change_days" className="form-input mb-3 grey-bg-color" placeholder="Number of days" />
                    <ErrorMessage name="aligner_change_days" component="div" className='text-danger' />
                </div>
            </Col>

        )
    }

    const NextCheckDate = () => {
        const { values, setFieldValue } = useFormikContext()
        let nextReport = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(values.next_date_to_take_test)).format('MM/DD/YYYY') ? "Today" : moment(new Date(values.next_date_to_take_test)).format('DD/MM/YYYY')
        useEffect(() => {
            nextReport = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(values.next_date_to_take_test)).format('MM/DD/YYYY') ? "Today" : moment(new Date(values.next_date_to_take_test)).format('DD/MM/YYYY')

        }, [values.treatmentDate, values.aligner_change_days])
        return (
            <>
                {

                    <Col className='text-center'>
                        <div>Next Check is on <span className="text-primary ms-2">
                            {nextReport}
                        </span> </div>
                    </Col>
                }
            </>
        )
    }


    const UploadFileInput = () => {
        const { values, setFieldValue } = useFormikContext();
        return (
            <>
                {values.fileOption === 'upload' && (
                    <div className='my-2 ms-5'>
                        <label htmlFor="file">Select a mp4 file:</label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
                        />
                        <ErrorMessage name="file" component="div" className="text-danger" />
                    </div>
                )
                }

                {values.fileOption === "webUrl" && (
                    <div className='w-75'>
                        <label htmlFor="webUrl" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                                Web URL*
                            </span>
                        </label>
                        <Field type="text" id="webUrl" name="webUrl" className="form-input mb-3 grey-bg-color" placeholder="add your URL here" />
                        <ErrorMessage name="webUrl" component="div" className='text-danger' />
                    </div>
                )
                }
            </>
        );
    };




    return (
        <div className="sign-up-model-container">
            <div className="sign-up-model-form-container">
                <div className="popup-close-btn d-flex justify-content-end" style={{ margin: "-15px" }}>
                    <button className="bg-transparent border-0" onClick={() => { handleclose(); }} >
                        <img src={close} alt="close-btn" width="50px" height="50px"></img>
                    </button>
                </div>
                <div className="d-flex navbar_text_dashbord justify-content-center mb-3">
                    <div>
                        <FaUserPlus
                            size="30px"
                            color="#0090FF"
                            className="mx-3"
                        />
                    </div>
                    <div>Add User Details</div>
                </div>

                {snackbar && (
                    <SnackFire
                        open={snackbar}
                        onClose={() => { setsnackbar(false) }}
                        position="top-right"
                        timing={4000}
                        type={type}
                        message={snackbarmessage}
                    />
                )}
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Container>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="firstName" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    First Name*
                                                </span>
                                            </label>
                                            <Field type="text" id="firstName" name="firstName" className="form-input mb-3 grey-bg-color" placeholder="First Name" />
                                            <ErrorMessage name="firstName" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="lastName" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Last Name*
                                                </span>
                                            </label>
                                            <Field type="text" id="lastName" name="lastName" className="form-input mb-3 grey-bg-color" placeholder="Last Name" />
                                            <ErrorMessage name="lastName" component="div" className='text-danger' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="gender" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Gender*
                                                </span>
                                            </label>
                                            <div className='d-flex justify-content-evenly align-items-center mt-2'>
                                                <label>  <Field type="radio" id="male" name="gender" value="male" />   Male</label>
                                                <label>  <Field type="radio" id="female" name="gender" value="female" /> Female  </label>
                                                <label>  <Field type="radio" id="other" name="gender" value="other" /> Other</label>
                                            </div>
                                            <ErrorMessage name="gender" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="dob" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Date of Birth*
                                                </span>
                                            </label>
                                            <Field type="date" id="dob" name="dob" className="form-input mb-3 grey-bg-color" placeholder="Date Of Birth" />
                                            <ErrorMessage name="dob" component="div" className='text-danger' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="email" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Email*
                                                </span>
                                            </label>
                                            <Field type="email" id="email" name="email" className="form-input mb-3 grey-bg-color" placeholder="Email" />
                                            <ErrorMessage name="email" component="div" className='text-danger' />
                                        </div>
                                    </Col>

                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="mobileNumber" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Cell/Mobile Number*
                                                </span>
                                            </label>
                                            <Field type="text" id="mobileNumber" name="mobileNumber" className="form-input mb-3 grey-bg-color" placeholder="Cell/Mobile Number" />
                                            <ErrorMessage name="mobileNumber" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="assignedDentist" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Assign Dentist* :
                                                    </span>
                                                </label>
                                                {!doctorsLoading && <div>
                                                    {doctorsList.length > 0 ? <Field as="select" id="assignedDentist" name="assignedDentist" placeholder="Select a dentist" className="form-input mb-3 grey-bg-color" >
                                                        <option value=""> Select a dentist</option>
                                                        {doctorsList.map((eachDoctor) => {
                                                            return <option value={eachDoctor._id} key={eachDoctor._id} > {eachDoctor.name}</option>
                                                        })
                                                        }
                                                    </Field> : <p className='text-danger'>You need to add a dentist to create a user. <Link to="/dentist" >Click here </Link> to add a dentist</p>}
                                                </div>}
                                                <ErrorMessage name="assignedDentist" component="div" className='text-danger' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="zipcode" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    ZIP/Postal Code*
                                                </span>
                                            </label>
                                            <Field type="text" id="zipcode" name="zipcode" className="form-input mb-3 grey-bg-color" placeholder="Zipcode" />
                                            <ErrorMessage name="zipcode" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <TreatmentDate />
                                    <AlignerDays />

                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="total_aligners" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Total number of aligners*
                                                </span>
                                            </label>
                                            <Field type="number" id="total_aligners" name="total_aligners" className="form-input mb-3 grey-bg-color" placeholder="Total number of aligners" />
                                            <ErrorMessage name="total_aligners" component="div" className='text-danger' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="compare_aligner" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Current Aligner(number)
                                                </span>
                                            </label>
                                            <Field type="number" id="compare_aligner" name="compare_aligner" className="form-input mb-3 grey-bg-color" placeholder="Current Aligner Number" />
                                            <ErrorMessage name="compare_aligner" component="div" className='text-danger' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="fileOption" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Select a plan file* :
                                                    </span>
                                                </label>
                                                <Field as="select" id="fileOption" name="fileOption" placeholder="Select a plan file" className="form-input mb-3 grey-bg-color" >
                                                    <option value=""> Select a plan file</option>
                                                    <option value="upload">Upload a Video File</option>
                                                    <option value="webUrl">Web URL</option>
                                                </Field>
                                                <ErrorMessage name="fileOption" component="div" className='text-danger' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <UploadFileInput />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NextCheckDate days={initialValues.aligner_change_days} />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-center mt-4'>
                                            <Button type="submit" className="form-btn mx-3" disabled={isSubmitting}>
                                                {loader ? <div className="text-center">
                                                    <div className="spinner-border text-white" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div> : <span> {submitButtonText} </span>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>

    )
}

export default SignUpForm



