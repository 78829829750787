import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FaUserMd } from "react-icons/fa";
import close from "../../assets/Close.svg";
import {
  doctorListBasedOnCompany,
  assignDentistToReport,
  userAssignDentist,
  allLocationsBasedOnCompany,
  allDentistBasedOnLocation,
  branchDetailsBasedOnId,
  recentBranchOFSharedUser
} from "../../Services/AuthService";

const AssingDentistPopup = ({
  sendToDentistId,
  popupclose,
  // popupCloseWithoutApi,
  sharedLocationId,
}) => {
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [change, setChange] = useState(true);
  const [doctorList, setDoctorList] = useState();
  const [doctorName, setDoctorName] = useState();
  const [loading, setloading] = useState(true);
  const [locationList, setLocationList] = useState();
  const [locationName, setLocationName] = useState();
  const [userLocation, setUserLocation] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState();
  const initialCall = async () => {
    await recentBranchOFSharedUser({user: sharedLocationId })
      .then(async(res) => {
      await  branchDetailsBasedOnId({branch_id:res.data.data.branchDetails[0].branch}).then((res)=>{
        setUserLocation(res.data.data.branchDetails[0].location);

      }).catch(err=>console.log(err))
      })
      .catch((err) => console.log(err));

    await doctorListBasedOnCompany({ app })
      .then((res) => {
        setDoctorList(res.data.data.doctorList);
        setDoctorName(res.data.data.doctorList[0]._id);
      })
      .catch((err) => console.log(err));
    await allLocationsBasedOnCompany({ app })
      .then((res) => {
        setLocationList(res.data.data);
        setLocationName(res.data.data[0]._id)
       allDentistBasedOnLocation({ app, location_id: res.data.data[0]._id })
          .then((res) => {
            setDoctorList(res.data.data?res.data.data:"");
            setDoctorName(res.data.data.length!=0?res.data.data[0]._id:"");
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    initialCall();
  }, []);

  const changeCall = () => {
    allDentistBasedOnLocation({ app, location_id: locationName })
      .then((res) => {
        setDoctorList(res.data.data);
        setDoctorName(res.data.data[0]?res.data.data[0]._id:"");
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    changeCall();
  }, [change]);

  if (!loading) {
    return (
      <div className="model">
        <div className="model_container">
          <div className="close_position">
            <Button
              onClick={() => {
                popupclose();
              }}
            >
              {" "}
              <img src={close} width="50px" height="50px"></img>
            </Button>
          </div>
          <Col md={12}>
            <Row>
              <Col
                md={4}
                className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
              >
                <FaUserMd size="150px" color=" #F5F5FA" />
              </Col>
              <Col
                md={8}
                className="d-flex flex-column justify-content-center h-5 mt-5 align-items-start"
              >
                <Row className="mt-3 mx-2 d-flex width-100">
                  <Col md={7} className=" d-flex justify-content-start">
                    Patient's preferred Location
                  </Col>
                  <Col
                    md={5}
                    className="text-sans-bold font-32-ellipsis d-flex justify-content-start align-items-center"
                  >
                    {" "}
                    <OverlayTrigger
                         placement="bottom"
                         overlay={<Tooltip>{userLocation}</Tooltip>}>
                              <p className="text-truncate">{userLocation}</p>
                    </OverlayTrigger>  
                    </Col>
                </Row>

                <div  className="d-flex justify-content-center align-items-center mx-2 mt-4">
                    <div className="mx-3 d-flex justify-content-start align-items-center">
                      Location list
                    </div>
                    {" "}
                    <div className="text-sans-bold ">
                      <select
                        className="select-doctor-input my-2 grey-bg-color width-100"
                        value={locationName}
                        onChange={(e) => {
                          setLocationName(e.target.value);
                          setChange(!change);
                        }}
                      >
                        {locationList.map((option) => (
                          <option value={option._id}>
                              {`${option.address ? option.address + "," : ""} ${option.city ? option.city + ",": ""} ${option.state ? option.state + ",": "" } ${option.zip_code ? option.zip_code: "" }`}
                          </option>
                        ))}
                      </select>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mx-2 mt-4">
                  <div className="mx-3">Dentist list</div>
                  <div className="text-sans-bold">
                    {doctorList.length == 0 ? (
                      <div>
                        There are no dentist created for this particular
                        location
                      </div>
                    ) : (
                      <select
                        className="select-doctor-input my-2 grey-bg-color"
                        value={doctorName}
                        onChange={(e) => {
                          setDoctorName(e.target.value);
                        }}
                      >
                        {doctorList.map((option) => (
                          <option value={option._id}>Dr. {option.name}</option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>

                <div className="d-flex width-100 justify-content-center">
                  <Button
                    className="delete_popup_button mt-4"
                    onClick={() => {
                      userAssignDentist({
                        user_id: sendToDentistId,
                        assigned_dentist: doctorName,
                        location_id: locationName
                      })
                        .then((res) => {
                          popupclose();
                        })
                        .catch((err) => {
                          popupclose();
                        });
                    }}
                  >
                    
                    <span className="delete_button_text ">Save and Update</span>

                    
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    );
  }
};

export default AssingDentistPopup;
