import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getLatestClinicDetails } from "../../Services/AuthService";
import "./dentalOfficeDetails.css";

const DentalOfficeDetails = () => {
  const [ClinicDetailsData, setClinicDetailsData] = useState();
  const [loading, setloading] = useState(true);
  const company_mail=JSON.parse(localStorage.getItem("admin")).admin.email;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("admin"));
    getLatestClinicDetails({ user_id: user.admin._id })
      .then((res) => {
        if (res.status === 200) {
          setClinicDetailsData(res.data.data[0]);
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        return err;
      });
  }, []);

  if (loading) {
    <div>Loading...</div>;
  }
  if (!loading) {
    return (
      <div className="mt-2 office-box ">
        <div className=" mx-2 dental-office-header-design pt-3 text-primary">Dental Office Details</div>

        <Row>
          <Col
            md={5} xs={5} sm={5}
            className="ps-4 mt-2"
          >
            Office Name
          </Col>
          <Col
            md={7} xs={7} sm={7}
            className="d-flex justify-content-start align-items-start mt-2"
          >
            {ClinicDetailsData?ClinicDetailsData.clinic_name:"----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5} xs={5} sm={5}
            className="ps-4 mt-2"
          >
            Office Email{" "}
          </Col>
          <Col
            md={7} xs={7} sm={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
          <div  className="ellipsis-company-email">
             {company_mail}
            </div> 
          </Col>
        </Row>
        <Row>
          <Col
            md={5} xs={5} sm={5}
            className="ps-4 mt-2"
          >
            Phone Number
          </Col>
          <Col
            md={7} xs={7} sm={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {ClinicDetailsData?ClinicDetailsData.phone_number:"----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5} xs={5} sm={5}
            className="ps-4 mt-2"
          >
            City{" "}
          </Col>
          <Col
            md={7} xs={7} sm={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {ClinicDetailsData?ClinicDetailsData.clinic_city:"-----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5} xs={5} sm={5}
            className="ps-4 mt-2"
          >
            State{" "}
          </Col>
          <Col
            md={7} xs={7} sm={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {ClinicDetailsData?ClinicDetailsData.clinic_state:"----"}
          </Col>
        </Row>
      </div>
    );
  }
};

export default DentalOfficeDetails;
