import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const BarChartExample = (props) => {
    const { details } = props
    return (
        <div className='d-flex justify-content-center align-items-center mt-5'>
            <BarChart
                width={750}
                height={300}
                data={details}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="visits" fill="#4cadfc" />
            </BarChart>
        </div>
    );
};

export default BarChartExample;