import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  dentistDetailsBasedOnId,
  branchDetailsBasedOnId,
} from "../../Services/AuthService";

const DentistCardDetails = () => {
  const [dentistData, setDentistData] = useState();
  const [branchData, setBranchData] = useState();

  const [loading, setloading] = useState(true);
  const company_mail = JSON.parse(localStorage.getItem("admin")).admin.email;
  const dentist_id = JSON.parse(localStorage.getItem("admin")).admin.dentist_id;

  useEffect(() => {
    dentistDetailsBasedOnId({ dentist_id })
      .then((res) => {
        if (res.status === 200) {
          setDentistData(res.data.data.dentistDetails[0]);
          branchDetailsBasedOnId({branch_id:res.data.data.dentistDetails[0].location})
            .then((res) => {
              setBranchData(res.data.data.branchDetails[0])
              setloading(false);
            })
            .catch((err) => {
              setloading(false);
            });
        }
      })
      .catch((err) => {
        setloading(false);
        return err;
      });
  }, []);

  if (loading) {
    <div>Loading...</div>;
  }
  if (!loading) {
    return (
      <div className="mt-2 office-box ">
        <div className="font-doctor mx-2 pt-1">Dentist Details</div>

        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center  align-items-center mt-2"
          >
            Dentist Name
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.name : "----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Dentist Email{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            <div className="ellipsis-company-email">{company_mail}</div>
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Phone Number
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.phone_number : "----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Designation{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.designation : "-----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Location{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {branchData ? branchData.location : "----"}
          </Col>
        </Row>
      </div>
    );
  }
};

export default DentistCardDetails;
