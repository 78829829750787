import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/navbarLogo.svg";

function Navbars() {
  return (
    <>
      <Navbar bg="light" className="mb-3">
        <Container fluid>
          <div className="mx-3">
            <img alt="" src={logo} width="40px" height="40px" />
          </div>
          <div className="navbar_text_dashbord ">Toothlens Inquiry form</div>
          <div className="mx-3">
            <img alt="" src={logo} width="40px" height="40px" />
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Navbars;
