import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormLabel } from "react-bootstrap";
import Update from "../../assets/update.svg";
import Delete from "../../assets/delete.svg";
import {
  doctorListBasedOnCompany,
  branchDetailsBasedOnCompany,
} from "../../Services/AuthService";
import LocationUpdatePopup from "./locationUpdatePopup";
import Button from "@mui/material/Button";
import LocationDeletePopup from "./locationDeletePopup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate } from "react-router-dom";

const LocationList = ({ change }) => {
  const [editBranchpopup, seteditBranchpopup] = useState(false);
  const [deletepopup, setdeletepopup] = useState(false);
  const [render, setrender] = useState(false);
  const [email,setEmail]=useState()
  const navigate = useNavigate();
  const [branchId, setBranchId] = useState();
  const [deleteBranchid, setdeleteBranchid] = useState();
  const [locationList, setlocationList] = useState();
  const [loading, setloading] = useState(true);
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [currentpage, setcurrentpage] = useState(0);
  const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
  const totalpage = Math.ceil(totalreportfilteredcount / 6);
  const verified = JSON.parse(localStorage.getItem("flag"))
  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setrender(!render);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setrender(!render);
  };
  const closeupdatepopup = () => {
    seteditBranchpopup(false);
  };
  const closedeletepopup = () => {
    setdeletepopup(false);
  };

  useEffect(() => {
    branchDetailsBasedOnCompany({ app })
      .then((res) => {
        settotalreportfilteredcount(res.data.data[0][0].total_branches);
        setlocationList(res.data.data[1]);
        setloading(false);
      })
      .catch((err) => navigate("/") );
  }, []);
  useEffect(() => {
    let object = {
      currentpage,
      app,
      limit: 6,
    };
    branchDetailsBasedOnCompany(object)
      .then((res) => {
        settotalreportfilteredcount(res.data.data[0][0].total_branches);
        setlocationList(res.data.data[1]);
        setloading(false);
      })
      .catch((err) =>navigate("/"));
  }, [render]);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!loading) {
    return (
      <div>
        <div className="d-flex justify-content-between  w-5">
              <div className=" mt-1 mx-1">
                Location List
              </div>
              <div className="d-flex  p-1 ">
              
                <Button
                  disabled={currentpage == 0}
                  onClick={handlepreviouspage}
                >
                  <ArrowBackIosIcon />
                </Button>
                <div className="p-1 ">
                  {currentpage == 0 ? currentpage + 1 : currentpage * 6 + 1}-
                  {currentpage == totalpage - 1
                    ? totalreportfilteredcount
                    : (currentpage + 1) * 6}{" "}
                  <span className="mx-1">of</span> {totalreportfilteredcount}
                </div>
                <Button
                  disabled={
                    currentpage ==
                    (totalpage == 0 ? currentpage : totalpage - 1)
                  }
                  onClick={handlenextpage}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
        </div>

        {locationList.map((data) => {
          return (
            <Row key={data._id} className="doctors-row  mx-2 my-2">
              <Col md={4} sm={6} className=" mt-3">
                <Row className="   new">
                  <Col
                    md={3}
                    sm={4}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">Email</div>
                    <div className="text-size">Phone Number</div>
                  </Col>

                  <Col
                    md={9}
                    sm={8}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold mb-2">
                      {" "}
                      {data.email ? data.email : "--"}
                    </div>
                    <div className="text-bold mb-2">
                    {data.phone_number ? data.phone_number : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={4} sm={6} className="dotted_line mt-3">
                <Row className="new">
                  <Col
                    md={3}
                    sm={6}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">address</div>
                    <div className="text-size">City</div>
                  </Col>

                  <Col
                    md={9}
                    sm={6}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold ellipsis">
                    {data.address ? data.address : "--"}
                    </div>
                    <div className="text-bold ellipsis">
                    {data.city ? data.city : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={2} sm={6} className="dotted_line mt-3">
                <Row className="   new">
                  <Col
                    md={5}
                    sm={6}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    
                    <div className="text-size">State</div>
                    <div className="text-size text-nowrap">Zip Code</div>
                  </Col>

                  <Col
                    md={7}
                    sm={6}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold text-nowrap text-truncate">
                      {" "}
                      {data.state ? data.state : "--"}
                    </div>
                    <div className="text-bold">
                      {" "}
                      {data.zip_code ? data.zip_code : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={2} sm={6} className="dotted_line mt-3">
                <Row className="d-flex justify-content-center">
                  <Col md={6} sm={6}>
                    {" "}
                    <Button
                      onClick={() => {
                        seteditBranchpopup(true);
                        setBranchId(data._id);
                      }}
                      disabled = {verified}
                    >
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <img
                          src={Update}
                          height="60px"
                          width="60px"
                          alt="upload absolute"
                        />
                      </div>
                    </Button>
                  </Col>
                  <Col md={6} sm={6}>
                    {" "}
                    {!data.is_chief_location && (
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <Button
                          onClick={() => {
                            setdeletepopup(true);
                            setdeleteBranchid(data._id);
                            setEmail(data.email)
                          }}
                          disabled = {verified}
                        >
                          <img
                            src={Delete}
                            height="60px"
                            width="60px"
                            alt="upload absolute"
                          />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}

        {editBranchpopup && (
          <LocationUpdatePopup
            popupstatus={closeupdatepopup}
            change={change}
            branchId={branchId}
          />
        )}
        {deletepopup && (
          <LocationDeletePopup
            deletePopupStatus={closedeletepopup}
            change={change}
            email={email}
            deleteBranchid={deleteBranchid}
          />
        )}
      </div>
    );
  }
};

export default LocationList;
