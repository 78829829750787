import React, { useState, useEffect } from "react"
import { getAllApiSubscribers, getDetailsOfSelectedSubscriber } from "../../Services/AuthService"
import BarChartExample from "./stachBar"

const Subscribers = (props) => {
    const { app, close } = props
    const [subscribers, setSubscribers] = useState()
    const [selectedOne, setSelectedOne] = useState()
    const [isBarchartLoading, setIsBarchartLoading] = useState(true)
    const [barchartData, setBarChartData] = useState()
    const [areSubscribersGot, setAreSubscribersGot] = useState(false)

    useEffect(() => {
        getAllApiSubscribers({ app })
            .then(res => {
                setSubscribers(res.data.data)
                setSelectedOne(res.data.data[0].user)
                setAreSubscribersGot(true)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (areSubscribersGot) {
            setIsBarchartLoading(true)
            getDetailsOfSelectedSubscriber({ app, selectedOne })
                .then(res => {
                    const data = res.data.data.visits
                    const chartData = Object.entries(data).map(([name, visits]) => ({ name, visits }));
                    setBarChartData(chartData)
                    setIsBarchartLoading(false)
                })
                .catch(error => {
                    console.log("error error")
                })
        }
    }, [selectedOne])


    const DropDown = () => {
        return (
            areSubscribersGot ?
                <select onChange={(event) => { setSelectedOne(event.target.value) }} value={selectedOne} className="rounded px-2" >
                    {subscribers.map(each => <option key={each._id} value={each.user} > {each.user} </option>)}
                </select> : <div className="spinner-border text-primary" role="status">
                              <span className="visually-hidden">Loading...</span>
                           </div>
        )
    }

    const Header = () => {
        return (
            <div className="col-11 tbs-container mb-5 border-primary pt-2 mx-2 mx-md-5 shadow rounded-3 d-flex justify-content-between align-items-center">
                <DropDown />
                <button type="button" className="btn btn-primary" onClick={() => close()}>Go Back</button>
            </div>
        )

    }

    const Loader = () => {
        return (
            <div className="d-flex justify-content-center align-items-center three-dots">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <Header />
            {isBarchartLoading ? <Loader /> : <BarChartExample details={barchartData} />}
        </div>
    )
}

export default Subscribers