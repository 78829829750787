import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbars from "./navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { partnerReferralRegisterService } from "../../Services/AuthService";

const FormSubmission = () => {
  let { form_id } = useParams();
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState(false);

  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <Container fluid>
      <Navbars />

      {
        <Formik
          validate={(values) => {
            let errors = {};
            if (!values.name) {
              errors.name = "Name is required";
            }
            if (!values.email) {
              errors.email = "Email is required";
            }
            if (!values.phone_number) {
              errors.phone_number = "Phone Number is required";
            }
            if (!values.company_name) {
              errors.company_name = "Company Name is required";
            }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            email: Yup.string().required("Email is required"),
            phone_number: Yup.string().required("Phone Number is required"),
            company_name: Yup.string().required("Company Name is required"),
          })}
          initialValues={{
            email: "",
            name: "",
            phone_number: "",
            company_name: "",
            pms: "",
            number_of_offices: "",
            city: "",
            state: "",
            country: "",
            website: "",
          }}
          onSubmit={(values) => {
            partnerReferralRegisterService({ form_id, ...values })
              .then((res) => {
                setsnackbar(true);
              })
              .catch((err) => {
                settype(true);
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} sm={12}>
                    <div className="d-flex w-5 justify-content-center align-items-center mx-2">
                      <Col className="mx-5">
                        <div className="d-flex w-5">
                          <Col md={6}>
                            <FormLabel
                              component="legend"
                              className="mt-4 font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">Name*</span>
                            </FormLabel>
                          </Col>
                        </div>
                        <Col className="mt-1">
                          {" "}
                          <input
                            id="name"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="firstName lastName"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.name}
                            </div>
                          )}
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3">
                              {" "}
                              Email Address*
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="email"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="johndow@gmail.com"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3">
                              {" "}
                              Phone Number*
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="phone_number"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="123456789"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.phone_number && touched.phone_number && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.phone_number}
                            </div>
                          )}
                        </Col>
                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3">
                              {" "}
                              Company Name*
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="company_name"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="company"
                            value={values.company_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.company_name && touched.company_name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.company_name}
                            </div>
                          )}
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3">
                              {" "}
                              Practice Management System
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="pms"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="Practice Management System"
                            value={values.pms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Col>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="d-flex w-5 justify-content-center align-items-center mx-2">
                      <Col className="mx-5">
                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 mt-4"
                          >
                            <span className=" field-texts mx-3">
                              {" "}
                              Number of Offices/Locations
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="number_of_offices"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="Number of offices/locations"
                            value={values.number_of_offices}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3"> City</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="city"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3"> State</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="state"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3"> Country</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="country"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="country"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col>
                          <FormLabel
                            component="legend"
                            className="ml-3 pt-2 pl-5 "
                          >
                            <span className=" field-texts mx-3"> Website</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            id="website"
                            type="text"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="toothlens.com"
                            value={values.website}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <Button className="form-btn-for-partner-form" type="submit">
                    <span className="btn-text">Submit</span>
                  </Button>
                </div>
                {snackbar && (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    Thanks for submitting the form and we will get back to you
                    soon!!
                  </div>
                )}
                {type && (
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    Server error please try again!!
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default FormSubmission;
