import React, { useEffect, useState, CSSProperties } from "react";
import Container from "react-bootstrap/Container";
import ClipLoader from "react-spinners/ClipLoader";
// import { SpinnerCircular } from 'spinners-react';
import PacmanLoader from "react-spinners/PacmanLoader";

// import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/navbarLogo.svg";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import "./dashboard.css";
import Hamburger from "../../assets/Hamburger.svg";
import Notification from "../../assets/notification.svg";
import filter from "../../assets/filter.svg";
import UpArrow from "../../assets/upArrow.svg";
import Delete from "../../assets/delete.svg";
// import profile from "../../assets/profileImage.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getLatestClinicDetails,
  DeleteReportById,
  getPatientDetails,
  getReportCount,
  getReportViewStatus,
  updateReportById,
  branchDetailsBasedOnCompany,
  doctorListBasedOnCompany,
  unSharedReportsCountBasedOnCompany,
  unSharedReportsBasedOnCompany,
  DeleteReportByIdOnReport,
  getPatientDetailsforcsv,
  getPatientDetailsforUnSharedcsv,
  getAppDetails,
  doctorListPaginationBasedOnCompany
} from "../../Services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import profile from "../../assets/userIcon.svg";
import moment from "moment";
import Update from "../../assets/update.svg";
import { FaUserMd } from "react-icons/fa";
import Navbars from "./navbar";
import { CSVLink, CSVDownload } from "react-csv";
import riskAnalysisimg from "../../assets/riskAnalysisImg.svg";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import teethcry from "../../assets/teethcry.svg";
import close from "../../assets/Close.svg";
import SnackFire from "../../UI/Snackbar/Snackbar";
import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";
import AssignDentistPopup from "./assignDentistPopup";
import ProspectUsers from "./prospectUsers";
import {FaDownload} from "react-icons/fa";

const Dashboard = () => {
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const [excelData, setExcelData] = useState([]);
  const [loading, setloading] = useState(true);
  const [prospectTab, setProspectTab] = useState(false);
  const [data, setdata] = useState();
  const [count, setcount] = useState();
  const [opened, setopened] = useState();
  const [unopened, setunopened] = useState();
  let [color, setColor] = useState("#ffffff");
  const [change, setchange] = useState(true);
  const [gender, setgender] = useState("");
  const [patienttype, setpatienttype] = useState("");
  const [genderinfo, setgenderinfo] = useState({ languages: [] });
  const [branches, setbranches] = useState();
  const [locationfilterinfo, setlocationfilterinfo] = useState({
    branches: [],
  });
  const [doctorfilterinfo, setdoctorfilterinfo] = useState({ doctors: [] });
  const [reportstatusfilterinfo, setreportstatusfilterinfo] = useState({
    reports: [],
  });
  const [filters, setFilters] = useState({ gender: [], type: [] });
  const [insurance, setinsurance] = useState("");
  const [age, setage] = useState("");
  const [appdata, setappdata] = useState();
  const [filteropenedreport, setfilteropenedreport] = useState("");
  const [filterunopenedreport, setfilterunopenedreport] = useState();
  const [doctor, setdoctor] = useState("");
  const [doctorList, setdoctorList] = useState("");
  const [doctorname, setdoctorname] = useState("");
  const [status, setstatus] = useState("");
  const [show, setshow] = useState();
  const [sorting, setsorting] = useState("");
  const [unsharedreport, setunsharedreport] = useState(false);
  const [sharedreport, setsharedreport] = useState(true);
  const [unsharedreportdata, setunsharedreportdata] = useState("");
  const [unsharedreportopened, setunsharedreportopened] = useState();
  const [unsharedreportunopened, setunsharedreportunopened] = useState();
  const [unsharedcount, setunsharedcount] = useState();
  const [appointmentrequest, setappointmentrequest] = useState("");
  const [csvdata, setcsvdata] = useState("");
  const [csvUnSharedData, setcsvUnSharedData] = useState("");
  const [deletepopupUnShared, setdeletepopupUnShared] = useState(false);
  const [userdata, setuserdata] = useState("");
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [snacktype, setsnacktype] = useState("error");
  const [sharedadvertisinguser, setsharedadvertisinguser] = useState("");
  const [usereditpopup, setusereditpopup] = useState(false);
  const [userId, setUserId] = useState("");
  const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
  const [
    SendToDentistIdForDentistAssignment,
    setSendToDentistIdForDentistAssignment,
  ] = useState();
  const [prospectUserTab, setProspectUserTab] = useState(false);
  //  ---------------------pagination for shared report--------------------------
  const [currentpage, setcurrentpage] = useState(0);
  const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
  const reportsperpage = 8;
  const totalpage = Math.ceil(totalreportfilteredcount / reportsperpage);
  const navigate = useNavigate();

  // ----------------------pagination for unshared report----------------------
  const [currentpageunshared, setcurrentpageunshared] = useState(0);
  const [
    totalunsharedreportfilteredcount,
    settotalunsharedreportfilteredcount,
  ] = useState(0);
  const totalpageunshared = Math.ceil(
    totalunsharedreportfilteredcount / reportsperpage
  );
  const company_name = JSON.parse(localStorage.getItem("admin")).admin
    .company_name;
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  // const admin_id=  JSON.parse(localStorage.getItem("admin")).admin._id;

// -------------------------------------pagination for location filters-------------------
const [currentpageLocation, setcurrentpageLocation] = useState(0);
const [totalLocation, setTotalLocation] = useState(0);
const totalpageLocation = Math.ceil(totalLocation/ 6);
const [render,setrender]=useState(true);
const [branchlistPagination,setbranchlistPagination]=useState()
// -----------------------Pagination for Dentist filters----------------
const [currentpageDentist, setcurrentpageDentist] = useState(0);
const [totalDentist, setTotalDentist] = useState(0);
const totalpageDentist = Math.ceil(totalDentist/ 6);
const [renderDentist,setrenderDentist]=useState(true);
const [dentistlistPagination,setdentistlistPagination]=useState();

  // ----------------------------------CSV headers for shared reports---------------------

  const headers = [
    { label: "First Name", key: "fromusers[0].first_name" },
    { label: "Last Name", key: "fromusers[0].last_name" },
    { label: "Email", key: "fromusers[0].email" },
    { label: "Phone Number", key: "fromusers[0].phone_number" },
    { label: "Is_insured", key: "fromusers[0].is_insured" },
    { label: "State", key: "fromusers[0].state" },
    { label: "City", key: "fromusers[0].city" },
    { label: "Country", key: "fromusers[0].country" },
    { label: "Is_Report_viewed", key: "is_report_viewed" },
    {
      label: "Overall Tartar Score",
      key: "fromreport[0].report_score[0].overall_score[0]",
    },
    {
      label: "Overall Cavity Score",
      key: "fromreport[0].report_score[0].overall_score[1]",
    },
    { label: "Report Remarks", key: "fromreport[0].report_remarks" },
    { label: "Location", key: "frombranches[0].name" },
  ];
  const headersUnShared = [
    { label: "First Name", key: "fromusers.first_name" },
    { label: "Last Name", key: "fromusers.last_name" },
    { label: "Email", key: "fromusers.email" },
    { label: "Phone Number", key: "fromusers.phone_number" },
    { label: "Is_insured", key: "fromusers.is_insured" },
    { label: "State", key: "fromusers.state" },
    { label: "City", key: "fromusers.city" },
    { label: "Country", key: "fromusers.country" },
    {
      label: "Overall Tartar Score",
      key: "report_score[0].overall_score[0]",
    },
    {
      label: "Overall Cavity Score",
      key: "report_score[0].overall_score[1]",
    },
  ];

  const csvReport = {
    data: csvdata,
    headers: headers,
    filename: "Shared_Report.csv",
  };
  const csvReportUnShared = {
    data: csvUnSharedData,
    headers: headersUnShared,
    filename: "UnShared_Report.csv",
  };
  // -----------------------------------delete popup----------------
  const [deletepopup, setdeletepopup] = useState(false);
  const [deletereportid, setdeletereportid] = useState("");
  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  // ------------------------------Snackbar close---------------------------
  const handleclose = () => {
    setsnackbar(false);
  };

  // -----------------------------Assigndentist popup close----------------
  const closeAssigndentistPopupWithoutApi = () => {
    setassignedDentistPopup(false);
  };
  const closeAssigndentistPopup = () => {
    setgender("");
    setpatienttype("");
    setgenderinfo({ languages: [] });
    setFilters({ gender: [], type: [] });
    setinsurance("");
    setage("");
    setdoctorfilterinfo({ doctors: [] });
    setreportstatusfilterinfo({ reports: [] });
    setsorting("");
    setdoctor("");
    setstatus("");
    setlocationfilterinfo({ branches: [] });
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedreport(true);
    setunsharedreport(false);
    setsharedadvertisinguser("");
    setfilteropenedreport("");
    call2();
    setassignedDentistPopup(false);
  };

  const onCloseUserPopup = () => {
    setgender("");
    setpatienttype("");
    setgenderinfo({ languages: [] });
    setFilters({ gender: [], type: [] });
    setinsurance("");
    setage("");
    setdoctorfilterinfo({ doctors: [] });
    setreportstatusfilterinfo({ reports: [] });
    setsorting("");
    setdoctor("");
    setstatus("");
    setlocationfilterinfo({ branches: [] });
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedreport(true);
    setunsharedreport(false);
    setsharedadvertisinguser("");
    setfilteropenedreport("");
    call2();
    setusereditpopup(false);
  };
  const call2 = async () => {
    const user = JSON.parse(localStorage.getItem("admin"));

    if (app == "63808e24a3be9e7f00c5c3b3") {
        setProspectTab(true);
    }
    // if (user.admin._id != "633d8dd3144a77308dd52a4d") {
    //   navigate("/signupsucess");
    // }
    await doctorListPaginationBasedOnCompany({app})
    .then((res) => {
      console.log(res.data.data,"rrrrrrrr")
      setTotalDentist(res.data.data[0][0].total_dentist);
      setdoctorList(res.data.data[1]);
    })
    .catch((err) => console.log(err));
    await getAppDetails({ app })
      .then((res) => {
        setappdata(res.data.data);
        setshow(true);
      })
      .catch((err) => {
        console.log(err);
      });
    await getPatientDetails({ app })
      .then((res) => {
        if (res.status === 200) {
          setdata(res.data.data.patientDetails);
          setuserdata([res.data.data.patientDetails[0].fromusers]);
          console.log(res.data.data.patientDetails,"uuuuuuuuuuu")
          res.data.data[0].total_filtered_reports
            ? settotalreportfilteredcount(
                res.data.data[0].total_filtered_reports
              )
            : settotalreportfilteredcount(0);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await getPatientDetailsforcsv({ app })
      .then((res) => {
        if (res.status === 200) {
          setcsvdata(res.data.data.patientDetails);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await getPatientDetailsforUnSharedcsv({ app })
      .then((res) => {
        if (res.status === 200) {
          setcsvUnSharedData(res.data.data.patientDetails);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await unSharedReportsBasedOnCompany({ app })
      .then((res) => {
        if (res.status === 200) {
          setunsharedreportdata(res.data.data.patientDetails);
          res.data.data[0].total_filtered_reports
            ? settotalunsharedreportfilteredcount(
                res.data.data[0].total_filtered_reports
              )
            : settotalunsharedreportfilteredcount(0);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await getReportViewStatus({ app })
      .then((res) => {
        setcount(res.data.data.reportViewedStatus[0].total);
        setopened(res.data.data.reportViewedStatus[0].opended);
        setunopened(res.data.data.reportViewedStatus[0].unopened);
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await unSharedReportsCountBasedOnCompany({ app })
      .then((res) => {
        setunsharedcount(res.data.data.reportViewedStatus[0].total);
        setunsharedreportopened(res.data.data.reportViewedStatus[0].opended);
        setunsharedreportunopened(res.data.data.reportViewedStatus[0].unopened);
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await branchDetailsBasedOnCompany({ app })
      .then((res) => {
        setbranches(res.data.data[1]);
        setTotalLocation(res.data.data[0][0].total_branches)
      })
      .catch((err) => console.log(err));

    await doctorListBasedOnCompany({ app })
      .then((res) => {
        setdoctorList(res.data.data.doctorList);
        setdoctorname(res.data.data.doctorList[0].name);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };

  const call = async () => {
    
  if (app == "63808e24a3be9e7f00c5c3b3") {
      setProspectTab(true);
  }
    let object = {
      filteropenedreport,
      genderinfo,
      doctorfilterinfo,
      reportstatusfilterinfo,
      patienttype,
      insurance,
      age,
      sorting,
      locationfilterinfo,
      currentpage,
      appointmentrequest,
      sharedadvertisinguser,
      app,
    };
    let objectunshared = {
      currentpage: currentpageunshared,
      insurance,
      sorting,
      app,
      sharedadvertisinguser,
    };
    await getAppDetails({ app })
      .then((res) => {
        setappdata(res.data.data);
        setshow(true);
      })
      .catch((err) => {
        console.log(err);
      });
    await getPatientDetails(object)
      .then((res) => {
        if (res.status === 200) {
          res.data.data[0].total_filtered_reports
            ? settotalreportfilteredcount(
                res.data.data[0].total_filtered_reports
              )
            : settotalreportfilteredcount(0);
          setdata(res.data.data.patientDetails);
          setuserdata([res.data.data.patientDetails.fromusers]);

          // setcsvdownload(res.data.data.patientDetails)
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });

    await getPatientDetailsforcsv(object)
      .then((res) => {
        if (res.status === 200) {
          setcsvdata(res.data.data.patientDetails);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await getPatientDetailsforUnSharedcsv(object)
      .then((res) => {
        if (res.status === 200) {
          setcsvUnSharedData(res.data.data.patientDetails);
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await unSharedReportsBasedOnCompany(objectunshared)
      .then((res) => {
        if (res.status === 200) {
          setunsharedreportdata(res.data.data.patientDetails);
          // console.log(res.data.data.patientDetails,"4444444444444")
          // settotalreportfilteredcount(res.data.data[0].total_filtered_reports)
          res.data.data[0].total_filtered_reports
            ? settotalunsharedreportfilteredcount(
                res.data.data[0].total_filtered_reports
              )
            : settotalunsharedreportfilteredcount(0);
          // console.log(totalreportfilteredcount,"ppppppppp")
        }
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });
    await unSharedReportsCountBasedOnCompany({ app }).then((res) => {
      setunsharedcount(res.data.data.reportViewedStatus[0].total);
      setunsharedreportopened(res.data.data.reportViewedStatus[0].opended);
      setunsharedreportunopened(res.data.data.reportViewedStatus[0].unopened);
    });
    await getReportViewStatus({ app })
      .then((res) => {
        setcount(res.data.data.reportViewedStatus[0].total);
        setopened(res.data.data.reportViewedStatus[0].opended);
        setunopened(res.data.data.reportViewedStatus[0].unopened);
      })
      .catch((err) => {
        // setloading(false)
        return err;
      });

    await branchDetailsBasedOnCompany({ app })
      .then((res) => {
        setTotalLocation(res.data.data[0][0].total_branches)
        setbranches(res.data.data[1]);
      })
      .catch((err) => console.log(err));
    await doctorListBasedOnCompany({ app })
      .then((res) => {
        // console.log(res.data.data,"doctordetails")
        // setdoctorList(res.data.data)
        setdoctorList(res.data.data.doctorList);

        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  // console.log(typeof data, data, "uuuuuuuuuuu");
  // console.log(typeof filteropenedreport, filteropenedreport, "ccccccccccccuu");

  const callSharedReportsTab = () => {
    setgender("");
    setpatienttype("");
    setgenderinfo({ languages: [] });
    setFilters({ gender: [], type: [] });
    setinsurance("");
    setage("");
    setdoctorfilterinfo({ doctors: [] });
    setreportstatusfilterinfo({ reports: [] });
    setsorting("");
    setdoctor("");
    setstatus("");
    setlocationfilterinfo({ branches: [] });
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedreport(true);
    setunsharedreport(false);
    setProspectUserTab(false);
    setsharedadvertisinguser("");
    call2();
  };
  const callUnSharedDetailsTab = () => {
    setgender("");
    setpatienttype("");
    setgenderinfo({ languages: [] });
    setFilters({ gender: [], type: [] });
    setinsurance("");
    setage("");
    setdoctorfilterinfo({ doctors: [] });
    setreportstatusfilterinfo({ reports: [] });
    setsorting("");
    setdoctor("");
    setstatus("");
    setlocationfilterinfo({ branches: [] });
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedreport(false);
    settotalreportfilteredcount(0);
    settotalunsharedreportfilteredcount(0);
    setcurrentpageunshared(0);
    setunsharedreport(true);
    setProspectUserTab(false);
    setappointmentrequest("");
    call2();
  };
  const callProspectsTab = () => {
    setpatienttype("");
    setFilters({ gender: [], type: [] });
    setinsurance("");
    setage("");
    setdoctorfilterinfo({ doctors: [] });
    setreportstatusfilterinfo({ reports: [] });
    setsorting("");
    setdoctor("");
    setstatus("");
    setlocationfilterinfo({ branches: [] });
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedreport(false);
    setProspectUserTab(true);
    settotalreportfilteredcount(0);
    settotalunsharedreportfilteredcount(0);
    setcurrentpageunshared(0);
    setunsharedreport(false);
    setappointmentrequest("");
    setProspectUserTab(true);
    call2();
  };


  useEffect((()=>{
    let object = {
      currentpage:currentpageDentist,
      app,
     limit:6
    };
    doctorListPaginationBasedOnCompany(object)
      .then((res) => {
        setTotalDentist(res.data.data[0][0].total_dentist);
        setdentistlistPagination(res.data.data[1]);
      })
      .catch((err) => console.log(err));
  }),[renderDentist])

  useEffect((()=>{
    let object = {
      currentpage:currentpageLocation,
      app,
     limit:6
    };
    console.log(object,"ccccc")
     branchDetailsBasedOnCompany(object )
    .then((res) => {
      console.log(res.data.data)
      setTotalLocation(res.data.data[0][0].total_branches)
      setbranchlistPagination(res.data.data[1]);
    })
    .catch((err) => console.log(err));
  }),[render])
  useEffect(() => {
    call();
  }, [change]);

  useEffect(() => {
    call2();
  }, []);

  if (loading) {
    return (
      <div>
        <Navbars />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    const handleChange = (e) => {
      const { value, checked } = e.target;
      const { languages } = genderinfo;
      if (checked) {
        setgenderinfo({
          languages: [...languages, value],
        });
        setchange(!change);
      }
      // Case 2  : The user unchecks the box
      else {
        setgenderinfo({
          languages: languages.filter((e) => e !== value),
        });
        setchange(!change);
      }
    };
    const handlenextpageLocation= () => {
      setcurrentpageLocation(currentpageLocation + 1);
      setrender(!render);
    };
    const handlepreviouspageLocation = () => {
      setcurrentpageLocation(currentpageLocation - 1);
      setrender(!render);
    };
    const handlenextpageDentist= () => {
      setcurrentpageDentist(currentpageDentist + 1);
      setrenderDentist(!renderDentist);
    };
    const handlepreviouspageDentist= () => {
      setcurrentpageDentist(currentpageDentist - 1);
      setrenderDentist(!renderDentist);
    };
    const handleDoctorFilterChange = (e) => {
      const { value, checked } = e.target;
      const { doctors } = doctorfilterinfo;

      if (checked) {
        setdoctorfilterinfo({
          doctors: [...doctors, value],
        });
        setchange(!change);
      }
      // Case 2  : The user unchecks the box
      else {
        setdoctorfilterinfo({
          doctors: doctors.filter((e) => e !== value),
        });
        setchange(!change);
      }
    };

    const handleReportFilterChange = (e) => {
      const { value, checked } = e.target;
      const { reports } = reportstatusfilterinfo;

      if (checked) {
        setreportstatusfilterinfo({
          reports: [...reports, value],
        });
        setchange(!change);
      }
      // Case 2  : The user unchecks the box
      else {
        setreportstatusfilterinfo({
          reports: reports.filter((e) => e !== value),
        });
        setchange(!change);
      }
    };
    const handlelocationFilterChange = (e) => {
      const { value, checked } = e.target;
      const { branches } = locationfilterinfo;

      if (checked) {
        setlocationfilterinfo({
          branches: [...branches, value],
        });
        setchange(!change);
      }
      // Case 2  : The user unchecks the box
      else {
        setlocationfilterinfo({
          branches: branches.filter((e) => e !== value),
        });
        setchange(!change);
      }
    };

    const handlesorting = (e) => {
      setsorting(e.target.value);
      setchange(!change);
    };

    const handlenextpage = () => {
      setcurrentpage(currentpage + 1);
      setchange(!change);
    };
    const handlepreviouspage = () => {
      setcurrentpage(currentpage - 1);
      setchange(!change);
    };

    const handlepreviouspageunshared = () => {
      setcurrentpageunshared(currentpageunshared - 1);
      setchange(!change);
    };
    const handlenextpageunshared = () => {
      setcurrentpageunshared(currentpageunshared + 1);
      setchange(!change);
    };

    const samplefunction = (e, values, index) => {
      setdoctor(e.target.value);
      const select = [...data];
      select[index].report_assigned_doctor = e.target.value;
      // setdata(select);
    };
    {
      return (
        <Container fluid>
          <Navbars />

          {sharedreport && (
            <Row>
              <Col className="my-1 " xs={12} md={3}>
                <div className="filter m-2 ">
                  <div className="pt-2 font-doctor mx-2">SUMMARY</div>

                  <div className="d-flex  w-5 justify-content-evenly ">
                    <Button
                      onClick={() => {
                        setfilteropenedreport("");
                        setchange(!change);
                      }}
                    >
                      <div className="summary-box mt-2 mr-1 summary-text px-3 py-2">
                        <span>Shared REPORTS</span>{" "}
                        <span className="font-60">{count}</span>
                      </div>
                    </Button>
                    <Button
                      onClick={() => {
                        setfilteropenedreport(true);
                        setchange(!change);
                      }}
                    >
                      <div className="summary-box mt-2  m-1 summary-text  px-3 py-2">
                        <span>OPENED REPORTS</span>{" "}
                        <span className="font-32">{opened}</span>
                      </div>
                    </Button>

                    <Button
                      onClick={() => {
                        setfilteropenedreport(false);
                        setchange(!change);
                      }}
                    >
                      <div className="summary-box mt-2 summary-text  px-2 py-2">
                        UNOPENED REPORTS{" "}
                        <span className="font-12"> {unopened}</span>
                      </div>
                    </Button>
                  </div>

                  <Col
                    md={12}
                    className="bg-warning line-horizontal mb-3 mt-2"
                  ></Col>
                  <Row>
                    <Col md={4} xs={4}>
                      <div className="d-flex justify-content-start align-items-center mx-2  ">
                        {" "}
                        <img
                          src={filter}
                          width="15px"
                          height="15px"
                          alt="filter"
                        ></img>
                        <div className=" mx-2 text-filter d-flex justify-content-start ">
                          Filter
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={7}
                      xs={7}
                      className=" d-flex w-5 justify-content-end text-reset-all"
                    >
                      <Button
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={() => {
                          setgender("");
                          setpatienttype("");
                          setgenderinfo({ languages: [] });
                          setFilters({ gender: [], type: [] });
                          setinsurance("");
                          setage("");
                          setdoctorfilterinfo({ doctors: [] });
                          setreportstatusfilterinfo({ reports: [] });
                          setsorting("");
                          setdoctor("");
                          setstatus("");
                          setlocationfilterinfo({ branches: [] });
                          settotalreportfilteredcount(0);
                          setcurrentpage(0);
                          setsharedreport(true);
                          setunsharedreport(false);
                          setsharedadvertisinguser("");
                          setfilteropenedreport("");
                          call2();
                        }}
                      >
                        {" "}
                        Clear Filters{" "}
                      </Button>
                    </Col>
                  </Row>

                  {/* ------------------------------------unshared report filter----------------------------------- */}

                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>

              

                  {/* -------------------------------User type filter------------------------ */}

                  {!prospectTab && <div>
                    <Col className="mb-2 mt-3 text-source mx-3">Type of User</Col>
                  <Row className="mx-1">
                    <Col md={1} xs={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setsharedadvertisinguser("true");
                          setchange(!change);
                        }}
                      >
                        <input
                             type="radio"
                          value={sharedadvertisinguser}
                          name="sharedadvertisinguser"
                          checked={sharedadvertisinguser == "true"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      xs={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Advertising URL
                    </Col>
                  </Row>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setsharedadvertisinguser("false");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          value={sharedadvertisinguser}
                          name="sharedadvertisinguser"
                          checked={sharedadvertisinguser == "false"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Office URL
                    </Col>
                  </Row>
                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>
                    </div>}
           

                
                  {/* -------------------------------Patient filter------------------------ */}

                  <Col className="mb-2 mt-3 text-source mx-3">
                    Type of Patient
                  </Col>
                  <Row className="mx-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setpatienttype("true");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          value={patienttype}
                          name="New Patient"
                          checked={patienttype == "true"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      New Patient
                    </Col>
                  </Row>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setpatienttype("false");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          value={patienttype}
                          name="Existing Patient"
                          checked={patienttype == "false"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Existing Patient
                    </Col>
                  </Row>
                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>

                  {/* -------------------------------Insurance filter------------------------ */}
{
  !prospectTab&& <div>
       <Col className="my-2 text-source mx-3">Insurance</Col>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setinsurance("true");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          defaultvalue={insurance}
                          name="insurance"
                          checked={insurance == "true"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Yes
                    </Col>
                  </Row>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setinsurance("false");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          defaultvalue={insurance}
                          name="no insurance"
                          checked={insurance == "false"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      No
                    </Col>
                  </Row>
                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>
  </div>
}
               


                  {/* -------------------------------Location filter------------------------ */}

                {!prospectTab && 
                 <div>  <Row className="mx-1  mt-3 text-source  d-flex">
                  <Col md={4}>Location</Col>
              
<Col md={8}>
<div className="button-flex d-flex">
<div className="p-1 d-flex">
                      <div className="mx-1">{currentpageLocation == 0
                          ? currentpageLocation + 1
                          : currentpageLocation * 6+ 1}
                        </div>  
                        -
                       <div className="mx-1">{currentpageLocation == totalpageLocation - 1
                          ? totalLocation
                          : (currentpageLocation + 1) * 6}{" "}
                        </div> 
                        <div className="mx-1">of</div>{" "}
                      <div className="mx-1"> {totalLocation}
                        </div> 
                      </div>
                    
                      <Button
                        disabled={currentpageLocation == 0}
                        onClick={handlepreviouspageLocation}
                      >
                        <ArrowBackIosIcon />
                      </Button>
                      <Button
                        disabled={
                          currentpageLocation ==
                          (totalpageLocation == 0 ? currentpageLocation : totalpageLocation - 1)
                        }
                        onClick={handlenextpageLocation}
                      >
                        <ArrowForwardIosIcon />
                      </Button>

                   {/* <div>Location</div> */}

                    </div>
</Col>
              
                    </Row>

{branchlistPagination.map((value, id) => {
  return (
    <Row className="mx-1 mt-1" key={id}>
      <Col md={1}>
        {" "}
        <input
          className="form-check-input"
          type="checkbox"
          name="location"
          value={value._id}
          id="flexCheckDefault"
          onChange={handlelocationFilterChange}
          checked={locationfilterinfo.branches.includes(
            value._id
          )}
        />
      </Col>
      <Col
        md={10}
        className="checkbox-text d-flex align-items-top"
      >
        {" "}
        <label htmlFor="flexCheckDefault">
          {value.name}{" "}
        </label>
      </Col>
    </Row>
  );
})}

<Col md={12} className="bg-warning line-horizontal m-1"></Col></div>
}
               

                  {/* -------------------------------Doctor filter------------------------ */}
                  <div>  <Row className="mx-1  mt-3 text-source  d-flex">
                  <Col md={4}>Dentist</Col>
              
<Col md={8}>
<div className="button-flex d-flex">
<div className="p-1 d-flex">
                      <div className="mx-1">{currentpageDentist == 0
                          ? currentpageDentist + 1
                          : currentpageDentist * 6+ 1}
                        </div>  
                        -
                       <div className="mx-1">{currentpageDentist == totalpageDentist - 1
                          ? totalDentist
                          : (currentpageDentist + 1) * 6}{" "}
                        </div> 
                        <div className="mx-1">of</div>{" "}
                      <div className="mx-1"> {totalDentist}
                        </div> 
                      </div>
                    
                      <Button
                        disabled={currentpageDentist == 0}
                        onClick={handlepreviouspageDentist}
                      >
                        <ArrowBackIosIcon />
                      </Button>
                      <Button
                        disabled={
                          currentpageDentist ==
                          (totalpageDentist == 0 ? currentpageDentist : totalpageDentist - 1)
                        }
                        onClick={handlenextpageDentist}
                      >
                        <ArrowForwardIosIcon />
                      </Button>

                   {/* <div>Location</div> */}

                    </div>
</Col>
              
                    </Row>

                    {dentistlistPagination.map((value, id) => {
                    return (
                      <Row className="mx-1 mt-1" key={id}>
                        <Col md={1}>
                          {" "}
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="doctorFilter"
                            value={value._id}
                            id="doctorFilterDefault"
                            onChange={handleDoctorFilterChange}
                            checked={doctorfilterinfo.doctors.includes(
                              value._id
                            )}
                          />
                        </Col>
                        <Col
                          md={10}
                          className="checkbox-text d-flex align-items-top"
                        >
                          {" "}
                          <label htmlFor="doctorFilterDefault">
                            Dr. {value.name}
                          </label>
                        </Col>
                      </Row>
                    );
                  })}


<Col md={12} className="bg-warning line-horizontal m-1"></Col></div>

             
                </div>
              </Col>

              {/* -------------------------------Report body display------------------------ */}

              <Col className="  my-2 " xs={12} md={9}>
                <div className="lists m-2">
                  <div className="d-flex  w-5 ">
                    <FormLabel className="font-doctor">
                      <Button
                        onClick={() => {
                          callSharedReportsTab();
                        }}
                      >
                        <div className="d-flex align-items-center w-5 h-5">
                          <div>
                            {sharedreport && (
                              <img
                                src={dentalAnalysisimg}
                                width="25px"
                                height="25px"
                                alt="filter"
                              ></img>
                            )}
                          </div>
                          <div
                            className={
                              sharedreport
                                ? "shared_report_text mx-1"
                                : "unselected_report_text_lite"
                            }
                          >
                            Shared Reports
                          </div>
                        </div>
                      </Button>

                      {
                        !prospectTab &&
                        <Button
                        className="mx-2"
                        onClick={() => {
                          callUnSharedDetailsTab();
                        }}
                      >
                        <div className="unselected_report_text_lite">
                          {" "}
                          UnShared Details
                        </div>
                      </Button>
                      }
                     
                      {prospectTab && (
                        <Button
                          className="mx-2"
                          onClick={() => {
                            callProspectsTab();
                          }}
                        >
                          <div className="unselected_report_text_lite">
                            {" "}
                            Prospects
                          </div>
                        </Button>
                      )}
                    </FormLabel>

                    <div className="button-flex flex-row-reverse p-1 ">
                      <Button
                        disabled={
                          currentpage ==
                          (totalpage == 0 ? currentpage : totalpage - 1)
                        }
                        onClick={handlenextpage}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                      <Button
                        disabled={currentpage == 0}
                        onClick={handlepreviouspage}
                      >
                        <ArrowBackIosIcon />
                      </Button>
                      <div className="p-1 ">
                        {currentpage == 0
                          ? currentpage + 1
                          : currentpage * 8 + 1}
                        -
                        {currentpage == totalpage - 1
                          ? totalreportfilteredcount
                          : (currentpage + 1) * 8}{" "}
                        <span className="mx-1">of</span>{" "}
                        {totalreportfilteredcount}
                      </div>

                      <select
                        className="form-btns-dashboard text-sans-bold mx-3"
                        value={sorting}
                        onChange={handlesorting}
                      >
                        <option value="" className="text-sans-bold ">
                          Sort By{" "}
                        </option>
                        <option value="Newest on top">Newest on top </option>
                        <option value="Oldest on top">Oldest on top</option>
                        <option value="First Name">First Name (A-Z)</option>
                        <option value="First Name Des">First Name (Z-A)</option>
                      </select>
                      {/* </Button> */}

                      <CSVLink {...csvReport}>
                        <Button> <FaDownload size="22px" color='#0090FF'className='mx-2'  /></Button>
                      </CSVLink>
                    </div>
                  </div>

                  {data.map((value, index) => {
                    const statusfilter = (e, values) => {
                      setstatus(e.target.value);
                      value.report_status = e.target.value;
                    };
                    return (
                      <Row
                        key={index}
                        className={
                          value.is_report_viewed
                            ? "patients-row m-1  mx-3"
                            : " patients-rows m-1 mx-3"
                        }
                      >
                        <div className=" d-flex width-100 ">
                          <Col md={3} className="  height-100">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to={`/patient-detail-report/${value.fromreport[0]._id}/${value._id}`}
                            >
                              <div className="d-flex h-100 w-5 margin-left">
                                <Col md={11} className="">
                                  <div className="d-flex w-5 height-100">
                                    <Col
                                      md={3}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans mx-1">Name</div>
                                      <div className="text-sans mx-1">
                                        Email
                                      </div>
                                    </Col>

                                    <Col
                                      md={9}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans-bold ellipsis">
                                        {value.fromusers[0].first_name}  {value.fromusers[0].last_name}

                                      </div>
                                      <div className="text-sans-bold ellipsis">
                                        {value.fromusers[0].email}
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </div>
                            </Link>
                          </Col>
                          <Col md={2} className=" dotted_line height-100">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to={`/patient-detail-report/${value.fromreport[0]._id}/${value._id}`}
                              state={{ from: "occupation" }}
                            >
                              <div className="d-flex h-100 w-5 margin-left">
                                <Col md={11} className="">
                                  <div className="d-flex w-5 height-100">
                                    <Col
                                      md={5}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans mx-1">
                                        Ph.no
                                      </div>
                                      <div className="text-sans mx-1">Age</div>
                                    </Col>

                                    <Col
                                      md={7}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans-bold ">
                                        {" "}
                                        {value.fromusers[0].phone_number}
                                      </div>
                                      <div className="text-sans-bold">
                                        {calculateAge(value.fromusers[0].dob)}
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </div>
                            </Link>
                          </Col>
                          <Col md={3} className=" dotted_line height-100">
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              to={`/patient-detail-report/${value.fromreport[0]._id}/${value._id}`}
                            >
                              <div className="d-flex h-100 w-5 margin-left">
                                <Col md={11} className="">
                                  <div className="d-flex w-5 height-100">
                                    <Col
                                      md={5}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans mx-3">
                                        {" "}
                                        Rep.Date
                                      </div>

                                      <div className="text-sans mx-3">
                                   {prospectTab?"City":"Insured"}
                                      </div>
                                    </Col>

                                    <Col
                                      md={7}
                                      className=" d-flex flex-column justify-content-evenly  height-100"
                                    >
                                      <div className="text-sans-bold">
                                        {moment(
                                          value.createdAt
                                        ).format("MM/DD/YYYY")}
                                      </div>

                                      <div className="text-sans-bold ellipsis_state">
                            { prospectTab? value.fromusers.city:value.fromusers.is_insured
                                          ? "Yes"
                                          : "No" }           
                                      </div>
                                    </Col>
                                  </div>
                                </Col>
                              </div>
                            </Link>
                          </Col>

                          <Col md={3} className=" dotted_line height-100">
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={12} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={7}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-3">
                                      Assigned Dentist
                                    </div>

                                    <div className="text-sans mx-3">State</div>
                                  </Col>

                                  <Col
                                    md={5}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold ellipsis_state">
                                      {value.fromdoctors[0]
                                        ? `Dr. ${value.fromdoctors[0].name}`
                                        : "---"}
                                    </div>

                                    <div className="text-sans-bold ellipsis_state">
                                      {" "}
                                      {value.fromusers[0].state}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col md={1}>
                            <Col
                              md={12}
                              className="d-flex height-100  align-items-center"
                            >
                              <Col
                                md={6}
                                className="d-flex  height-100  align-items-center"
                              >
                                <Button
                                  // style={{ display: 'none' }}
                                  onClick={() => {
                                    setSendToDentistIdForDentistAssignment(
                                      value._id
                                    );
                                    setassignedDentistPopup(true);
                                  }}
                                >
                                  {" "}
                                  <FaUserMd size="22px" color="#8787e8;" />
                                </Button>
                              </Col>

                              <Col
                                md={6}
                                className="d-flex  height-100  align-items-center"
                              >
                        {value.fromusers.email!="support@toothlens.com" &&   <Button
                                  // id={value._id}
                                  // style={{ display: 'none' }}
                                  onClick={() => {
                                    setdeletepopup(true);
                                    setdeletereportid(value._id);
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={Delete}
                                    alt="Delete"
                                    style={{ cursor: "pointer" }}
                                    height="55px"
                                    width="39px"
                                  />
                                </Button> }     
                         
                              </Col>
                            </Col>
                          </Col>
                        </div>
                      </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>
          )}

          {unsharedreport && (
            <Row>
              <Col className="my-1 " md={3}>
                <div className="filter m-2 ">
                  <div className="pt-2 font-doctor mx-2">SUMMARY</div>

                  <div className="d-flex  w-5 mx-2 ">
                    {/* <div className="summary-box mt-2 mr-1 summary-text px-3 py-2">
                      <span>Total Reports</span>{" "}
                      <span className="font-60">api</span>
                    </div>
                    <div className="summary-box mt-2  m-1 summary-text  px-3 py-2">
                      <span>Shared Reports</span>{" "}
                      <span className="font-32">{count}</span>
                    </div> */}
                    <div className="summary-box mt-2 summary-text-prospect px-2 py-2">
                      UnShared REPORTS
                      <span className="font-12">{unsharedcount}</span>
                    </div>
                  </div>

                  <Col
                    md={12}
                    className="bg-warning line-horizontal mb-3 mt-2"
                  ></Col>
                  <Row>
                    <Col md={7}>
                      <div className="d-flex justify-content-start align-items-center mx-2  ">
                        {" "}
                        <img
                          src={filter}
                          width="15px"
                          height="15px"
                          alt="filter"
                        ></img>
                        <div className=" mx-2 text-filter d-flex justify-content-start ">
                          Filter
                        </div>
                      </div>
                    </Col>
                    <Col
                      md={4}
                      className=" d-flex w-5 justify-content-end text-reset-all"
                    >
                      <Button
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={() => {
                          setgender("");
                          setpatienttype("");
                          setgenderinfo({ languages: [] });
                          setFilters({ gender: [], type: [] });
                          setinsurance("");
                          setage("");
                          setdoctorfilterinfo({ doctors: [] });
                          setreportstatusfilterinfo({ reports: [] });
                          setsorting("");
                          setdoctor("");
                          setstatus("");
                          setlocationfilterinfo({ branches: [] });
                          settotalreportfilteredcount(0);
                          settotalunsharedreportfilteredcount(0);
                          setcurrentpage(0);
                          setcurrentpageunshared(0);
                          setsharedadvertisinguser("");
                          call2();
                        }}
                      >
                        {" "}
                        Clear Filters{" "}
                      </Button>
                    </Col>
                  </Row>

                  {/* -------------------------------Patient filter------------------------ */}
                  {/* -------------------------------User type filter------------------------ */}

                  <Col className="mb-2 mt-3 text-source mx-3">Type of User</Col>
                  <Row className="mx-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setsharedadvertisinguser("true");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          value={sharedadvertisinguser}
                          name="sharedadvertisinguser"
                          checked={sharedadvertisinguser == "true"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Advertising URL
                    </Col>
                  </Row>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setsharedadvertisinguser("false");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          value={sharedadvertisinguser}
                          name="sharedadvertisinguser"
                          checked={sharedadvertisinguser == "false"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Office URL
                    </Col>
                  </Row>
                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>

                  {/* -------------------------------Insurance filter------------------------ */}

                  <Col className="my-2 text-source mx-3">Insurance</Col>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setinsurance("true");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          defaultvalue={insurance}
                          name="insurance"
                          checked={insurance == "true"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      Yes
                    </Col>
                  </Row>
                  <Row className="mx-1 mt-1">
                    <Col md={1}>
                      {" "}
                      <div
                        className="radio-btn"
                        onClick={() => {
                          setinsurance("false");
                          setchange(!change);
                        }}
                      >
                        <input
                          type="radio"
                          defaultvalue={insurance}
                          name="no insurance"
                          checked={insurance == "false"}
                        />
                      </div>
                    </Col>
                    <Col
                      md={10}
                      className="checkbox-text d-flex align-items-top"
                    >
                      No
                    </Col>
                  </Row>
                  <Col md={12} className="bg-warning line-horizontal m-1"></Col>
                </div>
              </Col>

              {/* -------------------------------Un shared details body display------------------------ */}

              <Col className="  my-2 " md={9}>
                <div className="lists m-2">
                  <div className="d-flex  w-5 ">
                    <FormLabel className="font-doctor">
                      <Button
                        onClick={() => {
                          callSharedReportsTab();
                        }}
                      >
                        <div className="unselected_report_text_lite">
                          {" "}
                          Shared Reports
                        </div>
                      </Button>

                      {
                        !prospectTab &&   <Button
                        className="mx-2"
                        onClick={() => {
                          callUnSharedDetailsTab();
                        }}
                      >
                        <div className="d-flex align-items-center w-5 h-5">
                          <div>
                            {unsharedreport && (
                              <img
                                src={dentalAnalysisimg}
                                width="25px"
                                height="25px"
                                alt="filter"
                              ></img>
                            )}
                          </div>
                          <div className={"shared_report_text mx-1"}>
                            UnShared Details
                          </div>
                        </div>
                      </Button>
                      }
                    
                      {prospectTab && (
                        <Button
                          className="mx-2"
                          onClick={() => {
                            callProspectsTab();
                          }}
                        >
                          <div className="d-flex align-items-center w-5 h-5">
                            <div className="unselected_report_text_lite mx-3">
                              {" "}
                              Prospects
                            </div>
                          </div>
                        </Button>
                      )}
                    </FormLabel>

                    {/* <div className='d-flex bg-warning'>
        

        </div> */}
                    <div className="button-flex flex-row-reverse p-1 ">
                      <Button
                        disabled={
                          currentpageunshared ==
                          (totalpageunshared == 0
                            ? currentpageunshared
                            : totalpageunshared - 1)
                        }
                        onClick={handlenextpageunshared}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                      <Button
                        disabled={currentpageunshared == 0}
                        onClick={handlepreviouspageunshared}
                      >
                        <ArrowBackIosIcon />
                      </Button>
                      <div className="p-1 ">
                        {currentpageunshared == 0
                          ? currentpageunshared + 1
                          : currentpageunshared * 8 + 1}
                        -
                        {currentpageunshared == totalpageunshared - 1
                          ? totalunsharedreportfilteredcount
                          : (currentpageunshared + 1) * 8}{" "}
                        <span className="mx-1">of</span>{" "}
                        {totalunsharedreportfilteredcount}
                      </div>
                      {/* <Button className="form-btns-dashboard m-1">
                  <img src={UpArrow} alt="uparrow" />
                </Button> 
                     <Button className="form-btns-dashboard m-1">  */}
                      <select
                        className="form-btns-dashboard text-sans-bold mx-3"
                        value={sorting}
                        onChange={handlesorting}
                      >
                        <option value="" className="text-sans-bold ">
                          Sort By{" "}
                        </option>
                        <option value="Newest on top">Newest on top </option>
                        <option value="Oldest on top">Oldest on top</option>
                        <option value="First Name">First Name (A-Z)</option>
                        <option value="First Name Des">First Name (Z-A)</option>
                      </select>
                      <CSVLink {...csvReportUnShared}>
                        <Button> <FaDownload size="22px" color='#0090FF'className='mx-2'  /></Button>
                      </CSVLink>
                      {/* </Button> */}
                    </div>
                  </div>

                  {unsharedreportdata.map((value, index) => {
                    return (
                      <Row
                        key={index}
                        className="patients-row m-1 mx-3"
                      >
                        <div className=" d-flex width-100 ">
                          <Col md={3} className=" dotted_line height-100">
                            {/* <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={`/patient-detail-report/${value.fromreport._id}/${value._id}`}
                          > */}
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={3}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-1">Name</div>
                                    <div className="text-sans mx-1">Email</div>
                                  </Col>

                                  <Col
                                    md={9}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold ellipsis">
                                      {value.fromusers.first_name} {value.fromusers.last_name}
                                    </div>
                                    <div className="text-sans-bold ellipsis">
                                      {value.fromusers.email}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                            {/* </Link> */}
                          </Col>
                          <Col md={2} className=" dotted_line height-100">
                            {/* <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={`/patient-detail-report/${value.fromreport._id}/${value._id}`}
                            state={{ from: "occupation" }}
                          > */}
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={5}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-1">Ph.no</div>
                                    <div className="text-sans mx-1">Age</div>
                                  </Col>

                                  <Col
                                    md={7}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold ">
                                      {" "}
                                      {value.fromusers.phone_number}
                                    </div>
                                    <div className="text-sans-bold">
                                      {calculateAge(value.fromusers.dob)}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                            {/* </Link> */}
                          </Col>
                          <Col md={3} className=" dotted_line height-100">
                            {/* <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={`/patient-detail-report/${value.fromreport._id}/${value._id}`}
                          > */}
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={6}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-3">
                                      {" "}
                                      Report Date
                                    </div>

                                    <div className="text-sans mx-3">
                                      Insured
                                    </div>
                                  </Col>

                                  <Col
                                    md={6}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold">
                                      {moment(value.createdAt).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </div>

                                    <div className="text-sans-bold">
                                      {value.fromusers.is_insured
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                            {/* </Link> */}
                          </Col>

                          <Col md={3} className=" dotted_line height-100">
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={12} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={8}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-3">
                                      Overall Cavity Score
                                    </div>

                                    <div className="text-sans mx-3">
                                      Overall Tartar Score{" "}
                                    </div>
                                  </Col>

                                  <Col
                                    md={4}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold">
                                      {value.report_score[0].overall_score[1]}
                                    </div>

                                    <div className="text-sans-bold">
                                      {" "}
                                      {value.report_score[0].overall_score[0]}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Col>

                          <Col md={1}>
                            <Col
                              md={12}
                              className="d-flex height-100  align-items-center"
                            >
                              <Col
                                md={12}
                                className="d-flex  height-100  align-items-center"
                              >
                              
                            { value.fromusers.email!="support@toothlens.com" &&  <Button
                                  id={value._id}
                                  // style={{ display: 'none' }}
                                  onClick={() => {
                                    setdeletepopupUnShared(true);
                                    setdeletereportid(value._id);

                                    // DeleteReportByIdOnReport({ id: value._id })
                                    //   .then((res) => {
                                    //     setchange(!change);
                                    //   })
                                    //   .catch((err) => console.log(err));
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={Delete}
                                    alt="Delete"
                                    style={{ cursor: "pointer" }}
                                    height="55px"
                                    width="39px"
                                  />
                                </Button>} 
                              </Col>
                            </Col>
                          </Col>
                        </div>
                      </Row>
                    );
                  })}
                </div>
              </Col>
            </Row>
          )}

          {prospectUserTab && (
            <ProspectUsers
              unSharedDetails={callUnSharedDetailsTab}
              sharedReports={callSharedReportsTab}
              prospect={callProspectsTab}
              showProspect={prospectTab}
            />
          )}

          {/* --------------------------dddddddddeelete popup---------------------- */}
          {deletepopup && (
            <div className="model">
              <div className="model_container">
                <div className="close_position">
                  <Button
                    onClick={() => {
                      call();
                      setdeletepopup(false);
                    }}
                  >
                    {" "}
                    <img src={close} width="50px" height="50px"></img>
                  </Button>
                </div>
                <Col md={12}>
                  <Row>
                    <Col
                      md={6}
                      className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
                    >
                      <img
                        src={teethcry}
                        width="150px"
                        height="150px"
                        // alt="filter"
                      ></img>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column justify-content-center h-5 align-items-center"
                    >
                      <div>Are you sure want </div>
                      <div> to delete from Dashboard?</div>
                      <Button
                        className="delete_popup_button mt-4"
                        onClick={() => {
                          DeleteReportById({ id: deletereportid })
                            .then((res) => {
                              setchange(!change);
                              setdeletepopup(false);
                            })
                            .catch((err) => console.log(err));
                        }}
                      >
                        <span className="delete_button_text">Confirm</span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          )}

          {/* --------------------------dddddddddeelete popup un Shared---------------------- */}
          {deletepopupUnShared && (
            <div className="model">
              <div className="model_container">
                <div className="close_position">
                  <Button
                    onClick={() => {
                      call();
                      setdeletepopupUnShared(false);
                    }}
                  >
                    {" "}
                    <img src={close} width="50px" height="50px"></img>
                  </Button>
                </div>
                <Col md={12}>
                  <Row>
                    <Col
                      md={6}
                      className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
                    >
                      <img
                        src={teethcry}
                        width="150px"
                        height="150px"
                        // alt="filter"
                      ></img>
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column justify-content-center h-5 align-items-center"
                    >
                      <div>Are you sure want </div>
                      <div> to delete the report permanently?</div>
                      <Button
                        className="delete_popup_button mt-4"
                        onClick={() => {
                          DeleteReportByIdOnReport({ id: deletereportid })
                            .then((res) => {
                              setchange(!change);
                              setdeletepopupUnShared(false);
                            })
                            .catch((err) => console.log(err));
                        }}
                      >
                        <span className="delete_button_text">Confirm</span>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>
          )}
          {/* ------------------------------------useredit popup--------------------------- */}

          {usereditpopup && (
            <EditUserDetailsPopUp
              open={usereditpopup}
              onClose={onCloseUserPopup}
              userId={userId}
            />
          )}

          {/* ----------------------------------Dentist update popup----------------- */}

          {assignedDentistPopup && (
            <AssignDentistPopup
              sendToDentistId={SendToDentistIdForDentistAssignment}
              popupCloseWithoutApi={closeAssigndentistPopupWithoutApi}
              popupclose={closeAssigndentistPopup}
            />
          )}
          {/* -------------------------------------Snackbar-------------------------------------------------------- */}

          {snackbar && (
            <SnackFire
              open={snackbar}
              onClose={handleclose}
              position="top-right"
              timing={5000}
              type={snacktype}
              message={snackbarmessage}
            />
          )}
        </Container>
      );
    }
  }
};
export default Dashboard;
