import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  annotatedNotReviewedReports
} from "../../Services/AuthService";
import Container from "react-bootstrap/Container";
import NavbarPatientEnquiry from "./navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import { Link, useNavigate } from "react-router-dom";
import Annotation from "../Annotation/annotation";

const PatientReportAnnotator = () => {
  localStorage.setItem("consultingUsersTabNav", true);
  localStorage.setItem("propspectTabNav", false);
  localStorage.setItem("analyticsTabNav", false);
  const navigate = useNavigate();
 
  const [loading, setLoading] = useState(true);

  const [reportList, setReportList] = useState();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
const [style,setStyle]=useState()
  const [annotation, setAnnotation] = useState(
    JSON.parse(localStorage.getItem("admin")).admin.app ==
      "63808e24a3be9e7f00c5c3b3"
      ? true
      : false
  );
  const [annotationImage,setAnnotationImage]=useState();
  const [annotationActiveImage,setAnnotationActiveImage]=useState()
 
  const [annotationStatus,setAnnotationStatus]=useState({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})
  
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  

  const call = async () => {
    setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})

    await annotatedNotReviewedReports().then(res=>{
      setReportList(res.data.data)
      setAnnotationActiveImage(res.data.data[0].front_teeth)
      setAnnotationImage(res.data.data[0])
      setStyle(1)
      setLoading(false)
    }).catch(err=>{
      console.log(err)
    })
  };


  useEffect(() => {
    call();
  }, []);

  if (loading) {
    return (
      <div>
        <NavbarPatientEnquiry />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container fluid>
        <NavbarPatientEnquiry />
      
        <Row>
          <Col md={3} className="mt-1 ">
            <div className="patient_status_annotator_display_board mt-2 overflow-scroll">
              <Row>
               

             
                <Col md={12} className="">
                  <span className="patient_status m-2 mx-4 d-flex w-5 justify-content-center "> Reports and Annotator Name</span>

                  <Container className=" ">
                    {reportList.map((value, index) => {
                      return (
                        <Row key={index}>
                          <Button
                            onClick={async () => {
                              setStyle(index+1)
                              setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})

                              setAnnotationActiveImage(reportList[index].front_teeth)
                              setAnnotationImage(reportList[index])
                            }}
                          >
                            <Col md={12}
                            >
                              <Row className={style==index+1?"bg-dark":"bg-light"}>
                                <Col md={1}>{index+1} </Col>
                                <Col md={8} className="px-1 "> {value._id}</Col>
                                <Col md={4}> <b className="px-1 overflow-auto"> {value.fromToolUsers[0]?.first_name}{value.fromToolUsers[0]?.last_name}</b>
</Col>
                              </Row>
                            </Col>
                          </Button>
                        </Row>
                      );
                    })}
                  </Container>
                </Col>

               
              </Row>
            </div>
          </Col>

          <Col md={9} className="mt-2 overflow-scroll">
        
             
            <div className="d-flex   analysis-background">
              <Button
                onClick={() => {
                  setAnnotationStatus({front_teeth:true, upper_jaw:false,lower_jaw:false,right_side:false,left_side:false})

                    setAnnotationActiveImage(annotationImage.front_teeth)
                }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.front_teeth && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.front_teeth
                          ? "dental_analysis_text px-2"
                          : "dental_analysis_text_lite px-2"
                      }
                    >
                      Front Teeth
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:true,lower_jaw:false,right_side:false,left_side:false})

                    setAnnotationActiveImage(annotationImage.upper_jaw)

                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.upper_jaw && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.upper_jaw? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Upper Jaw
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:true,right_side:false,left_side:false})
                    setAnnotationActiveImage(annotationImage.lower_jaw)

                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.lower_jaw && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.lower_jaw ? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Lower Jaw
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:false,right_side:true,left_side:false})

                    setAnnotationActiveImage(annotationImage.right_side)
                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.right_side && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.right_side
                          ? "  dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Right side
                    </div>
                  </div>
                </Button>
                <Button
                  onClick={() => {
                    setAnnotationStatus({front_teeth:false, upper_jaw:false,lower_jaw:false,right_side:false,left_side:true})

                    setAnnotationActiveImage(annotationImage.left_side)
                  }}
                >
                  <div className="d-flex mx-3 align-items-center ">
                    {annotationStatus.left_side && (
                      <img
                        src={dentalAnalysisimg}
                        width="25px"
                        height="25px"
                        alt="filter"
                      ></img>
                    )}
                    <div
                      className={
                        annotationStatus.left_side ? " dental_analysis_text px-2"
                          : " dental_analysis_text_lite px-2"
                      }
                    >
                      Left side
                    </div>
                  </div>
                </Button>
              </div>
            
            
            
                
             
                
                 <Row>
                      <Col md={12}>
                    <Annotation annotation={annotationActiveImage} />
                  </Col>
                
                  </Row>
                
               
                
               

       
          </Col>
        </Row>
      </Container>
    );
  }
};

export default PatientReportAnnotator

