import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/navbarLogo.svg";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import apollo from "../../assets/apollo.svg";
import FormLabel from "@mui/material/FormLabel";
import camera from "../../assets/camera.svg";

const DentalClinic = () => {
  return (
    <Container fluid>
      <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              <img
                alt=""
                src={logo}
                width="40px"
                height="40px"
                //   width="0"
                //   height="30"
                className="d-inline-block align-top"
              />{" "}
            </div>

            <div className="navbar_text">My Clinic</div>
          </div>
        </Container>
      </Navbar>

      <Row>
        <Col md={5} className="pic_content">
          <div className="apollo_image mt-5  bg-warning positions">
            <div className="absolute">
              <img src={camera} alt="upload absolute" />
            </div>
          </div>
        </Col>

        <Col md={7}>
          <Col md={6}>
            <Col className="mx-5 mt-5 mb-4 positions ">
              <div className="dental_office_text "> Dental Office</div>
              <Col md={2} className="mt-1">
                {" "}
                <div className="underline"></div>
              </Col>
              {/* <div >hello</div> */}
            </Col>
            {/* <Col>helo</Col> */}

            <Col className="mx-5">
              <FormLabel
                component="legend"
                className="ml-3  pl-5 font-weight-500 text-dark "
              >
                <span className="field-texts mx-3">Dental Office name</span>
              </FormLabel>
            </Col>

            <Col className="mt-1 mx-5">
              {" "}
              <input
                id="standard-basic"
                className="form-input mb-3 grey-bg-color"
                placeholder="380 Dental"
              />
            </Col>

            <Col className="mx-5">
              <FormLabel
                component="legend"
                className="ml-3  pl-5 font-weight-500 text-dark "
              >
                <span className="field-texts mx-3">Address</span>
              </FormLabel>
            </Col>

            <Col className="mt-1 mx-5">
              {" "}
              <input
                id="standard-basic"
                className="form-input mb-3 grey-bg-color"
                placeholder="380 Dental"
              />
            </Col>

            <Col className="mx-5">
              <FormLabel
                component="legend"
                className="ml-3  pl-5 font-weight-500 text-dark "
              >
                <span className="field-texts mx-3">City</span>
              </FormLabel>
            </Col>

            <Col className="mt-1 mx-5">
              {" "}
              <input
                id="standard-basic"
                className="form-input mb-3 grey-bg-color"
                placeholder="380 Dental"
              />
            </Col>

            <Col className="mx-5">
              <FormLabel
                component="legend"
                className="ml-3  pl-5 font-weight-500 text-dark "
              >
                <span className="field-texts mx-3">ZipCode</span>
              </FormLabel>
            </Col>

            <Col className="mt-1 mx-5">
              {" "}
              <input
                id="standard-basic"
                className="form-input mb-3 grey-bg-color"
                placeholder="380 Dental"
              />
            </Col>

            <Col className="mx-5">
              <FormLabel
                component="legend"
                className="ml-3  pl-5 font-weight-500 text-dark "
              >
                <span className="field-texts mx-3">Reg.ID</span>
              </FormLabel>
            </Col>

            <Col className="mt-1 mx-5">
              {" "}
              <input
                id="standard-basic"
                className="form-input mb-3 grey-bg-color"
                placeholder="380 Dental"
              />
            </Col>

            <Col className="mx-5 mt-3">
              <Button className="form-btns">NEXT</Button>
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default DentalClinic;
