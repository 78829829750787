import React, { PureComponent, useState } from "react";
import Slider from "@mui/material/Slider";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./practice.css";
const Example = (props) => {
  const cavity = props.cavity;
  const name = props.name;
  const color= props.shade=="json"?"warning":"primary";
  const count = 8;
  return (
    <div>
      <Col md={12} className="">
        <Row>
        { JSON.parse(localStorage.getItem("admin")).admin.app ==
      "63808e24a3be9e7f00c5c3b3"
      ? <Col md={6}>
      <div  className= "over-flow-anywhere d-flex w-5 justify-content-start "><b>{name}</b></div></Col>
      : <Col md={5}>
      <div  className= "over-flow-anywhere d-flex w-5 justify-content-start "><b>{name}</b></div></Col>} 
          <Col md={5}>
            <Slider
              value={cavity}
              step={2}
              min={0}
              max={count}
              valueLabelDisplay="auto"
              color={color}
              // className="mx-2  "
            />
          </Col>
          <Col md={1} className=" my-1">({cavity})</Col>
        </Row>
      </Col>
    </div>
  );
};
export default Example;
