import React, { useState } from "react";
import teethcry from "../../assets/teethcry.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import close from "../../assets/Close.svg";
import {
  deleteDentistBasedOnId,
  deleteBranchBasedOnId,
} from "../../Services/AuthService";
import { FaBuilding } from "react-icons/fa";
import SnackFire from "../../UI/Snackbar/Snackbar";

const LocationDeletePopup = ({
  deletePopupStatus,
  deleteBranchid,
  change,
  email,
}) => {
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <div>
      {" "}
      <div className="model">
        <div className="model_container">
          <div className="close_position">
            <Button
              onClick={() => {
                deletePopupStatus();
              }}
            >
              {" "}
              <img src={close} width="50px" height="50px"></img>
            </Button>
          </div>
          <Col md={12}>
            <Row>
              <Col
                md={6}
                className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
              >
                <FaBuilding size="150px" color="#FFFF" className="mx-2" />

                {/* <img
                  src={teethcry}
                  width="150px"
                  height="150px"
                  // alt="filter"
                ></img> */}
              </Col>
              <Col
                md={6}
                className="d-flex flex-column justify-content-center h-5 align-items-center"
              >
                <div>Are you sure want </div>
                <div> to delete the </div>
                <div>Location details permanently?</div>
                <Button
                  className="delete_popup_button mt-4"
                  onClick={() => {
                    deleteBranchBasedOnId({ id: deleteBranchid, email })
                      .then((res) => {
                        setsnackbarmessage(res.data.message);
                        JSON.stringify(res.data.data) === "{}"
                          ? settype("error")
                          : settype("success");
                        setsnackbar(true);
                        if (JSON.stringify(res.data.data) != "{}") {
                          change();
                        }
                      })
                      .catch((err) => {
                        setsnackbarmessage(err.message);
                        setsnackbar(true);
                      });
                  }}
                >
                  <span className="delete_button_text">Confirm</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
      {snackbar && (
        <SnackFire
          open={snackbar}
          onClose={handleclose}
          position="top-right"
          timing={5000}
          type={type}
          message={snackbarmessage}
        />
      )}
    </div>
  );
};

export default LocationDeletePopup;
