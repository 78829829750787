import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import "./accountDetails.css";
import Button from "@mui/material/Button";
import camera from "../../assets/camera.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  clinicPic,
  clinicDetails,
  updateDentistNameFromOfficeDetails,
  getLatestClinicDetails,
  getBinaryDataOfClinicImage,
} from "../../Services/AuthService";
import {
  //   clinicPic,
  //   clinicDetails,
  //   updateDentistNameFromOfficeDetails,
  //   getLatestClinicDetails,
  //   getBinaryDataOfClinicImage,
    getClinicDetailsUsingAdminMail,
    postDeploymentAppDetails,
    getAdminDetailsByMail
  } from "../../Services/AuthDeploymentService";
import ImagePreview from "./imagePreview";
import { useNavigate,useLocation } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import SnackFire from "../../UI/Snackbar/Snackbar";

const Deployment = () => {
  const navigate = useNavigate();
  const location = useLocation();
// console.log(location.state,"llllllll")
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const [clinicDetailsData, setClinicDetailsData] = useState();
  const [loading, setloading] = useState(true);
  const [imgs, setimgs] = useState();
  const [photo, setphoto] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snacktype, setsnacktype] = useState("error");
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const handleclose = () => {
    setsnackbar(false);
  };

  
  useEffect(() => {
    if( location.state!=null){
      getLatestClinicDetails({ user_id: location.state._id})
      .then((res) => {
        if (res.status === 200) {
          setClinicDetailsData(res.data.data[0]);
          if (!res.data.data.length == 0) {
            if (res.data.data[0].clinic_image_name) {
              const imageName = res.data.data[0].clinic_image_name.split("/")[1]
              getBinaryDataOfClinicImage(imageName).then((res) => {
                if (res.status == 200) {
                  const byteArray = new Uint8Array(res.data.data.binaryData.data); // Convert the array of integers to a byte array
                  const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
                  const imageUrl = URL.createObjectURL(blob);
                  setimgs([imageUrl]);
                  setphoto(true);
                } 
              }).catch((err) => {
                setphoto(false);
              })
            } 
            // else if (res.data.data[0].fullUrl.length != 0) {
            //   setimgs([`${res.data.data[0].fullUrl}`]);
            //   setphoto(true);
            // }
          }
          // if(imgs.length)
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        return err;
      });
    }else{
      setloading(false);

    }
  
  }, []);

  if(loading){
    return <div>loading...</div>
  }

  if(!loading){
    return (
      <Container fluid>
        <Navbar fixed="fixed" className="navbar">
          <Container fluid>
            <div className="navbar_content">
              <div>
                {" "}
                {/* <img
                alt=""
                src={logo}
                width="40px"
                height="40px"
                //   width="0"
                //   height="30"
                className="d-inline-block align-top"
              />{" "} */}
              </div>
  
              <div className="navbar_text">Deployment Details</div>
            </div>
          </Container>
        </Navbar>
  
        {
          <Formik
            validate={(values) =>
               {
              let errors = {};
              if (!values.admin_mail) {
                errors.admin_mail = "Admin mail id is required";
              }
              if (!values.app_name) {
                errors.app_name = "Clinic name is required";
              }
              if (!values.notificationToDoctor) {
                errors.notificationToDoctor = "notificationToDoctor mail is required";
              }
              if (!values.phone_number) {
                errors.phone_number = "Phone Number is required";
              }
              if (!values.address) {
                errors.address = "Address is required";
              }
              if (!values.city) {
                errors.city = "City is required";
              }
              if (!values.state) {
                errors.state = "State is required";
              }
              if (!values.preferred_url) {
                errors.preferred_url = " URL is required";
              }
              if (!values.country) {
                errors.country = "Country is required";
              }
              if (!values.zip_code) {
                errors.zip_code = "Zip Code is required";
              }
              if (!values.first_name) {
                errors.first_name = "First Name is required";
              }
              if (!values.last_name) {
                errors.last_name = "Last Name is required";
              }
          
              // if (!values.file) {
              //   errors.file = "Office Image is required";
              // }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              admin_mail: Yup.string().required("Chief admin mail id is required"),
             app_name: Yup.string().required("App Name is required"),
             preferred_url: Yup.string().required("URL is Required"),
              notificationToDoctor: Yup.string().required(" notification mail  id is Required"),
              phone_number: Yup.string().required(" phone number is Required"),
              address: Yup.string().required(" Address is Required"),
              city: Yup.string().required("City is Required"),
              state: Yup.string().required("State is Required"),
              country: Yup.string().required(" Country is Required"),
              zip_code: Yup.string().required(" Zipcode is Required"),
              first_name: Yup.string().required("First Name is required"),
              last_name: Yup.string().required("Last Name is required"),


              // file: Yup.string().required("Office Image is Required"), 
            })}
            initialValues={{
              admin_mail:location.state ? location.state.email : "",
              app_name: clinicDetailsData
              ? clinicDetailsData.clinic_name
              : "",
              first_name: clinicDetailsData
              ? clinicDetailsData.chief_dental_officer_name
              : "",
              last_name: "",
              preferred_url: clinicDetailsData
              ? clinicDetailsData.preferred_url
              : "",
              notificationToDoctor:location.state ? location.state.email : "",
              phone_number: clinicDetailsData
              ? clinicDetailsData.phone_number
              : "",
              address: clinicDetailsData
              ? clinicDetailsData.clinic_address
              : "",
              city: clinicDetailsData ? clinicDetailsData.clinic_city : "",
              state:  clinicDetailsData ? clinicDetailsData.clinic_state : "",
              country: clinicDetailsData
              ? clinicDetailsData.clinic_country
              : "",
              zip_code:clinicDetailsData
              ? clinicDetailsData.clinic_zipcode
              : "",
              file: null,
            }}
            onSubmit={(values) => {
              // console.log(values,"vvvvvvvvvv")
              if (values.file) {
                const reader = new FileReader();
                reader.readAsDataURL(values.file);
                reader.onload = () => {
                  clinicPic(reader.result)
                    .then((response) => {
                      if (response.data) {
                        const data = {
                          ...values,
                          BaseUrl: response.data.BaseUrl,
                          fullUrl: response.data.fullUrl,
                          imageName: response.data.imageName.split("/")[1],
                        };
                        // console.log(data,"ddddddd")
                          postDeploymentAppDetails(data)
                          .then((res) => {
                            setsnackbar(true)
                            setsnackbarmessage(res.data.message)
                            setsnacktype("success");
                          })
                          .catch((err) => {
                            setsnackbar(true)
                            setsnackbarmessage(err.data.message)
                            setsnacktype("error");
                            return err;
                          });
                      }
                    })
                    .catch((err) => {
                      setsnackbar(true)
                      setsnackbarmessage(err.data.message)
                      setsnacktype("error");
                      console.log(err);
                    });
                };
              }
              else{
            
                        const data = {
                          ...values,
                          BaseUrl: clinicDetailsData.BaseUrl,
                          fullUrl: clinicDetailsData.fullUrl,
                          imageName:clinicDetailsData.clinic_image_name.split("/")[1],
                        
                        };
                        // console.log(data,"dddddd")
                        postDeploymentAppDetails(data)
                          .then((res) => {
                            setsnackbar(true)
                            setsnackbarmessage(res.data.message)
                            setsnacktype("success");
                            
                          })
                          .catch((err) => {
                            console.log(err)
                            setsnackbar(true)
                            setsnackbarmessage(err.data.message)
                            setsnacktype("error");
                            return err;
                          });
                    
                  
        
                };
              }
              
              // postDeploymentAppDetails(values).then(res=>console.log("res",res)).catch(err=>console.log(err))
            }
            
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                     {
              snackbar &&  <SnackFire
               open={snackbar}
               onClose={handleclose}
               position="top-right"
               timing={5000}
               type={snacktype}
               message={snackbarmessage}
             />
             }
                  <Row className="office-details-container">
                    <Col md={5} className="pic_content">
                      <div className="apollo_image mt-5  positions">
                        {!values.file && photo && (
                          <img
                            disable={true}
                            className="apollo_image  positions"
                            src={imgs[0]}
                            alt="Upload logo(It will be published in the user app)"
                          />
                        )}
                        {!values.file && !photo && (
                          <div className="d-flex flex-column align-items-center justify-content-center upload_logo_text">
                            <div className="upload_logo_font">Logo </div>
                            <div className="mt-2">
                              (Using cheif admin mail id, get the admin_id, using
                              that admin_id, check for logo specified in clinics
                              schema and If not specified by default taking
                              toothlens logo){" "}
                            </div>
                            <div className="mt-2">Dimension: 500px x 500px </div>
                          </div>
                        //   <img
                        //   disable={true}
                        //   className="apollo_image  positions"
                        //   src="https://dentulu.toothlens.com/static/media/logo67x67.71b426ee.svg"
                        //   alt="Upload logo(It will be published in the user app)"
                        // />
                        )}
  
                        {values.file && <ImagePreview file={values.file} />}
  
                       <div className="absolute">
                            <label htmlFor="upload-button">
                              <img
                                src={camera}
                                className="camera-icon"
                                alt="upload image"
                              />
                            </label>
  
                            <input
                              id="upload-button"
                              className="my-3 custom-file-input"
                              placeholder="Upload your Card"
                              type="file"
                              style={{ display: "none" }}
                              value={""}
                              onChange={(event) => {
                                setFieldValue("file", event.target.files[0]);
                              }}
                              onBlur={handleBlur}
                            />
                          </div> 
                      </div>
                    </Col>
  
                    <Col md={7} className="overflow-auto office-form-container">
                      {/* ------------------------------------------admin schema----------------------------- */}
                      <Col md={8} className="">
                        <div className="text-success d-flex w-5 justify-content-center mt-5 mx-2">
                          ADMIN SCHEMA RELATED DETAILS
                        </div>
                        <Row className="mx-5">
                          <Col md={12} className="">
                            <span className="field-texts mx-2">
                              CHIEF ADMIN MAIL (Should be known while filling
                              this form)
                            </span>{" "}
                          </Col>
                        </Row>
  
                        <div className=" d-flex mx-5 mt-2">
                          <Col md={12} className="mx-3">
                            <input
                              id="admin_mail"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="chief admin mail id"
                              type="text"
                              value={values.admin_mail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                          </Col>
                          {/* <Col md={5} className="">
                               <input
                                 id="last_name"
                                 className="form-input-account mb-3 grey-bg-color"
                                 placeholder="Last Name"
                                 type="text"
                                 value={values.last_name}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                               />
                             </Col> */}
                          {errors.admin_mail && touched.admin_mail && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.admin_mail}
                            </div>
                          )}
                        </div>
                        <Row className="mx-5">
                          <Col md={12} className="">
                            <span className="field-texts mx-2">
                              Chief Dental Officer Name
                            </span>{" "}
                          </Col>
                        </Row>

                        <div className=" d-flex mx-5 mt-2">
                          {/* <Col md={2} className=""> */}
                            {/* <input
                              id="prefix_name"
                              className="form-input-chief mb-3 grey-bg-color"
                              placeholder="Dr."
                              type="text"
                              value={values.prefix_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "} */}
                          {/* </Col> */}
                          <Col md={6} className="mx-3">
                            <input
                              id="first_name"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="FirstName"
                              type="text"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                          </Col>
                      
                          {errors.first_name && touched.first_name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.first_name}
                            </div>
                          )}
                            <Col md={6} className="">
                            <input
                              id="last_name"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="Last Name"
                              type="text"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col> 
                          {errors.last_name && touched.last_name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.last_name}
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* ----------------------APP SCHEMA------------ */}
  
                      <Col md={8} className="">
                        <div className="text-success d-flex w-5 justify-content-center mt-2 mx-2">
                          APP SCHEMA RELATED DETAILS (App mail id is same as chief
                          dentist mail id)
                        </div>
                        <Row className="mx-5">
                          <Col md={12} className="">
                            <span className="field-texts mx-2">APP NAME</span>{" "}
                          </Col>
                        </Row>
  
                        <div className=" d-flex mx-5 mt-2">
                          <Col md={12} className="mx-3">
                            <input
                              id="app_name"
                              className="form-input-account mb-3 grey-bg-color"
                              placeholder="app name"
                              type="text"
                              value={values.app_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />{" "}
                          </Col>
                          {/* <Col md={5} className="">
                              <input
                                id="last_name"
                                className="form-input-account mb-3 grey-bg-color"
                                placeholder="Last Name"
                                type="text"
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col> */}
                          {errors.app_name && touched.app_name && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.app_name}
                            </div>
                          )}
                        </div>
  
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              DOMAIN URL (Specify the full url)
                            </span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5 ">
                          {" "}
                          <input
                            id="preferred_url"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="should be with https://(format)app url"
                            type="text"
                            value={values.preferred_url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.preferred_url && touched.preferred_url && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.preferred_url}
                            </div>
                          )}
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              REPORT SEND TO DOCTOR
                            </span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="notificationToDoctor"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="manoj@toothlens.com"
                            type="text"
                            value={values.notificationToDoctor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.notificationToDoctor && touched.notificationToDoctor && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.notificationToDoctor}
                            </div>
                          )}
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              APP Phone Number
                            </span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="phone_number"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Phone number"
                            type="text"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.phone_number && touched.phone_number && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.phone_number}
                            </div>
                          )}
                        </Col>
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3"> APP Address</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="address"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="address"
                            type="text"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.address && touched.address && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.address}
                            </div>
                          )}
                        </Col>
  
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">APP City</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="city"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="city"
                            type="text"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.city && touched.city && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.city}
                            </div>
                          )}
                        </Col>
  
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">APP State</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="state"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="State"
                            type="text"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.state && touched.state && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.state}
                            </div>
                          )}
                        </Col>
  
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">APP Country</span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="country"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="country"
                            type="text"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.country && touched.country && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.country}
                            </div>
                          )}
                        </Col>
  
                        <Col className="mx-5">
                          <FormLabel
                            component="legend"
                            className="ml-3  pl-5 font-weight-500 text-dark "
                          >
                            <span className="field-texts mx-3">
                              APP ZipCode/Pincode
                            </span>
                          </FormLabel>
                        </Col>
  
                        <Col className="mt-1 mx-5">
                          {" "}
                          <input
                            id="zip_code"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Zip code/Pin code"
                            type="text"
                            value={values.zip_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.zip_code && touched.zip_code && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.zip_code}
                            </div>
                          )}
                        </Col>
                      </Col>
  
                      <Col className="mx-5 mt-3 mb-5">
                        <Button
                          className="form-btns-office-save-and-update"
                          type="submit"
                        >
                          Deploy
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </form>
              );
            }}
          </Formik>
        }
      </Container>
    );
  }
 
};

export default Deployment;
