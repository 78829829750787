import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./alignerDashboard.css"


const ReportSubmitForm = (props) => {
    const { selectedReport, onSubmit, noGoReport, handleShow } = props
    const compare = noGoReport > 0
    const [remarksText, setRemarksText] = useState("")
    const [reportstatus, setReportstatus] = useState("")
    const [noOfDays, setDays] = useState("")
    const [error, setError] = useState(false)
    const [durationError, setDurationError] = useState(false)
    const [loader, setLoading] = useState(false)

    useEffect(() => {
        const text = selectedReport ? (selectedReport.remarks ? selectedReport.remarks : " " ) : " "
        setRemarksText(text)
        setReportstatus("")
        setDays("")
        setError(false)
        setDurationError(false)

    }, [selectedReport])


    const handleReportRemark = e => {
        setRemarksText(e.target.value)

    }
    const handleReportChange = status => {
        setReportstatus(status)
        setError(false)
    }

    const afterSubmission = (status) => {
        setReportstatus("")
        setDays("")
        setLoading(false)

    }

    const submitReport = () => {
        setLoading(true)
        onSubmit({remarksText, reportstatus, noOfDays, afterSubmission })
        
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!error) {
            if (reportstatus === "go") {
                submitReport()
            } else if (reportstatus === "noGo" && noOfDays) {
                submitReport()
            } else if (reportstatus === "noGo" && !noOfDays) {
                setDurationError(true)
            } else {
                setError(true)
            }
        }
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Container fluid className="">
                    <Row className="">
                        <Col md={4} className="">
                            <div>
                                <div className=" d-flex w-5 justify-content-center mt-1 font-DM-sans">
                                    Remarks
                                </div>
                                <textarea
                                    className="form-control px-3 py-2 form-input-remarks mt-2 mb-3 grey-bg-color font-DM-sans"
                                    type="text"
                                    value={remarksText}
                                    onChange={handleReportRemark}
                                >
                                    {remarksText}
                                </textarea>
                            </div>
                        </Col>
                        <Col md={4} className=" d-flex flex-column justify-content-start ">
                            <Row>
                                {
                                    selectedReport && selectedReport.is_report_reviewed ? <div className="d-flex align-items-center justify-content-center mt-2 me-5 w-100">
                                        <Col md={7} className="d-flex justify-content-end"> <span className=" my-auto me-3 font-DM-sans"> Recommendation: </span> </Col>
                                        <Col md={5}>
                                            <div className="d-flex justify-content-center">
                                                {
                                                    selectedReport.status_by_annotator ? <span className="active-go me-3 px-4"> Go </span>
                                                        : <span className="active-no-go"> No Go </span>
                                                }

                                            </div>

                                        </Col>
                                    </div> : null
                                }
                            </Row>
                            <Row className="mt-2 ">
                                <Col md={7}>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <label className={` font-DM-sans ${error ? "text-danger  " : ""} mt-2`}>Please select either of these: </label>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className='d-flex justify-content-center'>
                                        <button type="button" className={`${reportstatus === "go" ? "active-go" : "go"} me-3 px-4 shadow`} value="go" onClick={() => { handleReportChange("go") }} >  Go</button>
                                        <button type="button" className={`${reportstatus === "noGo" ? "active-no-go" : "no-go"} shadow`} value="noGo" onClick={() => { handleReportChange("noGo") }} >  No Go</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className="">
                            <div className='d-flex flex-column justify-content-between h-75'>
                                {compare && (
                                    <div className='d-flex justify-content-start mt-0 align-items-center'>
                                         <label className="me-4 font-DM-sans">Compare with: </label>
                                        <button type="button" className="btn btn-primary test_text_style my-2 p-1 px-4 font-DM-sans" onClick={() => handleShow()}>Aligner {noGoReport}</button>
                                    </div>
                                   )
                                }

                               
                                <div className='d-flex justify-content-end align-items-center mt-2'>
                                {reportstatus === "noGo" &&
                                    <div className=' d-flex justify-content-center align-items-center'>
                                        <label className={`font-DM-sans  ${durationError ? "text-danger me-3" : "me-3"}`}>Please specify the <b>number</b> of days to continue existing aligner</label>

                                        <input type='number' className='text-center me-3' style={{ width: "70px" }} value={noOfDays} onChange={(event) => {
                                            setDays(event.target.value)
                                            setDurationError(false)
                                        }} />
                                    </div>
                                }
                                    <button type="submit" className='btn btn-primary' >
                                        {loader ? <div className="text-centerp">
                                            <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div> : <span className=" mx-2 font-DM-sans"> Submit </span>}

                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </form>
        </>
    )
}

export default ReportSubmitForm