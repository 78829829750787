import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";

// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
//   } from "@material-ui/pickers";
// import "./nodeploy.css";
import { nodeploy } from "../../Services/AuthService";
// import TextField from '@mui/material/TextField';
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Nodeploy = () => {
  const [nopassword, setnopassword] = useState();
  const [notest, setnotest] = useState();
  const [questionere, setquestionere] = useState();
  const [loading, setloading] = useState(true);
  const [popup, setpopup] = useState();
  const [date, setdate] = useState("2022-05-10");
  const [dateend, setdateend] = useState("2022-09-22");
  const [selectedClient,setSelectedClient] = useState("edental"); //default value
    
        function handleSelectChange(event) {
            setSelectedClient(event.target.value);
        }
const [totalreport,settotalreport]=useState()
  useEffect(() => {
    var fromdate = moment(new Date('2022-05-10')).format("YYYY-MM-DD");
    var todate = moment(new Date('2022-09-22')).format("YYYY-MM-DD");
    // console.log(fromdate,todate,"dddd")

    nodeploy(fromdate, todate,selectedClient)
      .then((res) => {
        console.log(res, "ddddddd");
        setnopassword(res.data.data.userWithoutPassword);
        setnotest(res.data.data.userWhoNotTakenTest);
        setquestionere(res.data.data.usersDataWithQuestionnaire);
        setpopup(res.data.data.usersDataWithQuestionnaire);
        settotalreport(res.data.data.getTotalReportCount)
        // console.log(totalreport,"jjjjjjjjj")
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    var fromdate = moment(new Date('2022-05-10')).format("YYYY-MM-DD");
    var todate = moment(new Date('2022-09-22')).format("YYYY-MM-DD");
    // console.log(fromdate,todate,"dddd")
    nodeploy(fromdate, todate,selectedClient)
      .then((res) => {
        // console.log(res, "ddddddd");
        setnopassword(res.data.data.userWithoutPassword);
        setnotest(res.data.data.userWhoNotTakenTest);
        setquestionere(res.data.data.usersDataWithQuestionnaire);
        setpopup(res.data.data.usersDataWithQuestionnaire);
        settotalreport(res.data.data.getTotalReportCount)
        // console.log(totalreport,"jjjjjjjjj")
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, [selectedClient]);
  if (loading) {
    return <div>Loading...</div>;
  }
  // console.log(totalreport,"jjjjjjjjj")

  if (!loading) {
    return (
      <div className="mt-5">
          <select value={selectedClient} onChange={handleSelectChange}> 
                {/* <option value="one">Toothlens</option> */}
                {/* <option value="guest">Dental Office</option> */}
                {/* <option value="edental">Local db</option> */}
                <option value="teledentist">Teledentist</option>
                <option value="toothlens">Toothlens</option>
                <option value="edental">edental</option>

            </select>
        <Container>
          <div className="mt-5 d-flex justify-content-center">
            <div>
              {" "}
              <h1>Date picker</h1>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="from date"
                // format="MM-dd-yyyy"
                value={date}
                onChange={(newValue) => {
                  setdate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <div>To </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To date"
                // format="MM/dd/yyyy"
                value={dateend}
                onChange={(newValue) => {
                  setdateend(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <Button
              onClick={() => {
                var fromdate = moment(new Date(date.$d)).format("YYYY-MM-DD");
                var todate = moment(new Date(dateend.$d)).format("YYYY-MM-DD");
                console.log(typeof fromdate, typeof todate, "oooooooooo");
                nodeploy(fromdate, todate,selectedClient).then((res) => {
                  setnopassword(res.data.data.userWithoutPassword);
                  setnotest(res.data.data.userWhoNotTakenTest);
                  setquestionere(res.data.data.usersDataWithQuestionnaire);
                  setpopup(res.data.data.usersDataWithQuestionnaire);
                  settotalreport(res.data.data.getTotalReportCount)
                  setloading(false);
                });
              }}
            >
              Submit
            </Button>
          </div>

          <div className="mt-5">
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="dob"
                              format="MM/dd/yyyy"
                              name="insurance_date"
                              placeholder="Insurance date"
                              className="form-input mb-3 grey-bg-color"
                              value={values.insurance_date}
                              onChange={(value) =>
                                props.setFieldValue("insurance_date", value)
                              }
                              onClick={() => setPickerStatus(true)}
                              onClose={() => setPickerStatus(false)}
                              open={isdatePickerOpen}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                          {errors.insurance_date && touched.insurance_date && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.insurance_date}
                            </div>
                          )}
                        </MuiPickersUtilsProvider> */}
            <div className="d-flex bg-warning justify-content-center">
              <h1>1.For signups and test taken ({questionere.count})</h1>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr class="bg-info">
                {/* <th>S.No</th>
                  <th>Date</th> */}
                  <th>S.No</th>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Email</th>
                  <th>Age</th>
                  <th>Sex</th>
                  <th>Total Reports</th>
                  <th>Chief Compliant</th>
                  {/* <th>Tartar Count</th> */}
                  {/* <th>Cavity Count</th> */}
                  <th>Feedback Rating</th>
                  <th>Feedback message</th>
                  <th>Device Details</th>
                  <th>Q1 (Pregrancy and hormonal changes)</th>
                  <th>Q2(Diabetes)</th>
                  <th>Q3(Family history of cavities and gym infections)</th>
                  <th>Q4(Tooth brushing)</th>
                  <th>Q5(Flossing)</th>
                  <th>Q6(Replace toothbrush)</th>
                  <th>Q7(Smoking and tobacco use)</th>
                  <th>Q8(cavities or treatment in last 36 months)</th>
                  <th>Q9(Dental scaling and gum treatment)</th>
                  <th>Q10(cups of tea/coffe in a day)</th>
                  <th>Q11(Any problems as of now)</th>
                </tr>
              </thead>
              <tbody>
                
          


                {questionere.users.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(value.createdAt).format("MM/DD/YYYY")}</td>
                      <td>{value.first_name}</td>
                      <td>{value.last_name}</td>
                      <td>{value.state}</td>
                      <td>{value.country}</td>
                      <td>{value.email}</td>
                      <td>{value.age}</td>
                      <td>{value.gender}</td>
                      <td>{value.total_reports}</td>
                      <td>
                        {value.user_looking_for?.map((value, index) => {
                          return <li>{value}</li>;
                        })}
                      </td>
                      {/* <td>
                        {value.report[0]?.report_score[0]?.overall_score[0]}
                      </td>
                      <td>
                        {value.report[0]?.report_score[0]?.overall_score[1]}
                      </td> */}
                      <td>{value.rating[0]?.rating}</td>
                      <td>{value.rating[0]?.message}</td>
                      <td>{value?.device_details}</td>
                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[0]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[0].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : ""}
                      </td>
                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[1]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[1].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[0]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[0].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[2]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[2].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[1]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[1].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[3]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[3].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[2]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[2].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[4]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[4].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[3]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[3].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[5]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[5].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[4]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[4].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[6]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[6].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[5]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[5].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[7]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[7].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[6]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[6].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[8]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[8].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )
                          : value.questionnaires[0]?.questions[7]?.options?.map(
                              (fil) => {
                                if (
                                  fil.value ==
                                  value.questionnaires[0].questions[7].score
                                )
                                  return <div>{fil.option} </div>;
                              }
                            )}
                      </td>

                      <td>  
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[9]?.answer
                          : value.questionnaires[0]?.questions[8]?.answer}
                      </td>

                      <td>
                        {value.gender == "female"
                          ? value.questionnaires[0]?.questions[10]?.answer
                          : value.questionnaires[0]?.questions[9]?.answer}
                      </td>
                    </tr>
                  );
                })}
                
          
              </tbody>
            </Table>
          </div>


          <div className="mt-5">
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container justifyContent="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              id="dob"
                              format="MM/dd/yyyy"
                              name="insurance_date"
                              placeholder="Insurance date"
                              className="form-input mb-3 grey-bg-color"
                              value={values.insurance_date}
                              onChange={(value) =>
                                props.setFieldValue("insurance_date", value)
                              }
                              onClick={() => setPickerStatus(true)}
                              onClose={() => setPickerStatus(false)}
                              open={isdatePickerOpen}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </Grid>
                          {errors.insurance_date && touched.insurance_date && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.insurance_date}
                            </div>
                          )}
                        </MuiPickersUtilsProvider> */}
            <div className="d-flex bg-warning justify-content-center">
              <h1>1.A) Total reports taken in the particular date ({totalreport.count})</h1>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr class="bg-info">
                {/* <th>S.No</th>
                  <th>Date</th> */}
                  <th>S.No</th>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>

                  <th>State</th>
                  <th>Country</th>
                  <th>Zipcode</th>

                  <th>Age</th>
                  <th>Sex</th>
                  {/* <th>Total Reports in the particular date </th> */}
                  <th>Overall Tartar Count</th>
                  <th>Over all Cavity Count</th>
               
                </tr>
              </thead>
              <tbody>
                
                 {
                  totalreport.Reports.map((value,index)=>{
return(
  <tr>
  <td>
    {index+1}
  </td>
  <td>{moment(value.createdAt).format("MM/DD/YYYY")}</td>
  <td>{value.fromusers.first_name}</td>
                      <td>{value.fromusers.last_name}</td>
                      <td>{value.fromusers.email}</td>
                      <td>{value.fromusers.state}</td>

                      <td>{value.fromusers.country}</td>
                      <td>{value.fromusers.zipcode}</td>

                      <td>{value.fromusers.age}</td>
                      <td>{value.fromusers.gender}</td>
                      {/* <td>{value.report_score[0].overall_score[0]}</td>
                      <td>{value.report_score[0].overall_score[1]}</td>   */}


  <td>
    {/* {value._id} */}
  </td>
</tr>
)
 

                  })
                }

                

             
                
          
              </tbody>
            </Table>
          </div>





          <div className="mt-5">
            <div className="d-flex bg-secondary justify-content-center">
              <h1>2. Total signups and test not taken ({notest.count})</h1>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr class="bg-info">
                  <th>S.No</th>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>State</th>
                  <th>Country</th>

                  <th>Email</th>
                  <th>organisation</th>
                  <th>Age</th>
                  <th>Sex</th>
                  <th>Device Details</th>
                </tr>
              </thead>
              <tbody>
                {notest.users.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(value.createdAt).format("MM/DD/YYYY")}</td>
                      <td>{value.first_name}</td>
                      <td>{value.last_name}</td>
                      <td>{value.state}</td>
                      <td>{value.country}</td>
                      <td>{value.email}</td>
                      <td>-</td>
                      <td>{value.age}</td>
                      <td>{value.gender}</td>
                      <td>-</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="d-flex bg-danger justify-content-center ">
            <h1>
              3. Email Entered but password not generated{" "}
              <span>({nopassword.count})</span>{" "}
            </h1>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr class="bg-info">
                <th>S.No</th>
                <th>Date</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>organisation</th>
                <th>Age</th>
                <th>Sex</th>
                <th>Device Details</th>
              </tr>
            </thead>
            <tbody>
              {nopassword.users.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td> {moment(value.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{value.first_name}</td>
                    <td>{value.last_name}</td>
                    <td>{value.email}</td>
                    <td>-</td>
                    <td>{value.age}</td>
                    <td>{value.gender}</td>
                    <td>-</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
};

export default Nodeploy;
