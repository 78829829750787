import React, {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./legend.css"
const AnnotationLegendList = (props) => {
    const {filteration, image} = props
    const checkedBoxes = ["C", "T", "E", "GR", "GS", "BT" ]
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([...checkedBoxes])

    useEffect(() => {
        filteration(selectedCheckBoxes)
    }, [selectedCheckBoxes])

    useEffect(() => {
        setSelectedCheckBoxes([...checkedBoxes])
    }, [image])

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        if (checked) {
            setSelectedCheckBoxes([...selectedCheckBoxes, value])
        } else {
            const filteredCheckBoxes = selectedCheckBoxes.filter(eachCheckBocId => eachCheckBocId !== value)
            setSelectedCheckBoxes(filteredCheckBoxes)
        }
    }

    return (
        <div className='legends mt-2 mx-2   px-3'>
            {/* <h5 className='font-size-14'>Legends</h5> */}
            <Row>
                <Col md={12} className='d-flex w-5 justify-content-center'>
                    <Col md={4}>
                    <div className='d-flex align-items-center'>
                            <input type="checkbox" id="C" value="C" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("C")} className="me-2" />
                            <label htmlFor="C"> <span style={{color:"#FF0000"}} >C - Cavity</span> </label>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className='d-flex'>
                           <input type="checkbox" id="T" value="T" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("T")} className="me-2" />
                           <label htmlFor="T"> <span style={{color:"#0000FF"}}>T - Tartar/Stains</span>  </label>
                        </div>
                    </Col> 
                    <Col md={4}>
                    <div className='d-flex'>
                           <input type="checkbox" id="E" value="E" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("E")} className="me-2" />
                            <label htmlFor="E">  <span style={{color: "#298542"}} >E - Enamel Loss</span> </label>
                        </div>
                    </Col>
                </Col>
                    

            </Row>
            <Row>
                <Col md={12} className='d-flex w-5 justify-content-center'>
                <Col md={4}>
                <div className='d-flex'>
                            <input type="checkbox" id="GR" value="GR" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("GR")} className="me-2" />
                            <label htmlFor="GR">  <span style ={{color: "#FFA500"}}  >GR - Gingival Recession</span> </label>
                        </div>
                    </Col>
                    <Col md={4}>
                    <div className='d-flex'>
                            <input type="checkbox" id="GS" value="GS" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("GS")} className="me-2" />
                            <label htmlFor="GS"><span style={{color: "#68a8a8"}}>GS - Gingival Swelling</span> </label>
                        </div>
                    </Col> 
                    <Col md={4}>
                    <div className='d-flex align-items-start'>
                            <input type="checkbox" id="BT" value="BT" onChange={handleCheckboxChange} checked={selectedCheckBoxes.includes("BT")} className="me-2 mt-2"  />
                            <label htmlFor="BT"> <span style={{color: "#EE82EE"  }} >BT -Chipped/Fractured teeth</span> </label>
                        </div>
                    </Col>
              
                </Col>
                    

            </Row>
        </div>
    )
}

export default AnnotationLegendList;