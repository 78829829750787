import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/navbarLogo.svg";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import profile from "../../assets/profileImage.svg";
import FormLabel from "@mui/material/FormLabel";
import "./accountInformation.css";
import camera from "../../assets/camera.svg";

const AccountInformation = () => {
  return (
    <Container fluid>
      <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              <img
                alt=""
                src={logo}
                width="40px"
                height="30px"
                //   width="0"
                //   height="30"
                className="d-inline-block align-top"
              />{" "}
            </div>

            <div className="navbar_text">Account Information</div>
          </div>
        </Container>
      </Navbar>

      <Row>
        <Col md={5} className="pic_content">
          <div
            src={profile}
            className="apollo_image mt-5  bg-warning positions"
          >
            <div className="absolute">
              <img src={camera} alt="upload absolute" />
            </div>
          </div>

          {/* <div className='positions'>
      <img src={profile}alt="image" className="apollo_image mt-5 "/>
<div className="absolute">
<img src={camera} alt="upload" />

</div>
      </div> */}
        </Col>

        <Col md={7}>
          <Col md={10}>
            {/* <Col className="mt-5 ml-3 ">DOCTOR</Col> */}
            <Col className="mx-5 mt-5 mb-4 positions ">
              <div className="dental_office_text "> Doctor</div>
              <Col md={1} className="mt-1">
                {" "}
                <div className="underline"></div>
              </Col>
            </Col>

            <Row className="mt-3 mx-5">
              <Col md={2} className=" px-3">
                <span className="field-texts mx-2">Prefix</span>
              </Col>
              <Col md={5}>
                <span className="field-texts mx-3">First Name</span>
              </Col>
              <Col md={5}>
                <span className="field-texts mx-3">Last Name</span>
              </Col>
            </Row>

            <Row className="mt-1 mx-5 ">
              <Col md={2}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
              <Col md={5}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
              <Col md={5}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={3}>
                {" "}
                <span className="field-texts mx-3">Email Id</span>
              </Col>
            </Row>
            <Row className="mt-1 mx-5     ">
              <Col md={12}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={4}>
                {" "}
                <span className="field-texts mx-3">Degree</span>
              </Col>
              <Col md={8}>
                {" "}
                <span className="field-texts mx-3">Specialisation</span>
              </Col>
            </Row>

            <Row className="mt-1 mx-5">
              <Col md={4}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
              <Col md={8}>
                <input
                  id="standard-basic"
                  className="form-input w-5 mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={4}>
                {" "}
                <span className="field-texts mx-3">Unknown</span>
              </Col>
              <Col md={8}>
                {" "}
                <span className="field-texts mx-3">Designation</span>
              </Col>
            </Row>

            <Row className="mt-1 mx-5">
              <Col md={4}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
              <Col md={8}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={12}>
                {" "}
                <span className="field-texts mx-3">Locations</span>
              </Col>
            </Row>
            <Row className="mt-1 mx-5">
              <Col md={12}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="xxxxx"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={12}>
                {" "}
                <span className="field-texts mx-3">Availabilities </span>
              </Col>
            </Row>

            <Row className="mt-1 mx-5">
              <Col md={5}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="Weekdays"
                />
              </Col>
              <Col md={3}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="10:00 AM"
                />
              </Col>
              <Col className=" d-flex align-items-top " md={1}>
                <span className="sans">To</span>
              </Col>
              <Col md={3}>
                <input
                  id="standard-basic"
                  className="form-input mb-3 grey-bg-color"
                  placeholder="8:00 PM"
                />
              </Col>
            </Row>

            <Row className="mx-5">
              <Col md={1}>
                {" "}
                <input type="checkbox" className="checkbox-signin" />
              </Col>
              <Col md={8}>
                <span className="super-doctor-text"></span>Assign as a super
                doctor
              </Col>
            </Row>
            <Row className="mx-5">
              <Col className="my-3">
                <Button className="form-btn">SAVE AND CONTINUE</Button>
              </Col>
            </Row>
            <Row className="mx-5">
              {" "}
              <Col className="mb-5 ">
                <span className="sans">
                  You can add more doctor later in your Doctor list
                </span>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountInformation;
