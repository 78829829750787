import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormLabel } from "react-bootstrap";
import Update from "../../assets/update.svg";
import Delete from "../../assets/delete.svg";
import {
  doctorListBasedOnCompany,
  doctorListPaginationBasedOnCompany,
  roleList,
  othersListPaginationBasedOnCompany
} from "../../Services/AuthService";
import DentistUpdatePopup from "./dentistUpdatePopup";
import Button from "@mui/material/Button";
import DentistDeletePopup from "./dentistDeletePopup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";

const DentistList = ({ change }) => {
  const [email,setEmail]=useState()
  const [editdoctorpopup, seteditdoctorpopup] = useState(false);
  const [deletepopup, setdeletepopup] = useState(false);
  const [dentistLists, setdentistLists] = useState(true);
  const [dentistId, setDentistId] = useState();
  const [deletedoctorid, setdeletedoctorid] = useState();
  const [doctorList, setdoctorList] = useState();
  const [loading, setloading] = useState(true);
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [currentpage, setcurrentpage] = useState(0);
  const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
  const verified = JSON.parse(localStorage.getItem("flag"))
  const totalpage = Math.ceil(totalreportfilteredcount / 6);
  const [render, setrender] = useState(false);
  const isAlignerAdmin = JSON.parse(localStorage.getItem("admin")).admin.is_aligner_admin

// ----------------------------Pagination for otherList---------------------
const [currentpageOther, setcurrentpageOther] = useState(0);
  const [totalotherfilteredcount, settotalotherfilteredcount] = useState(0);
  const totalpageOther= Math.ceil(totalotherfilteredcount / 6);
  const [otherList, setOtherList] = useState();




  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setrender(!render);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setrender(!render);
  };


  const handlenextpageOther = () => {
    setcurrentpageOther(currentpageOther + 1);
    setrender(!render);
  };
  const handlepreviouspageOther = () => {
    setcurrentpageOther(currentpageOther - 1);
    setrender(!render);
  };

  const closeupdatepopup = () => {
    seteditdoctorpopup(false);
  };
  const closedeletepopup = () => {
    setdeletepopup(false);
  };

const initialCall = async()=>{
 await doctorListPaginationBasedOnCompany({ app })
  .then((res) => {
    const cheifDentistFilterCount = res.data.data[0].length > 0 ? res.data.data[0][0].total_dentist  : 0
    settotalreportfilteredcount(cheifDentistFilterCount);
    setdoctorList(res.data.data[1]);
  })
  .catch((err) => console.log(err));

  await othersListPaginationBasedOnCompany({app})
  .then((res) => {
    const otherDoctorsFilterCount = res.data.data[0].length > 0 ? res.data.data[0][0].total_dentist  : 0
    settotalotherfilteredcount(otherDoctorsFilterCount);
    setOtherList(res.data.data[1]);
    setloading(false);
  })
  .catch((err) => console.log(err));
}

  useEffect(() => {
    initialCall()
  }, []);

  const renderCall= async()=>{
        let object = {
      currentpage,
      app,
      limit: 6,
    };

    let objectOther = {
      currentpageOther,
      app,
      limit: 6,
    };

    await  doctorListPaginationBasedOnCompany(object)
      .then((res) => {
        const cheifDentistFilterCount = res.data.data[0].length > 0 ? res.data.data[0][0].total_dentist  : 0
        settotalreportfilteredcount(cheifDentistFilterCount);
        setdoctorList(res.data.data[1]);
      })
      .catch((err) => console.log(err));

      await othersListPaginationBasedOnCompany(objectOther)
      .then((res) => {
        const otherDoctorsFilterCount = res.data.data[0].length > 0 ? res.data.data[0][0].total_dentist  : 0
        settotalotherfilteredcount(otherDoctorsFilterCount);
        setOtherList(res.data.data[1]);
        setloading(false);
      })
      .catch((err) => console.log(err));

  }
  useEffect(() => {
 renderCall()
  }, [render]);
  if (loading) {
    return <div className="py-5 text-center">Loading...</div>;
  }
  if (!loading) {
    return (
      <div>
      {dentistLists && 
      <div>
        <div className="d-flex justify-content-between">
            <div>
                    <Button
                      onClick={() => {
                        setdentistLists(false)
                      }}
                    >
                      <div className="d-flex align-items-center ">
                        <div>
                          {/* {sharedreport &&  */}

                          <img
                            src={dentalAnalysisimg}
                            width="25px"
                            height="25px"
                            alt="filter"
                          ></img>
                        </div>
                        <div>
                          Dentist List
                        </div>
                      </div>
                    </Button>

                    <Button
                      className="mx-2"
                      onClick={() => {
                        setdentistLists(false)
                      }}
                    >
                      <span className="unselected_report_text_lite ">
                        {" "}
                      Other's List
                      </span>
                    </Button>
                  </div>
              <div className="d-flex  p-1 ">
              
                <Button
                  disabled={currentpage == 0}
                  onClick={handlepreviouspage}
                >
                  <ArrowBackIosIcon />
                </Button>
                <div className="p-1 ">
                  {currentpage == 0 ? currentpage + 1 : currentpage * 6 + 1}-
                  {currentpage == totalpage - 1
                    ? totalreportfilteredcount
                    : (currentpage + 1) * 6}{" "}
                  <span className="mx-1">of</span> {totalreportfilteredcount}
                </div>
                <Button
                  disabled={
                    currentpage ==
                    (totalpage == 0 ? currentpage : totalpage - 1)
                  }
                  onClick={handlenextpage}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
          </div>
          

{
  doctorList.length > 0 ? 
      <div>
        {doctorList.map((data) => {
          return (
            <Row key={data._id} className="doctors-row  mx-2 mt-2">
              <Col md={4} sm={6} className=" mt-3">
                <Row className="   new">
                  <Col
                    md={3}
                    sm={4}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">Name</div>
                    <div className="text-size">Email</div>
                  </Col>

                  <Col
                    md={9}
                    sm={8}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold"> DR. {data.name}</div>
                    <div className="text-bold">
                      {data.email ? data.email : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={6} className="dotted_line mt-3">
                <Row className="   new">
                  <Col
                    md={6}
                    sm={6}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">role</div>
                    <div className="text-size">Phone Number</div>
                  </Col>

                  <Col
                    md={6}
                    sm={6}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold">
                      {data.role ? "Dentist": "--"}
                    </div>
                    <div className="text-bold">
                      {data.phone_number ? data.phone_number : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={6} className="dotted_line mt-3">
                <Row className="   new">
                  <Col
                    md={5}
                    sm={6}
                    className=" d-flex flex-column justify-content-start "
                  >
                    {!isAlignerAdmin &&  <div className="text-size my-2">Location</div>}
                    <div className="text-size">Designation</div>
                  </Col>

                  <Col
                    md={7}
                    sm={6}
                    className="d-flex flex-column justify-content-start ellipsis"
                  >
                    { !isAlignerAdmin &&  <div className="text-bold ellipsis_state my-2">
                      {" "}
                      {data.fromLocation.length!=0? data.fromLocation[0].location: "--" }
                    </div>}
                    <div className="text-bold">
                      {" "}
                      {data.designation? data.designation : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={2} sm={6} className="dotted_line mt-3">
                <Row className="d-flex justify-content-center">
                  <Col md={6} sm={6}>
                    {" "}
                    <Button
                      onClick={() => {
                        seteditdoctorpopup(true);
                        setDentistId(data._id);
                      }}
                      disabled = {verified}
                    >
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <img
                          src={Update}
                          height="60px"
                          width="60px"
                          alt="upload absolute"
                        />
                      </div>
                    </Button>
                  </Col>
                  <Col md={6} sm={6}>
                    {" "}
                    {!data.is_chief_doctor && (
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <Button
                          onClick={() => {
                            setdeletepopup(true);
                            setdeletedoctorid(data._id);
                            setEmail(data.email)
                          }}

                          disabled = {verified}
                        >
                          <img
                            src={Delete}
                            height="60px"
                            width="60px"
                            alt="upload absolute"
                          />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
        </div>  : <p className="py-5 text-center">Added Dentist List will appear here</p>
}
        
        </div>}  
        {!dentistLists && <div>
            <div  className=" d-flex justify-content-between ">
            <div className="font-doctor">
                 
                    <Button
                      className="mx-2"
                      onClick={() => {
                        // callProspectsTab();
                        setdentistLists(true)

                      }}
                    >
                      <div className="unselected_report_text_lite">
                        {" "}
                      Dentist List
                      </div>
                    </Button>
                    <Button
                      onClick={() => {
                        setdentistLists(false)
                        // callSharedReportsTab();
                      }}
                    >
                      <div className="d-flex align-items-center w-5 h-5">
                        <div>
                          {/* {sharedreport &&  */}

                          <img
                            src={dentalAnalysisimg}
                            width="25px"
                            height="25px"
                            alt="filter"
                          ></img>
                        </div>
                        <div>
                          Other's List
                        </div>
                      </div>
                    </Button>

                  </div>
              <div className="d-flex  p-1 ">
               
                <Button
                  disabled={currentpageOther == 0}
                  onClick={handlepreviouspageOther}
                >
                  <ArrowBackIosIcon />
                </Button>
                <div className="p-1 ">
                  {currentpageOther == 0 ? currentpageOther + 1 : currentpageOther * 6 + 1}-
                  {currentpageOther == totalpageOther - 1
                    ? totalotherfilteredcount
                    : (currentpageOther + 1) * 6}{" "}
                  <span className="mx-1">of</span> {totalotherfilteredcount}
                </div>
                <Button
                  disabled={
                    currentpageOther ==
                    (totalpageOther == 0 ? currentpageOther : totalpageOther - 1)
                  }
                  onClick={handlenextpageOther}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </div>
            </div>
          

        
{
  otherList.length > 0 ?  
  <div>
{otherList.map((data) => {
          return (
            <Row key={data._id} className="doctors-row  mx-2 mt-2">
              <Col md={4} sm={6} className=" mt-3">
                <Row className="   new">
                  <Col
                    md={3}
                    sm={4}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">Name</div>
                    <div className="text-size">Email</div>
                  </Col>

                  <Col
                    md={9}
                    sm={8}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold">  {data.name}</div>
                    <div className="text-bold">
                      {data.email ? data.email : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={6} className="dotted_line mt-3">
                <Row className="   new">
                  <Col
                    md={6}
                    sm={6}
                    className=" d-flex flex-column justify-content-evenly "
                  >
                    <div className="text-size">role</div>
                    <div className="text-size">Phone Number</div>
                  </Col>

                  <Col
                    md={6}
                    sm={6}
                    className="d-flex flex-column justify-content-evenly"
                  >
                    <div className="text-bold">
                      {data.role ? "Others": "--"}
                    </div>
                    <div className="text-bold">
                      {data.phone_number ? data.phone_number : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={3} sm={6} className="dotted_line mt-3">
                <Row className="   new">
                  <Col
                    md={5}
                    sm={6}
                    className=" d-flex flex-column justify-content-start "
                  >
                  {!isAlignerAdmin &&  <div className="text-size my-2">Location</div>}
                    <div className="text-size">Designation</div>
                  </Col>

                  <Col
                    md={7}
                    sm={6}
                    className="d-flex flex-column justify-content-start"
                  >
                   {!isAlignerAdmin && <div className="text-bold ellipsis_state my-2">
                      {" "}
                   { data.fromLocation.length!=0? data.fromLocation[0].location: "--"}
                    </div>}
                    <div className="text-bold">
                      {" "}
                      {data.designation ? data.designation : "--"}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={2} sm={6} className="dotted_line mt-3">
                <Row className="d-flex justify-content-center">
                  <Col md={6} sm={6}>
                    {" "}
                    <Button
                      onClick={() => {
                        seteditdoctorpopup(true);
                        setDentistId(data._id);
                      }}
                      disabled = {verified}
                    >
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <img
                          src={Update}
                          height="60px"
                          width="60px"
                          alt="upload absolute"
                        />
                      </div>
                    </Button>
                  </Col>
                  <Col md={6} sm={6}>
                    {" "}
                    {!data.is_chief_doctor && (
                      <div className="d-flex justify-content-center align-items-center mt-2">
                        <Button
                          onClick={() => {
                            setdeletepopup(true);
                            setdeletedoctorid(data._id);
                            setEmail("")
                          }}
                          disabled = {verified}
                        >
                          <img
                            src={Delete}
                            height="60px"
                            width="60px"
                            alt="upload absolute"
                          />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
    </div> : <p className="py-5 text-center">Added Other's List will appear here</p>
}
        
        </div>}  
      

        {editdoctorpopup && (
          <DentistUpdatePopup
            popupstatus={closeupdatepopup}
            change={change}
            dentistId={dentistId}
          />
        )}
        {deletepopup && (
          <DentistDeletePopup
            deletePopupStatus={closedeletepopup}
            change={change}
            deletedoctorid={deletedoctorid}
            email={email}
          />
        )}
      </div>
    );
  }
};

export default DentistList;
