import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import profile from "../../assets/userIcon.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import {
  getProspectCount,
  getProspectUserLists,
} from "../../Services/AuthService";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";

const ProspectUsers = ({ unSharedDetails, sharedReports, prospect,showProspect}) => {
  
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;  
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [change, setChange] = useState(true);
 const [userEditPopUp,setUserEditPopup]=useState(false)
  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(0);
  const [data, setData] = useState("");
  const [userId,setUserId]=useState();
  const [currentpage, setcurrentpage] = useState(0);
  const [totalpage, settotalpage] = useState(0);
  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setChange(!change);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setChange(!change);
  };
  const onCloseUserPopup=()=>{
    setUserEditPopup(false)
    callChange()
  }
  const call = async () => {
    await getProspectCount({ app })
      .then((res) => {
        setcount(res.data.data);
        settotalpage(Math.ceil(res.data.data[0].prospectusers / 8));
      })
      .catch((err) => console.log(err));

    await getProspectUserLists({ app })
      .then((res) => {
        setData(res.data.data[0]);
        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    call();
  }, []);
  const callChange = async () => {
    let object = {
      currentpage,
      app,
    };
    await getProspectCount(object)
      .then((res) => {
        setcount(res.data.data);
        settotalpage(Math.ceil(res.data.data[0].prospectusers / 8));
      })
      .catch((err) => console.log(err));

    await getProspectUserLists(object)
      .then((res) => {
        setData(res.data.data[0]);

        setloading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    callChange();
  }, [!change]);

  if (loading) {
    return(
      <div> <div className="mt-5 mx-5">
      <div className=" d-flex align-items-center justify-content-center">
      <PacmanLoader color={'#0090FF'}
    css={override} size={150} /> 
          {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}
  
          <span className="mt-3 navbar_text_dashbord">Loading...</span>  
        </div>
        </div></div>
    )
  }

  if (!loading) {
    return (
      <div>
        <Row>
          <Col className="my-1 " md={3}>
            <div className="filter m-2 ">
              <div className="pt-2 font-doctor mx-2">SUMMARY</div>

              <div className="d-flex  w-5 justify-content-start">
                  {/* <div className="summary-box mt-2 mr-1 summary-text-prospect px-3 py-2">
                    <span>Total Users</span>{" "}
                    <span className="font-60">{count[1].users}</span>
                  </div> */}
               
                  <div className="summary-box mt-2  m-1 summary-text-prospect  px-2 py-2">
                    <span>Total Prospects</span>{" "}
                    <span className="font-32">{count[0].prospectusers}</span>
                  </div>
              
                  {/* <div className="summary-box mt-2 summary-text-prospect px-2 py-2">
                    existing Users
                    <span className="font-12">
                      {" "}
                      {count[0].unProspectUserdata.Unprospectusers}
                    </span>
                  </div> */}
              </div>
            </div>
          </Col>

          {/* -------------------------------Prospects body display------------------------ */}

          <Col className="  my-2 " md={9}>
            <div className="lists m-2">
              <div className="d-flex  w-5 ">
                <FormLabel className="font-doctor">
                  <Button
                    onClick={() => {
                      sharedReports();
                    }}
                  >
                    <div className="unselected_report_text_lite">
                      {" "}
                      Shared Reports
                    </div>
                  </Button>
                  { 
                  !showProspect &&
 <Button
 className="mx-2"
 onClick={() => {
   unSharedDetails();
 }}
>
 <div className="d-flex align-items-center w-5 h-5">
   <div className="unselected_report_text_lite">
     UnShared Details
   </div>
 </div>
</Button>
                  }
                 
                  <Button
                    className="mx-2"
                    onClick={() => {
                      prospect();
                    }}
                  >
                    <div className="d-flex align-items-center w-5 h-5">
                      <div className="d-flex align-items-center w-5 h-5">
                        <div>
                          <img
                            src={dentalAnalysisimg}
                            width="25px"
                            height="25px"
                            alt="filter"
                          ></img>
                        </div>
                        <div className="shared_report_text mx-1">Prospects</div>
                      </div>
                    </div>
                  </Button>
                </FormLabel>
                <div className="button-flex flex-row-reverse p-1 ">
                  <Button
                    disabled={
                      currentpage ==
                      (totalpage == 0 ? currentpage : totalpage - 1)
                    }
                    onClick={handlenextpage}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                  <Button
                    disabled={currentpage == 0}
                    onClick={handlepreviouspage}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                  <div className="p-1 ">
                    {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}-
                    {currentpage == totalpage - 1
                      ? `${count[0].prospectusers}`
                      : (currentpage + 1) * 8}{" "}
                    <span className="mx-1">of</span> {count[0].prospectusers}
                  </div>

                  {/* <select
                        className="form-btns-dashboard text-sans-bold mx-3"
                        value={sorting}
                        onChange={handlesorting}
                      >
                        <option value="" className="text-sans-bold ">
                          Sort By{" "}
                        </option>
                        <option value="Newest on top">Newest on top </option>
                        <option value="Oldest on top">Oldest on top</option>
                        <option value="First Name">First Name (A-Z)</option>
                        <option value="First Name Des">First Name (Z-A)</option>
                      </select> */}
                  {/* </Button> */}

                  {/* <CSVLink {...csvReport}> */}
                  {/* <Button>Export</Button> */}
                  {/* </CSVLink> */}
                </div>
              </div>

              {data.map((res) => {
                return (
                  <Row className={"patients-row m-1 mx-3"}>
                    <div className=" d-flex width-100 ">
                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={3}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-1">Name</div>
                                <div className="text-sans mx-1">Email</div>
                              </Col>

                              <Col
                                md={9}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ellipsis">
                                  {res.first_name} {res.last_name}

                                </div>
                                <div className="text-sans-bold ellipsis">
                                  {res.email}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                        {/* </Link> */}
                      </Col>
                      <Col md={2} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={5}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-1">Ph.no</div>
                                <div className="text-sans mx-1">Age</div>
                              </Col>

                              <Col
                                md={7}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ">
                                  {" "}
                                  {res.phone_number}
                                </div>
                                <div className="text-sans-bold">
                                  {calculateAge(res.dob)}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={11} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3"> Gender </div>

                                <div className="text-sans mx-3">
                                  City{" "}
                                </div>
                              </Col>

                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold">
                                  {res.gender}
                                </div>

                                <div className="text-sans-bold ellipsis_state">
                                  {res.city}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                        {/* </Link> */}
                      </Col>

                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={12} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3 ellipsis_state">
                                  State
                                </div>

                                <div className="text-sans mx-3 ellipsis_state">
                                  Country
                                </div>
                              </Col>

                              <Col
                                md={6}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ellipsis_state">
                                  {res.state}
                                </div>

                                <div className="text-sans-bold ellipsis_state">
                                  {res.country}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>

                      <Col md={1}>
                        <Col
                          md={12}
                          className="d-flex height-100  align-items-center"
                        >
                          <Col
                            md={12}
                            className="d-flex  height-100  align-items-center"
                          >
                            <Button 
                            onClick={()=>{
                              setUserId(res._id)
                              setUserEditPopup(true)
                            }}
                            >
                              {" "}
                              <img
                                src={profile}
                                alt="Delete"
                                style={{ cursor: "pointer" }}
                                height="55px"
                                width="39px"
                              />
                            </Button>
                          </Col>
                        </Col>
                      </Col>
                    </div>
                  </Row>
                );
              })}
            </div>
          </Col>
        </Row>

{userEditPopUp && <EditUserDetailsPopUp onClose={onCloseUserPopup} userId={userId}/>}

      </div>
    );
  }
};

export default ProspectUsers;
