import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import "../Dashboard/dashboard.css";
import filter from "../../assets/filter.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
    // getLatestClinicDetails,
    getConsultingPatientLists,
    getConsultingPatientListsForCsv,
    doctorListBasedOnCompany,
    userViewedStatus,
} from "../../Services/AuthService";
import AssingDentistPopup from "../Dashboard_USA/assingDentistPopup";
import DentalOfficeDetails from "./dentalOfficeDetails";
import { FaUserMd } from "react-icons/fa";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import { CSVLink, CSVDownload } from "react-csv";
import { FaDownload } from "react-icons/fa";

const SharedUsers = (props) => {
    const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
    const navigate = useNavigate();
    const { app } = props
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [patientsLoad, setPatientsLoad] = useState(true)
    const [prospectUserTab, setProspectUserTab] = useState(JSON.parse(localStorage.getItem("propspectTabNav")));
    const [userConsultingTab, setUserConsultingTab] = useState(JSON.parse(localStorage.getItem("consultingUsersTabNav")));
    const [doctorList, setdoctorList] = useState([]);
    const [sharedLocationId, setsharedLocationId] = useState()
    const [doctorfilterinfo, setdoctorfilterinfo] = useState({ doctors: [] });
    const [patienttype, setpatienttype] = useState();
    const [openedReport, setOpenedReport] = useState();
    const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
    const [change, setchange] = useState(false);
    const [sharedadvertisinguser, setsharedadvertisinguser] = useState("");
    const [sorting, setsorting] = useState();
    const [csvdata, setcsvdata] = useState([]);
    const [userViewedTotalCount, setUserViewedTotalCount] = useState();
    const [userOpenedCount, setUserOpenedCount] = useState();
    const [userUnOpenedCount, setUserUnOpenedCount] = useState();
    const [truecount, settruecount] = useState();
    const [falsecount, setfalsecount] = useState();
    const [totalusers, settotalusers] = useState();
    const [currentpage, setcurrentpage] = useState(0);
    const [analyticsTab, setAnalyticsTab] = useState(JSON.parse(localStorage.getItem("analyticsTabNav")))
    const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
    const [quickScan, setQuickScan] = useState()
    const reportsperpage = 8;
    const totalpage = Math.ceil(totalreportfilteredcount / reportsperpage);
    const [
        SendToDentistIdForDentistAssignment,
        setSendToDentistIdForDentistAssignment,
    ] = useState();

    const threeDViewer =
    "6488594ef68bc13c13c1143f" ==
    JSON.parse(localStorage.getItem("admin")).admin._id;

    useEffect(() => {

        const apiCalls = async() => {
          await doctorListBasedOnCompany({ app })
          .then((res) => {
            setdoctorList(res.data.data.doctorList);
            
          })
          .catch((err) => navigate("/"));
        }
    
        apiCalls()
    
      }, [app])

      const initialCall = async () => {
        let object = { doctorfilterinfo, currentpage, app, sharedadvertisinguser, quickScan, newUser: patienttype, openedReport, sorting };
        setPatientsLoad(true)
        await getConsultingPatientLists(object)
          .then((res) => {
            setUsers(res.data.data.users);
            setPatientsLoad(false)
            res.data.data[0].total_filtered_reports
              ? settotalreportfilteredcount(res.data.data[0]?.total_filtered_reports)
              : settotalreportfilteredcount(0);
    
            setUserViewedTotalCount(res.data.data.userViewedStatus[0]?.total);
            setUserOpenedCount(res.data.data.userViewedStatus[0]?.opended);
            setUserUnOpenedCount(res.data.data.userViewedStatus[0]?.unopened);
            setLoading(false);
            
          })
          .catch((err) => {
            console.log(err)
            navigate("/")
          });
    
    
        await getConsultingPatientListsForCsv(object)
          .then((res) => {
            if (res.status === 200) {
              const  data = res.data.data.users
              const updatedData = data.map(each => {
                return {
                  ...each, latestReportScore:  each.userReportsData.length > 0 ?  each.userReportsData[each.userReportsData.length-1].report_score[0]?.overall_score : ""
                }
              })
              setcsvdata(updatedData);
            }
          })
          .catch((err) => {
            console.log(err)
            navigate("/")
            return err;
          });
        
      };
      
      
      useEffect(() => {
        initialCall();
      }, [app, doctorfilterinfo, currentpage, app, sharedadvertisinguser, quickScan, patienttype, openedReport, sorting]);
    
      

    const handlesorting = (e) => {
        setsorting(e.target.value);
        setchange(!change);
    };

    const handlenextpage = () => {
        setcurrentpage(currentpage + 1);
        setchange(!change);
    };
    const handlepreviouspage = () => {
        setcurrentpage(currentpage - 1);
        setchange(!change);
    };

    const closeAssigndentistPopup = () => {
        setdoctorfilterinfo({ doctors: [] });
        setsorting("");
        settotalreportfilteredcount(0);
        setcurrentpage(0);
        setsharedadvertisinguser("");
        initialCall();
        setassignedDentistPopup(false);
    };
    const callUsersTab = () => {
        setdoctorfilterinfo({ doctors: [] });
        setsorting("");
        settotalreportfilteredcount(0);
        setcurrentpage(0);
        setpatienttype();
        setQuickScan();
        setsharedadvertisinguser("");
        setOpenedReport();
        initialCall();
        setUserConsultingTab(true);
        setProspectUserTab(false);
        setAnalyticsTab(false)

    };

    const callProspectsTab = () => {
        setdoctorfilterinfo({ doctors: [] });
        setsorting("");
        settotalreportfilteredcount(0);
        setcurrentpage(0);
        setpatienttype();
        setQuickScan();
        setsharedadvertisinguser("");
        setOpenedReport();
        initialCall();
        setUserConsultingTab(false);
        setAnalyticsTab(false)
        setProspectUserTab(true);
    };
    const callAnalyticsTab = () => {
        setdoctorfilterinfo({ doctors: [] });
        setsorting("");
        settotalreportfilteredcount(0);
        setcurrentpage(0);
        setpatienttype();
        setQuickScan();
        setsharedadvertisinguser("");
        setOpenedReport();
        initialCall();
        setUserConsultingTab(false);
        setProspectUserTab(false);
        setAnalyticsTab(true)
    }
    const handleDoctorFilterChange = (e) => {
        const { value, checked } = e.target;
        const { doctors } = doctorfilterinfo;

        if (checked) {
            setdoctorfilterinfo({
                doctors: [...doctors, value],
            });
            setchange(!change);
        }
        // Case 2  : The user unchecks the box
        else {
            setdoctorfilterinfo({
                doctors: doctors.filter((e) => e !== value),
            });
            setchange(!change);
        }
    };

    const Load = () => {
        return (
            <div className="d-flex justify-content-center align-items-center three-dots">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    const calculateAge = (dobgiven) => {
        const dob = new Date(dobgiven);
        let diff_ms = Date.now() - dob.getTime();
        let age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    // ----------------------------------CSV headers for shared users---------------------

    const headers = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Phone Number", key: "phone_number" },
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Country", key: "country" },
        { label: "Is_Report_viewed", key: "is_report_viewed" },
        {label:"Overall_Tartar_Score", key: "latestReportScore[0]"},
        {label:"Overall_Cavity_Score", key: "latestReportScore[1]"},
        {label: "Cheif_Compliant", key: "user_looking_for"}
      ];
      const csvReport = {
        data: csvdata,
        headers: headers,
        filename: "Shared_Users.csv",
      };

    if (loading) {
        return (
            <div>

                <div className="mt-5 mx-5">
                    <div className=" d-flex align-items-center justify-content-center mt-5">
                        <PacmanLoader color={"#0090FF"} css={override} size={150} />
                        {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

                        <span className="mt-3 navbar_text_dashbord">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (!loading) {
        return (
            <Container fluid>
                <Row>
                    <Col className="my-1 " md={3}>
                        <div className="filter m-2">
                            <DentalOfficeDetails app={app} />

                            <Col md={12} className=" line-horizontal mb-3 mt-2"></Col>
                            <div className=" font-doctor mx-2">SUMMARY</div>
                            <div className="d-flex  w-5 justify-content-evenly ">
                                <Button
                                    onClick={() => {
                                        setdoctorfilterinfo({ doctors: [] });
                                        setsorting("");
                                        settotalreportfilteredcount(0);
                                        setcurrentpage(0);
                                        setpatienttype();
                                        setQuickScan();
                                        setsharedadvertisinguser("");
                                        setOpenedReport();
                                        initialCall();
                                    }}
                                >
                                    <div className="summary-box mt-2 mr-1 summary-text-prospect  px-3 py-2">
                                        <span>Total Users</span>{" "}
                                        <span className="font-60">{userViewedTotalCount}</span>
                                    </div>
                                </Button>

                                <Button
                                    onClick={() => {
                                        setOpenedReport(true);
                                        setchange(!change);
                                    }}
                                >
                                    <div className="summary-box mt-2  m-1 summary-text-prospect  px-3 py-2">
                                        <span>OPENED users</span>{" "}
                                        <span className="font-32">{userOpenedCount}</span>
                                    </div>
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpenedReport(false);
                                        setchange(!change);
                                    }}
                                >
                                    <div className="summary-box mt-2 summary-text-prospect   px-2 py-2">
                                        Unopened Users{" "}
                                        <span className="font-12">{userUnOpenedCount}</span>
                                    </div>
                                </Button>
                            </div>

                            <Col md={12} className=" line-horizontal mb-3 mt-2"></Col>

                            <Row>
                                <Col md={4} xs={4}>
                                    <div className="d-flex justify-content-start align-items-center mx-2  ">
                                        {" "}
                                        <img
                                            src={filter}
                                            width="15px"
                                            height="15px"
                                            alt="filter"
                                        ></img>
                                        <div className=" mx-2 text-filter d-flex justify-content-start ">
                                            Filter
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    md={7}
                                    xs={7}
                                    className=" d-flex w-5 justify-content-end text-reset-all"
                                >
                                    <Button
                                        style={{ textDecoration: "none", color: "inherit" }}
                                        onClick={() => {
                                            setdoctorfilterinfo({ doctors: [] });
                                            setsorting("");
                                            settotalreportfilteredcount(0);
                                            setcurrentpage(0);
                                            setpatienttype();
                                            setQuickScan();
                                            setsharedadvertisinguser("");
                                            setOpenedReport();
                                            initialCall();
                                        }}
                                    >
                                        {" "}
                                        Clear Filters{" "}
                                    </Button>
                                </Col>
                            </Row>

                            {/* ------------------------------------unshared report filter----------------------------------- */}

                            <Col md={12} className=" line-horizontal m-1"></Col>

                            {/* -------------------------------User type filter------------------------ */}

                            <Col className="mb-2 mt-3 text-source mx-3">
                                How They Found Us
                            </Col>
                            <Row className="mx-1">
                                <Col md={1} xs={1}>
                                    {" "}
                                    <div
                                        className="radio-btn"
                                        onClick={() => {
                                            setsharedadvertisinguser("true");
                                            setchange(!change);
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            value={sharedadvertisinguser}
                                            name="sharedadvertisinguser"
                                            checked={sharedadvertisinguser == "true"}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    md={10}
                                    xs={10}
                                    className="checkbox-text d-flex align-items-top"
                                >
                                    Advertising URL
                                </Col>
                            </Row>
                            <Row className="mx-1 mt-1">
                                <Col md={1}>
                                    {" "}
                                    <div
                                        className="radio-btn"
                                        onClick={() => {
                                            setsharedadvertisinguser("false");
                                            setchange(!change);
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            value={sharedadvertisinguser}
                                            name="sharedadvertisinguser"
                                            checked={sharedadvertisinguser == "false"}
                                        />
                                    </div>
                                </Col>
                                <Col md={10} className="checkbox-text d-flex align-items-top">
                                    Office URL
                                </Col>
                            </Row>
                            <Col md={12} className="line-horizontal m-1"></Col>

                            {/* -------------------------------Patient filter------------------------ */}

                            <Col className="mb-2 mt-3 text-source mx-3">
                                Type of Patients
                            </Col>
                            <Row className="mx-1">
                                <Col md={1}>
                                    {" "}
                                    <div
                                        className="radio-btn"
                                        onClick={() => {
                                            setpatienttype("true");
                                            setchange(!change);
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            value={patienttype}
                                            name="New Patient"
                                            checked={patienttype == "true"}
                                        />
                                    </div>
                                </Col>
                                <Col md={10} className="checkbox-text d-flex align-items-top">
                                    New Patients
                                </Col>
                            </Row>
                            <Row className="mx-1 mt-1">
                                <Col md={1}>
                                    {" "}
                                    <div
                                        className="radio-btn"
                                        onClick={() => {
                                            setpatienttype("false");
                                            setchange(!change);
                                        }}
                                    >
                                        <input
                                            type="radio"
                                            value={patienttype}
                                            name="Existing Patient"
                                            checked={patienttype == "false"}
                                        />
                                    </div>
                                </Col>
                                <Col md={10} className="checkbox-text d-flex align-items-top">
                                    Existing Patients
                                </Col>
                            </Row>
                            <Col md={12} className="line-horizontal m-1"></Col>

                            {/* -------------------------------Scan filter------------------------ */}
                            {app == "63d0f71290a9cdf5c42bdc8f" &&
                                <div>
                                    <Col className="mb-2 mt-3 text-source mx-3">
                                        Type of Scan
                                    </Col>
                                    <Row className="mx-1">
                                        <Col md={1}>
                                            {" "}
                                            <div
                                                className="radio-btn"
                                                onClick={() => {
                                                    setQuickScan("true");
                                                    setchange(!change);
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    value={quickScan}
                                                    name="Quick Scan"
                                                    checked={quickScan == "true"}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={10} className="checkbox-text d-flex align-items-top">
                                            Quick Scan
                                        </Col>
                                    </Row>
                                    <Row className="mx-1 mt-1">
                                        <Col md={1}>
                                            {" "}
                                            <div
                                                className="radio-btn"
                                                onClick={() => {
                                                    setQuickScan("false");
                                                    setchange(!change);
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    value={quickScan}
                                                    name="Complete Scan"
                                                    checked={quickScan == "false"}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={10} className="checkbox-text d-flex align-items-top">
                                            Complete Scan
                                        </Col>
                                    </Row>
                                    <Col md={12} className="line-horizontal m-1"></Col>
                                </div>}


                            {/* -------------------------------Dentist filter------------------------ */}

                            <Col className="my-2 text-source mx-3">Assigned Dentist</Col>
                            {doctorList.map((value, id) => {
                                return (
                                    <Row className="mx-1 mt-1" key={id}>
                                        <Col md={1}>
                                            {" "}
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="doctorFilter"
                                                value={value._id}
                                                id="doctorFilterDefault"
                                                onChange={handleDoctorFilterChange}
                                                checked={doctorfilterinfo.doctors.includes(value._id)}
                                            />
                                        </Col>
                                        <Col
                                            md={10}
                                            className="checkbox-text d-flex align-items-top"
                                        >
                                            {" "}
                                            <label htmlFor="doctorFilterDefault">
                                                Dr. {value.name}
                                            </label>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </Col>

                    {/* -------------------------------Report body display------------------------ */}

                    <Col className="  my-2 " md={9}>
                        <div className="lists m-2">
                            <div className="d-flex  w-5 ">
                                <div className="button-flex flex-row-reverse p-1 ">
                                    <Button
                                        disabled={
                                            currentpage ==
                                            (totalpage == 0 ? currentpage : totalpage - 1)
                                        }
                                        onClick={handlenextpage}
                                    >
                                        <ArrowForwardIosIcon />
                                    </Button>
                                    <Button
                                        disabled={currentpage == 0}
                                        onClick={handlepreviouspage}
                                    >
                                        <ArrowBackIosIcon />
                                    </Button>
                                    <div className="p-1 ">
                                        {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}
                                        -
                                        {currentpage == totalpage - 1
                                            ? totalreportfilteredcount
                                            : (currentpage + 1) * 8}{" "}
                                        <span className="mx-1">of</span>{" "}
                                        {totalreportfilteredcount}
                                    </div>

                                    <select
                                        className="form-btns-dashboard text-sans-bold mx-3"
                                        value={sorting}
                                        onChange={handlesorting}
                                    >
                                        <option value="" className="text-sans-bold ">
                                            Sort By{" "}
                                        </option>
                                        <option value="Newest on top">Newest on top </option>
                                        <option value="Oldest on top">Oldest on top</option>
                                        <option value="First Name">First Name (A-Z)</option>
                                        <option value="First Name Des">First Name (Z-A)</option>
                                    </select>

                                    <CSVLink {...csvReport}>
                                        <Button>   <FaDownload size="22px" color='#0090FF' className='mx-2' />
                                        </Button>
                                    </CSVLink>
                                </div>
                            </div>

                            {patientsLoad ? <Load /> : users.map((value) => {
                                // var lengths = value.fromsendtodentist.length;

                                return (
                                    <Row key={value._id} className={"patients-row m-1  mx-3"}>
                                        <div className=" nav width-100 ">
                                        <Col xl={3} className="  height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={ threeDViewer ?  `/user-detail-dental-3D/${value._id}` :  `/user-detail-dental/${value._id}`}
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col xl={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  xl={3}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-1">Name</div>
                                  <div className="text-sans mx-1">Email</div>
                                </Col>

                                <Col
                                  xl={9}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div
                                    className={
                                      value.is_report_viewed
                                        ? "text-sans-bold   font-32-ellipsis"
                                        : "text-sans-bold font-12-ellipsis"
                                    }
                                  >
                                    {value.first_name} {value.last_name}
                                  </div>
                                  <div className="text-sans-bold ellipsis">
                                    {value.email}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>
                      <Col xl={3} className=" dotted_line height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={
                            threeDViewer
                              ? `/user-detail-dental-3D/${value._id}`
                              : `/user-detail-dental/${value._id}`
                          }
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col xl={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  xl={5}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-1">Ph.no</div>
                                  <div className="text-sans mx-1">Age</div>
                                </Col>

                                <Col
                                  lg={7}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans-bold ellipsis">
                                    {" "}
                                    {value.phone_number}
                                  </div>
                                  <div className="text-sans-bold ellipsis">
                                    {calculateAge(value.dob)}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>
                      <Col lg={3} className=" dotted_line height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={
                            threeDViewer
                              ? `/user-detail-dental-3D/${value._id}`
                              : `/user-detail-dental/${value._id}`
                          }
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col lg={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  lg={5}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-3"> Date</div>

                                  <div className="text-sans mx-3">City</div>
                                </Col>

                                <Col
                                  lg={7}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans-bold">
                                    {value.last_report_shared_date
                                      ? moment(
                                          value.last_report_shared_date
                                        ).format("MM/DD/YYYY")
                                      : "----"}
                                  </div>

                                  <div className="text-sans-bold ellipsis_state">
                                    {value.city}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>

                      <Col lg={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col lg={12} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                // lg={7}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3">
                                  Assigned Dentist
                                </div>

                                <div className="text-sans mx-3">State</div>
                              </Col>

                              <Col
                                // lg={5}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans-bold ellipsis_state">
                                  {value.assigned_dentist
                                    ? `Dr. ${
                                        value.fromdoctorlist[0]
                                          ? value.fromdoctorlist[0].name
                                          : ""
                                      }`
                                    : "---"}
                                </div>

                                <div className="text-sans-bold ellipsis_state">
                                  {" "}
                                  {value.state}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>

                                        </div>
                                    </Row>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
   
            </Container>
        );
    }
};

export default SharedUsers;