import React,{useEffect,useState} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import {FaUserMd} from "react-icons/fa";
import close from "../../assets/Close.svg";
import {
    doctorListBasedOnCompany,
assignDentistToReport
  } from "../../Services/AuthService";

const AssignDentistPopup = ({sendToDentistId,popupclose,popupCloseWithoutApi}) => {
    const app = JSON.parse(localStorage.getItem("admin")).admin.app;
       const [doctorList,setDoctorList]=useState();
const [doctorName,setDoctorName]=useState()
       const [loading,setloading]=useState(true)
useEffect(()=>{
 doctorListBasedOnCompany({ app })
    .then((res) => {
      setDoctorList(res.data.data.doctorList);
      setDoctorName(res.data.data.doctorList[0]._id);

      setloading(false);
    })
    .catch((err) => console.log(err));
},[])

if(!loading){
    return (
        <div className="model">
        <div className="model_container">
          <div className="close_position">
            <Button
              onClick={() => {
                popupCloseWithoutApi()
              }}
            >
              {" "}
              <img src={close} width="50px" height="50px"></img>
            </Button>
          </div>
          <Col md={12}>
            <Row>
              <Col
                md={5}
                className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
              >
                 <FaUserMd size="150px" color=' #F5F5FA'  />
              </Col>
              <Col
                md={7}
                className="d-flex flex-column justify-content-center h-5 align-items-center"
              >
                <div className="text-sans-bold">
                                          <select
                                            className="select-doctor-input my-2 grey-bg-color"
                                            value={doctorName}
                                            onChange={(e)=>{
                                              setDoctorName(e.target.value)
                                            
                                            }}
                                          >
                                            {doctorList.map((option) => (
                                              <option value={option._id}>  
                                               Dr. {option.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

<div>
    <Button 
    className="delete_popup_button mt-5"
    onClick={()=>{
        assignDentistToReport({
            id:sendToDentistId,
             doctor:doctorName
        }).then(res=>{popupclose()}).catch(err=>{console.log(err);popupclose()})
    }}>
                              <span className="delete_button_text">Save and Update</span>

    </Button>
</div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
      )
}
 
}

export default AssignDentistPopup