import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import "./baa.css";
import Button from "@mui/material/Button";
import TermsAndCondition from "./termsAndCondition";
const BaaAggrement = () => {
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  return (
    <Container>
      <div className="model">
        <div className="model-container  m-5 p-5">
          <div>Toothlens Manager BUSINESS ASSOCIATE AGREEMENT </div>
          <div>Last Updated: August 15, 2022 </div>
          <br></br>
          <div className="">
            PLEASE READ ALL OF THIS DOCUMENT. PRINT THIS PAGE FOR YOUR RECORDS.
            BY ACCESSING AND USING THE PLATFORM, YOU AGREE THAT YOU HAVE READ
            AND UNDERSTAND THIS BUSINESS ASSOCIATE AGREEMENT AND AGREE TO BE
            BOUND BY IT. Toothlens, LLC and its affiliates (referred herein as
            “Toothlens” or “Business Associate”), provides the Toothlens
            Manager, a web-based virtual platform developed, designed and owned
            by Toothlens pursuant to which dental professionals and dental
            businesses manage their marketing ,lead generation and remote
            patient monitoring (collectively, the “Service”). By accessing and
            using the technology used by Toothlens to provide the Service,
            including the Toothlens Manager website, or any Toothlens mobile
            application (“Toothlens App(s)”)(collectively, the “Platform”), the
            Provider (referred to herein as “Provider” or ‘Covered Entity”) has
            executed the Toothlens Terms of Use (the “Platform Terms of Use”).
            Pursuant to the Platform Terms of Use, the Business Associate and/or
            its affiliates (as applicable) will provide certain functions or
            activities on behalf of, or certain services to, Covered Entity that
            may involve the use and/or disclosure of Protected Health
            Information; By entering into the Platform Terms of Use and
            accessing and using the technology used by Toothlens to provide the
            Services, the Provider agrees to be bound by the terms and
            conditions of this Business Associate Agreement (the “BAA”). The
            purpose of this BAA is to comply with the requirements of the Health
            Insurance Portability and Accountability Act of 1996 (“HIPAA”), the
            Health Information Technology for Economic and Clinical Health Act
            (“HITECH”), enacted as part of the American Recovery and
            Reinvestment Act of 2009, and the implementing regulations
            thereunder, including but not limited to the Standards for Privacy
            of Individually Identifiable Health Information at 45 CFR Parts 160
            and 164 (the “Privacy Rule”) and the Security Standards for the
            Protection of Electronic Health Information at 45 C.F.R. Parts 160
            and 164 (the “Security Rule”), as may be amended from time to time.
            WE WILL REVISE AND UPDATE THE TERMS, AND CONDITIONS OF THE BAA, AND
            THE UPDATES SHALL BE PROVIDED TO YOU WHEN YOU ENTER THE PLATFORM FOR
            THE FIRST TIME AFTER SUCH CHANGES. CONTINUED USE OF THE PLATFORM
            AFTER ANY SUCH CHANGES CONSITUTES YOUR ACCEPTANCE OF THE NEW TERMS
            AND CONDITIONS. IF YOU DO NOT AGREE TO ABIDE BY SUCH TERMS, DO NOT
            USE, OR CONTINUE TO USE, THE PLATFORM. In consideration of the
            Parties’ continuing obligations under the Platform Terms of Use,
            compliance with HIPAA, and other good and valuable consideration,
            the receipt and sufficiency of which is hereby acknowledged, the
            Parties agree to the provisions of this BAA in order to address the
            requirements of the HIPAA Privacy and Security Rules and to protect
            the interests of both Parties. I. DEFINITIONS A. Except as otherwise
            defined herein, terms (including capitalized terms) used in this BAA
            shall have the definition set forth out in 45 CFR Parts 160-164, as
            may be amended from time to time. B. The term “Protected Health
            Information” or PHI shall have the definition as defined in 45 CFR
            §160.103 and shall include “Electronic Protected Health Information”
            (EPHI). II. PERMITTED USES AND DISCLOSURES OF PHI BY BUSINESS
            ASSOCIATE A. All PHI created, or received, by Covered Entity and
            made available in any form, including paper record, oral
            communication, audio recording, and electronic media by Covered
            Entity to Business Associate or its subcontractor, as well as PHI
            that is created, received, maintained or transmitted by Business
            Associate on Covered Entity’s behalf, is the property of the Parties
            and shall be subject to this BAA. Business Associate shall make
            copies of such PHI available to Covered Entity upon reasonable
            request and in such a fashion so as to permit Covered Entity to meet
            its obligations under the HIPAA Privacy and Security Rule. B.
            Business Associate shall only use or disclose PHI: (1) To fulfill
            its obligations under any agreement evidencing a business
            relationship, between the Covered Entity and the Business Associate;
            (2) As required by an accrediting or credentialing organization to
            whom Business Associate is required to disclose such information;
            (3) If necessary for the proper management and administration of
            Business Associate or to carry out the legal responsibilities of
            Business Associate, provided that: (a) The disclosure is required by
            law; or (b) Business Associate obtains reasonable, written
            assurances from the person to whom the information is disclosed that
            PHI will be held confidentially and used or further disclosed only
            as required by law or for the purposes for which it was disclosed to
            the person, and the person agrees to notify Business Associate of
            any instances of which it is aware in which the confidentiality of
            the PHI has been breached. (4) To provide data aggregation services
            relating to the health care operations of Covered Entity, as
            permitted by 45 CFR §164.504(e)(2(i)(B); (5) To de-identify PHI
            consistent with the provisions set forth in 45 CFR §164.514, and
            once de-identified, Business Associate shall own such de-identified
            information and may use and disclose such information, provided that
            any such use or disclosure shall be consistent with applicable law;
            and (6) As otherwise permitted or required under this BAA or the
            Platform Terms of Use, or as required by law. C. Business
            Associate’s uses and further disclosures of PHI shall comply with
            the minimum necessary requirements of 45 CFR §§164.502(b) and
            164.514, of the HIPAA Privacy Rule at such time as those
            requirements are applicable to Business Associate. D. To the extent
            Business Associate is to carry out one or more of Covered Entity's
            obligations under the Privacy Rule, Business Associate shall comply
            with those requirements of the Privacy Rule applicable to Covered
            Entity in performing such obligations. III. OBLIGATIONS OF BUSINESS
            ASSOCIATE A. Business Associate will use reasonable and appropriate
            safeguards to prevent Use or Disclosure of the PHI other than as
            provided for by this BAA, consistent with the requirements of
            Subpart C of 45 CFR Part 164 (with respect to EPHI) as determined by
            Business Associate and as reflected in this BAA. B. Business
            Associate will report to Covered Entity any Use or Disclosure of PHI
            not permitted or required by this BAA of which Business Associate
            becomes aware. C. Business Associate will report to Covered Entity
            on no less than a quarterly basis any Security Incidents involving
            PHI of which Business Associate becomes aware in which there is a
            successful unauthorized access, use, disclosure, modification, or
            destruction of information or interference with system operations in
            an Information System in a manner that risks the confidentiality,
            integrity, or availability of such information. Notice is hereby
            deemed provided, and no further notice will be provided, for
            unsuccessful attempts at such unauthorized access, use, disclosure,
            modification, or destruction, such as pings and other broadcast
            attacks on a firewall, denial of service attacks, port scans,
            unsuccessful login attempts, or interception of encrypted
            information where the key is not compromised, or any combination of
            the above. D. Business Associate will report to Covered Entity any
            Breach of Covered Entity Unsecured PHI that Business Associate may
            discover to the extent required by 45 CFR §164.410. Business
            Associate will make such report without unreasonable delay, and in
            no case later than 60 calendar days after discovery of such Breach.
            E. Business Associate will ensure that any subcontractors that
            create, receive, maintain, or transmit PHI on behalf of Business
            Associate agree to restrictions and conditions at least as stringent
            as those found in this BAA, and agree to implement reasonable and
            appropriate safeguards to protect PHI. F. Business Associate will
            make PHI in a Designated Record Set available to Covered Entity so
            that Covered Entity can comply with 45 CFR §164.524. G. Business
            Associate will make PHI in a Designated Record Set available to
            Covered Entity for amendment and incorporate any amendments to PHI,
            as may reasonably be requested by Covered Entity in accordance with
            45 CFR §164.526. H. Business Associate will make available to
            Covered Entity the information required to provide an accounting of
            Disclosures in accordance with 45 CFR §164.528 of which Business
            Associate is aware, if requested by Covered Entity. I. Business
            Associate will make its internal practices, books, records relating
            to the Use and Disclosure of PHI available to the Secretary of the
            U.S. Department of Health and Human Services (“HHS”) for purposes of
            determining Covered Entity’s compliance with HIPAA. Nothing in this
            section will waive any applicable privilege or protection, including
            with respect to trade secrets and confidential commercial
            information. IV. COVERED ENTITY OBLIGATIONS. A. Covered Entity will
            not agree to any restriction requests or place any restrictions in
            any notice of privacy practices that would cause Business Associate
            to violate this BAA or any applicable law. B. Covered Entity shall
            not request Business Associate to use or disclose PHI in any manner
            that would not be permissible under the HIPAA Privacy Rule if done
            by Covered Entity, except as described in Section II (B)(3)-(4). C.
            Covered Entity will not request or cause Business Associate to make
            a Use or Disclosure of PHI in a manner that does not comply with
            HIPAA or this BAA. V. TERMINATION A. At the termination of this BAA
            or the Platform Terms of Use, Business Associate will, if feasible,
            return, or destroy and attest to such destruction of, all PHI
            received from, or created or received by Business Associate on
            behalf of Covered Entity that Business Associate stills maintains in
            any form and retain no copies of such information. If return or
            destruction is not feasible, Business Associate shall extend the
            protections of this BAA to the PHI and limit further uses and
            disclosures to those purposes that make the return or destruction of
            PHI infeasible for so long as Business Associate maintains such PHI.
            B. If Covered Entity determines that Business Associate has violated
            a material term of this BAA, Covered Entity may provide an
            opportunity for Business Associate to cure the breach or end the
            violation, if the breach or violation is capable of being cured. If
            Business Associate does not cure the breach or end the violation
            within the time specified by Covered Entity, or if the breach or
            violation is not capable of cure, Covered Entity has the right to
            immediately terminate this BAA. VI. MISCELLANEOUS A. If there is an
            inconsistency between the provisions of this BAA and mandatory
            provisions of the HIPAA Privacy and Security Rule, the HIPAA Privacy
            and Security Rule shall control. Where provisions of this BAA are
            different from those mandated in the HIPAA Privacy and Security
            Rule, but are permitted by the HIPAA Privacy and Security Rule, the
            provisions of this BAA shall control. B. If the Platform Terms of
            Use or any other agreement between the parties contains provisions
            relating to the use or disclosure of PHI which are more restrictive
            than the provisions of this BAA, the provisions of the more
            restrictive documentation will control. Otherwise, the terms of this
            BAA shall supersede and control any conflicting provisions in the
            Platform Terms of Use. C. Except as expressly stated herein or in
            the HIPAA Privacy and Security Rule, the Parties do not intend to
            create any rights in any third parties. D. The obligations of
            Business Associate under this BAA shall survive the expiration,
            termination, or cancellation of this BAA, the Platform Terms of Use
            and/or the business relationship of the parties, and shall continue
            to bind Business Associate, its agents, employees, contractors,
            successors, and permitted assigns as set forth herein. E. None of
            the provisions of this BAA are intended to create, nor will they be
            deemed to create, any relationship between the Parties other than
            that of independent parties contracting with each other solely for
            the purposes of effecting the provisions of this BAA and any other
            agreements between the Parties evidencing their business
            relationship. Nothing in this BAA shall be construed to create a
            partnership, joint venture or principal-agency relationship between
            the Parties, and the parties specifically intend that their business
            relationship not be that of principal-agent. F. No change, waiver or
            discharge of any liability or obligation hereunder on any one or
            more occasions shall be deemed a waiver of performance of any
            continuing or other obligation, or shall prohibit enforcement of any
            obligation, on any other occasion. G. If any provision of this BAA
            is held by a court of competent jurisdiction to be invalid or
            unenforceable, the remainder of the provisions of this BAA will
            remain in full force and effect. H. If a Party believes in good
            faith that any law, rule, or regulation of any authority having
            jurisdiction over the Parties to this BAA raises questions as to the
            legality, enforceability, or appropriateness of this BAA, or any
            provision hereof, such Party shall notify the other Party in
            writing. For a period of up to thirty (30) days, the Parties shall
            address such concern in good faith and amend the terms of this BAA
            if necessary to bring it into compliance. If, after the thirty (30)
            day period, the Parties are not able to agree upon a modification,
            either Party has the right to terminate this BAA upon written notice
            to the other Party. I. To the extent not pre-empted by federal law,
            this BAA will be governed by the laws of the State of Delaware. J.
            Notices or other required or permitted communication to be given
            hereunder shall be deemed effectively given when emailed to
            Attn.Chief Data Officer,Toothlens at contact@toothlens.com, and to
            the attention of such other person(s) or officer(s), as either Party
            may designate by written notice to the other Party.
          </div>
          <div className="d-flex w-5 justify-content-center">
            <Button
              className="form-btn mt-4"
              type="button"
              onClick={() => {
                setTermsAndCondition(true);
              }}
            >
              Next
            </Button>
          </div>
          {termsAndCondition && <TermsAndCondition />}
        </div>
      </div>
    </Container>
  );
};

export default BaaAggrement;
