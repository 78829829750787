import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import Container from "react-bootstrap/Container";
import PacmanLoader from "react-spinners/PacmanLoader";
import {
  countOfMarkettingTypeUser,
  countOfAiCheckProspectUser
} from "../../Services/AuthService";

import { Link, useNavigate } from "react-router-dom";
import PieCharts from "./pieChart.js";

import StackedBarChart from "./stackedBarChart";
const AnalyticsTab= ({ callUsersTab, prospect,analytics}) => {
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const navigate = useNavigate();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [loading,setLoading]=useState(true)
const [pieChartDataForUsers,setPieChartDataForUsers]=useState("")
const [BarChartDataForUsersType,setBarChartDataForUsersType]=useState("")


  const data = [
    { name: 'Group A', value:316 },
    { name: 'Group B', value: 85 },
    { name: 'Group C', value: 115 },
  ];
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    countOfAiCheckProspectUser({app}).then(res=>{
      setPieChartDataForUsers(res.data.data)
      countOfMarkettingTypeUser({app}).then(res=>{
        setBarChartDataForUsersType(res.data.data)
        setLoading(false)
      }).catch(err=>console.log(err))

    }).catch(err=>{
      console.log(err)
    })
  }, []);


  if (loading) {
    return (
      <div>
        {" "}
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container>
        
     

          {/* -------------------------------Prospects body display------------------------ */}

          <Col className="  my-2 " xs={12} md={12}> 
            <div className="lists m-2">
              <div className="d-flex  w-5 align-items-center justify-content-center mt-5">
                {/* <FormLabel className="font-doctor"> */}
               
                  <Button
                    onClick={() => {
                      callUsersTab();
                    }}
                  >
                    <div className="unselected_report_text_lite">
                      {" "}
                      Shared Users
                    </div>
                  </Button>

                  <Button
                    className="mx-2"
                    onClick={() => {
                      prospect();
                    }}
                  >
                    <div className="unselected_report_text_lite">
                      {" "}
                      Prospects
                    </div>
                  </Button>
                  <Button
                    onClick={() => {
                      analytics();
                    }}
                  >
                     <div className="d-flex align-items-center w-5 h-5">
                      <div className="d-flex align-items-center w-5 h-5">
                        <div>
                          <img
                            src={dentalAnalysisimg}
                            width="25px"
                            height="25px"
                            alt="filter"
                          ></img>
                        </div>
                        <div className="shared_report_text mx-1">Analytics</div>
                      </div>
                    </div>
                  </Button>
                {/* </FormLabel> */}
              
              </div> 
            </div>

           <Col md={12} className="mt-5"> 
       <Row>
      <Col md={12} lg={12} sm={12} xs={12} xl={5}>
        <div className="d-flex justify-content-center align-items-center w-5   pt-3 font-32">
User Details
        </div>
          <div className="d-flex justify-content-center align-items-center w-5 m-3 mt-2">
          <PieCharts data={pieChartDataForUsers} />

          </div>
        </Col>
        <Col md={12}lg={12} sm={12} xs={12} xl={7}>
        <div className="d-flex justify-content-center align-items-center w-5   pt-4 font-32">
Advertising URL/Office URL
        </div>
          <div className="d-flex justify-content-center align-items-center w-5 mt-5">
         <StackedBarChart data={BarChartDataForUsersType}/>
          </div>
        </Col>

      
      </Row> 
    
       
    </Col> 
          </Col>
        

     
      </Container>
    );
  }
};

export default AnalyticsTab;
