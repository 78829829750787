import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import filter from "../../assets/filter.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getConsultingPatientLists,
  getConsultingPatientListsForCsv,
  userViewedStatusForLocationDashboard
} from "../../Services/AuthService";
import { FaUserMd } from "react-icons/fa";
import Navbars from "./navbar";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import { CSVLink, CSVDownload } from "react-csv";
import { FaDownload } from "react-icons/fa";
import LocationCardDetails from "./locationCardDetails";

const LocationDashboard = () => {
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const navigate = useNavigate();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const location_id= {branches:[JSON.parse(localStorage.getItem("admin")).admin.location_id]}

  const [prospectUserTab, setProspectUserTab] = useState(false);
  const [userConsultingTab, setUserConsultingTab] = useState(true);
  const [doctorList, setdoctorList] = useState("");
  const [sharedLocationId, setsharedLocationId] = useState();
  const doctorfilterinfo = {
    doctors: [],
  };
  const [patienttype, setpatienttype] = useState();
  const [openedReport, setOpenedReport] = useState();
  const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
  const [change, setchange] = useState(false);
  const [sharedadvertisinguser, setsharedadvertisinguser] = useState("");
  const [sorting, setsorting] = useState();
  const [csvdata, setcsvdata] = useState("");
  const [userViewedTotalCount, setUserViewedTotalCount] = useState();
  const [userOpenedCount, setUserOpenedCount] = useState();
  const [userUnOpenedCount, setUserUnOpenedCount] = useState();
  const [truecount, settruecount] = useState();
  const [falsecount, setfalsecount] = useState();
  const [totalusers, settotalusers] = useState();
  const [currentpage, setcurrentpage] = useState(0);
  const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
  const reportsperpage = 8;
  const totalpage = Math.ceil(totalreportfilteredcount / reportsperpage);
  const [
    SendToDentistIdForDentistAssignment,
    setSendToDentistIdForDentistAssignment,
  ] = useState();
  const initialCall = async () => {
  
    let object = {
      app,
      locationfilterinfo:location_id,
    };
    await userViewedStatusForLocationDashboard(object)
      .then((res) => {
        setUserViewedTotalCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].total
            : 0
        );
        setUserOpenedCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].opended
            : 0
        );
        setUserUnOpenedCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].unopened
            : 0
        );
      })
      .catch((err) => {
        navigate("/");
      });
    await getConsultingPatientLists(object)
      .then((res) => {
        setUsers(res.data.data.users);
        res.data.data[0].total_filtered_reports
          ? settotalreportfilteredcount(res.data.data[0].total_filtered_reports)
          : settotalreportfilteredcount(0);
      })
      .catch((err) => navigate("/"));

    await getConsultingPatientListsForCsv(object)
      .then((res) => {
        if (res.status === 200) {
          setcsvdata(res.data.data.users);
        }
      })
      .catch((err) => {
        navigate("/");
        return err;
      });
    await getConsultingPatientLists(object)
      .then((res) => {
        const callfunction = (res) => {
          var lengths = res.fromsendtodentist.length;
          return res.fromsendtodentist[lengths - 1].is_report_viewed;
        };
        const overall = res.data.data.users.map(callfunction);
        var counter = 0;

        var total_true_values = () => {
          for (var i = 0; i < overall.length; i++) {
            if (overall[i] == true) {
              counter++;
            }
          }
          return counter;
        };
        var counterfalse = 0;
        var total_false_values = () => {
          for (var i = 0; i < overall.length; i++) {
            if (overall[i] == false) {
              counterfalse++;
            }
          }
          return counterfalse;
        };
        settotalusers(overall.length);
        settruecount(total_true_values());
        setfalsecount(total_false_values());
        setLoading(false);
      })
      .catch((err) => navigate("/"));
  };

  const secondCall = async () => {
    let dentistObject = {
      app,
      locationfilterinfo:location_id   };
    let object = {
      currentpage,
      app,
      sharedadvertisinguser,
      newUser: patienttype,
      openedReport,
      sorting,
      locationfilterinfo:location_id  };

    await userViewedStatusForLocationDashboard(dentistObject)
      .then((res) => {
        setUserViewedTotalCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].total
            : 0
        );
        setUserOpenedCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].opended
            : 0
        );
        setUserUnOpenedCount(
          res.data.data.userViewedStatus.length != 0
            ? res.data.data.userViewedStatus[0].unopened
            : 0
        );
      })
      .catch((err) => {
        navigate("/");
      });
    await getConsultingPatientLists(object)
      .then((res) => {
        setUsers(res.data.data.users);
        res.data.data[0].total_filtered_reports
          ? settotalreportfilteredcount(res.data.data[0].total_filtered_reports)
          : settotalreportfilteredcount(0);
      })
      .catch((err) => navigate("/"));

    await getConsultingPatientListsForCsv(object)
      .then((res) => {
        if (res.status === 200) {
          setcsvdata(res.data.data.users);
        }
      })
      .catch((err) => {
        // setloading(false)
        navigate("/")
        return err;
      });

    await getConsultingPatientLists(object)
      .then((res) => {
        const callfunction = (res) => {
          var lengths = res.fromsendtodentist.length;
          return res.fromsendtodentist[lengths - 1].is_report_viewed;
        };
        const overall = res.data.data.users.map(callfunction);
        var counter = 0;

        var total_true_values = () => {
          for (var i = 0; i < overall.length; i++) {
            if (overall[i] == true) {
              counter++;
            }
          }
          return counter;
        };
        var counterfalse = 0;
        var total_false_values = () => {
          for (var i = 0; i < overall.length; i++) {
            if (overall[i] == false) {
              counterfalse++;
            }
          }
          return counterfalse;
        };
        settotalusers(overall.length);
        settruecount(total_true_values());
        setfalsecount(total_false_values());
      })
      .catch((err) => navigate("/"));
  };

  useEffect(() => {
    initialCall();
  }, []);

  useEffect(() => {
    secondCall();
  }, [change]);

  const handlesorting = (e) => {
    setsorting(e.target.value);
    setchange(!change);
  };

  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setchange(!change);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setchange(!change);
  };

  // const closeAssigndentistPopup = () => {
  //   setdoctorfilterinfo({ doctors: [] });
  //   setsorting("");
  //   settotalreportfilteredcount(0);
  //   setcurrentpage(0);
  //   setsharedadvertisinguser("");
  //   initialCall();
  //   setassignedDentistPopup(false);
  // };
  // const callUsersTab = () => {
  //   setdoctorfilterinfo({ doctors: [] });
  //   setsorting("");
  //   settotalreportfilteredcount(0);
  //   setcurrentpage(0);
  //   setpatienttype();
  //   setsharedadvertisinguser("");
  //   setOpenedReport();
  //   initialCall();
  //   setUserConsultingTab(true);
  //   setProspectUserTab(false);
  // };

  // const callProspectsTab = () => {
  //   setdoctorfilterinfo({ doctors: [] });
  //   setsorting("");
  //   settotalreportfilteredcount(0);
  //   setcurrentpage(0);
  //   setpatienttype();
  //   setsharedadvertisinguser("");
  //   setOpenedReport();
  //   initialCall();
  //   setUserConsultingTab(false);

  //   setProspectUserTab(true);
  // };

  // const handleDoctorFilterChange = (e) => {
  //   const { value, checked } = e.target;
  //   const { doctors } = doctorfilterinfo;

  //   if (checked) {
  //     setdoctorfilterinfo({
  //       doctors: [...doctors, value],
  //     });
  //     setchange(!change);
  //   }
  //   else {
  //     setdoctorfilterinfo({
  //       doctors: doctors.filter((e) => e !== value),
  //     });
  //     setchange(!change);
  //   }
  // };

  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  // ----------------------------------CSV headers for shared users---------------------

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_number" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Is_Report_viewed", key: "is_report_viewed" },
  ];
  const csvReport = {
    data: csvdata,
    headers: headers,
    filename: "Shared_Users.csv",
  };

  if (loading) {
    return (
      <div>
        <Navbars />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container fluid>
        <Navbars />

        {userConsultingTab && (
          <Row>
            <Col className="my-1 " xs={12} md={3} sm={12}>
              <div className="filter m-2">
                <LocationCardDetails />
                <Col
                  md={12}
                  sm={12}
                  xs={12}
                  className=" line-horizontal mb-3 mt-2"
                ></Col>

                <Col md={12} sm={12} xs={12} className=" font-doctor mx-2">
                  SUMMARY
                </Col>

                <Row>
                  <Col xxl={3} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      onClick={() => {
                        setsorting("");
                        settotalreportfilteredcount(0);
                        setcurrentpage(0);
                        setpatienttype();
                        setsharedadvertisinguser("");
                        setOpenedReport();
                        initialCall();
                      }}
                    >
                      <div className="summary-box mt-2 mr-1 summary-text-prospect  px-3 py-2">
                        <span>Total Users</span>{" "}
                        <span className="font-60">{userViewedTotalCount}</span>
                      </div>
                    </Button>
                  </Col>
                  <Col
                    xxl={3}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mx-3"
                  >
                    <Button
                      onClick={() => {
                        setOpenedReport(true);
                        setchange(!change);
                      }}
                    >
                      <div className="summary-box mt-2  m-1 summary-text-prospect  px-3 py-2">
                        <span>OPENED users</span>{" "}
                        <span className="font-32">{userOpenedCount}</span>
                      </div>
                    </Button>
                  </Col>
                  <Col
                    xxl={3}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mx-3"
                  >
                    <Button
                      onClick={() => {
                        setOpenedReport(false);
                        setchange(!change);
                      }}
                    >
                      <div className="summary-box mt-2 summary-text-prospect   px-2 py-2">
                        Unopened Users{" "}
                        <span className="font-12">{userUnOpenedCount}</span>
                      </div>
                    </Button>
                  </Col>
                </Row>

                <Col
                  md={12}
                  sm={12}
                  xs={12}
                  className=" line-horizontal mb-3 mt-2"
                ></Col>

                <Row>
                  <Col md={4} xs={4}>
                    <div className="d-flex justify-content-start align-items-center mx-2  ">
                      {" "}
                      <img
                        src={filter}
                        width="15px"
                        height="15px"
                        alt="filter"
                      ></img>
                      <div className=" mx-2 text-filter d-flex justify-content-start ">
                        Filter
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={7}
                    xs={7}
                    className=" d-flex w-5 justify-content-end text-reset-all"
                  >
                    <Button
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        setsorting("");
                        settotalreportfilteredcount(0);
                        setcurrentpage(0);
                        setpatienttype();
                        setsharedadvertisinguser("");
                        setOpenedReport();
                        initialCall();
                      }}
                    >
                      {" "}
                      Clear Filters{" "}
                    </Button>
                  </Col>
                </Row>

                {/* ------------------------------------unshared report filter----------------------------------- */}

                <Col
                  md={12}
                  sm={12}
                  xs={12}
                  className=" line-horizontal m-1"
                ></Col>

                {/* -------------------------------User type filter------------------------ */}

                <Col className="mb-2 mt-3 text-source mx-3">
                  How They Found Us
                </Col>
                <Row className="mx-1">
                  <Col md={1} xs={1}>
                    {" "}
                    <div
                      className="radio-btn"
                      onClick={() => {
                        setsharedadvertisinguser("true");
                        setchange(!change);
                      }}
                    >
                      <input
                        type="radio"
                        value={sharedadvertisinguser}
                        name="sharedadvertisinguser"
                        checked={sharedadvertisinguser == "true"}
                      />
                    </div>
                  </Col>
                  <Col
                    md={10}
                    xs={10}
                    className="checkbox-text d-flex align-items-top"
                  >
                    Advertising URL
                  </Col>
                </Row>
                <Row className="mx-1 mt-1">
                  <Col md={1} sm={1} xs={1}>
                    {" "}
                    <div
                      className="radio-btn"
                      onClick={() => {
                        setsharedadvertisinguser("false");
                        setchange(!change);
                      }}
                    >
                      <input
                        type="radio"
                        value={sharedadvertisinguser}
                        name="sharedadvertisinguser"
                        checked={sharedadvertisinguser == "false"}
                      />
                    </div>
                  </Col>
                  <Col
                    md={10}
                    xs={10}
                    sm={10}
                    className="checkbox-text d-flex align-items-top"
                  >
                    Office URL
                  </Col>
                </Row>
                <Col
                  md={12}
                  xs={12}
                  sm={12}
                  className="line-horizontal m-1"
                ></Col>

                {/* -------------------------------Patient filter------------------------ */}

                <Col className="mb-2 mt-3 text-source mx-3">
                  Type of Patients
                </Col>
                <Row className="mx-1">
                  <Col md={1} xs={1} sm={1}>
                    {" "}
                    <div
                      className="radio-btn"
                      onClick={() => {
                        setpatienttype("true");
                        setchange(!change);
                      }}
                    >
                      <input
                        type="radio"
                        value={patienttype}
                        name="New Patient"
                        checked={patienttype == "true"}
                      />
                    </div>
                  </Col>
                  <Col
                    md={10}
                    xs={10}
                    sm={10}
                    className="checkbox-text d-flex align-items-top"
                  >
                    New Patients
                  </Col>
                </Row>
                <Row className="mx-1 mt-1">
                  <Col md={1} xs={1} sm={1}>
                    {" "}
                    <div
                      className="radio-btn"
                      onClick={() => {
                        setpatienttype("false");
                        setchange(!change);
                      }}
                    >
                      <input
                        type="radio"
                        value={patienttype}
                        name="Existing Patient"
                        checked={patienttype == "false"}
                      />
                    </div>
                  </Col>
                  <Col
                    md={10}
                    xs={10}
                    sm={10}
                    className="checkbox-text d-flex align-items-top"
                  >
                    Existing Patients
                  </Col>
                </Row>
              </div>
            </Col>

            {/* -------------------------------Report body display------------------------ */}

            <Col className="  my-2 " xs={12} md={9} sm={12}>
              <div className="lists m-2">
                <Col
                  md={12}
                  xs={12}
                  sm={12}
                  className="d-flex  w-5  justify-content-end  align-items-center"
                >
                  <CSVLink {...csvReport}>
                    <Button>
                      {" "}
                      <FaDownload size="22px" color="#0090FF" />
                    </Button>
                  </CSVLink>
                  <select
                    className="form-btns-dashboard text-sans-bold mx-3"
                    value={sorting}
                    onChange={handlesorting}
                  >
                    <option value="" className="text-sans-bold ">
                      Sort By{" "}
                    </option>
                    <option value="Newest on top">Newest on top </option>
                    <option value="Oldest on top">Oldest on top</option>
                    <option value="First Name">First Name (A-Z)</option>
                    <option value="First Name Des">First Name (Z-A)</option>
                  </select>

                  <Button
                    disabled={currentpage == 0}
                    onClick={handlepreviouspage}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                  <div className="p-1 ">
                    {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}-
                    {currentpage == totalpage - 1
                      ? totalreportfilteredcount
                      : (currentpage + 1) * 8}{" "}
                    <span className="mx-1">of</span> {totalreportfilteredcount}
                  </div>
                  <Button
                    disabled={
                      currentpage ==
                      (totalpage == 0 ? currentpage : totalpage - 1)
                    }
                    onClick={handlenextpage}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </Col>

                {users.map((value) => {
                  var lengths = value.fromsendtodentist.length;

                  return (
                    <Row key={value._id} className={"patients-row m-1  mx-3"}>
                      <div className=" d-flex width-100 ">
                        <Col md={3} xs={12} sm={12} className="  height-100">
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            to={`/user-detail-dental/${value._id}`}
                          >
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={3}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-1">Name</div>
                                    <div className="text-sans mx-1">Email</div>
                                  </Col>

                                  <Col
                                    md={9}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div
                                      className={
                                        value.fromsendtodentist[lengths - 1]
                                          .is_report_viewed
                                          ? "text-sans-bold   font-32-ellipsis"
                                          : "text-sans-bold font-12-ellipsis"
                                      }
                                    >
                                      {value.first_name} {value.last_name}
                                    </div>
                                    <div className="text-sans-bold ellipsis">
                                      {value.email}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Link>
                        </Col>
                        <Col
                          md={2}
                          xs={12}
                          sm={12}
                          className=" dotted_line height-100"
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            to={`/user-detail-dental/${value._id}`}
                          >
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={5}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-1">Ph.no</div>
                                    <div className="text-sans mx-1">Age</div>
                                  </Col>

                                  <Col
                                    md={7}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold ">
                                      {" "}
                                      {value.phone_number}
                                    </div>
                                    <div className="text-sans-bold">
                                      {calculateAge(value.dob)}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Link>
                        </Col>
                        <Col
                          md={3}
                          xs={6}
                          sm={6}
                          className=" dotted_line height-100"
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            to={`/user-detail-dental/${value._id}`}
                          >
                            <div className="d-flex h-100 w-5 margin-left">
                              <Col md={11} className="">
                                <div className="d-flex w-5 height-100">
                                  <Col
                                    md={5}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans mx-3"> Date</div>

                                    <div className="text-sans mx-3">City</div>
                                  </Col>

                                  <Col
                                    md={7}
                                    className=" d-flex flex-column justify-content-evenly  height-100"
                                  >
                                    <div className="text-sans-bold">
                                      {value.last_report_shared_date
                                        ? moment(
                                            value.last_report_shared_date
                                          ).format("MM/DD/YYYY")
                                        : "----"}
                                    </div>

                                    <div className="text-sans-bold ellipsis_state">
                                      {value.city}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </div>
                          </Link>
                        </Col>

                        <Col
                          md={3}
                          xs={6}
                          sm={6}
                          className=" dotted_line height-100"
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col md={12} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  md={7}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-3">State</div>

                                  <div className="text-sans mx-3">Country</div>
                                </Col>

                                <Col
                                  md={5}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans-bold ellipsis_state">
                                    {value.state}
                                  </div>

                                  <div className="text-sans-bold ellipsis_state">
                                    {" "}
                                    country
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
};

export default LocationDashboard;
