import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import "./patientReport.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Col from "react-bootstrap/Col";

const SliderCavityTartarCard = (props) => {
  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    cssEase: "linear",
  };
  return (
    <>
      <Slider {...settings}>
        {props.reportScoreImg.map((item, index) => {
          return (
            <Col md={12} key={index}>
              {/* <Col md={6}className=" bg-primary"> */}
              <div key={item} className="cavity-slider ">
                <Card className="cavity-card">
                 <Card.Img
                    variant="top"
                    src={props.imgUrl + item.split("-").join(".")}
                    alt="report images"
                    height="20px"
                    width="20px"
                  /> 
                </Card>
                <div className="px-1 black-color font-size-17 ">
                  {props.tartarcontents.length > 0 &&
                    props.tartarcontents[index]?.subText.map((item) => {
                      return (
                        <div key={item} className="row ">
                          <div className="col-2 text-center pr-0">
                          <i className="far fa-circle primary-color font-size-16 circle-icon" />
                        </div> 
                          <div className="pt-3">
                            <p>{item}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className=" black-color ">
                  {props.cavitycontents.length > 0 &&
                    props.cavitycontents[index]?.subText.map((item) => {
                      return (
                        <div key={item} className="row">
                           <div className="col-2 text-center pr-0 bg-primary">
                          <i className="far fa-circle primary-color font-size-16 circle-icon" />
                        </div>
                          <div className="pt-3">
                            <p>{item}</p>
                          </div>
                        </div>
                      );
                    })}

                </div>
              </div>
              {/* </Col> */}
            </Col>
          );
        })}
      </Slider>
    </>
  );
};

export default SliderCavityTartarCard;
