import React, { useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/ToothLensLogo.png";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  loginAdmin,
  resendRegisterCodeService,
  getLatestClinicDetails,
} from "../../Services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SnackFire from "../../UI/Snackbar/Snackbar";
import { Call } from "@mui/icons-material";
import appSettings from "../../partner-settings.json";


const Login = () => {
  const {InternalDashboard} = appSettings
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  // const [user,setuser]=useState("")

  const Call = (loginResponse) => {
    localStorage.setItem("authToken", loginResponse.headers.authorization);
    localStorage.setItem("admin", JSON.stringify(loginResponse.data.data));
    const verified = "6464849bed565e54b468ef47" == JSON.parse(localStorage.getItem("admin")).admin._id
    const isAccessible = verified && InternalDashboard
    localStorage.setItem("flag", JSON.stringify(isAccessible))
    // setuser(JSON.parse(localStorage.getItem("admin"))) ;
    const user = JSON.parse(localStorage.getItem("admin"));
    const dentist=JSON.parse(localStorage.getItem("admin")).admin.dentist_id
    const location=JSON.parse(localStorage.getItem("admin")).admin.location_id
     const is_usa_type_admin=JSON.parse(localStorage.getItem("admin")).admin.is_usa_type_admin
                 
                 if(user.admin._id=='64cca52714fe8d1d546179bb'){
                  navigate("/admin-deployment");
                 }
                   else if (user.admin.company.length != 0)
                  {
                    if(user.admin.is_chief_admin){

                      localStorage.setItem("consultingUsersTabNav",true);
                      localStorage.setItem("propspectTabNav",false);
                      localStorage.setItem("analyticsTabNav",false);
                      if (JSON.parse(localStorage.getItem("flag"))) {
                        navigate("/dashboard-internal")
                      } else if (JSON.parse(localStorage.getItem("admin")).admin._id=='6471034b95cc7d7a49bb92a9') {
                        navigate("/annotation-display-board")
                      } else if (JSON.parse(localStorage.getItem("admin")).admin.is_aligner_admin) {
                        navigate("/aligner-dashboard")
                      }
                      else {
                        navigate("/dashboard-dental");
                      }
                    }else if (!user.admin.is_chief_admin){
                      if(user.admin.is_first_login){
                        navigate("/first-login");
                      }else if(dentist){
                        navigate("/dashboard-dentist");
                      }else {
                        navigate("/dashboard-location")
                      }
                    }
           }else if(user.admin.company.length == 0 && is_usa_type_admin ){
            navigate("/signupsucess");
           }else{
             navigate("/account-details");
           }
  };
  return (
    <Container fluid className="">
      {
        <Formik
          validate={(values) => {
            let errors = {};

            if (!values.password) {
              errors.password = "Password is required";
            }
            if (!values.email) {
              errors.email = "Email ID is required";
            }

            return errors;
          }}
          validationSchema={Yup.object().shape({    
            password: Yup.string().required("Password is required"),
            email: Yup.string().email().required(" Email is Required"),
          })}
          initialValues={{
            email: "",
            password: "",
            checkbox:false
          }}
          onSubmit={async (values) => {
            await loginAdmin(values)
              .then(async (loginResponse) => {
                if (loginResponse) {
                  await Call(loginResponse);

                  // setsnackbarmessage("Chief Dentist login successful!!");
                  // settype("success");
                  // setsnackbar(true);
              
                  // await getLatestClinicDetails({user_id:user.admin._id})
                  //   .then((res) => {
                  //     if (res.status === 200) {
                  //       // if(res.data.data[0]){
                  //       //   navigate('/dashboard')
                  //       // }
                  //       const user = JSON.parse(localStorage.getItem("admin"));
                           
                  //     }
                  //   })
                  //   .catch((err) => console.log(err));
                }
              })
              .catch(async (error) => {
                setsnackbar(false);
                setsnackbarmessage(error.data.message);
                setsnackbar(true);
                settype("error");
                // if(error.status == 404){
                //   console.log("invalid used")
                // }
                if (error.status == 401) {
                  const verifiedEmail = error?.data?.data?.is_email_verify;
                  const verifiedAggrement = error?.data?.data?.is_baa_verified;
                  if (!verifiedEmail || !verifiedAggrement) {
                    const data = {
                      email: error?.data?.data.email,
                    };
                    navigate("/signup-step-two", { state: data });

                    await resendRegisterCodeService(data)
                      .then((res) => {
                        if (res) {
                          navigate("/signup-step-two", {
                            state: error?.data?.data.email,
                          });
                        }
                      })
                      .catch((err) => {
                        return err;
                      });
                  }
                }

                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            const handleClickShowPassword = () => {
              setshowPassword(!showPassword);
            };

            const handleclose = () => {
              setsnackbar(false);
            };
            return (
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <Row>
                <Col md={6} xs={12} className="signup_image">
                    <div className=" d-flex w-5 h-100 justify-content-center align-items-center">
                      <img
                        className="img-fluid rounded"
                        src={ToothScan}
                        alt="Scan"
                      />
                    </div>
                    <div className="image d-flex justify-content-center py-5 signup-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex justify-content-center align-items-center position"
                  >
                    <div className="position-absolute">
                      {/* <img src={Logo} className="images" alt="Logo" /> */}
                    </div>

                    <div>
                      <Col md={6}>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark font-style"
                        >
                          <span className="field-texts">Email Address</span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          id="email"
                          className="form-input "
                          placeholder="johndow@gmail.com"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.email}
                          </div>
                        )}
                      </Col>

                      <Col>
                        <FormLabel
                          component="legend"
                          className="mt-3 font-weight-500 text-dark font-style"
                        >
                          <span className="field-texts">Password</span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        <div className="position-relative">
                          <input
                            id="password"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="xxxxx"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span
                            className={`show-passwords`}
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </span>
                        </div>

                        {errors.password && touched.password && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.password}
                          </div>
                        )}
                      </Col>
                      <Row className="mt-1">
                        <Col md={1} xs={1}>
                          {" "}
                          <input type="checkbox"
                          id="checkbox"
                         onChange={()=>{
                          values.checkbox=!values.checkbox;
                         }}
                          className="checkbox-signin" />
                        </Col>
                        <Col md={5} xs={5} className="field-texts">
                          Remember me
                        </Col>
                        <Col md={6} xs={6}className="field-texts">
                          <Link to="/forgot-password">Forgot password?</Link>
                        </Col>
                      </Row>

                      <Col className="mt-4">
                        <Button className="form-btn" type="submit">
                          LOG IN
                        </Button>
                      </Col>
                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 mt-3 pt-3 pl-5 font-weight-500 text-dark "
                        >
                          <span className="already-text">
                            {" "}
                            Don't have an account.{" "}
                          </span>
                          <span className="login-link-text">
                            {" "}
                            <Link to="/signup/64233094abcc9054c50ae0e8"> Sign up</Link>{" "}
                          </span>
                        </FormLabel>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default Login;
