import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  branchDetailsBasedOnId
} from "../../Services/AuthService";

const LocationCardDetails = () => {
  const [dentistData, setDentistData] = useState();
  const [branchData, setBranchData] = useState();

  const [loading, setloading] = useState(true);
  const company_mail = JSON.parse(localStorage.getItem("admin")).admin.email;
  const branch_id = JSON.parse(localStorage.getItem("admin")).admin.location_id;

  useEffect(() => {
    branchDetailsBasedOnId({ branch_id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data,"dddddddd")
          setDentistData(res.data.data.branchDetails[0]);
          setloading(false);
        }
      })
      .catch((err) => {
        setloading(false);
        return err;
      });
  }, []);

  if (loading) {
    <div>Loading...</div>;
  }
  if (!loading) {
    return (
      <div className="mt-2 office-box ">
        <div className="font-doctor mx-2 pt-1">Location Details</div>

        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center  align-items-center mt-2"
          >
             
            Location Name
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            <div  className="ellipsis-company-email">
            {dentistData ? dentistData.location: "----"}            </div>  
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Location Email{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            <div className="ellipsis-company-email">{company_mail}</div>
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            Phone Number
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.phone_number : "----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
            City{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.city : "-----"}
          </Col>
        </Row>
        <Row>
          <Col
            md={5}
            className="d-flex  justify-content-center align-items-center mt-2"
          >
          State{" "}
          </Col>
          <Col
            md={7}
            className="d-flex justify-content-start align-items-center mt-2"
          >
            {dentistData ? dentistData.state: "----"}
          </Col>
        </Row>
      </div>
    );
  }
};

export default LocationCardDetails;
