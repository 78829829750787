import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Switch from '@mui/material/Switch';
import moment from "moment";

// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from "react-bootstrap/Container";
import NavbarPatientEnquiry from "../PatientReportDental/navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import Update from "../../assets/update.svg";
import { useNavigate } from "react-router-dom";
import SnackFire from "../../UI/Snackbar/Snackbar";
import {
    getAlignerUserDetails, getAlignerUserReports, GetBucketFile, getBinaryDataofVideo,
    updateReportFromDentist, updateReportViewedStatus, updateUserToViewed,
} from "../../Services/AuthService";
import "./alignerDashboard.css"
import ReportSubmitForm from "./reportSubmitForm";
import UpdateUser from "./updateUser";
import "./alignerDashboard.css"


const AlignerReportDetails = () => {
    let { user_id } = useParams()
    const navigate = useNavigate();
    const imgRef = useRef(null);
    const teethSides = ["front_teeth", "upper_jaw", "lower_jaw", "right_side", "left_side"]
    const [userData, setUserData] = useState();
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(true)
    const [totalReports, setTotalReports] = useState([])
    const [selectedReport, setSelectedReport] = useState()
    const [isWithAligner, setAlignerType] = useState(true)
    const [imageSide, setImageSide] = useState("front_teeth")
    const [normalImages, setNormalImages] = useState()
    const [alignerImages, setAlignerImages] = useState()
    const [videoURL, setVideoURL] = useState(null);
    const [normalTags, setNormalTags] = useState()
    const [alignerTags, setAlignerTags] = useState()
    const fileOption = userData ? userData.file_option : ""
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const [usereditpopup, setusereditpopup] = useState(false);
    const [noGoReport, setNoGoReport] = useState(0)
    const [reportsWithWeekId, setReportsWithWeekId] = useState([])
    const [latestReport, setLatestReport] = useState("")

    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    const apiCalls = async () => {
        let alignerNo;
        let validReports;
        let invalidReports = 0
        let aligner_restarts_at;

        await getAlignerUserDetails(user_id).then((res) => {
            setUserData(res.data.data)
            const compare_aligner = res.data.data.compare_aligner ? res.data.data.compare_aligner : 0
            setNoGoReport(compare_aligner)
            validReports = res.data.data.report_start_at - 1
            alignerNo = res.data.data.report_start_at - 1
            aligner_restarts_at = res.data.data.aligner_restarts_at ? res.data.data.aligner_restarts_at : []
            if (res.data.data.file_option === "upload") {
                getVideoDetails(res.data.data.video_name)
            }

        }).catch((err) => {
            console.log(err)
            navigate("/aligner-dashboard")
        })
        await getAlignerUserReports(user_id).then((res) => {
            const reports = res.data.data
            setTotalReports(reports)
            if (reports.length > 0) {
                setSelectedReport([...reports].reverse()[0])
                setLatestReport([...reports].reverse()[0])
            }
            const reportsWithWeeks = reports.map((eachReport, index) => {
                const newFlowObj = aligner_restarts_at.find((each => each.restartsAt == index))
                const isNewFlow = newFlowObj ? true : false

                if (eachReport.is_week_report) {
                    validReports += 1
                    alignerNo = newFlowObj ? newFlowObj.alignerNo : (alignerNo + 1)
                    invalidReports = 0
                    return { reportId: eachReport._id, weekNo: validReports, alignerNo, isNewFlow }
                } else {
                    invalidReports += 1
                    alignerNo = newFlowObj ? newFlowObj.alignerNo : alignerNo
                    return { reportId: eachReport._id, weekNo: `${validReports}.${invalidReports}`, alignerNo, isNewFlow }
                }

            })
            const updatedReports = [...reportsWithWeeks].reverse()        // reverse method modifies the original array.
            setReportsWithWeekId(updatedReports)
            const latestReport = [...reports].reverse().find(eachReport => {
                if (eachReport.is_week_report) {
                    return eachReport
                }
            })
            setLatestReport(latestReport)
            setLoading(false)

        }).catch((err) => {
            console.log("error")
            setLoading(false)
            navigate("/aligner-dashboard")

        })

        await updateUserToViewed({ user_id }).then((res) => {
        }).catch((err) => {
        })
    }


    useEffect(() => {
        apiCalls()
    }, [])

    const getVideoDetails = async (fileName) => {
        await getBinaryDataofVideo({ fileName }).then((resData) => {
            if (resData.data.data !== "No Data") {
                setVideoURL(resData.data.data.videoUrl)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const onCloseUserPopup = () => {
        setusereditpopup(false);
        apiCalls()
    };


    const getImages = async (images, storage) => {
        const normalImages = await Promise.all(teethSides.map(async (teeth, index) => {
            const fileName = images[teeth].split("/")[1]
            const fileFolder = "toothlens-image-store/input"
            return await GetBucketFile({ fileName, fileFolder }).then((resData) => {
                if (resData.data.data !== "No Data") {
                    const byteArray = new Uint8Array(resData.data.data.data)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });
                    const imageName = teeth
                    const imageUrl = URL.createObjectURL(blob);
                    return { imageName, imageUrl }
                }
            }).catch((err) => {
                console.log(err)
            })
        }))
        storage(normalImages)

    }

    const getJsonData = async (normalImages, storage) => {

        const jsonDataOfNormalImages = await Promise.all(teethSides.map(async (teeth) => {
            const fileName = `${normalImages[teeth].split("/")[1].split(".")[0]}-aligner.json`
            const fileFolder = "toothlens-image-store/json_output"
            return await GetBucketFile({ fileName, fileFolder }).then((tags) => {
                
                const tagsData = tags.data.data
                if (tagsData !== "No Data") {
                    return { ...tagsData, imageName: teeth, tagsData: tagsData.aligner }
                } else {
                    return { imageName: teeth, tagsData: [] }
                }
            }).catch((err) => {
                console.log("err")
            })
        }))
        storage(jsonDataOfNormalImages)

    }

    useEffect(() => {
        const makeSyncronous = async () => {
            if (selectedReport) {
                const normalImages = {
                    front_teeth: selectedReport.front_teeth,
                    upper_jaw: selectedReport.upper_jaw,
                    lower_jaw: selectedReport.lower_jaw,
                    right_side: selectedReport.right_side,
                    left_side: selectedReport.left_side
                }
                const alignerImages = {
                    front_teeth: selectedReport.aligner_front_teeth,
                    upper_jaw: selectedReport.aligner_upper_jaw,
                    lower_jaw: selectedReport.aligner_lower_jaw,
                    right_side: selectedReport.aligner_right_side,
                    left_side: selectedReport.aligner_left_side
                }

                await getImages(normalImages, setNormalImages)
                await getImages(alignerImages, setAlignerImages)
                if (selectedReport.is_report_reviewed) {
                    await getJsonData(normalImages, setNormalTags)
                    await getJsonData(alignerImages, setAlignerTags)
                } else {
                    setNormalTags()
                    setAlignerTags()
                }
                updateReportViewedStatus({ reportId: selectedReport._id })
            } else {
            }
        }

        makeSyncronous()
    }, [selectedReport])



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const calculateAge = (dobgiven) => {
        const dob = new Date(dobgiven);
        let diff_ms = Date.now() - dob.getTime();
        let age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    const Loader = () => {
        return (
            <div>
                <div className="mt-5 mx-5">
                    <div className=" d-flex align-items-center justify-content-center mt-5">
                        <PacmanLoader color={"#0090FF"} css={override} size={150} />
                        <span className="mt-3 navbar_text_dashbord">Loading...</span>
                    </div>
                </div>
            </div>
        )

    }



    const modifyTagsToImageDimension = tagsData => {
        const imgRenderWidth = imgRef.current.width
        const imgRenderHeight = imgRef.current.height
        const imgNaturalWidth = imgRef.current.naturalWidth
        const imgNaturalHeight = imgRef.current.naturalHeight
        const updatedTags = tagsData.map(eachTag => {
            let { x, y, w, h } = eachTag
            x = (x * imgRenderWidth) / imgNaturalWidth;
            y = (y * imgRenderHeight) / imgNaturalHeight;
            w = (w * imgRenderWidth) / imgNaturalWidth;
            h = (h * imgRenderHeight) / imgNaturalWidth
            return { x, y, w, h }
        })

        return updatedTags
    }

    const ImageNotUsable = ({reason}) => {
        
        return (
            <div className="imgage-not-uasable" >
                This image is {reason.join(", ")}
           </div>
        )
    }

    const AnnotatedTags = data => {
        const { imageName } = data.imageObject
        const tagsFolder = isWithAligner ? alignerTags : normalTags
        const selectedTags = tagsFolder.find((eachTagObj) => eachTagObj.imageName === imageName)
        const { tagsData } = selectedTags
        const updatedTags = modifyTagsToImageDimension(tagsData)
        return (
            <>
                {updatedTags.map((eachtag, index) => {
                    const { x, y, w, h } = eachtag
                    const tagStyles = {
                        position: "absolute",
                        top: `${y}px`,
                        left: `${x}px`,
                        width: `${w}px`,
                        height: `${h}px`,
                        border: "3px solid red"

                    }
                    return <div key={index} style={tagStyles} >  </div>
                })}
                {selectedTags.image_useable && !selectedTags.image_useable.is_image_useable &&  <ImageNotUsable reason={selectedTags.image_useable.reason} />}
            </>
        )

    }

    const EachReport = (props) => {
        const { reportId, weekNo, alignerNo, isNewFlow } = props.eachReport
        const activeReport = totalReports.find((report) => report._id === reportId)
        const { is_week_report, is_doctor_reviewed, status_by_doctor } = activeReport
        const style = activeReport._id === selectedReport._id ? "text-primary bg-dark" : activeReport.is_report_viewed ? " text-primary" : " text-danger"
        return (
            <li className="">
                <Row key={reportId} className="d-flex ">
                    <Col md={1}> {activeReport._id === selectedReport._id && <img src={dentalAnalysisimg} className="mt-1" width="25px" height="25px" alt="filter" />} </Col>
                    <Col md={5} className="">
                        <button className={`test_text_style  my-1  bg-white ${style} d-flex justify-content-between w-100 border border-0 `} onClick={() => {
                            setSelectedReport(activeReport)
                            setImageSide("front_teeth")
                            setNormalImages()
                            setAlignerImages()
                            setNormalTags()
                            setAlignerTags()
                            handleClose()
                        }} >
                            {
                                is_week_report ? <span >  Scan {weekNo} </span>
                                    : <span style={{ textSize: "25px", color: "#83b0f7" }}> Scan  {weekNo} </span>
                            }

                        </button>
                    </Col>
                    <Col md={2} className="d-flex justify-content-center  ">  {is_week_report && <p className="aligner-number badge text-bg-primary m-auto p-2 opacity-75"> {alignerNo} </p>} </Col>
                    <Col md={3} className="d-flex justify-content-center ms-2"> {is_doctor_reviewed && is_week_report ? (status_by_doctor ? <span className="text-success fw-semibold m-auto ">Go</span> : <span className="text-danger fw-semibold m-auto">No Go</span>) : ""} </Col>


                </Row>
                {isNewFlow && <Row> <Col> <p className="restart-flow-element ps-3 py-1 rounded-5 " > Dentist restarted the new aligner here. </p> </Col></Row>}

            </li>

        )
    }

    const ActiveImage = () => {
        const imageFolder = isWithAligner ? alignerImages : normalImages
        const imageObject = imageFolder.find(eachObj => eachObj.imageName === imageSide)
        const imageUrl = imageObject.imageUrl
        // const sampleImage = "https://source.unsplash.com/user/c_v_r/1900x800"
        return (
            <Col className="border">
                {
                    selectedReport ?
                        <div className="active-image-container image-container">
                            <img src={imageUrl} alt="report" ref={imgRef} width="100%" height="100%" />
                            {normalTags && alignerTags && imgRef.current && <AnnotatedTags imageObject={imageObject} />}
                        </div>
                        : <div className="active-image-container border d-flex justify-content-center align-items-center ">
                            <p className="text-primary fs-4">User haven't shared their report yet.</p>
                        </div>
                }

            </Col>
        )
    }

    const TeethAnimation = () => {
        return (
            <Col>
                {
                    selectedReport ?
                        <div className="active-image-container  image-container d-flex flex-column align-items-center justify-content-center ">
                            <div className="sub-container">
                                <img src={process.env.PUBLIC_URL + '/loading-icon.svg'} alt="LoadingIcon" />
                            </div>
                            <div className="loader mb-5">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div> : <div className="active-image-container border d-flex justify-content-center align-items-center ">
                            <p className="text-primary fs-4">User haven't shared their report yet.</p>
                        </div>
                }

            </Col>
        )
    }

    const getNewDate = (date, days = userData.aligner_change_days) => {
        const resultDate = new Date(date)
        resultDate.setDate(resultDate.getDate() + parseInt(days));
        return resultDate
    }

    const submitReport = async (reportData) => {
        const { remarksText, reportstatus, noOfDays, afterSubmission } = reportData
        if (selectedReport) {
            if (latestReport && selectedReport._id === latestReport._id) {
                const compare_aligner = reportsWithWeekId.find(eachReport => eachReport.reportId === selectedReport._id).alignerNo
                const next_date_to_take_test = reportstatus !== "go" ? getNewDate(selectedReport.createdAt, noOfDays) : getNewDate(selectedReport.createdAt)
                const compareNumber = reportstatus !== "go" ? compare_aligner : 0
                const emailDate = moment(new Date(next_date_to_take_test)).format('MM/DD/YYYY')
                setNoGoReport(compareNumber)
                const details = {
                    reportId: selectedReport._id,
                    is_doctor_reviewed: true,
                    status_by_doctor: reportstatus === "go" ? true : false,
                    number_of_days: reportstatus !== "go" ? noOfDays : "",
                    next_date_to_take_test,
                    remarks: remarksText,
                    compare_aligner: reportstatus !== "go" ? compare_aligner : 0,
                    emailDate
                }

                await updateReportFromDentist(details).then((res) => {
                    settype("success");
                    setsnackbarmessage(res.data.message);
                    setsnackbar(true);
                    afterSubmission(true)


                }).catch((err) => {
                    console.log("Errorrrrrrrrrrrrrrrrrrrrr")
                })
            } else {
                settype("error")
                setsnackbarmessage("Please select latest report taken on prescribed date.")
                setsnackbar(true)
                afterSubmission(false)

            }

        } else {
            settype("error")
            setsnackbarmessage("User haven't shared their report")
            setsnackbar(true)
            afterSubmission(false)

        }


    }


    return (
        <>
            <NavbarPatientEnquiry />
            {
                isLoading ? <Loader /> :
                    <>
                        <>
                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title> <span className="patient-summary"> User Details </span></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Row>
                                        <Col md={10} className="px-5 pt-3 font-DM-sans">
                                            <div className="patient_name ">{userData.first_name}</div>
                                            <div className="patient_email mt-2 font-DM-sans-12">{userData.email}</div>

                                            <div className="patient_id mt-2 font-DM-sans-12">{userData.phone_number}</div>
                                            <div className="patient_gender mt-2 ">
                                                {userData.gender}, {calculateAge(userData.dob)}
                                            </div>
                                            <div className="patient_email mt-2 font-DM-sans-12" >Aligner change period : <span className="text-primary ms-2"> {userData.aligner_change_days ? userData.aligner_change_days + " days" : ""}  </span> </div>
                                            <div className="patient_email mt-2 font-DM-sans-12" >Next check is on <span className="text-primary ms-2">  {moment(new Date(userData.next_date_to_take_test)).format('MM/DD/YYYY')} </span></div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="d-flex w-5  justify-content-end mt-3 mx-3">
                                                <Button
                                                    onClick={() => {
                                                        setusereditpopup(true);
                                                        handleClose()
                                                    }}
                                                >
                                                    <img
                                                        alt=""
                                                        src={Update}
                                                        width="60px"
                                                        height="60px"
                                                        className="m-2"
                                                    />
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <Col md={12} className=" line-horizontal mt-2"></Col>
                                        </Col>
                                        <Col md={12} className="">
                                            <Row className="patient_status ms-3 mb-3 patient-summary">
                                                <Col md={5} className="ms-3">  Checks </Col>
                                                <Col md={3}> Aligner  </Col>
                                                <Col md={3}> Status</Col>
                                            </Row>
                                            {
                                                reportsWithWeekId.length > 0 ?
                                                    <ul className="list-unstyled ms-3">
                                                        {reportsWithWeekId.map((eachReport, index) => <EachReport key={index} eachReport={eachReport} index={index} />)}
                                                    </ul> : <p className="ms-5">User haven't shared reports yet.</p>
                                            }
                                        </Col>
                                        <Col md={12}>
                                            <Col md={12} className=" line-horizontal mt-1"></Col>
                                        </Col>
                                    </Row>

                                </Offcanvas.Body>
                            </Offcanvas>
                        </>
                        <Container fluid>
                            <Row className="d-flex flex-column align-items-center">
                                <Col className=" text-center mb-2">
                                </Col>

                            </Row>
                            <Row className="bg-light border">
                                <Col>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="me-5" >
                                            <button type="button" className="btn reports-btn font-DM-sans" onClick={handleShow}>
                                                Open Reports
                                            </button>
                                        </div>
                                        <div className="d-flex font-DM-sans"> <span> Report date :  </span> <span className="text-primary ms-2 "> {selectedReport && moment(new Date(selectedReport.createdAt)).format('MM/DD/YYYY')}  </span></div>
                                        <div className="d-flex ms-5 align-items-center ">
                                            <span className="font-DM-sans">Images with aligner</span>
                                            <Switch
                                                checked={isWithAligner}
                                                onChange={() => { setAlignerType(!isWithAligner) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <ul className=" list-unstyled d-flex  px-5 my-2 d-flex justify-content-between">
                                            {
                                                teethSides.map((eachSide, index) => {
                                                    const isActive = eachSide === imageSide
                                                    return (
                                                        <li key={index} className=" me-4 d-flex ">
                                                            {isActive && <img src={dentalAnalysisimg} width="25px" height="25px" alt="filter" />}
                                                            <button type="button" className={` aligner-img-side-button  ${isActive ? "text-primary" : ""}`} onClick={() => { setImageSide(eachSide) }}>
                                                                {eachSide.toLowerCase().charAt(0).toUpperCase()+ eachSide.slice(1).split("_").join(" ")}
                                                            </button>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Row>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="border d-flex justify-content-center align-items-center ">
                                    {
                                        fileOption === "upload" ?
                                            videoURL ? (
                                                <div className="h-100 ">

                                                    <video controls autoPlay width="100%" height="100%">
                                                        <source src={videoURL} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            ) : (
                                                <p>Loading video...</p>
                                            )
                                            :
                                            <div className="w-100 h-100">
                                                <iframe src={userData.web_url}         // src="https://www.toothlens.com"
                                                    title="plan file"
                                                    width="100%"
                                                    height="500px"
                                                    style={{ border: 'none' }}></iframe>
                                            </div>
                                    }
                                </Col>
                                {alignerImages && normalImages ? <ActiveImage /> : <TeethAnimation />}
                            </Row>
                            <Row>
                                <ReportSubmitForm selectedReport={selectedReport} onSubmit={submitReport} noGoReport={noGoReport} handleShow={handleShow} />
                            </Row>
                            {snackbar && (
                                <SnackFire
                                    open={snackbar}
                                    onClose={() => { setsnackbar(false) }}
                                    position="top-right"
                                    timing={4000}
                                    type={type}
                                    message={snackbarmessage}
                                />
                            )}
                            {usereditpopup && (
                                <UpdateUser
                                    open={usereditpopup}
                                    onClose={onCloseUserPopup}
                                    userData={userData}
                                    noOfReports={totalReports.length}
                                />
                            )}
                        </Container>

                    </>


            }


        </>
    )
}

export default AlignerReportDetails