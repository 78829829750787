import React, { useEffect,useState } from "react";
import NavbarPatientEnquiry from "./navbar";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import "./dentist.css";
import AddDentist from "./addDentist";
import DentistList from "./dentistList";

const Dentist = () => {
  localStorage.setItem("consultingUsersTabNav",true);
  localStorage.setItem("propspectTabNav",false);
  localStorage.setItem("analyticsTabNav",false);
  const [change, setchange] = useState(false);
  const [running, setrunning] =useState(false);
const Torender=()=>{
  setrunning(false)
  setchange(!change)
}

  useEffect(() => {
    setrunning(true);
  }, [change]);
  useEffect(() => {
    setrunning(true);
  }, []);
  if (running) {
    return (
      <Container fluid>
        <NavbarPatientEnquiry />
        <Row>
          <Col className="my-1 " xs={12} md={12} sm={12} lg={4}>
            <div className="filter-location m-2 d-flex justify-content-center align-items-center">
              <AddDentist change={Torender} />
            </div>
          </Col>
          <Col className="  my-2 " xs={12} sm={12} md={12} lg={8}>
            <div className="list m-2">
              <DentistList change={Torender} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (!running) {
    <div>
      <Container fluid>
        <NavbarPatientEnquiry />
      </Container>
      <div>Loading...</div>
    </div>;
  }
};

export default Dentist;
