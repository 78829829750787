import React, { useEffect, useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/ToothLensLogo.png";
import Button from "@mui/material/Button";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { verifyForgotPasswordService } from "../../Services/AuthService";
import SnackFire from "../../UI/Snackbar/Snackbar";

const VerifyCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [snackbarmessage, setsnackbarmessage] = useState(
    "Code sent to your email. Please check your spam folder also!!"
  );
  const [snackbar, setsnackbar] = useState(true);
  const [type, settype] = useState("success");

  useEffect(() => {
    setsnackbarmessage(
      "Code sent to your email. Please check your spam folder also!!"
    );
    setsnackbar(true);
    settype("success");
  }, []);

  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <Container fluid className="">
      {
        <Formik
          validate={(values) => {
            let errors = {};

            if (!values.forgotpass_code) {
              errors.forgotpass_code = "Email ID is required";
            }

            return errors;
          }}
          validationSchema={Yup.object().shape({
            forgotpass_code: Yup.string().required(
              "Verification Code is required"
            ),
          })}
          initialValues={{
            forgotpass_code: "",
          }}
          onSubmit={(values) => {
            const data = {
              ...values,
              email: location.state.email,
            };
            verifyForgotPasswordService(data)
              .then((response) => {
                if (response) {
                  navigate("/new-password", { state: data });
                }
              })
              .catch((error) => {
                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <Row>
                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex flex-column signup_image justify-content-center align-items-center"
                  >
                    <img
                      className="image-position"
                      src={ToothScan}
                      alt="Scan"
                    />
                    <div className="dashboard-footer d-flex  my-5 align-items-end height-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex justify-content-center align-items-center position"
                  >
                    <div className="position-absolute">
                      <img src={Logo} className="images" alt="Logo" />
                    </div>

                    <div>
                      <Col md={12}>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark font-style"
                        >
                          <span className="field-texts">
                            Forgot Password Code
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          id="forgotpass_code"
                          className="form-input "
                          placeholder="johndow@gmail.com"
                          type="text"
                          value={values.forgotpass_code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.forgotpass_code && touched.forgotpass_code && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.forgotpass_code}
                          </div>
                        )}
                      </Col>

                      <Col className="mt-4">
                        <Button className="form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default VerifyCode;
