import React, { useEffect, useRef, useState } from "react";
import { GetBucketFile } from "../../Services/AuthService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImagePlaceholder from "../Annotation/image-placeholder1.svg";


const ActiveImage = (props) => {
  const [activeImage, setActiveImage] = useState();
  const imgRef = useRef();
  
   
  useEffect(() => {
    GetBucketFile({
      fileName: props.annotation.split("/")[1],
      fileFolder: "toothlens-image-store/input",
    }).then((resData) => {
      if (resData.data.data !== "No Data") {
        const byteArray = new Uint8Array(resData.data.data.data)
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setActiveImage(imageUrl);
      } else {
        
        // setSnackbarData({ message: 'Image not found', severity: 'error' });
      }
    });
  }, [props.annotation]);

  useEffect(() => {
    GetBucketFile({
      fileName: props.annotation.split("/")[1],
      // fileName:"42121667209989241.jpeg",
      fileFolder: "toothlens-image-store/input",
    }).then((resData) => {
      if (resData.data.data !== "No Data") {
        const byteArray = new Uint8Array(resData.data.data.data)
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setActiveImage(imageUrl);
      } else {
        // setSnackbarData({ message: 'Image not found', severity: 'error' });
      }
    });
  }, []);

 

  return (
    <Row fluid className="">
      <Col md={5} style={{ minHeight: "550px" }} >
          <div className="position-relative" id="drawContainer">
            <img
              // width="752"
              // height="512"
              className="w-100 user-report-image mt-5"
              src={activeImage ? activeImage : ImagePlaceholder}
              id="screenshot"
              alt="report"
              draggable="false"
              ref={imgRef}
            />
          </div>
      </Col>
      <Col md={7}>
          <div>
            <iframe
              src="https://www.image-instruments.de/webviewer/index.html?mlink=https://cloud.frenchdesigners.fr/Client3615/21664/E34BD5E6DEA14390AF89B6D7FF51548A.iiwgl&fg=555&bg=eee&p=WBDPVF"
              title="Embedded Content"
              width="100%"
              height="500px"
           />
         </div>
      </Col>
    </Row>
  );
};

export default ActiveImage;
