import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import close from "../../assets/Close.svg";
import { FaBuilding } from "react-icons/fa";
import { Formik, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import {
  branchDetailsBasedOnId,
  updateBranchDetails,
} from "../../Services/AuthService";
import SnackFire from "../../UI/Snackbar/Snackbar";

const LocationUpdatePopup = ({ popupstatus, branchId, change }) => {
  const [loading, setloading] = useState(true);
  const [chiefDoctor, setChiefDoctor] = useState();
  const [branchDetails, setBranchDetails] = useState();
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const handleclose = () => {
    setsnackbar(false);
  };
  useEffect(() => {
    branchDetailsBasedOnId({ branch_id: branchId })
      .then((res) => {
        if (res.status === 200) {
          setBranchDetails(res.data.data.branchDetails[0]);
          setChiefDoctor(res.data.data.branchDetails[0].is_chief_doctor);
          setloading(false);
        }
      })
      .catch((err) => {
        return err;
      });
  }, []);
  // if(loading){
  //   return(<div>Loading...</div>)
  // }

  if (!loading) {
    return (
      <Formik
        validate={(values) => {
          let errors = {};
          
          if (!values.email) {
            errors.email = "Email is required";
          }
          if (!values.address) {
            errors.address = "Address is required";
          }
          return errors;
        }}
        validationSchema={Yup.object().shape({
          
          address: Yup.string().required("Address is required"),
          email: Yup.string().email().required(" Email is Required"),
        })}
        initialValues={{
          address: branchDetails.address ? branchDetails.address : "",
          email: branchDetails.email ? branchDetails.email : "",
          phone_number: branchDetails.phone_number
            ? branchDetails.phone_number
            : "",
          location: branchDetails.location ? branchDetails.location : "",
          city: branchDetails.city ? branchDetails.city : "",
          state: branchDetails.state ? branchDetails.state : "",
          zip_code : branchDetails.zip_code ? branchDetails.zip_code : "",
        }}
        onSubmit={(values) => {
          const object = { chiefDoctor, ...values };
            const updateData = { is_email_update:values.email == branchDetails.email?false:true, ...object,oldEmail:branchDetails.email };
            updateBranchDetails({ _id: branchDetails._id, ...updateData })
              .then((res) => {
                setsnackbarmessage(res.data.message);
                JSON.stringify(res.data.data) === '{}'?settype("error"):settype("success");
                setsnackbar(true);
                 if( JSON.stringify(res.data.data) != '{}'){change()} 
              })
              .catch((err) => {
                setsnackbarmessage(err.data.message);
                setsnackbar(true);
                // change();
              });
          
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
       
          return (
            <form onSubmit={handleSubmit}>
                    {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
              <div className="model-user">
                <div className="model_container-user">
                  <div className="close_position">
                    <Button
                      onClick={() => {
                        // call();
                        popupstatus();
                      }}
                    >
                      {" "}
                      <img src={close} width="50px" height="50px"></img>
                    </Button>
                  </div>

                  <div>
                    <div className="d-flex navbar_text_dashbord justify-content-center mt-5">
                      <div>
                        <FaBuilding
                          size="22px"
                          color="#0090FF"
                          className="mx-2"
                        />
                      </div>{" "}
                      <div>Location Details</div>
                    </div>
                    <div>
                      {" "}
                      <Col
                        md={12}
                        className="d-flex justify-content-center  mt-3"
                      >
                        <Col
                          md={5}
                          xs={12}
                          className="d-flex align-items-center justify-content-center position my-3 mx-3 mt-3"
                        >
                          <div>
                            <div className="d-flex w-5">
                              <Col md={6} className="mt-3">
                                <FormLabel
                                  component="legend"
                                  className="mt-1 font-weight-500 text-dark field-text"
                                >
                                  <span className="field-texts  mx-3">
                                    Address*
                                  </span>
                                </FormLabel>
                              </Col>
                            </div>

                            <Col>
                              <div className="d-flex w-5 ">
                                <Col md={12} className="mt-1 px-2">
                                  <input
                                    type="text"
                                    id="address"
                                    className="form-input mb-3 grey-bg-color"
                                    placeholder="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </div>
                              <div className="mx-3">
                                {" "}
                                {errors.address && touched.address && (
                                  <div className="text-danger mb-2 ml-2">
                                    {errors.address}
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col>
                              <FormLabel
                                component="legend"
                                className="pt-2 font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">
                                  Email*
                                </span>
                              </FormLabel>
                            </Col>

                            <Col className="mt-1">
                              {" "}
                              <input
                                type="text"
                                id="email"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <div className="mx-3">
                              {" "}
                              {errors.email && touched.email && (
                                <div className="text-danger mb-2 ml-2">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                            <Col>
                              <FormLabel
                                component="legend"
                                className="pt-2 font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">
                                  Phone Number
                                </span>
                              </FormLabel>
                            </Col>

                            <Col className="mt-1">
                              {" "}
                              <input
                                type="text"
                                id="phone_number"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="phone_number"
                                value={values.phone_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </div>

                          {/* </div> */}
                        </Col>

                        <Col
                          md={5}
                          xs={12}
                          className="d-flex align-items-center justify-content-center position my-3 mx-3 mt-3"
                        >
                          <div>
                            
                            <Col>
                              <FormLabel
                                component="legend"
                                className="pt-2 font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">City</span>
                              </FormLabel>
                            </Col>

                            <Col className="mt-1">
                              {" "}
                              <input
                                type="text"
                                id="city"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>

                            <Col>
                              <FormLabel
                                component="legend"
                                className="pt-2 font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">State</span>
                              </FormLabel>
                            </Col>

                            <Col className="mt-1">
                              {" "}
                              <input
                                type="text"
                                id="state"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <Col>
                              <FormLabel
                                component="legend"
                                className="pt-2 font-weight-500 text-dark field-text"
                              >
                                <span className="field-texts  mx-3">Zip code</span>
                              </FormLabel>
                            </Col>

                            <Col className="mt-1">
                              {" "}
                              <input
                                type="text"
                                id="zip_code"
                                className="form-input mb-3 grey-bg-color"
                                placeholder="zip code"
                                value={values.zip_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </div>

                          {/* </div> */}
                        </Col>
                      </Col>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-4">
                      <Button className="form-btn" type="submit">
                        <span className="btn-text">Save and Update</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
};

export default LocationUpdatePopup;
