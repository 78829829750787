import axios from "axios";
import { API_HOST } from "./BaseUrl";

let refreshTokenTimeout;

const AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) =>
    status === 200 || status === 201 || status === 204,
});

const axiosJWT = axios.create();

export function stopRefreshTokenTimer() {
  localStorage.clear();
  clearTimeout(refreshTokenTimeout);
}

export function refreshToken(refreshTokens, email) {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(
        API_HOST + `admin/refresh-token`,
        { token: refreshTokens, email },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("admin", JSON.stringify(response.data.data));
          localStorage.setItem("authToken", response.data.data.jwtToken);
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Network error", isError: true });
        }
      });
  });
}

export const registerAdmin = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/register`, request, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        if (error && error.response?.status === 406) {
          reject({ message: "User is Already Registerd!!!", isError: true });
        } else if (error && error.response?.status === 409) {
          reject({ message: "Something went wrong please try again later.", isError: true });
        }
      });
  });
};

export const verifyAggrementSignService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/aggrementverify`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyCodeService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/emailverify`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginAdmin = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/login`, request, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};

export const forgotPasswordService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/forgotpassword`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyForgotPasswordService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/verifyforgotpasswordcode`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendRegisterCodeService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/resendcode`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const rememberMe = () => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getTokenDetails`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAppDetails = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAppDetails`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createNewPasswordService = (request) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/resetpassword`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const clinicPic = (request) => {
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  var blob = dataURItoBlob(request);
  var file = new File([blob], "clinic_image.jpeg", { type: "image/jpeg" });
  var formData = new FormData();
  if (file) {
    formData.append("clinic_image", file);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `admin/clinicPic`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const clinicDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `adminClinicDetails`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `edental/updateUserProfile`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getLatestClinicDetails = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `clinicDetails`, { params: filters, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};

export const getBinaryDataOfClinicImage = (imageName) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT 
      .get(
        API_HOST + `binaryDataOfClinicImage/${imageName}`,
        {
          headers
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getUserDetails = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `edental/getindividualuserdetails`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logoutUserService = () => {
  const authToken = localStorage.getItem("authToken");
  const refreshToken = JSON.parse(localStorage.getItem("admin")).token;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axios
      .put(
        API_HOST + `admin/logout`,
        { token: refreshToken },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          stopRefreshTokenTimer();
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};

// -------------------company specified routes-----------

export const DeleteReportById = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .delete(API_HOST + `deletePatientDetailsById`, { data }, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPatientDetails = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `patientDetails`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ----------------------------------------for csv download---------------------------
export const getPatientDetailsforcsv = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `patientDetailsforcsv`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPatientDetailsforUnSharedcsv = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `patientDetailsforUnSharedcsv`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getPatientDetailsById = (filters) => {
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `patientDetailsById`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPatientDetailsByIdInitially = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `patientDetailsById`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportCount = () => {
  const authToken = localStorage.getItem("admin");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `reportCount`, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportViewStatus = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getReportViewedStatus`,
        {
          params: filters,
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postReportViewStatus = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `reportStatusAsOpened`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateReportById = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateReport`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateReportRemarksBasedOnReportId = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateReportRemarksBasedOnReportId`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const ReportsBasedOnUserId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `ReportsBasedOnUserId`,
        {
          params: filters,
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const questionereBasedOnUserId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `questionereBasedOnUserId`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCavityTartarContents = (reportId, cavityScore, tartarScore) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST +
          `report/cavity-tartar/${reportId}/${cavityScore}/${tartarScore}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getGraphDetialsForAi = (reportId) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST +
          `graphDetailsOfAi/${reportId}`,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const sendToDentistBasedOnId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `sendToDentistBasedOnId`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const branchDetailsBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getBranch`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doctorListBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `doctorList`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const allLocationsBasedOnCompany= (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `allLocations`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const allDentistBasedOnLocation= (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getDentistListBasedOnLocation`,
        {
          params: filters,
          headers
        },
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const unSharedReportsBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `unshared/patientDetails`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const unSharedReportsCountBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `report/count/nonShared`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeleteReportByIdOnReport = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .delete(API_HOST + `ReportById`, { data }, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// -------------------------Analytics api---------------------

export const nodeploy = (fromdate, todate, db) => {
  // var fromdate="2021-05-10";
  // var todate="2022-09-22"
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  if (fromdate && todate) {
    return new Promise((resolve, reject) => {
      return axiosJWT
        .get(
          API_HOST + `${db}/user/user-analytics/${fromdate}/${todate}`,
          {
            // params:{
            //   fromdate,todate
            // }
          },
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      return axiosJWT
        .get(
          API_HOST + `user/user-analytics/2022-05-10/2022-09-22`,
          {
            // params: filters
          },
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

// -----------------------------dentist routes----------------
export const postAddDentist = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `addDoctor`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteDentistBasedOnId = (data) => {
  const authToken = localStorage.getItem("authToken");

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `deleteDentistById`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const dentistDetailsBasedOnId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getDentistDetailsBasedOnId`,
        {
          params: filters,
          headers
        },
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateDentistDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const app = JSON.parse(localStorage.getItem("admin")).admin._id;
  const sendingdata = { ...data, app };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateDentistProfileById`, sendingdata, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const assignDentistToReport = (data) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `assignDentist`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// -------------------------------------------prospect-------------------------
export const getProspectCount = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `prospectUserCount`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProspectUserLists = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `prospectUserData`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProspectUserListsForCsv = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `prospectUserDataForCsv`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getConsultingPatientLists = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `consultingPatients`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};



export const getConsultingPatientListsForCsv = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `consultingPatientsForCsv`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userAssignDentist = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `edental/updateAssignDentist`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendToDentistListBasedOnUserId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `usersInSendToDentistBasedOnUserId`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const userViewedStatus = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `userViewedStatus`, { params: filters, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const userViewedStatusForDentistDashboard = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `userViewedStatusForDentistDashboard`, { params: filters, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const userViewedStatusForLocationDashboard = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `userViewedStatusForLocationDashboard`, { params: filters, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postUserViewedStatus = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `post/userViewedStatus`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDentistNameFromOfficeDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateDentistNameFromOfficeDetails`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const partnerReferralRegisterService = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `partnerReferral/register`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


// -----------------------------Location routes------------------
export const postAddLocation = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `postBranch`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteBranchBasedOnId = (data) => {
  const authToken = localStorage.getItem("authToken");

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `deleteBranchById`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const branchDetailsBasedOnId = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getBranchDetailsBasedOnId`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const recentBranchOFSharedUser = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getRecentBranchDetailsBasedOnUserId`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBranchDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const app = JSON.parse(localStorage.getItem("admin")).admin._id;
  const sendingdata = { ...data, app };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateBranchProfileById`, sendingdata, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const doctorListPaginationBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getDentistPagination`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const othersListPaginationBasedOnCompany = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const company = JSON.parse(localStorage.getItem("admin")).admin.company;
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getOtherListPagination`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const roleList = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `rolesAvailable`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateDentistPasswordForFirstTime= (data) => {
  const authToken = localStorage.getItem("authToken");
  const admin_id = JSON.parse(localStorage.getItem("admin")).admin._id;
  const sendingdata = { ...data, admin_id };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updatePasswordForDentistForFirstTime`, sendingdata, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const countOfAiCheckProspectUser = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `countOfAiCheckProspectUser`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const countOfMarkettingTypeUser = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `dataForUserBarChart`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const mailProspectUser = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `deliverMailToProspectUser`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// -----------------------------------------------Annotation----------------------
export const GetBucketFile = (fileData) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return new Promise((resolve, reject) => {
    return axios
      .post(API_HOST + `review-report/get-file`, fileData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};


export const annotatedNotReviewedReports = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `annotatedAndNotReviewedReports`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------------------------   Dashboard Internal -------------------------

export const getAllClients = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getAllClients`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
        console.log("Failure in AuthServices")
      });
  });
};

export const getAdmin = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getAdmin`,  { 
          params: filters, headers 
        }, 
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
        console.log("Failure in AuthServices")
      });
  });
};

export const getAllApiSubscribers = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getAllApiSubscribers`,  { 
          params: filters, headers
        }, 
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
        console.log("Failure in AuthServices")
      });
  });
};

export const getDetailsOfSelectedSubscriber = (data) => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }

  return new Promise(async (resolve, reject) => {
    return axios 
    .get(API_HOST + `getApiUsageDetails`, {params: data, headers} , {headers})
     .then((response) => {
      if (response.status === 200) {
        resolve(response);
      }
      reject(response);
    })
    .catch((error) => {
      reject(error);
      console.log("Failure in AuthServices")
    });
    
  })

}

//  ------------------   Aligner Dashboard  --------------------------

export const uploadVideoTos3Bucket = ({formData, app, userEmail}) => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken
  };

  return new Promise((resolve, reject) => {
    return axios 
    .post(API_HOST + `uploadVideoTos3Bucket`, formData , {
      params: {app, userEmail},
      headers:headers})
    .then((response) => {
      if(response.status === 200) {
        resolve(response)
      }
      reject(response)
    })
    .catch((error) => {
      reject(error)
    })
  })

}

export const signUpNewUser = (userDetails) => {

  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }
  
  return new Promise((resolve, reject) => {
    return axios 
    .post(API_HOST + `signUpNewUser`, userDetails, {headers:headers})
    .then((response) => {
      if(response.status === 200) {
        resolve(response)
      }
      reject(response)
    })
    .catch((error) => {
      reject(error)
    })
  })
}

export const getAlignerUsers = (filters) => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type" : "application/json",
    Authorization : authToken
  }

  return new Promise((resolve, reject) => {
    return axios
    .get(API_HOST + `getAlignerUser`, {
      params: filters,
      headers})
    .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    })
    .catch((err) => {
      console.log("error at authservice")
      reject(err)
    })
  })
}

export const getAlignerUserDetails = (userId) => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type" : "application/json",
    Authorization : authToken
  }

  return new Promise((resolve, reject) => {
    return axios
    .get(API_HOST + `getAlignerUserDetails/${userId}`, {headers})
    .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

export const updateDentistforAlignerUer = (data) => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type" : "application/json",
    Authorization : authToken
  }

  return new Promise((resolve, reject) => {
    return axios
    .post(API_HOST + `updateDentistforAlignerUer`, data, { headers })
      .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    }).catch((err) => {
      reject(err)
    })
  })

}

export const getAlignerUserReports = userId => {
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type" : "application/json",
    Authorization: authToken
  }
  return new Promise((resolve, reject) => {
    return axios
    .get(API_HOST + `alignerUserReports/${userId}`, { headers})
    .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

export const getBinaryDataofVideo = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axios 
    .get(API_HOST + `getBinaryDataofVideo`, {params: data, headers})
    .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    })
    .catch((err) => {
      reject(err)
    })
  })
}

export const updateReportFromDentist = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axios
    .post(API_HOST + `updateReportFromDentist`, data, { headers })
      .then((res) => {
      if (res.status === 200) {
        resolve(res)
      }
      reject(res)
    }).catch((err) => {
      reject(err)
    })
  })

}

export const updateReportViewedStatus = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateReportViewedStatus` , data,  { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};

export const updateUserToViewed = data => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axios
     .post(API_HOST + `updateUserToViewed`, data, {headers})
     .then((response) => {
      if(response.status === 200) {
        resolve(response)
      }
      reject(response)
     })
     .catch((err) => {
      reject(err)
     })
  })

}

export const updateAlignerUserProfile = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateAlignerUserProfile`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAlignerUserFlow = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateAlignerUserFlow`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAlignerReportsForCsv = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAlignerReportsForCsv`,
      {
        params: filters,
        headers
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};