import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import "./patientReport.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Col from "react-bootstrap/Col";
import Annotation from "../Annotation/annotation";
import {GetBucketFile} from "../../Services/AuthService"
import "./slider.css"

const SliderCavityTartarCard = (props) => {
  const {reportScoreImg, folder} = props
  const [binaryData, setBinaryData] = useState([])
  const [sliderImages, setSliderImages] = useState([])
  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    cssEase: "linear",
  };


useEffect(() => {
  initialCall()
}, [reportScoreImg])

const initialCall = async() => {
  setBinaryData([])
  setSliderImages([])
  const unorderedSliderImages = await Promise.all(reportScoreImg.map(async (eachImage) => {
    const fileName = eachImage.split("-").join(".")
    const fileFolder = folder == "output" ? "toothlens-image-store/output" : "toothlens-image-store/input"
      return await GetBucketFile({fileName ,fileFolder}).then((res) => {
        if (res.status === 200 && res.data.data !== "No Data") {
          const byteArray = new Uint8Array(res.data.data.data); // Convert the array of integers to a byte array
          const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming it's a JPEG image
          const imageUrl = URL.createObjectURL(blob);
           setBinaryData(prevState => [...prevState, {imageName:eachImage, imageUrl}])
           return {imageName:eachImage, imageUrl}
        }
      }).catch((err) => {
      })
  }))
   //  sorting the images
     const orderedImages = reportScoreImg.map((eachImage) => {
     const selected = unorderedSliderImages.find((binaryDataObj) => binaryDataObj.imageName.split("-")[0] == eachImage.split("-")[0])
     return selected
  })
     setSliderImages(orderedImages)

  
}


 
  return (
    <>
      
       {
        reportScoreImg.length == sliderImages.length ?
       
         <Slider {...settings}>
          {sliderImages.map((item, index) => {
            return (
              <Col md={12} key={index}>
              <div key={item.imageName} className="cavity-slider "> 
                  <Card className="cavity-card"> 
                   <Card.Img
                      variant="top"
                      src={item.imageUrl}
                      alt="report images"
                      height="20px"
                      width="20px"
                    /> 
                   {/* <Annotation/>  */}
                  </Card> 
                  
                 </div>
              </Col>
            );
          })}
        </Slider>   :  
                    <div className=" d-flex flex-column align-items-center mt-5">
                        <div className="sub-container mt-5">
                            <img src={process.env.PUBLIC_URL + '/loading-icon.svg'} alt="LoadingIcon" />
                        </div>
                        <div className="loader mb-5">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                      </div>   }
      
    </>
  );
};

export default SliderCavityTartarCard;



