import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import profile from "../../assets/userIcon.svg";
import { postAddLocation } from "../../Services/AuthService";
import { FaBuilding } from "react-icons/fa";
import AddDentist from "../dentist/addDentist";
import SnackFire from "../../UI/Snackbar/Snackbar";
import { set } from "date-fns";

const AddLocation = ({ change }) => {
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const verified = JSON.parse(localStorage.getItem("flag"))
  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <div>
      <Formik
        validate={(values) => {
          let errors = {};
          if (!values.address) {
            errors.address = "Address is required";
          }
          if (!values.email) {
            errors.email = "Email is required";
          }
          if (!values.phone_number) {
            errors.phone_number = "Phone number is required"
          }
          if (!values.city) {
            errors.city = "City is required"
          }
          if (!values.state) {
            errors.state = "State is required"
          }
          if (!values.zip_code) {
            errors.location = "Zip Code  is required";
          }
          return errors;
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required("Email is Required"),
          phone_number: Yup.string().required("Phone number is Required").length(10, 'Phone number must be exactly 10 characters'),
          city: Yup.string().required("City is Required") ,
          state: Yup.string().required("State is Required") ,
          address: Yup.string().required("Location Name is Required"),
          zip_code: Yup.string().required("Zip Code is Required")
        })}
        initialValues={{
          email: "",
          phone_number: "",
          address: "",
          city: "",
          state: "",
          zip_code:""
        }}
        onSubmit={(values) => {
          postAddLocation({ app, ...values })
            .then((res) => {
              setsnackbarmessage(res.data.message);
              JSON.stringify(res.data.data) === '{}'?settype("error"):settype("success");
              setsnackbar(true);
             if( JSON.stringify(res.data.data) != '{}'){change()} 
            })
            .catch((err) => {
              setsnackbarmessage(err.data.message);
              setsnackbar(true);
            });
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
                    {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
              <div>
                <div className="d-flex navbar_text_dashbord justify-content-center">
                  <div>
                    <FaBuilding size="22px" color="#0090FF" className="mx-2" />
                  </div>{" "}
                  <div className="mt-2">Add Location</div>
                </div>
                <div>
                  {" "}
                  <Col
                    md={12}
                    lg={12}
                    xs={12}
                    className="d-flex justify-content-center mx-3 mt-3"
                  >
                    <Col
                      md={12}
                      xs={12}
                      lg={12}
                      className="d-flex position mx-3 mt-2"
                    >
                      <div>
                       
                        {/* <div>
                          <Col md={12} lg={12} xs={12}>
                            <FormLabel
                              component="legend"
                              className="font-weight-500 text-dark field-text"
                            >
                              <span className="field-texts  mx-3">
                                Location name*
                              </span>
                            </FormLabel>
                          </Col>

                          <Col className="mt-1">
                            {" "}
                            <input
                              type="text"
                              id="location"
                              className="form-input-location mb-3 grey-bg-color"
                              placeholder="location"
                              value={values.location}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Col>

                          <div className="mx-3">
                            {" "}
                            {errors.location && touched.location && (
                              <div className="text-danger mb-2 ml-2">
                                {errors.location}
                              </div>
                            )}
                          </div>
                        </div> */}

                        <Col>
                          <FormLabel
                            component="legend"
                            className=" font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">Email*</span>
                          </FormLabel>
                        </Col>
                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="email"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.email && touched.email && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="pt-1 font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">
                              Phone Number*
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="phone_number"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.phone_number && touched.phone_number && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.phone_number}
                            </div>
                          )}
                        </div>
                        <Col>
                          <FormLabel
                            component="legend"
                            className="font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">Address*</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="address"
                            className="form-input-location mb-3 grey-bg-color"
                            placeholder="address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.address && touched.address && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.address}
                            </div>
                          )}
                        </div>

                        {/* <Col>
                          <FormLabel
                            component="legend"
                            className=" font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">
                              Location Name*
                            </span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Location Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div className="mx-3">
                            {" "}
                            {errors.name && touched.name && (
                              <div className="text-danger mb-2 ml-2">
                                {errors.name}
                              </div>
                            )}
                          </div>
                        </Col> */}
                        <Col>
                          <FormLabel
                            component="legend"
                            className=" font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">City*</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="city"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.city && touched.city && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.city}
                            </div>
                          )}
                        </div>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">State*</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="state"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.state && touched.state && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.state}
                            </div>
                          )}
                        </div>

                        <Col>
                          <FormLabel
                            component="legend"
                            className="font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">Zip Code*</span>
                          </FormLabel>
                        </Col>

                        <Col className="mt-1">
                          {" "}
                          <input
                            type="text"
                            id="zip_code"
                            className="form-input-location mb-3 grey-bg-color"
                            placeholder="zip code"
                            value={values.zip_code}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <div className="mx-3">
                          {" "}
                          {errors.zip_code && touched.zip_code && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.zip_code}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* </div> */}
                    </Col>
                  </Col>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-1">
                  <Button className="form-btn" type="submit" disabled = {verified}>
                    <span className="btn-text">Add Location</span>
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>{" "}
    </div>
  );
};

export default AddLocation;
