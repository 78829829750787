import React,{useEffect}from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Components/LandingPage/landingPage";
import Login from "./Components/Login/login";
import SignUp from "./Components/SignUp/signUp";
import AccountDetails from "./Components/AccountDetails/accountDetails";
import DentalClinic from "./Components/DentalClinic/dentalClinic";
import AccountInformation from "./Components/AccountInformation/accountInformation";
import DoctorList from "./Components/DoctorList/doctorList";
import Dashboard from "./Components/Dashboard/dashboard";
// import Practice from './Components/practice/practice';
import SignUpStepTwo from "./Components/SignUp/signUpStepTwo";
import ForgotPassword from "./Components/Login/forgotPassword";
import VerifyCode from "./Components/Login/verifyCode";
import NewPassword from "./Components/Login/newPassword";
import TermsAndCondition from "./Components/SignUp/termsAndCondition";
// import Practice from './Components/practice/practice';
import PatientReport from "./Components/PatientReport/patientReport";
import Nodeploy from "./Components/nodeploy/nodeploy";
import SnackFire from "./UI/Snackbar/Snackbar";
import Thanks from "./Components/ThanksForSignUp/thanks";
import Dentist from "./Components/dentist/dentist";
import DashboardUSA from "./Components/Dashboard_USA/dashboardUSA";
import PatientReportDental from "./Components/PatientReportDental/patientReport";
import FormSubmission from "./Components/LeadFormSubmission/formSubmission";
import TrialLink from "./Components/LeadFormSubmission/trialLink";
import Location from "./Components/LocationDetails/location";
import DentistDashboard from "./Components/DentistDashboard/dentistDashboard";
import FirstLogin from "./Components/Login/firstLogin";
import LocationDashboard from "./Components/LocationDashboard/locationDashboard";
import Example from "./Components/practice/practice";
import Annotation from "./Components/Annotation/annotation";
import PatientReportAnnotator from "./Components/AnnotatorDisplayBoard/patientAnnotatorReport";
import DashBoardInternal from "./Components/DashboardInternal";
import PatientReport3D from "./Components/PatientReport3DView/patientReport"
import AlignerDashboard from "./Components/AlignerDashboard";
import AlignerUserDetails from "./Components/AlignerDashboard/alignerPatientReport";

import Deployment from "./Components/deployment/deployment";
import AdminDeployment from "./Components/deployment/adminIdDeployment";
const App = () => {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="signup/:signupReferral_id" element={<SignUp />} />
        <Route path="verify-code" element={<VerifyCode />} />
        <Route path="signup-step-two" element={<SignUpStepTwo />} />
        <Route path="account-details" element={<AccountDetails />} />
        <Route path="dental-clinic" element={<DentalClinic />} />
        <Route path="account-information" element={<AccountInformation />} />
        <Route path="doctor-list" element={<DoctorList />} />
        <Route path="patient-detail-report/:id/:sendtodentist" element={<PatientReport />} />
        <Route path="nodeploy" element={<Nodeploy />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="practice" element={<SnackFire />} />
        <Route path="signupsucess" element={<Thanks />} />
        <Route path="dentist" element={<Dentist />} />
        <Route path="dashboard-dental" element={<DashboardUSA />} />
        <Route path="user-detail-dental/:user_id" element={<PatientReportDental/>}/>
        <Route path="user-detail-dental-3D/:user_id" element={<PatientReport3D/>}/>
        <Route path="form/:form_id" element={<FormSubmission/>}/>
        <Route path="trialLink" element={<TrialLink/>}/>
        <Route path="location-details" element={<Location/>}/>
        <Route path="dashboard-dentist" element={<DentistDashboard/>} />
        <Route path="dashboard-location" element={<LocationDashboard/>} />
        <Route path="bar" element={<Example/>} />
        <Route path="first-login" element={<FirstLogin/>} />
        <Route path="annotation" element={<Annotation/>} />
        <Route path="annotation-display-board" element={<PatientReportAnnotator/>} />
        <Route path="dashboard-internal" element={<DashBoardInternal />} />
        <Route path="aligner-dashboard" element={<AlignerDashboard />} />
        <Route path="aligner-user-detail-dental/:user_id" element={<AlignerUserDetails />}  />
        <Route path="deployment" element={<Deployment/>} />
        <Route path="admin-deployment" element={<AdminDeployment/>} />


      </Routes>
    </BrowserRouter>
    </div>
  );
};

export default App;
