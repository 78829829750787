import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCard = () => {
  return (
    <div>
      <Skeleton       style={{
        display: 'block',
        lineHeight: 2,
        padding: '1rem',
        margin: '0.5rem',
      }} />
      {/* <Skeleton />{" "} */}
    </div>
  );
};

export default SkeletonCard;
