import axios from "axios";
import { API_HOST } from "./BaseUrl";

let refreshTokenTimeout;

const AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) =>
    status === 200 || status === 201 || status === 204,
});

const axiosJWT = axios.create();

export const getClinicDetailsUsingAdminMail = (filters) => {
    const authToken = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    return new Promise((resolve, reject) => {
      return axiosJWT
        .get(API_HOST + `getClinicDetailsByAdminMailId`, {
          params: filters,
          headers,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          console.log(error)
          reject(error);
        });
    });
  };

  
export const getAdminDetailsByMail = (filters) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAdminDetailsByMailId`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};


export const postDeploymentAppDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `postDeploymentAppDetails`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};