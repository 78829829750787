import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  postReportViewStatus,
  getPatientDetailsById,
  getPatientDetailsByIdInitially,
  updateReportRemarksBasedOnReportId,
  sendToDentistBasedOnId,
  getUserDetails,
  sendToDentistListBasedOnUserId,
  postUserViewedStatus,
} from "../../Services/AuthService";
import Container from "react-bootstrap/Container";
import NavbarPatientEnquiry from "./navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./patientReport.css";
import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";
import Update from "../../assets/update.svg";
import SnackFire from "../../UI/Snackbar/Snackbar";
import Annotation from "../Annotation/annotation";
import { FaDownload } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import AssingDentistPopup from "../Dashboard_USA/assingDentistPopup";

const PatientReportDental = () => {
  localStorage.setItem("consultingUsersTabNav", true);
  localStorage.setItem("propspectTabNav", false);
  localStorage.setItem("analyticsTabNav", false);
  const verified = JSON.parse(localStorage.getItem("flag"));
  const navigate = useNavigate();
  let { user_id } = useParams();
  const [usereditpopup, setusereditpopup] = useState(false);
  const [sendtodentistid, setsendtodentistid] = useState();
  const [loading, setloading] = useState(true);
  const [temp, settemp] = useState("");
  const [userData, setuserData] = useState();
  const [reportList, setReportList] = useState();
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const [
    SendToDentistIdForDentistAssignment,
    setSendToDentistIdForDentistAssignment,
  ] = useState();
  const [sharedLocationId, setsharedLocationId] = useState();
  const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
  const [annotationImage, setAnnotationImage] = useState();
  const [annotationActiveImage, setAnnotationActiveImage] = useState();
  const [download, setDownload] = useState();
  const [annotationStatus, setAnnotationStatus] = useState({
    front_teeth: true,
    upper_jaw: false,
    lower_jaw: false,
    right_side: false,
    left_side: false,
  });
  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const onCloseUserPopup = () => {
    setusereditpopup(false);
    call();
  };
  const handleclose = () => {
    setsnackbar(false);
  };
  const handleReportRemark = (event) => {
    settemp(event.target.value);
  };

  const closeAssigndentistPopup = () => {
    setassignedDentistPopup(false);
  };
  const call = async () => {
    setAnnotationStatus({
      front_teeth: true,
      upper_jaw: false,
      lower_jaw: false,
      right_side: false,
      left_side: false,
    });
    if (!verified) {
      await postUserViewedStatus({ user_id });
    } else {
    }
    await getUserDetails({ user_id })
      .then((res) => {
        if (res.status === 200) {
          setuserData(res.data.data.userDetails[0]);
        }
      })
      .catch((err) => {
        return err;
      });
    await sendToDentistListBasedOnUserId({ app, user_id })
      .then(async (res) => {
        setReportList(res.data.data.sendToDentistBasedOnUserId);
        setsendtodentistid(res.data.data.sendToDentistBasedOnUserId[0]._id);
        if (!verified) {
          await postReportViewStatus({
            _id: res.data.data.sendToDentistBasedOnUserId[0]._id,
          });
        }

        await getPatientDetailsByIdInitially({
          id: res.data.data.sendToDentistBasedOnUserId[0].report,
        }).then(async (res) => {
          setDownload(
            res.data.data[0]?.fullUrl
              ? res.data.data[0].fullUrl
              : res.data.data[0]?.pdf_url
              ? res.data.data[0].pdf_url
              : ""
          );
          setAnnotationActiveImage(res.data.data[0].front_teeth);
          setAnnotationImage(res.data.data[0]);
        });

        await sendToDentistBasedOnId({
          sendtodentistid: res.data.data.sendToDentistBasedOnUserId[0]._id,
        })
          .then((res) => {
            settemp(res.data.data.sendtodentistdetails[0].report_remarks);
            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        navigate("/");
      });
  };

  useEffect(() => {
    call();
  }, []);
  const dateFormat = (date) => {
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  if (loading) {
    return (
      <div>
        <NavbarPatientEnquiry />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container fluid>
        <NavbarPatientEnquiry />
        {snackbar && (
          <SnackFire
            open={snackbar}
            onClose={handleclose}
            position="top-right"
            timing={5000}
            type={type}
            message={snackbarmessage}
          />
        )}
        <Row>
          <Col md={3} className="mt-1 ">
            <div className="patient_filter mt-2">
              <Row>
                <Col md={10} className="px-5 pt-3">
                  <div className="patient_name">{userData.first_name}</div>
                  <div className="patient_email mt-2">{userData.email}</div>

                  <div className="patient_id mt-2">{userData.phone_number}</div>
                  <div className="patient_gender mt-2">
                    {userData.gender}, {calculateAge(userData.dob)}
                  </div>
                </Col>
                <Col md={2}>
                  <div className="d-flex w-5  justify-content-end mt-3 mx-3">
                    <div>
                      <Button
                        onClick={() => {
                          setusereditpopup(true);
                        }}
                      >
                        <img
                          alt=""
                          src={Update}
                          width="60px"
                          height="60px"
                          className="m-2"
                        />
                      </Button>
                    </div>
                    <div>
                      <div className="mt-4">
                        <Button
                          onClick={() => {
                            setSendToDentistIdForDentistAssignment(
                              userData._id
                            );
                            setsharedLocationId(userData._id);
                            setassignedDentistPopup(true);
                          }}
                        >
                          <FaUserMd size="32px" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-2"></Col>
                </Col>
                <div className="dental_analysis_text d-flex w-5 justify-content-center mt-3 pt-1">
                  Chief Complaint
                </div>
                <div className="mx-3 mt-3">
                  {userData.user_looking_for.map((note) => {
                    if (note.length != 0) {
                      return <li key={note}>{note}</li>;
                    }
                  })}
                </div>
                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </Col>

                <Col md={12} className="">
                  <span className="patient_status m-2 mx-4 "> Reports</span>

                  <Container className=" ">
                    {reportList.map((value, index) => {
                      return (
                        <div>
                          <div className="d-flex" key={index}>
                            <Button
                              onClick={async () => {
                                setsendtodentistid(value._id);
                                await postReportViewStatus({ _id: value._id });
                                await getPatientDetailsById({
                                  id: value.report,
                                })
                                  .then(async (res) => {
                                    setDownload(
                                      res.data.data[0]?.fullUrl
                                        ? res.data.data[0].fullUrl
                                        : res.data.data[0]?.pdf_url
                                        ? res.data.data[0].pdf_url
                                        : ""
                                    );
                                    setAnnotationActiveImage(
                                      res.data.data[0].front_teeth
                                    );
                                    setAnnotationImage(res.data.data[0]);
                                    setAnnotationStatus({
                                      front_teeth: true,
                                      upper_jaw: false,
                                      lower_jaw: false,
                                      right_side: false,
                                      left_side: false,
                                    });
                                  })
                                  .catch((err) => console.log(err));

                                await sendToDentistBasedOnId({
                                  sendtodentistid: value._id,
                                })
                                  .then((res) => {
                                    settemp(
                                      res.data.data.sendtodentistdetails[0]
                                        .report_remarks
                                    );
                                  })
                                  .catch((err) => console.log(err));
                              }}
                            >
                              <Col
                                className={
                                  value.is_report_viewed
                                    ? "d-flex justify-content-start test_text_style active_report mx-4"
                                    : "d-flex justify-content-start test_text_style mx-4"
                                }
                              >
                                <Row>
                                  <Col md={3} className="">
                                    {" "}
                                    {value._id == sendtodentistid && (
                                      <img
                                        src={dentalAnalysisimg}
                                        width="25px"
                                        height="25px"
                                        alt="filter"
                                      ></img>
                                    )}{" "}
                                  </Col>
                                  <Col md={4} className="">
                                    {dateFormat(value.createdAt)}
                                  </Col>
                                </Row>
                              </Col>
                            </Button>
                            <Col md={4}>
                              {download && value._id == sendtodentistid && (
                                <Button>
                                  <a href={`${download}`} target="blank">
                                    <FaDownload
                                      size="18px"
                                      color="#0090FF"
                                      className="mx-2"
                                    />
                                  </a>
                                </Button>
                              )}
                            </Col>
                          </div>
                        </div>
                      );
                    })}
                  </Container>
                </Col>

                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </Col>
                <div>
                  <div className="dental_analysis_text d-flex w-5 justify-content-center mt-4 ">
                    Remarks
                  </div>
                  <div>
                    <textarea
                      className="form-control px-3 py-2 form-input-remarks mt-2 mb-3 grey-bg-color"
                      type="text"
                      value={temp}
                      onChange={handleReportRemark}
                    >
                      {temp}{" "}
                    </textarea>
                  </div>
                  <div className="d-flex w-5 justify-content-center">
                    <Col md={12} className="my-3">
                      <Button
                        className="form-btn-remarks"
                        onClick={() => {
                          updateReportRemarksBasedOnReportId({
                            _id: sendtodentistid,
                            reportRemarks: temp,
                          })
                            .then((res) => {
                              setsnackbarmessage(res.data.message);
                              JSON.stringify(res.data.data) === "{}"
                                ? settype("error")
                                : settype("success");
                              setsnackbar(true);
                            })
                            .catch((err) => {
                              setsnackbarmessage(err.data.message);
                              setsnackbar(true);
                            });
                        }}
                      >
                        SAVE AND UPDATE
                      </Button>
                    </Col>
                  </div>
                  <Col md={12} className=" line-horizontal mt-1"></Col>
                </div>
              </Row>
            </div>
          </Col>
          <Col md={9} className="mt-2">
            {/* -----------------Internal Navbar---------------- */}
            <div className="d-flex   analysis-background">
              <Button
                onClick={() => {
                  setAnnotationStatus({
                    front_teeth: true,
                    upper_jaw: false,
                    lower_jaw: false,
                    right_side: false,
                    left_side: false,
                  });
                  setAnnotationActiveImage(annotationImage.front_teeth);
                }}
              >
                <div className="d-flex mx-3 align-items-center ">
                  {annotationStatus.front_teeth && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      annotationStatus.front_teeth
                        ? "dental_analysis_text px-2"
                        : "dental_analysis_text_lite px-2"
                    }
                  >
                    Front Teeth
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => {
                  setAnnotationStatus({
                    front_teeth: false,
                    upper_jaw: true,
                    lower_jaw: false,
                    right_side: false,
                    left_side: false,
                  });

                  setAnnotationActiveImage(annotationImage.upper_jaw);
                }}
              >
                <div className="d-flex mx-3 align-items-center ">
                  {annotationStatus.upper_jaw && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      annotationStatus.upper_jaw
                        ? " dental_analysis_text px-2"
                        : " dental_analysis_text_lite px-2"
                    }
                  >
                    Upper Jaw
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => {
                  setAnnotationStatus({
                    front_teeth: false,
                    upper_jaw: false,
                    lower_jaw: true,
                    right_side: false,
                    left_side: false,
                  });
                  setAnnotationActiveImage(annotationImage.lower_jaw);
                }}
              >
                <div className="d-flex mx-3 align-items-center ">
                  {annotationStatus.lower_jaw && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      annotationStatus.lower_jaw
                        ? " dental_analysis_text px-2"
                        : " dental_analysis_text_lite px-2"
                    }
                  >
                    Lower Jaw
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => {
                  setAnnotationStatus({
                    front_teeth: false,
                    upper_jaw: false,
                    lower_jaw: false,
                    right_side: true,
                    left_side: false,
                  });
                  setAnnotationActiveImage(annotationImage.right_side);
                }}
              >
                <div className="d-flex mx-3 align-items-center ">
                  {annotationStatus.right_side && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      annotationStatus.right_side
                        ? "  dental_analysis_text px-2"
                        : " dental_analysis_text_lite px-2"
                    }
                  >
                    Right side
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => {
                  setAnnotationStatus({
                    front_teeth: false,
                    upper_jaw: false,
                    lower_jaw: false,
                    right_side: false,
                    left_side: true,
                  });

                  setAnnotationActiveImage(annotationImage.left_side);
                }}
              >
                <div className="d-flex mx-3 align-items-center ">
                  {annotationStatus.left_side && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      annotationStatus.left_side
                        ? " dental_analysis_text px-2"
                        : " dental_analysis_text_lite px-2"
                    }
                  >
                    Left side
                  </div>
                </div>
              </Button>
            </div>
            {/* -----------------User Edit Popup--------------------    */}
            {usereditpopup && (
              <EditUserDetailsPopUp
                open={usereditpopup}
                onClose={onCloseUserPopup}
                userId={user_id}
              />
            )}
            {/* -----------------Annotation Component----------------- */}
            <div>
              <Row>
                <Col md={12}>
                  <Annotation annotation={annotationActiveImage} />
                </Col>
              </Row>
            </div>
            {/* -----------------Assign Dentist popup-------------- */}
            {assignedDentistPopup && (
              <AssingDentistPopup
                sendToDentistId={SendToDentistIdForDentistAssignment}
                sharedLocationId={sharedLocationId}
                popupclose={closeAssigndentistPopup}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
};

export default PatientReportDental;
