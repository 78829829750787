import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  postReportViewStatus,
  getPatientDetailsById,
  getPatientDetailsByIdInitially,
  ReportsBasedOnUserId,
  updateReportById,
  getCavityTartarContents,
  questionereBasedOnUserId,
  updateReportRemarksBasedOnReportId,
  doctorListBasedOnCompany,
  sendToDentistBasedOnId,
} from "../../Services/AuthService";
import Container from "react-bootstrap/Container";
import NavbarPatientEnquiry from "./navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import profile from "../../assets/userIcon.svg";
import SliderCavityTartarCard from "./slider";
import "./patientReport.css";
import { CAVITY_IMG_URL, TARTAR_IMG_URL } from "../../Services/BaseUrl";
import Button from "@mui/material/Button";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import riskAnalysisimg from "../../assets/riskAnalysisImg.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import Update from "../../assets/update.svg";

import EditUserDetailsPopUp from "../EditUserDetailsPopUp/editUserDetailsPopUp";
const PatientReport = () => {
  let { id, sendtodentist } = useParams();
  const [usereditpopup,setusereditpopup]=useState(false);
  const [sendtodentistid, setsendtodentistid] = useState(sendtodentist);
  const location = useLocation();
  const [cavityContents, setCavityContents] = useState("");
  const [tartarContents, setTartarContents] = useState("");
  const [overallReportContents, setOverallReportContents] = useState("");
  const [userId, setUserId] = useState("");
  const [reportImages, setReportImages] = useState([]);
  const [reportId, setReportId] = useState(id);
  const [loading, setloading] = useState(true);
  const [patientdata, setpatientdata] = useState();
  const [status, setstatus] = useState("");
  const [doctor, setdoctor] = useState("");
  const [reportstatusId, setreportstatusId] = useState(id);
  const [dentalAnalysis, setdentalAnalysis] = useState(true);
  const [riskAnalysis, setriskAnalysis] = useState(false);
  const [questionere, setquestioneredata] = useState();
  const [sendtodentistdata, setsendtodentistdata] = useState("");
  const [temp, settemp] = useState("");
  const [prospectTab, setProspectTab] = useState(false);
  const [doctorList, setdoctorList] = useState("");
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  // const [usereditpopup,setusereditpopup]=useState(false);
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  // const [popup,setpopup]=useState(id)
  const onCloseUserPopup=()=>{
    setusereditpopup(false)
    call()
  }
  const call = async () => {
    if (app == "63808e24a3be9e7f00c5c3b3") {
        setProspectTab(true);
    }
    await postReportViewStatus({ _id: sendtodentist });
    await getPatientDetailsByIdInitially({ id: reportId })
      .then(async (res) => {
        const resObj = res.data.data[0];
        setReportId(resObj._id);
        setUserId(resObj.user_id);
        const reportScore = Object.keys(resObj.report_score[0]);
        reportScore.splice(reportScore.length - 1, 1);
        setReportImages(reportScore);
        const cavityScore = resObj.report_score[0].overall_score[1];
        const tartarScore = resObj.report_score[0].overall_score[0];

        await getCavityTartarContents(resObj._id, cavityScore, tartarScore)
          .then((res1) => {
            if (
              res1 &&
              res1?.data?.code === 200 &&
              res1?.data?.data?.cavityContent &&
              res1?.data?.data?.tartarContent
            ) {
              setCavityContents(res1.data.data.cavityContent);
              setTartarContents(res1.data.data.tartarContent);
              setOverallReportContents(res1.data.data);
            }
          })
          .catch((err) => {});
        await ReportsBasedOnUserId({ user_id: resObj.user_id, sendtodentist })
          .then((res) => {
            setpatientdata(res.data.data.patientDetails);
            // setloading(false);
          })
          .catch((err) => console.log(err));
        await sendToDentistBasedOnId({ sendtodentistid: sendtodentist })
          .then((res) => {
            setsendtodentistdata(res.data.data.sendtodentistdetails[0]);

            settemp(res.data.data.sendtodentistdetails[0].report_remarks);
          })
          .catch((err) => console.log(err));
        await doctorListBasedOnCompany()
          .then((res) => {
            setdoctorList(res.data.data.doctorList);

            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    call();
  }, []);

  if (loading) {
    return <div>
              <NavbarPatientEnquiry />
              <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div></div>;
  }
  if (!loading) {
    const dateFormat = (date) => {
      var today = new Date(date);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();

      return mm + "/" + dd + "/" + yyyy;
    };
    const convertTime12to24 = (time12h) => {
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes] = time.split(":");
      return `${hours}:${minutes}`;
    };

    const handleReportRemark = (event) => {
      settemp(event.target.value);
    };

    return (
      <Container fluid>
        <NavbarPatientEnquiry />

        <Row>
          <Col md={3} className="mt-1 ">
            <div className="patient_filter mt-2">
              <Row>
                <Col md={10} className="px-5 pt-3">
                  <div className="patient_name">
                    {patientdata[0].fromusers.first_name}   {patientdata[0].fromusers.last_name}

                  </div>
                  <div className="patient_email mt-2">
                    {patientdata[0].fromusers.email}
                  </div>

                  <div className="patient_id mt-2">
                    {patientdata[0].fromusers.phone_number}
                  </div>
                  <div className="patient_gender mt-2">
                    {patientdata[0].fromusers.gender},
                    {patientdata[0].fromusers.age}
                  </div>
                  {/* <div className='patient_gender mt-2'>{patientdata[0].fromusers.gender},{patientdata[0].fromusers.age}</div> */}
{
  !prospectTab &&  <div className=" mt-2 d-flex w-5">
  <div>Insurance Name :</div>
  <div className="mx-2">
    {patientdata[0].fromusers.insurance_name}
  </div>
</div>
}
                 
                </Col>
                <Col md={2}>
                  <div className="d-flex w-5  justify-content-end mt-3 mx-3">
                  <Button onClick={()=>{setusereditpopup(true)}}>

                    <img
                      alt=""
                      src={Update}
                      width="60px"
                      height="60px"
                      className="m-2"
                    />       
                                    </Button>
          
                  </div>
                </Col>
             
                <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-5"></Col>
                </Col>
             {
              !prospectTab && <div>
 <Col md={12} className="m-2">
                 <div className="patient_status mx-4">Location</div>
                 <Row className="mt-2 mx-1">
                    <Col
                      md={5}
                      className=" test_text_style active_report d-flex justify-content-center"
                    >
                      Branch details
                      </Col>
                      <Col
                      md={6}
                      className="test_text_style active_report d-flex justify-content-start"
                    >
                           {sendtodentistdata.frombranches.name &&
                            sendtodentistdata.frombranches.name}                      </Col>
                      </Row>
                    
                          {/* <div className="d-flex">
                      <Col md={5}>Branch Details</Col>
                      <Col md={7}>
                        <span className="dental_analysis_text_appointment mx-1">
                          {sendtodentistdata.frombranches.name &&
                            sendtodentistdata.frombranches.name}
                        </span>
                      </Col>
                    </div> */}
                 </Col>
                 <Col md={12}>
                  <Col md={12} className=" line-horizontal mt-2"></Col>
                </Col>
              </div>
             }
                
                {/* <Col md={12} className="m-2">
                  <div className="doctor_update">Dentist's Update</div>

                  <Row className="mt-3">
                    <Col
                      md={3}
                      className="mx-3 patient_status d-flex justify-content-end"
                    >
                      Status
                    </Col>
                    <Col md={7}>
                      <div className="text-sans-bold">
                        {" "}
                        <select
                          className="select-doctor-input-patient my-2 grey-bg-color"
                          value={status}
                          onChange={(e) => {
                            setstatus(e.target.value);
                          }}
                        >
                          <option value="">Select status </option>
                          <option value="Appointment fixed">
                            Appointment fixed{" "}
                          </option>
                          <option value="Appointment Rescheduled">
                            Rescheduled
                          </option>
                          <option value="Appointment Cancelled">
                            Cancelled
                          </option>
                        </select>
                      </div>
                    </Col>

                    <Col
                      md={3}
                      className="mx-3 patient_status d-flex justify-content-end"
                    >
                      Dentist
                    </Col>
                    <Col md={7}>
                      <div className="text-sans-bold">
                        <select
                          className="select-doctor-input-patient my-2 grey-bg-color"
                          value={doctor}
                          name="doctor"
                          onChange={(event) => {
                            setdoctor(event.target.value);
                          }}
                        >
                          {/* <option value="">Select doctor </option>
                          <option value="Doctor X">Doctor X</option>
                          <option value="Doctor Y">Doctor Y</option>
                          <option value="Doctor Z">Doctor Z</option> */}

                          {/* {doctorList.map((option) => (
                            <option value={option.name}>{option.name}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Col> */} 

                {/* <Col md={12}>
                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <Button
                      className="form-btn"
                      onClick={() => {
                        updateReportById({
                          id: sendtodentistid,
                          status,
                          doctor,
                        })
                          .then((res) => {})
                          .catch((err) => console.log(err));
                      }}
                    >
                      <span className="btn-text">save and update</span>
                    </Button>
                  </div>

                  <Col
                    md={12}
                    className=" d-flex-col flex-grow-1  line-horizontal mt-4"
                  ></Col>
                </Col> */}

                <Col md={12} className="">
                  <span className="patient_status m-2 mx-4 "> Reports</span>

                  <Container className=" ">
                    {patientdata.map((value, index) => {
                      return (
                        <Row key={index}>
                          <Button
                            onClick={async () => {
                              setsendtodentistid(value._id);
                              setReportId(value.fromreports._id);

                             
                              setreportstatusId(value.fromreports._id);
                              await getPatientDetailsById({
                                id: value.fromreports._id,
                              })
                                .then(async (res) => {
                                  const resObj = res.data.data[0];
                                  setReportId(resObj._id);
                                  setUserId(resObj.user_id);
                                  const reportScore = Object.keys(
                                    resObj.report_score[0]
                                  );
                                  reportScore.splice(reportScore.length - 1, 1);
                                  setReportImages(reportScore);
                                  const cavityScore =
                                    resObj.report_score[0].overall_score[1];
                                  const tartarScore =
                                    resObj.report_score[0].overall_score[0];
                                  await getCavityTartarContents(
                                    resObj._id,
                                    cavityScore,
                                    tartarScore
                                  )
                                    .then((res1) => {
                                      if (
                                        res1 &&
                                        res1?.data?.code === 200 &&
                                        res1?.data?.data?.cavityContent &&
                                        res1?.data?.data?.tartarContent
                                      ) {
                                        setCavityContents(
                                          res1.data.data.cavityContent
                                        );
                                        setTartarContents(
                                          res1.data.data.tartarContent
                                        );
                                        setOverallReportContents(
                                          res1.data.data
                                        );
                                      }
                                    })
                                    .catch((err) => {});
                                })
                                .catch((err) => console.log(err));

                              await sendToDentistBasedOnId({
                                sendtodentistid: value._id,
                              })
                                .then((res) => {
                                  settemp(
                                    res.data.data.sendtodentistdetails[0]
                                      .report_remarks
                                  );
                                  setsendtodentistdata(
                                    res.data.data.sendtodentistdetails[0]
                                  );
                                })
                                .catch((err) => console.log(err));
                            }}
                          >
                            {/* <Col
                              md={6}
                              className={
                                value._id == sendtodentistid
                                  ? "d-flex justify-content-start test_text_style active_report mx-3"
                                  : "d-flex justify-content-start test_text_style mx-3"
                              }
                            >
                               Test Report {index + 1} 
                            </Col> */}
                            <Col
                              className={
                                value._id == sendtodentistid
                                  ? "d-flex justify-content-start test_text_style active_report mx-4"
                                  : "d-flex justify-content-start test_text_style mx-4"
                              }
                            >
                              {dateFormat(value.fromreports.createdAt)}
                            </Col>
                          </Button>
                        </Row>
                      );
                    })}
                  </Container>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={9} className="mt-2">
            <div className="d-flex   analysis-background">
              <Button
                onClick={() => {
                  setdentalAnalysis(true);
                  setriskAnalysis(false);
                }}
              >
                <div className="d-flex mb-3 mx-5 align-items-center ">
                  {dentalAnalysis && (
                    <img
                      src={dentalAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      dentalAnalysis
                        ? "dental_analysis_text px-2"
                        : "dental_analysis_text_lite px-2"
                    }
                  >
                    Dental Analysis
                  </div>
                </div>
              </Button>
              <Button
                onClick={() => {
                  questionereBasedOnUserId({ user_id: userId })
                    .then((res) => {
                      setquestioneredata(res.data.data.patientDetails[0]);

                      setdentalAnalysis(false);
                      setriskAnalysis(true);
                    })
                    .catch((err) => console.log(err));
                }}
              >
                <div className="d-flex mb-3 mx-5 align-items-center ">
                  {riskAnalysis && (
                    <img
                      src={riskAnalysisimg}
                      width="25px"
                      height="25px"
                      alt="filter"
                    ></img>
                  )}
                  <div
                    className={
                      riskAnalysis
                        ? "  risk_analysis_text px-2"
                        : " dental_analysis_text_lite px-2"
                    }
                  >
                    Questionnaire
                  </div>
                </div>
              </Button>
            </div>
            {usereditpopup&&
<EditUserDetailsPopUp open={usereditpopup} onClose={onCloseUserPopup} userId={userId}/>
   }
            {dentalAnalysis && (
              <Row className="">
                <Col md={7} className="px-5">
                  {reportImages.length > 0 && (
                    <>
                      <div className="shadow bg-white m-3  border-radius-15px">
                        <SliderCavityTartarCard
                          reportId={reportId}
                          imgUrl={TARTAR_IMG_URL}
                          reportScoreImg={reportImages}
                          tartarcontents={tartarContents}
                          cavitycontents={cavityContents}
                        />
                      </div>
                    </>
                  )}
                </Col>
                <Col md={4}>
                  <div className="dental_analysis_text d-flex w-5 justify-content-center mt-3 pt-2">
                    Chief Complaint
                  </div>
                  <div className="mx-5 mt-3">
                    {patientdata[0].fromusers.user_looking_for.map((note) => {
                      return <li>{note}</li>;
                    })}
                  </div>
                  <Col md={12} className=" line-horizontal mt-1"></Col>

                  <div>
                    <div className="dental_analysis_text d-flex w-5 justify-content-center mt-4 ">
                      Remarks
                    </div>
                    <div>
                      <input
                        className="form-input-remarks mt-2 mb-3 grey-bg-color"
                        type="text"
                        value={temp}
                        onChange={handleReportRemark}
                      />
                    </div>
                    <div className="d-flex w-5 justify-content-center">
                      <Col md={12} className="my-3">
                        <Button
                          className="form-btn-remarks"
                          onClick={() => {
                            updateReportRemarksBasedOnReportId({
                              _id: sendtodentistid,
                              reportRemarks: temp,
                            });
                          }}
                        >
                          SAVE AND UPDATE
                        </Button>
                      </Col>
                    </div>
                    <Col md={12} className=" line-horizontal mt-1"></Col>
                  </div>

                  {/* <div>
                    <div className="dental_analysis_text d-flex w-5 justify-content-center mt-4 ">
                      Appointment Request
                    </div>

                    <div className="d-flex mt-3">
                      <Col md={5}>Appointment date</Col>
                      <Col md={7}>
                        <span className="dental_analysis_text_appointment mx-1">
                          {sendtodentistdata.preferred_appointment_date &&
                            dateFormat(
                              sendtodentistdata.preferred_appointment_date
                            )}
                        </span>
                      </Col>
                    </div>

                    <div className="d-flex ">
                      <Col md={5}>Appointment Time</Col>
                      <Col md={7}>
                        <span className="dental_analysis_text_appointment mx-1">
                          {sendtodentistdata.preferred_appointment_time &&
                            convertTime12to24(
                              sendtodentistdata.preferred_appointment_time
                            )}{" "}
                        </span>
                      </Col>
                    </div>

                    <div className="d-flex">
                      <Col md={5}>Branch Details</Col>
                      <Col md={7}>
                        <span className="dental_analysis_text_appointment mx-1">
                          {sendtodentistdata.frombranches.name &&
                            sendtodentistdata.frombranches.name}
                        </span>
                      </Col>
                    </div>
                  </div>
                  <Col md={12} className=" line-horizontal mt-4"></Col> */}
                </Col>
              </Row>
            )}

            {riskAnalysis && patientdata[0].fromusers.gender=="male" &&

             (
              <div>
                <div className=" dental_risk_score_background mt-2 py-5">
                  <Col md={12}>
                    <Row>
                      <Col md={4} className="px-5 border-right">
                        <div>
                          1. {questionere.questions[0].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            {/* <img ></img> */}
                            <div>
                              {questionere.questions[0].options.map((fil) => {
                                if (fil.value == questionere.questions[0].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          2. {questionere.questions[2].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            {/* <img ></img> */}
                            <div>
                              {questionere.questions[2].options.map((fil) => {
                                if (fil.value == questionere.questions[2].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          3. {questionere.questions[1].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            {/* <img ></img> */}
                            <div>
                              {questionere.questions[1].options.map((fil) => {
                                if (fil.value == questionere.questions[1].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          4. {questionere.questions[3].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[3].options.map((fil) => {
                                if (fil.value == questionere.questions[3].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          5. {questionere.questions[4].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[4].options.map((fil) => {
                                if (fil.value == questionere.questions[4].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          6. {questionere.questions[6].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>
                              {questionere.questions[6].options.map((fil) => {
                                if (fil.value == questionere.questions[6].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                      </Col>

                      <Col md={4} className="px-5 border-right">
                        <div>
                          7. {questionere.questions[5].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            {/* <img ></img> */}
                            <div>
                              {questionere.questions[5].options.map((fil) => {
                                if (fil.value == questionere.questions[5].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          {/* <Col md={12} className=" line-horizontal mt-5"></Col> */}
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>

                        <div className="mt-5">
                          8. {questionere.questions[7].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            {/* <img ></img> */}
                            <div>
                              {questionere.questions[7].options.map((fil) => {
                                if (fil.value == questionere.questions[7].score)
                                  return <div>{fil.option} </div>;
                              })}
                            </div>
                          </div>
                          <Col md={12} className=" line-horizontal mt-5"></Col>
                        </div>
                        <div className="mt-5">
                          9. {questionere.questions[8].question}
                          <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
                            <div>{questionere.questions[8].answer}</div>
                          </div>
                        </div>

                        <Col md={12} className=" line-horizontal mt-5"></Col>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </div>
            )}

{riskAnalysis && patientdata[0].fromusers.gender=="female" &&

(
 <div>
   <div className=" dental_risk_score_background mt-2 py-5">
     <Col md={12}>
       <Row>
         <Col md={4} className="px-5 border-right">
           <div>
             1. {questionere.questions[0].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               {/* <img ></img> */}
               <div>
                 {questionere.questions[0].options.map((fil) => {
                   if (fil.value == questionere.questions[0].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>
           <div className="mt-3">
             2. {questionere.questions[2].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               {/* <img ></img> */}
               <div>
                 {questionere.questions[2].options.map((fil) => {
                   if (fil.value == questionere.questions[2].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>
           <div className="mt-3">
             3. {questionere.questions[1].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               {/* <img ></img> */}
               <div>
                 {questionere.questions[1].options.map((fil) => {
                   if (fil.value == questionere.questions[1].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>
         </Col>

         <Col md={4} className="px-5 border-right">
           <div>
             4. {questionere.questions[3].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               <div>
                 {questionere.questions[3].options.map((fil) => {
                   if (fil.value == questionere.questions[3].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>

           <div className="mt-3">
             5. {questionere.questions[9].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               <div>{questionere.questions[9].answer}</div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>

           </div>

           <div className="mt-3">
             6. {questionere.questions[6].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               <div>
                 {questionere.questions[6].options.map((fil) => {
                   if (fil.value == questionere.questions[6].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>
         </Col>

         <Col md={4} className="px-5 border-right">
           <div>
             7. {questionere.questions[5].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               {/* <img ></img> */}
               <div>
                 {questionere.questions[5].options.map((fil) => {
                   if (fil.value == questionere.questions[5].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             {/* <Col md={12} className=" line-horizontal mt-5"></Col> */}
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>

           <div className="mt-3">
             8. {questionere.questions[7].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               {/* <img ></img> */}
               <div>
                 {questionere.questions[7].options.map((fil) => {
                   if (fil.value == questionere.questions[7].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-5"></Col>
           </div>
           <div className="mt-3">
             9. {questionere.questions[9].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               <div>
                 {questionere.questions[4].options.map((fil) => {
                   if (fil.value == questionere.questions[4].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-4"></Col>
           </div>
           <div className="mt-1">
             10. {questionere.questions[8].question}
             <div className="d-flex  w-5 justify-content-center mt-3 align-items-center question_box">
               <div>
                 {questionere.questions[8].options.map((fil) => {
                   if (fil.value == questionere.questions[8].score)
                     return <div>{fil.option} </div>;
                 })}
               </div>
             </div>
             <Col md={12} className=" line-horizontal mt-4"></Col>
           </div>
       

         </Col>
       </Row>
     </Col>
   </div>
 </div>
)}
          </Col>
        </Row>
      </Container>
    );
  }
};

export default PatientReport;
