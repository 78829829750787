import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getLatestClinicDetails, getAdmin } from "../../Services/AuthService";
import "../Dashboard_USA/dentalOfficeDetails.css";

const DentalOfficeDetails = ({ app }) => {
  const user = JSON.parse(localStorage.getItem("admin"));
  const [admin, setAdmin] = useState(user.admin._id)
  const [cheifAdminExist, setCheifAdminExist] = useState(false)
  const [ClinicDetailsData, setClinicDetailsData] = useState();
  const [loading, setloading] = useState(true);
  const company_mail = JSON.parse(localStorage.getItem("admin")).admin.email;

  // getting admin details by appId and proceeding to get dental clinnic details
  useEffect(() => {
    setloading(true)
    getAdmin({ app })
      .then((res) => {
        let adminArray = res.data.data.adminDetails
        if (adminArray.length !== 0) {
          setCheifAdminExist(true)
          setAdmin(res.data.data.adminDetails[0]._id)
          getLatestClinicDetails({ user_id: res.data.data.adminDetails[0]._id})
            .then((res) => {
              if (res.status === 200) {
                setClinicDetailsData(res.data.data[0]);
                setloading(false);
              }
            })
            .catch((err) => {
              setloading(false);
              return err;
            });
        } else {
          setCheifAdminExist(false)
          setloading(false)
          setAdmin("")
        }

      })
      .catch((err) => {
        console.log(err)
      })

  }, [app])



  if (loading) {
    return (
      <div className="d-flex justify-content-center"><div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
  </div></div>
    )
  } else {
    if ( cheifAdminExist) {
      return (
        <div className="mt-2 office-box ">
          <div className="font-doctor mx-2">Dental Office Details</div>

          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-center  align-items-center mt-2"
            >
              Office Name
            </Col>
            <Col
              md={7}
              className="d-flex justify-content-start align-items-center mt-2"
            >
              {ClinicDetailsData ? ClinicDetailsData.clinic_name : "----"}
            </Col>
          </Row>
          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-center align-items-center mt-2"
            >
              Office Email{" "}
            </Col>
            <Col
              md={7}
              className="d-flex justify-content-start align-items-center mt-2"
            >
              <div className="ellipsis-company-email">
                {company_mail}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-center align-items-center mt-2"
            >
              Phone Number
            </Col>
            <Col
              md={7}
              className="d-flex justify-content-start align-items-center mt-2"
            >
              {ClinicDetailsData ? ClinicDetailsData.phone_number : "----"}
            </Col>
          </Row>
          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-center align-items-center mt-2"
            >
              City{" "}
            </Col>
            <Col
              md={7}
              className="d-flex justify-content-start align-items-center mt-2"
            >
              {ClinicDetailsData ? ClinicDetailsData.clinic_city : "-----"}
            </Col>
          </Row>
          <Row>
            <Col
              md={5}
              className="d-flex  justify-content-center align-items-center mt-2"
            >
              State{" "}
            </Col>
            <Col
              md={7}
              className="d-flex justify-content-start align-items-center mt-2"
            >
              {ClinicDetailsData ? ClinicDetailsData.clinic_state : "----"}
            </Col>
          </Row>
        </div>
      );
    }
    if ( !cheifAdminExist) {
      return (
        <p className="no-cheif-dentist" > No Chief Dentist Found </p>
      )

    }
  }


};

export default DentalOfficeDetails;