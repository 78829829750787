import React, { useEffect, useRef, useState } from "react";
import { GetBucketFile } from "../../Services/AuthService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImagePlaceholder from "./image-placeholder1.svg";
import Example from "../../Components/practice/practice";
import AnnotationLegendList from "../PatientReportDental/annotationLegend";
import TeethTable from "./teethTable";
import "./annotation.css";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";

const Annotation = (props) => {
  const [rectanglesData, setRectanglesData] = useState([]);
  let imageTagsData = [];

  const [activeImage, setActiveImage] = useState();
  const [loading, setLoading] = useState(true);
  const [graphObject, setGraphObject] = useState([]);
  const imgRef = useRef();
  const [selectedImageTags, setSelectedImageTags] = useState([]);
  const [filteredImageTags, setFilteredImageTags] = useState([]);
  const [isMissingTeethTableOpen, toggleMissingTeethTable] = useState(true)
  const [showLabel, setShowLabel] = useState(false)
  const [imageTags, setImageTags] = useState({ zIndex: "9", display: "none" });
  const [jsonOutputData, setJsonOutputData] = useState();
  const [apiData, setApiData] = useState(false);
  const [darkTeeth,setDarkTeeth]=useState()
  const [poorAlignment,setPoorAlignment]=useState()
  const [gapTeeth,setGapTeeth]=useState()
  const GetShortName = (name, splitOption) => {
    return (
      name &&
      `${name
        .split(splitOption)
        .map((item) => item[0].toUpperCase())
        .join("")}`
    );
  };
  const TAGS_COLOR_CODE = {
    C:  "#FF0000",
    C1: "#FF0000",
    C2: "#FF0000",
    C3: "#FF0000",
    T: "#0000FF",
    E: "#298542",
    GS: "#68a8a8",
    GR: "#FFA500",
    BT: "#EE82EE",
    // PB: '#FF7A59',
  };
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

   const handleFilteredImageTags = (selectedItems) => {
     const updatedImageTags = selectedImageTags.filter(eachImageObj => selectedItems.includes(eachImageObj.name))
     setFilteredImageTags(updatedImageTags)
    
 }

  useEffect(() => {
    // console.log(
    //   "called here inside the reportImageInDialogBox",
    //   `${props.annotation.split("/")[1].split(".")[0]}.json`,
    //   `${props.annotation.split("/")[1].split(".")[0]}.txt`,
    //   "42121667209989241.jpeg"
    // );
    imageTagsData = [];
    setSelectedImageTags([]);
    setFilteredImageTags([])
    setJsonOutputData();
    // setTartarTxtCount(0);
    // setCavityTxtCount(0)
    GetBucketFile({
      fileName:`${ props.annotation.split("/")[1]}/${ props.annotation.split("/")[2]}`,
      fileFolder: "toothlens-image-store/input",
    }).then((resData) => {
      if (resData.data.data !== "No Data") {
        const byteArray = new Uint8Array(resData.data.data.data)
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setActiveImage(imageUrl)
        getImageCoordinates();
      } else {
        
        // setSnackbarData({ message: 'Image not found', severity: 'error' });
      }
    });
  }, [props.annotation]);
  useEffect(() => {
    // console.log(
    //   "called here inside the reportImageInDialogBox",
    //   `${props.annotation.split("/")[1].split(".")[0]}.json`,
    //   `${props.annotation.split("/")[1].split(".")[0]}.txt`,
    //   "42121667209989241.jpeg"
    // );

    imageTagsData = [];
    GetBucketFile({
      fileName: `${ props.annotation.split("/")[1]}/${ props.annotation.split("/")[2]}`,
      // fileName:"42121667209989241.jpeg",
      fileFolder: "toothlens-image-store/input",
    }).then((resData) => {
      if (resData.data.data !== "No Data") {
        const byteArray = new Uint8Array(resData.data.data.data)
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setActiveImage(imageUrl)
        getImageCoordinates();
      } else {
        // setSnackbarData({ message: 'Image not found', severity: 'error' });
      }
    });
  }, []);

  function getImageCoordinates() {
    const imgWidth = imgRef?.current?.width;
    const imgHeight = imgRef?.current?.height;

    let coordinates = [];
    const imageTags = [];

    GetBucketFile({
      fileName: `${props.annotation.split("/")[1].split(".")[0]}.json`,
      //   fileName:"42121667209989241.json",
      fileFolder: "toothlens-image-store/json_output",
    })
      .then(async (tags) => {
        const tagsData = tags.data.data;
       

        if (tagsData !== "No Data") {
          setJsonOutputData(tags.data.data);
          if(tags.data.data?.dark_teeth?.upper_teeth ||tags.data.data?.dark_teeth?.lower_teeth )
          {setDarkTeeth(true)}
          else{
            setDarkTeeth(false)
          }
          if(tags.data.data?.poor_alignment?.upper_teeth?.length==0 && tags.data.data?.poor_alignment?.lower_teeth?.length==0)
          {
            setPoorAlignment(false)
          }else{
            if(tags.data.data?.poor_alignment?.upper_teeth || tags.data.data?.poor_alignment?.lower_teeth){
              setPoorAlignment(true)
            }else{
              setPoorAlignment(false)

            }
          } 
          if(tags.data.data?.gaps_in_front_teeth?.upper_teeth?.length ==0 && tags.data.data?.gaps_in_front_teeth?.lower_teeth?.length == 0 ){
            setGapTeeth(false)
          }else{
            if(tags.data.data?.gaps_in_front_teeth?.upper_teeth || tags.data.data?.gaps_in_front_teeth?.lower_teeth)
            {
              setGapTeeth(true)}
              else{
                setGapTeeth(false)

              }
          }
          // console.log(gapTeeth,"oo")
          // const elements= []
          // for (const [index, value] of items.entries()) {
          //   elements.push(<li key="{index}">{value}</li>)
          // }
          setSelectedImageTags([]);
          setFilteredImageTags([])

          const imgNaturalWidth = imgRef.current.naturalWidth;
          const imgNaturalHeight = imgRef.current.naturalHeight;
          const renderImageWidth = imgRef.current.width;
          const renderImageHeight = imgRef.current.height;
          const coordinatesData = [];
          for (let key in tagsData) {
            if (Array.isArray(tagsData[key]) && key !== "missing_teeth") {
              // eslint-disable-next-line no-loop-func
              tagsData[key].forEach((item) => {
                let { x, y, w, h } = item;
                x = (x * renderImageWidth) / imgNaturalWidth;
                y = (y * renderImageHeight) / imgNaturalHeight;
                w = (w * renderImageWidth) / imgNaturalWidth;
                h = (h * renderImageHeight) / imgNaturalHeight;
                coordinatesData.push({ x, y, width: w, height: h });
                const shortName = GetShortName(key, "_");
                const colorCode = TAGS_COLOR_CODE[shortName];
                imageTags.push({
                  name: shortName,
                  style: {
                    left: `${x}px`,
                    top: `${y}px`,
                    width: `${w}px`,
                    height: `${h}px`,
                    border: `3px solid ${colorCode}`,
                    background: "transparent",
                  },
                  nameStyle: {
                    position: "relative",
                    top: "-2px",
                    left: "0",
                    background: colorCode,
                    color: colorCode,
                  },
                });
              });
            }
          }
          setSelectedImageTags(imageTags);
          setFilteredImageTags(imageTags)
          setRectanglesData(coordinatesData);
          imageTagsData = coordinatesData;
          setGraphObject([
            {
              name: "Cavity",
              cavity: Array.isArray(tags.data.data.cavity)
                ? tags.data.data.cavity.length
                : 0,
              shade: "json",
            },
            {
              name: "Tartar/Stains",
              cavity: Array.isArray(tags.data.data.tartar)
                ? tags.data.data.tartar.length
                : 0,
              shade: "json",
            },
            {
              name: "Enamel Loss",
              cavity: Array.isArray(tags.data.data.enamel)
                ? tags.data.data.enamel.length
                : 0,
              shade: "json",
            },
            {
              name: "Gingival Recession",
              cavity: Array.isArray(tags.data.data.gum_recession)
                ? tags.data.data.gum_recession.length
                : 0,
              shade: "json",
            },
            {
              name: "Gingival Swelling",
              cavity: Array.isArray(tags.data.data.gum_swelling)
                ? tags.data.data.gum_swelling.length
                : 0,
              shade: "json",
            },
            {
              name: "Chipped/Fractured teeth",
              cavity: Array.isArray(tags.data.data.broken_teeth)
                ? tags.data.data.broken_teeth.length
                : 0,
              shade: "json",
            },
          ]);
          setApiData(true);
          setLoading(false);
        } else {
          getImageTags(imgWidth, imgHeight);
        }
      })
      .catch((err) => {
        setDarkTeeth(false)
        setPoorAlignment(false)
        setGapTeeth(false)
        getImageTags(imgWidth, imgHeight);
      });
  }

  async function getImageTags(imgWidth, imgHeight) {
    setDarkTeeth(false)
    setPoorAlignment(false)
    setGapTeeth(false)
    let coordinates = [];
    setSelectedImageTags([]);
    setFilteredImageTags([])
    const imageTags = [];
    await GetBucketFile({
      fileName: `${props.annotation.split("/")[1].split(".")[0]}.txt`,
      // fileName:"38081674103685917.txt",
      fileFolder: "toothlens-image-store/output",
    })
      // .then(r => r.text())
      .then((tags) => {
        let tartar = 0;
        let cavity = 0;
        const cavityCall = () => {
          return (cavity = cavity + 1);
        };
        const tartarCall = () => {
          return (tartar = tartar + 1);
        };
        const tagsData = tags.data.data.split("\n");
        tagsData.forEach((data, index) => {
          const splitCoordinates = data.split(" ").slice(1, 5);
          if (splitCoordinates.length > 1) {
            const { x, y, w, h } = {
              x: Number(splitCoordinates[0]),
              y: Number(splitCoordinates[1]),
              w: Number(splitCoordinates[2]),
              h: Number(splitCoordinates[3]),
            };
            const { x_min, x_max, y_min, y_max } = getTagsCoordinates(
              { x, y, w, h },
              imgWidth,
              imgHeight
            );
            coordinates.push({
              x: x_min,
              y: y_min,
              width: x_max - x_min,
              height: y_max - y_min,
            });
            const colorCode = data.split(" ")[0] ? "#0000FF" : "#FF0000";
            data.split(" ")[0] ? tartarCall() : cavityCall();
        
            imageTags.push({
              name: data.split(" ")[0] ? "T" : "C",
              style: {
                left: `${x_min}px`,
                top: `${y_min}px`,
                width: `${x_max - x_min}px`,
                height: `${y_max - y_min}px`,
                border: `3px solid ${colorCode}`,
                background: "transparent",
              },
              nameStyle: {
                position: "relative",
                top: "-3px",
                left: "0",
                background: colorCode,
                color: colorCode,
              },
            });
          }
        });
        setSelectedImageTags(imageTags);
        setFilteredImageTags(imageTags)
        setRectanglesData(coordinates);
        imageTagsData = coordinates;
        setGraphObject([
          {
            name: "Cavity",
            cavity: cavity,
            shade: "txt",
          },
          {
            name: "Tartar/Stains",
            cavity: tartar,
            shade: "txt",
          },
          {
            name: "Enamel Loss",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Gingival Recession",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Gingival Swelling",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Chipped/Fractured teeth",
            cavity: 0,
            shade: "txt",
          },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setGraphObject([
          {
            name: "Cavity",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Tartar/Stains",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Enamel Loss",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Gingival Recession",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Gingival Swelling",
            cavity: 0,
            shade: "txt",
          },
          {
            name: "Chipped/Fractured teeth",
            cavity: 0,
            shade: "txt",
          },
        ]);
        setLoading(false);
      });
  }

  function getTagsCoordinates({ x, y, w, h }, imgWidth, imgHeight) {
    let x_min = (x - w / 2) * imgWidth;
    let x_max = (x + w / 2) * imgWidth;
    let y_min = (y - h / 2) * imgHeight;
    let y_max = (y + h / 2) * imgHeight;

    return {
      x_min,
      x_max,
      y_min,
      y_max,
    };
  }

  return (
    <Row>
      <Col md={8} style={{ minHeight: "550px" }}>
        {/* <div
          className="pt-2 overflow-hidden"
          style={{ width: "752px", height: "512px" }}
        > */}
          <div className="position-relative" id="drawContainer">
            <img
              // width="752"
              // height="512"
              className="w-100"
              src={activeImage ? activeImage : ImagePlaceholder}
              id="screenshot"
              alt="report"
              draggable="false"
              ref={imgRef}
            />
            {filteredImageTags.map((item, index) => {
              return (
                <div
                  key={index}
                  className="popup tag-name border-0"
                  style={item.style}
                >
                  <div
                    className="drag-tag-box h-100"
                    style={{ border: item.style.border }}
                  >
                    <div
                      className="d-inline-flex ps-1 font-size-14 popup-header"
                      style={item.nameStyle}
                    >
                     {showLabel && <> 
                            <span className="text-white text-capitalize">
                                 {item.name}
                             </span>
                              <span>
                                 <ArrowDropDownIcon
                                    className="primary-bg text-white"
                                    style={{
                                    width: "20px",
                                    background: item.nameStyle.background,
                                    height: "auto",
                          }}
                        /> 
                      </span> </> }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        {/* </div> */}
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center ">
            <div className="w-50"><input type="checkbox" checked={showLabel} id="showText" onChange={()=>{setShowLabel(!showLabel)}} /> <label htmlFor="showText">Show labels</label></div>
            <div className="dental_analysis_text d-flex w-5 mt-2 justify-content-center">
              LEGENDS
            </div>
            </div>
            <AnnotationLegendList filteration={handleFilteredImageTags} image={props.annotation} />
          </Col>
        </Row>
      </Col>
      <Col md={4} className="ai-observation-container">
        <div className="dental_analysis_text d-flex w-5 justify-content-center mt-3 mx-2">
          AI Observations
        </div >
        
        <div className="d-flex w-5 justify-content-center mx-3 font-size-14">
(Results may vary based on quality of photos. Observations are not to be used for diagnostic purposes.)
        </div>
        <div className="mt-3">
          {graphObject.map((item) => {
            return (
              <Example
                key={item.name}
                name={item.name}
                cavity={item.cavity}
                shade={item.shade}
              />
            );
          })}
        </div>
          {jsonOutputData && jsonOutputData.missing_teeth.length != 0 ? (
          <Col md={12} className="">
             <div className="mt-2">
               <div className="d-flex w-5 justify-content-center dental_analysis_text">
              Missing Teeth :  <button type="button" className="btn btn-primary ms-2 toggle-teeth-button" onClick={() => {toggleMissingTeethTable(!isMissingTeethTableOpen)}} > {isMissingTeethTableOpen? "CLOSE": "OPEN"} TOOTH CHART </button>
              </div> 
              <Col md={11} className="teeth-chart-cotainer mx-4">
                {jsonOutputData?.missing_teeth && isMissingTeethTableOpen
                  ? <TeethTable teeth={jsonOutputData.missing_teeth} /> : "" }
              </Col>
            </div> 
          </Col>
        ): <Col md={12} className="">
        <div className="mt-2">
          <div className="d-flex w-5 justify-content-center dental_analysis_text">
         Missing Teeth :  <button type="button" className="btn btn-primary ms-2 toggle-teeth-button" onClick={() => {toggleMissingTeethTable(!isMissingTeethTableOpen)}} > {isMissingTeethTableOpen? "CLOSE": "OPEN"} TOOTH CHART </button>
         </div> 
         <Col md={11} className="teeth-chart-cotainer mx-4">
             {isMissingTeethTableOpen && <TeethTable teeth={[]} /> }
         </Col>
       </div> 
     </Col>}  

     
         {
          gapTeeth && <Col xs={12} className="mx-4">
          <div className="my-1 mt-2">
            <div className="d-flex w-5 justify-content-center dental_analysis_text">
              Gaps in front teeth
            </div>
            <Row>
            <Col md={6}>
            <div className=" d-flex w-5 justify-content-center">
              <b>Upper Teeth</b>
            
            </div>
            </Col>
            <Col md={6}>
              <div className="px-3">
                {jsonOutputData?.gaps_in_front_teeth?.upper_teeth
                  ? "Yes"
                  : "No"}
              </div>
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <div className=" d-flex w-5 justify-content-center">
              <b>Lower Teeth</b>
            
            </div>
            </Col>
            <Col md={6}>
              <div className="px-3">
                {jsonOutputData?.gaps_in_front_teeth?.lower_teeth
                  ? "Yes"
                  : "No"}
              </div>
            </Col>
            </Row>
            
          </div>
        </Col>
         }   
         
         {
          darkTeeth &&  <Col xs={12} className="mx-4">
          <div className="mt-2">
            <div className="d-flex w-5 justify-content-center dental_analysis_text">
               Teeth Color
            </div>
     {
      jsonOutputData?.dark_teeth?.upper_teeth &&   <Row>
      <Col md={6}>
      <div className=" d-flex w-5 justify-content-center">
      <b>Upper Teeth</b>
    
    </div>
      </Col>
      <Col md={6}>
      <div className="px-3">
        {jsonOutputData?.dark_teeth?.upper_teeth
          ? jsonOutputData.dark_teeth?.upper_teeth
          : ""}
      </div>
      </Col>
    </Row>
     }       
   
     {
      jsonOutputData?.dark_teeth?.lower_teeth &&  <Row>
      <Col md={6}>
      <div className=" d-flex w-5 justify-content-center">
      <b>Lower Teeth</b>
    
    </div>
      </Col>
      <Col md={6}>
      <div className="px-3">
        {jsonOutputData?.dark_teeth?.lower_teeth
          ? jsonOutputData?.dark_teeth?.lower_teeth
          : ""}
      </div>
      </Col>
    </Row>
     }     
      
            
          </div>
        </Col>
         }      
           {poorAlignment &&   <Col xs={12} className="mx-4">
              <div className="mt-2">
                <div className="d-flex w-5 justify-content-center dental_analysis_text">
                  Poor Alignment
                </div>
                <Row>
                  <Col md={6}>
                    <div className="d-flex w-5 justify-content-center ">
                      <div className="mx-1">
                        <b>Upper Teeth</b>
                        <div className="px-1">
                          {jsonOutputData?.poor_alignment?.upper_teeth.length!=0?jsonOutputData?.poor_alignment?.upper_teeth.map(
                                (item) => {
                                  return <li key={item}>{item}</li>;
                                }
                              )
                            :  <li>None</li>}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex w-5 justify-content-center ">
                      <div className="mx-1">
                        <b>Lower Teeth</b>
                        <div className="px-1">
                          {jsonOutputData?.poor_alignment?.lower_teeth.length!=0?jsonOutputData?.poor_alignment?.lower_teeth.map(
                                (item) => {
                                  return <li>{item}</li>;
                                }
                              )
                            : <li>None</li>}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>} 
         
      
      </Col>
    </Row>
  );
};

export default Annotation;
