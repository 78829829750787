import React, { useState,useEffect } from "react";
import { Snackbar, Button, Alert, AlertProps } from "@mui/material";
// import  { useState, forwardRef } from 'react'
const SnackFire = (props) => {
  const [open, setOpen] = useState(props.open);
  const { type, message, timing, position, onClose, selectedValue } = props;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(selectedValue);
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message="Form submitted successfully!"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={props.type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackFire;
