import React from "react"
import "./annotation.css";
const TeethTable = (props) => {
    const {teeth} = props
    const firstRow =  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16"]
    const secondRow = ["", "", "", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "", "", ""]
    const thirdRow =  ["", "", "", "T", "S", "R", "Q", "P", "O", "N", "M", "L", "K", "", "", ""]
    const fourthRow = ["32", "31", "30", "29", "28", "27", "26", "25", "24", "23", "22", "21", "20", "19", "18", "17"]

    return (
        <table className='shadow-lg select-tooth-table'>
            <tbody>
                <tr>
                    {
                        firstRow.map(eachTooth => <td key = {eachTooth} id ={eachTooth} className = {teeth.includes(eachTooth) ? "selected-teeth": ""} > {eachTooth} </td>)
                    }
                </tr>
                <tr>
                   {
                       secondRow.map((eachTooth, index) => <td key ={eachTooth + index} id ={eachTooth} className = {teeth.includes(eachTooth) ? "selected-teeth": ""} > {eachTooth} </td>)
                   }
                </tr>
                <tr>
                    {
                       thirdRow.map((eachTooth, index) => <td key ={eachTooth + index} id ={eachTooth} className = {teeth.includes(eachTooth) ? "selected-teeth": ""} > {eachTooth} </td>)
                    }
                </tr>
                <tr>
                    {
                        fourthRow.map(eachTooth => <td key ={eachTooth} id ={eachTooth} className = {teeth.includes(eachTooth) ? "selected-teeth": ""} > {eachTooth} </td>)
                    }
                </tr>
            </tbody>
        </table>
    )
}

export default TeethTable