import React, { useEffect, useState,Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { FaEnvelopeOpenText } from "react-icons/fa";
import {mailProspectUser} from "../../Services/AuthService";
import close from "../../assets/Close.svg";

const MailingProspectUser = ({onClose,userId,userMail,userName}) => {

    const app = JSON.parse(localStorage.getItem("admin")).admin.app;


return(
    <div>  <div className="model">
    <div className="model_container">
      <div className="close_position">
        <Button
          onClick={() => {
            onClose()          }}
        >
          {" "}
          <img src={close} width="50px" height="50px"></img>

        </Button>
      </div>
      <Col md={12}>
        <Row>
          <Col
            md={6}
            className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
          >
                          <FaEnvelopeOpenText size="150px" color='#FFFF'className='mx-2'  />

            {/* <img
              src={teethcry}
              width="150px"
              height="150px"
              // alt="filter"
            ></img> */}
          </Col>
          <Col
            md={6}
            className="d-flex flex-column justify-content-center h-5 align-items-center"
          >
            <div>An email requesting check data</div>
            <div>will be send to  </div>
            <div className="shared_report_text_mail"> {userMail} </div>

            {/* <div> report to dental office. </div> */}

            <Button
              className="delete_popup_button mt-4"
              onClick={() => {
                mailProspectUser({email:userMail,userName,app})
                .then(res=>{
                    onClose();

                })
                .catch(err=>{console.log(err);
                    onClose();
                });
              }}
            >
              <span className="delete_button_text">Confirm</span>
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  </div></div>
)
  
    
  

 

};

export default MailingProspectUser;
