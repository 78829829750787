import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";


export default function StackedBarChart({data}) {
  return (
    <BarChart
      width={750}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />

      <Bar dataKey="AdvertisingUrl" stackId="a" fill="#8884d8" />
      <Bar dataKey="OfficeUrl" stackId="a" fill="#82ca9d" />
    </BarChart>
  );
}
