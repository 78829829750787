import React from "react";
import "./doctorList.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/navbarLogo.svg";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import css from "../../assets/css.png";
import Update from "../../assets/update.svg";
import Delete from "../../assets/delete.svg";
import filter from "../../assets/filter.svg";
import Star from "../../assets/star.svg";
import Add from "../../assets/multiply.svg";
import Dot from "../../assets/dot.svg";
import UpArrow from "../../assets/upArrow.svg";
import dashboard from "../../assets/backToDashboard.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Hamburger from "../../assets/Hamburger.svg";
import Notification from "../../assets/notification.svg";
import profile from "../../assets/profileImage.svg"

const DoctorList = () => {
  return (
    <Container fluid>
      <Navbar fixed="fixed" className="navbar">
        <Container fluid>
          <div className="navbar_content">
            <div>
              {" "}
              <img
                alt=""
                src={dashboard}
                //   width="0"
                //   height="30"
                width="30px"
                height="30px"
                className="d-inline-block align-top"
              />{" "}
            </div>

            <div className="navbar_text">My Doctors</div>
          </div>

          {/* <Dropdown> */}
          {/* <Dropdown.Toggle id="dropdown-basic"> */}
          <img
            className="mx-2"
            src={Notification}
            width="25px"
            height="25px"
            alt="dropdown"
          ></img>

          <img
            className="mx-2"
            src={Hamburger}
            width="25px"
            height="25px"
            alt="dropdown"
          ></img>
          {/* </Dropdown.Toggle> */}

          {/* <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
        </Container>
      </Navbar>

      <Row>
        <Col className=" my-2" md={3}>
       <div className="filters m-2">   
       <Row>
            <Col md={7}>
              
                <div className="d-flex justify-content-start align-items-center mx-2 mt-2 ">
                  {" "}
                  <img src={filter} width="15px"
            height="15px" alt="filter"></img>
             <div className=" mx-2 text-filter d-flex justify-content-start ">
                  Filter
                </div>
                </div>
               
          
            </Col>
            <Col
              md={4}
              className=" d-flex w-5 justify-content-end text-reset-all mt-2"
            >
              Reset all
            </Col>
          </Row>
          <Col className="my-3 text-source mx-2">Source</Col>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Insurance(12)
            </Col>
          </Row>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Facebook(2)
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Intagram(20)
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Print ads(17)
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Display ads(12)
            </Col>
          </Row>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Referrel (8)
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Mail(6)
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Others(12)
            </Col>
          </Row>
          <Col md={12} className="bg-warning line-horizontal m-1"></Col>
          <Col className="my-3 text-source mx-2">Type of Doctor</Col>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Generic Doctor(2)
            </Col>
          </Row>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Chief Doctor(20)
            </Col>
          </Row>
          <Col md={12} className="bg-warning line-horizontal m-1"></Col>
          <Col className="my-3 text-source mx-2">Status</Col>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Appointment fixed
            </Col>
          </Row>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Consult done
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Consult not done
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Tele-consult Fixed
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Tele-consult done
            </Col>
          </Row>
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Not interested
            </Col>
          </Row>{" "}
          <Row className="mx-1 mt-1">
            <Col md={1}>
              <input type="checkbox" className="checkbox-doctor" />
            </Col>
            <Col md={10} className="checkbox-text d-flex align-items-top">
              Cancelled
            </Col>
          </Row>
          </div>
        </Col>

        <Col className="  my-2 " md={9}>
          <div className="list m-2 ">
        
          <div className="d-flex  w-5">
            <Row>
              <Col md={12} className=" d-flex align-items-center">
                <FormLabel className="font-doctor ">
                  DOCTOR'S LIST (39)
                </FormLabel>
              </Col>
            </Row>

            <div className="button-flex flex-row-reverse p-1">
              <Button className="form-btns-doctor m-1">
                <img src={UpArrow} alt="uparrow" />
              </Button>
              <Button className="form-btns-doctor m-1">
                <span className="sorting-text">Sort By</span>
              </Button>
              <Button className="form-btns-doctor m-1">
                <span className="mx-2 sorting-text">Available Day</span>
                <img src={Dot} alt="dot" />
              </Button>
              <Button className="form-btns-doctor m-1">
                <img src={Add} alt="add" />
                <span className="mx-2 sorting-text">Add New Doctor</span>
              </Button>
            </div>
          </div>
      
          <Row className="doctors-row  mx-1 my-3">
            <Col md={1} className="d-flex align-items-center justify-content-center h-5" >
              <img src={profile} height="100px" width="100px" alt="doctor" className="p-2 doctor-images"/>
            </Col>

            <Col md={2} className=" d-flex flex-column justify-content-evenly px-3">
              <div className="doctor-name">Dr.John doe</div>
              <div className="doctor-degree">Degree</div>
              <div className="doctor-reg-number">REG ID NUMBER</div>
            </Col>

            <Col md={3} className="dotted_line mt-3">
              <Row className="   new">
                <Col
                  md={5}
                  className=" d-flex flex-column justify-content-evenly "
                >
                  <div className="text-size">Designation</div>
                  <div className="text-size">Specialisation</div>

                  <div className="text-size">PatientCount</div>
                </Col>

                <Col
                  md={7}
                  className="d-flex flex-column justify-content-evenly"
                >
                  <div className="text-bold"> designation</div>
                  <div className="text-bold"> Specialisation</div>

                  <div className="text-bold">06</div>
                </Col>
              </Row>
            </Col>

            <Col md={4} className="dotted_line mt-3">
              <Row className="   new">
                <Col
                  md={5}
                  className=" d-flex flex-column justify-content-evenly "
                >
                  <div className="text-size">Language</div>
                  <div className="text-size">Available time</div>

                  <div className="text-size">Date added</div>
                </Col>

                <Col
                  md={7}
                  className="d-flex flex-column justify-content-evenly"
                >
                  <div className="text-bold"> Englsih,chinese,japanese</div>
                  <div className="text-bold"> Weekdays, 10 AM to 5 PM</div>

                  <div className="text-bold">dd/mm/yyyy</div>
                </Col>
              </Row>
            </Col>

            <Col md={2} className="">
              <div className="pt-3 d-flex align-items-center justify-content-center">
                <Col
                  className=" super-doctor-text"
                  md={8}
                >
                  Super doctor
                </Col>
                <Col className="d-flex justify-content-end " md={2}>
                  {" "}
                  <img src={Star} alt="star"></img>
                </Col>
              </div>

              <Row className=" pt-3 d-flex align-items-center justify-content-end">
                <Col md={6} className="d-flex align-items-cente justify-content-end ">
                  {" "}
                  <img src={Update} height="60px" width="60px"  alt="upload absolute" />
                </Col>
                <Col md={6} className="d-flex align-items-cente justify-content-start">
                  {" "}
                  <img src={Delete} height="60px" width="60px" alt="upload absolute" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="doctors-row  mx-1 my-3">
            <Col md={1} className="d-flex align-items-center justify-content-center h-5" >
              <img src={profile} height="100px" width="100px" alt="doctor" className="p-2 doctor-images"/>
            </Col>

            <Col md={2} className=" d-flex flex-column justify-content-evenly px-3">
              <div className="doctor-name">Dr.John doe</div>
              <div className="doctor-degree">Degree</div>
              <div className="doctor-reg-number">REG ID NUMBER</div>
            </Col>

            <Col md={3} className="dotted_line mt-3">
              <Row className="   new">
                <Col
                  md={5}
                  className=" d-flex flex-column justify-content-evenly "
                >
                  <div className="text-size">Designation</div>
                  <div className="text-size">Specialisation</div>

                  <div className="text-size">PatientCount</div>
                </Col>

                <Col
                  md={7}
                  className="d-flex flex-column justify-content-evenly"
                >
                  <div className="text-bold"> designation</div>
                  <div className="text-bold"> Specialisation</div>

                  <div className="text-bold">06</div>
                </Col>
              </Row>
            </Col>

            <Col md={4} className="dotted_line mt-3">
              <Row className="   new">
                <Col
                  md={5}
                  className=" d-flex flex-column justify-content-evenly "
                >
                  <div className="text-size">Language</div>
                  <div className="text-size">Available time</div>

                  <div className="text-size">Date added</div>
                </Col>

                <Col
                  md={7}
                  className="d-flex flex-column justify-content-evenly"
                >
                  <div className="text-bold"> Englsih,chinese,japanese</div>
                  <div className="text-bold"> Weekdays, 10 AM to 5 PM</div>

                  <div className="text-bold">dd/mm/yyyy</div>
                </Col>
              </Row>
            </Col>

            <Col md={2} className="">
              <div className="pt-3 d-flex align-items-center justify-content-center">
                <Col
                  className=" super-doctor-text"
                  md={8}
                >
                  Super doctor
                </Col>
                <Col className="d-flex justify-content-end " md={2}>
                  {" "}
                  <img src={Star} alt="star"></img>
                </Col>
              </div>

              <Row className=" pt-3 d-flex align-items-center justify-content-end">
                <Col md={6} className="d-flex align-items-cente justify-content-end ">
                  {" "}
                  <img src={Update} height="60px" width="60px"  alt="upload absolute" />
                </Col>
                <Col md={6} className="d-flex align-items-cente justify-content-start">
                  {" "}
                  <img src={Delete} height="60px" width="60px" alt="upload absolute" />
                </Col>
              </Row>
            </Col>
          </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DoctorList;
