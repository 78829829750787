import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FormLabel } from "react-bootstrap";
import "../Dashboard/dashboard.css"
import filter from "../../assets/filter.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  // getLatestClinicDetails,
  getConsultingPatientLists,
  getConsultingPatientListsForCsv,
  doctorListBasedOnCompany,
  userViewedStatus, getAlignerReportsForCsv, 
  getAlignerUsers
} from "../../Services/AuthService";
import AssingDentistPopup from "../Dashboard_USA/assingDentistPopup";
import DentalOfficeDetails from "../Dashboard_USA/dentalOfficeDetails";
import { FaUserMd } from "react-icons/fa";
import Navbars from "../Dashboard_USA/navbar";
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import { CSVLink, CSVDownload } from "react-csv";
import { FaDownload } from "react-icons/fa";
import { Analytics } from "@mui/icons-material";
import SignUpForm from "./SignUpFormContainer";
import UpdateDentistPopup from "./updateDentistPopup";

const AlignerDashboard = () => {
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const navigate = useNavigate();
  const [users, setUsers] = useState();
  const [userData, setUserData] = useState({})       //   userId for updating the assign dentist for respective users based on user id
  const [assignedDentist, setassignedDentistId] = useState("")   //  used to popup in updateDentist
  const [loading, setLoading] = useState(true);
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const [doctorList, setdoctorList] = useState([]);
  const [doctorfilterinfo, setdoctorfilterinfo] = useState([]);
  const [patientsWithoutReports, setPatientsWithoutReports] = useState(false)
  const [goPatients, setGopatients] = useState()
  const [doctorSubmitted, setDoctorSubmitted] = useState()
  const [patienttype, setpatienttype] = useState();
  const [openedReport, setOpenedReport] = useState();
  const [assignedDentistPopup, setassignedDentistPopup] = useState(false);
  const [change, setchange] = useState(false);
  const [sharedadvertisinguser, setsharedadvertisinguser] = useState("");
  const [sorting, setsorting] = useState();
  const [csvdata, setcsvdata] = useState("");
  const [userOpenedCount, setUserOpenedCount] = useState(0);
  const [userUnOpenedCount, setUserUnOpenedCount] = useState(0);
  const [totalusers, settotalusers] = useState(0);
  const [filteredUsersCount, setFilteredUsersCount] = useState(0)
  const [currentpage, setcurrentpage] = useState(0);
  const [totalreportfilteredcount, settotalreportfilteredcount] = useState(0);
  const [quickScan, setQuickScan] = useState()

  const [totalpage, setTotalPAge] = useState(0)
  const [patientsLoad, setPatientsLoad] = useState(true)
  const [iSignUpFormOpen, setSignUpForm] = useState(false)


  useEffect(() => {

    const apiCalls = async () => {
      await doctorListBasedOnCompany({ app })
        .then((res) => {
          setdoctorList(res.data.data.doctorList);

        })
        .catch((err) => navigate("/aligner-dashboard"));


    }

    apiCalls()

  }, [])


  const initialCall = async () => {
    const object = { doctorfilterinfo, currentpage, app, patienttype, openedReport, sorting, goPatients, doctorSubmitted, patientsWithoutReports };


    await getAlignerUsers(object)
      .then((res) => {
        const {totalUsersCount, filteredUsersCount, alignerUsers, reportViewedUsercount, csvReports} = res.data.data
        
        setUsers(alignerUsers)
        settotalusers(totalUsersCount)
        setTotalPAge(Math.ceil(filteredUsersCount / 8));
        setFilteredUsersCount(filteredUsersCount)
        setUserOpenedCount(reportViewedUsercount)
        setUserUnOpenedCount(totalUsersCount - reportViewedUsercount)

        const updatedCsvData = csvReports.map(eachUser => {
          const updateUser = {
            ...eachUser, is_report_shared: eachUser.userReportsData.length > 0
          }
          return updateUser
        })
        setcsvdata(updatedCsvData)
        setPatientsLoad(false)
        setLoading(false);
        

      })
      .catch((err) => {
        console.log("error at home")
        navigate("/aligner-dashboard")
      })

  };


  useEffect(() => {
    initialCall();
  }, [doctorfilterinfo, currentpage, app, patienttype, openedReport, sorting, goPatients, doctorSubmitted, patientsWithoutReports]);


  const handlesorting = (e) => {
    setsorting(e.target.value);
    setchange(!change);
  };

  const handlenextpage = () => {
    setcurrentpage(currentpage + 1);
    setchange(!change);
  };
  const handlepreviouspage = () => {
    setcurrentpage(currentpage - 1);
    setchange(!change);
  };

  const getDoctorName = id => {
    const doctor = doctorList.find(doctorObj => doctorObj._id == id)
    const doctorName = doctor ? `Dr. ${doctor.name}` : "--"
    return doctorName
  }

  const closeAssigndentistPopup = () => {
    setdoctorfilterinfo([]);
    setsorting("");
    settotalreportfilteredcount(0);
    setcurrentpage(0);
    setsharedadvertisinguser("");
    initialCall();
    setassignedDentistPopup(false);
  };



  const handleDoctorFilterChange = (e) => {
    const { value, checked } = e.target;
    // const { doctors } = doctorfilterinfo;

    if (checked) {
      setdoctorfilterinfo([...doctorfilterinfo, value]);
      setchange(!change);
    }
    // Case 2  : The user unchecks the box
    else {
      const filteresFoctors = doctorfilterinfo.filter((e) => e !== value)
      setdoctorfilterinfo(filteresFoctors);
      setchange(!change);
    }
  };

  const calculateAge = (dobgiven) => {
    const dob = new Date(dobgiven);
    let diff_ms = Date.now() - dob.getTime();
    let age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  // ----------------------------------CSV headers for shared users---------------------

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone_number" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Country", key: "country" },
    { label: "Is Report Viewed", key: "is_report_viewed" },
    { label: "Is Report Shared", key: "is_report_shared" }
  ];
  const csvReport = {
    data: csvdata,
    headers: headers,
    filename: "Shared_Users.csv",
  };

  const Load = () => {
    return (
      <div className="d-flex justify-content-center align-items-center three-dots">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div>
        <Navbars />
        <div className="mt-5 mx-5">
          <div className=" d-flex align-items-center justify-content-center mt-5">
            <PacmanLoader color={"#0090FF"} css={override} size={150} />
            {/* {show &&  <img src={`${appdata.app_logo}`} width="150px" height="150px"></img> }  */}

            <span className="mt-3 navbar_text_dashbord">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <Container fluid>
        <Navbars />


        <Row>
          <Col className="my-1 overflow-container shadow" md={3} style={{ height: "92vh", }}>

            <div className="filter m-2 h-100 w-100" >
              <DentalOfficeDetails />

              <Col md={12} className=" line-horizontal mb-3 mt-2"></Col>
              <div className="patient-summary mx-2 text-primary"> Patients Summary</div>
              <div className="d-flex flex-wrap my-3" >
                <button type="button" className={` item-status d-flex flex-column flex-grow-1 ps-1 shadow py-1 ${openedReport == null ? "total-bg " : "border bg-white" }`} onClick={() => {
                  setdoctorfilterinfo([]);
                  setsorting("");
                  setOpenedReport();
                  initialCall();
                  setcurrentpage(0);
                }}>
                  <span className="status-number ps-1">{totalusers}</span>
                  <span className="status-type">Total </span>
                </button>

               
                
                <button type="button" className={`item-status d-flex flex-column  flex-grow-1 ps-1 shadow py-1 ${openedReport ? "opened-active" : "bg-white border"} `}  onClick={() => {
                    setOpenedReport(true);
                    setchange(!change);
                  }}>
                  <span className="status-number text-primary ps-1">{userOpenedCount}</span>
                  <span className="status-type">Opened</span>
                </button>
                <button type="button" className={`item-status d-flex flex-column  flex-grow-1 ps-1 shadow py-1 ${openedReport == false ? "un-opened-active" : "bg-white border"}  `} onClick={() => {
                    setOpenedReport(false);
                    setchange(!change);
                  }} >
                  <span className="status-number text-danger ps-1">{userUnOpenedCount}</span>
                  <span className="status-type">Unopened</span>
                </button>
              </div>

              <Col md={12} className=" line-horizontal mb-2 mt-2"></Col>

              <Row className="d-flex align-items-center">
                <Col md={4} xs={4}>
                  <div className="d-flex justify-content-start align-items-center mx-2  ">
                    {" "}
                    <FilterAltIcon className="text-primary" />
                    <div className=" mx-2  d-flex justify-content-start patient-summary text-primary">
                      Filter
                    </div>
                  </div>
                </Col>
                <Col
                  md={7}
                  xs={7}
                  className=" d-flex w-5 justify-content-end text-reset-all"
                >
                  <Button
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => {
                      setdoctorfilterinfo([]);
                      setsorting("");
                      settotalreportfilteredcount(0);
                      setcurrentpage(0);
                      setpatienttype();
                      setQuickScan();
                      setsharedadvertisinguser("");
                      setOpenedReport();
                      initialCall();
                      setGopatients()
                      setDoctorSubmitted()
                      setPatientsWithoutReports(false)
                    }}
                  >
                    {" "}
                    Clear Filters{" "}
                  </Button>
                </Col>
              </Row>

              {/* ------------------------------------unshared report filter----------------------------------- */}

              <Col md={12} className=" line-horizontal my-1"></Col>

              <div className="" >

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                  >
                    <Typography>Missed checks</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light">
                    <Typography>
                      <div
                        className="radio-btn"

                      >
                        <label className="d-flex ">  <input
                          type="checkbox"
                          value={patientsWithoutReports}
                          name="patients-without-Reports"
                          checked={patientsWithoutReports}
                          className="form-check-input"
                          onChange={() => { setPatientsWithoutReports(!patientsWithoutReports) }}
                        />  <span className="ms-2">Patients missed latest check</span></label>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                  >
                    <Typography>Patient Examination</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light">
                    <Typography>
                      <Row className="mx-1">
                        <Col >
                          {" "}
                          <div
                            className="radio-btn"
                            onClick={() => {
                              setDoctorSubmitted("true");
                            }}
                          >
                            <label className="d-flex ">  <input
                              type="radio"
                              value={doctorSubmitted}
                              name="doctorSubmitted"
                              checked={doctorSubmitted == "true"}
                            />  <span className="ms-2">Checks submitted</span></label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mx-1 mt-1">
                        <Col >
                          {" "}
                          <div
                            className="radio-btn"
                            onClick={() => {
                              setDoctorSubmitted("false");
                              setGopatients()
                            }}
                          >
                            <label className="d-flex ">   <input
                              type="radio"
                              value={doctorSubmitted}
                              name="doctorSubmitted"
                              checked={doctorSubmitted == "false"}
                            />   <span className="ms-2"> Checks not submitted </span>   </label>
                          </div>
                        </Col>
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><span className="">Go </span>  <span className=" ms-3 "> No Go</span> </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light">
                    <Typography>
                      <Row className="mx-1">
                        <Col >

                          <div
                            className="radio-btn"
                            onClick={() => {
                              setGopatients("true");
                              setDoctorSubmitted("true")
                            }}
                          >
                            <label className="d-flex ">  <input
                              type="radio"
                              value={goPatients}
                              name="goPatients"
                              checked={goPatients == "true"}
                            />  <span className="ms-2">Go</span></label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mx-1 mt-1">
                        <Col >
                          {" "}
                          <div
                            className="radio-btn"
                            onClick={() => {
                              setGopatients("false");
                              setDoctorSubmitted("true")
                            }}
                          >
                            <label className="d-flex ">   <input
                              type="radio"
                              value={goPatients}
                              name="goPatients"
                              checked={goPatients == "false"}
                            />   <span className="ms-2"> No Go </span>   </label>
                          </div>
                        </Col>
                      </Row>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography>Assigned Dentist</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="bg-light">
                    <Typography>
                      {doctorList.map((value, id) => {
                        return (
                          <Row className="mx-1 mt-1" key={id}>
                            <Col md={1}>
                              {" "}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="doctorFilter"
                                value={value._id}
                                id={value._id}
                                onChange={handleDoctorFilterChange}
                                checked={doctorfilterinfo.includes(value._id)}
                              />
                            </Col>
                            <Col
                              md={10}
                              className="checkbox-text d-flex align-items-top"
                            >
                              {" "}
                              <label htmlFor={value._id}>
                                Dr. {value.name}
                              </label>
                            </Col>

                          </Row>

                        );
                      })}
                      {doctorList.length < 1 && <>
                        <Row className="mx-1 mt-1 ">
                          <Col md={1} className="mt-1">
                            <input type="checkbox" id="sample" value="sample" />
                          </Col>
                          <Col
                            md={10}
                            className="checkbox-text d-flex align-items-top">
                           
                            <Link to="/dentist" >
                              <p>Add dentists </p>
                            </Link>
                          </Col>

                        </Row>

                      </>
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </div>

              
            </div>

          </Col>



          {/* -------------------------------Report body display------------------------ */}

          <Col className="  my-2 " md={9}>
            <div className="lists m-2">
              <div className="d-flex  w-5">
                <div className="m-2">
                  <Button className="add-user-btn ms-2" onClick={() => setSignUpForm(true)}>
                    <span className="">Add User</span>
                  </Button>
                </div>


                <div className="button-flex flex-row-reverse p-1  align-items-center ">
                  <Button
                    disabled={
                      currentpage ==
                      (totalpage == 0 ? currentpage : totalpage - 1)
                    }
                    onClick={handlenextpage}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                  <Button
                    disabled={currentpage == 0}
                    onClick={handlepreviouspage}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                  <div className="p-1 ">
                    {currentpage == 0 ? currentpage + 1 : currentpage * 8 + 1}
                    -
                    {currentpage == totalpage - 1
                      ? filteredUsersCount
                      : (currentpage + 1) * 8}{" "}
                    <span className="mx-1">of</span>{" "}
                    {filteredUsersCount}
                  </div>

                  <select
                    className="form-btns-dashboard text-sans-bold mx-3"
                    value={sorting}
                    onChange={handlesorting}
                  >
                    <option value="" className="text-sans-bold ">
                      Sort By{" "}
                    </option>
                    <option value="Newest on top">Newest on top </option>
                    <option value="Oldest on top">Oldest on top</option>
                    <option value="First Name">First Name (A-Z)</option>
                    <option value="First Name Des">First Name (Z-A)</option>
                  </select>

                  <CSVLink {...csvReport}>
                    <Button>   <FaDownload size="22px" color='#0090FF' className='mx-2' />
                    </Button>
                  </CSVLink>

                </div>
              </div>
              {patientsLoad ? <Load /> : users.map((value) => {

                return (
                  <Row key={value._id} className={"patients-row m-1  mx-3 aligner-patient-hover"}>

                    <div className=" d-flex width-100 ">
                      <Col md={3} className="  height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={`/aligner-user-detail-dental/${value._id}`}
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col md={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  md={3}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-1">Name</div>
                                  <div className="text-sans mx-1">Email</div>
                                </Col>

                                <Col
                                  md={9}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div
                                    className={
                                      value.is_report_viewed
                                        ? "text-sans-bold   font-32-ellipsis"
                                        : "text-sans-bold font-12-ellipsis"
                                    }
                                  >
                                    {value.first_name} {value.last_name}
                                  </div>
                                  <div className="text-sans-bold ellipsis">
                                    {value.email}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>
                      <Col md={2} className=" dotted_line height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={`/aligner-user-detail-dental/${value._id}`}
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col md={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  md={5}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-1">Ph.no</div>
                                  <div className="text-sans mx-1">Age</div>
                                </Col>

                                <Col
                                  md={7}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans-bold ">
                                    {" "}
                                    {value.phone_number}
                                  </div>
                                  <div className="text-sans-bold">
                                    {calculateAge(value.dob)}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>
                      <Col md={3} className=" dotted_line height-100">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          to={`/aligner-user-detail-dental/${value._id}`}
                        >
                          <div className="d-flex h-100 w-5 margin-left">
                            <Col md={11} className="">
                              <div className="d-flex w-5 height-100">
                                <Col
                                  md={5}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans mx-3">
                                    {" "}
                                    Date
                                  </div>

                                  <div className="text-sans mx-3">City</div>
                                </Col>

                                <Col
                                  md={7}
                                  className=" d-flex flex-column justify-content-evenly  height-100"
                                >
                                  <div className="text-sans-bold">
                                    {value.treatmentDate ? moment(value.treatmentDate).format(
                                      "MM/DD/YYYY"
                                    ) : "----"}
                                  </div>

                                  <div className="text-sans-bold ellipsis_state">
                                    {value.city ? value.city : "--"}
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </Link>
                      </Col>

                      <Col md={3} className=" dotted_line height-100">
                        <div className="d-flex h-100 w-5 margin-left">
                          <Col md={12} className="">
                            <div className="d-flex w-5 height-100">
                              <Col
                                md={7}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                                <div className="text-sans mx-3">
                                  Assigned Dentist
                                </div>

                                <div className="text-sans mx-3">State</div>
                              </Col>

                              <Col
                                md={5}
                                className=" d-flex flex-column justify-content-evenly  height-100"
                              >
                       

                                <div className="text-sans-bold ellipsis_state">
                                  {value.assigned_dentist
                                    ? getDoctorName(value.assigned_dentist)
                                    : ""
                                  }

                                </div>
                                <div className="text-sans-bold ellipsis_state">
                                  {" "}
                                  {value.state ? value.state : "--"}
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </Col>

                      <Col md={1}>
                        <Col
                          md={12}
                          className="d-flex height-100  align-items-center"
                        >
                          <Col
                            md={6}
                            className="d-flex  height-100  align-items-center"
                          >
                            <Button
                              // style={{ display: 'none' }}
                              onClick={() => {
                                setUserData({ userName: value.first_name, userId: value._id })
                                setassignedDentistId(value.assigned_dentist)
                                setassignedDentistPopup(true);
                              }}
                            >
                              <FaUserMd size="22px" color="#8787e8" />
                            </Button>
                          </Col>
                        </Col>
                      </Col>
                    </div>
                  </Row>
                );
              })}
            </div>
          </Col>
        </Row>

        {assignedDentistPopup && (
          <UpdateDentistPopup
            userData={userData}
            assignedDentist={assignedDentist}
            popupclose={closeAssigndentistPopup}
          />
        )}

        {
          iSignUpFormOpen && <SignUpForm reload={() => { initialCall() }} popupclose={() => { setSignUpForm(false) }} />
        }




      </Container>
    );
  }
};

export default AlignerDashboard;
