import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button";
import close from "../../assets/Close.svg";

import profile from "../../assets/userIcon.svg";
import EditUserDetailsPopUp from "./updateUserDetails";
import RestartPlan from "./restartUser";

const stages = {
    mainPage: "mainPage",
    updateUserDetails: "updateDetails",
    updatePlan: "updatePlan"

}

const UpdateUser = ({ onClose, userData, noOfReports }) => {
    const [currentStage, setCurrentStage] = useState(stages.mainPage)

    const MainPage = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center " style={{height: "300px"}}>
                <button className="btn btn-primary mb-4 form-btn" onClick={() => {setCurrentStage(stages.updateUserDetails)}}>
                    Update User
                </button>
                <button className=" btn btn-primary form-btn" onClick={() => {setCurrentStage(stages.updatePlan)}}>
                    Restart Plan 
                </button>
            </div>
        )
    }

    const back = () => {
        setCurrentStage(stages.mainPage)
    }

    const Display = () => {
        switch (currentStage) {
            case stages.mainPage:
                return <MainPage />
                break;
            case stages.updateUserDetails:
                return <EditUserDetailsPopUp back={back} onClose={onClose} userData={userData} />
                break;
            case stages.updatePlan:
                return <RestartPlan back={back} userData={userData} onClose={onClose} noOfReports={noOfReports} />
                break;
            default:
                return <MainPage />
                break
        }
    }

    return (
        <div className="model-user">
            <div className="model_container-user">
                <div className="close_position">
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                    >
                        {" "}
                        <img src={close} width="50px" alt="close" height="50px"></img>
                    </Button>
                </div>

                <div>

                    <div className="d-flex navbar_text_dashbord justify-content-center mt-5">
                        <div>
                            <img
                                alt=""
                                src={profile}
                                width="32px"
                                height="32px"
                                className="m-2"
                            />
                        </div> <div>User Details</div>
                    </div>
                    <Display />
                </div>
            </div>
        </div>


    )
}

export default UpdateUser