import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, BarChart, Bar,ComposedChart, ResponsiveContainer, Area
} from "recharts";

import Slider from '@mui/material/Slider';


const datas = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590
  },
  {
    name: "Page C",
    uv: 1397,
    pv: 1098,
    amt: 989,
    cnt: 350
  },
  {
    name: "Page D",
    uv: 1480,
    pv: 1200,
    amt: 1228,
    cnt: 480
  },
  {
    name: "Page E",
    uv: 1520,
    pv: 1108,
    amt: 1100,
    cnt: 460
  },
  {
    name: "Page F",
    uv: 1400,
    pv: 680,
    amt: 1700,
    cnt: 380
  }
];
const data = [
  {
    name: "Upper jaw",
    cavity: 4,
  tartar: 2,
  },
  {
    name: "Lower jaw",
    cavity: 3,
    tartar: 1,
  },
  {
    name: "front teeth",
    cavity: 2,
    tartar: 9,
  },
  {
    name: "left side",
    cavity: 2,
  tartar: 5,
  },
  {
    name: "right side",
    cavity: 1,
    tartar: 4,
  },
 
];

export default class Example extends PureComponent {

  render() {
    return (
      <div>
        {/* <p>Called here</p>
        <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="cavity"
        stroke="#dc3545"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="tartar" stroke="#0dcaf0" />
    </LineChart>
<p>Second example</p>

      <p>Third example</p>  */}

      {/* <ComposedChart
      layout="vertical"
      width={300}
      height={200}
      data={data}
     
    >
      <XAxis type="number" hide axisLine={false} />
      <YAxis dataKey="name" type="category" axisLine={false} />
      <Tooltip />
      
       <Bar dataKey="tartar" barSize={20} fill="#0090FF"  />
    
    </ComposedChart> */}

    <Slider
  aria-label="Small steps"
  defaultValue={0.00000005}
  // getAriaValueText={valuetext}
  step={0.00000001}
  marks
  min={-0.00000005}
  max={0.0000001}
  valueLabelDisplay="auto"

/>
      </div>
    
    );
  }
}
