import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

function Navbarthanks() {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container
            fluid
            className="d-flex justify-content-center align-items-center"
          >
            <div className="navbar_text_dashbord">Toothlens</div>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbarthanks;
