import React, { useEffect, useState,Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import close from "../../assets/Close.svg";
import { Formik, setNestedObjectValues } from "formik";
import * as Yup from "yup";
import {
  getUserDetails,
  userDetails
} from "../../Services/AuthService";
import {LocalizationProvider, LocolizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDayjs}from "@mui/x-date-pickers/AdapterDayjs";
import  {DatePicker} from "@mui/x-date-pickers/DatePicker"
// import Box from "@mui/material"
import profile from "../../assets/userIcon.svg";

const EditUserDetailsPopUp = ({onClose,userId}) => {
const [userData,setuserData]=useState();

const [loading,setloading]=useState(true);
const [value,setValue]=useState(null)

  useEffect(()=>{ 
    getUserDetails({user_id:userId}) 
    .then((res) => {
      if (res.status === 200) {
        // if(res.data.data[0].clinic_country){
        //   navigate('/dashboard')

        // }
        setuserData(res.data.data);


        // if(imgs.length)
        setloading(false);
      }
    })
    .catch((err) => {
      setloading(false);
      return err;
    });

},
  [])
  if (loading) {
    <div>Loading</div>;
  }

  if(!loading){
    return (
      <div>
  
        <Formik     validate={(values) => {
              let errors = {};
              // if (!values.dental_office_name) {
              //   errors.dental_office_name = "Dental Office Name is required";
              // }
              if (!values.first_name) {
                errors.first_name = "First Name is required";
              }
              if (!values.last_name) {
                errors.designation = "Designation is required";
              }
              if (!values.phone_number) {
                errors.password = "Phone Number is required";
              }
              // if (!values.dob) {
              //   errors.dob = "Date of Birth is Required";
              // }
              if (!values.city) {
                errors.email = "City is required";
              }
              if (!values.state) {
                errors.confirm_password = "State is required";
              }
              if (!values.country) {
                errors.confirm_password = "Country is required";
              }
              return errors;
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required("First Name is required"),
              last_name: Yup.string().required("Last Name is required"),
              // dental_office_name: Yup.string().required("Dental Office Name is required"),
              phone_number: Yup.string().required("Phone Number is required"),
              city: Yup.string().required("City is Required"),
              state: Yup.string().required("State is Required"),
              country: Yup.string().required("Country is Required"),
              // dob: Yup.string().required("Please enter your dob") 
              // .test("DOB", "User must be atleast 3 years old", (value) => {
              //   return moment().diff(moment(value), 'years') >= 3; 
              // }),
  
            })}
            initialValues={{
              first_name: userData?userData.userDetails[0].first_name:"hello world",
              last_name: userData?userData.userDetails[0].last_name:"",
              phone_number:userData?userData.userDetails[0].phone_number:"",
              city:userData?userData.userDetails[0].city:"",
              state:userData?userData.userDetails[0].state:"",
              country:userData?userData.userDetails[0].country:"",
              // dob: userData ? userData.dob : null,

            }}
          
            onSubmit={(values) => {
userDetails({user_id:userData.userDetails[0]._id,...values}).then((res)=>{onClose();
              }).catch(err=>console.log(err))
              // registerAdmin(values)
              //   .then((response) => {
              //     if (response) {
              //       setsnackbarmessage(response.message);
              //       settype("success");
              //       setsnackbar(true);
              //       navigate("/signup-step-two", { state: values.email });
              //     }
              //   })
              //   .catch((error) => {
              //     setsnackbarmessage(error.message);
              //     settype("error");
              //     setsnackbar(true);
              //     return error;
              //   });
            }}>
                  {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              const dob = new Date(values.dob);
              let diff_ms = Date.now() - dob.getTime();
              let age_dt = new Date(diff_ms);
              const age = Math.abs(age_dt.getUTCFullYear() - 1970);

            // console.log(initialValues,"iiiiiiiiii")
  return(<form onSubmit={handleSubmit}>
    <div className="model-user">
          <div className="model_container-user">
            <div className="close_position">
              <Button
                onClick={() => {
                  // call();
                  onClose();
                }}
              >
                {" "}
                <img src={close} width="50px" height="50px"></img>
              </Button>
            </div>
  
            <div>
              
              <div className="d-flex navbar_text_dashbord justify-content-center mt-5">
               <div>
                    <img
                      alt=""
                      src={profile}
                      width="32px"
                      height="32px"
                      className="m-2"
                    />       
                          </div> <div>User Details</div> 
              </div>
              <div>
                {" "}
                <Col
                  md={12}
                  className="d-flex justify-content-center  mt-3"
                >
                  <Col
                    md={5}
                    xs={12}
                    className="d-flex position mx-3 mt-4 pt-2"
                  >
                    <div>
                      <div className="d-flex w-5">
                        <Col md={6} className="">
                          <FormLabel
                            component="legend"
                            className="font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">First Name</span>
                          </FormLabel>
                        </Col>
                        <Col md={6} className="">
                          <FormLabel
                            component="legend"
                            className="font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">Last Name</span>
                          </FormLabel>
                        </Col>
                        
                      </div>
  
                      <div className="d-flex w-5 ">
                        <Col md={6} className="mt-1 px-2 ">
                          <input
                            type="text"
                            id="first_name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="first name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}                        />
                        </Col>
                      
                        <Col md={6} className="mt-1 px-2">
                          <input
                            type="text"
                            id="last_name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="last name"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                       
                      </div>
                      <div className="d-flex w-5">
                      <div>
                        {" "}
                        {errors.first_name && touched.first_name && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.first_name}
                          </div>
                        )}
                      </div>
                      <div className="mx-3">
                        {" "}
                        {errors.last_name && touched.last_name && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.last_name}
                          </div>
                        )}
                      </div>
                      </div>
                    
                      <Col>
                        <FormLabel
                          component="legend"
                          className="pt-2 font-weight-500 text-dark field-text"
                        >
                          <span className="field-texts  mx-3">Phone Number</span>
                        </FormLabel>
                      </Col>
  
                      <Col className="mt-1">
                        {" "}
                        <input
                          type="text"
                          id="phone_number"
                          className="form-input mb-3 grey-bg-color"
                          placeholder="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <div className="mx-3">
                        {" "}
                        {errors.phone_number && touched.phone_number && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.phone_number}
                          </div>
                        )}
                      </div>
                    </div>
  
                    {/* </div> */}
                  </Col>
  
                  <Col
                    md={5}
                    xs={12}
                    className="d-flex align-items-center justify-content-center position my-3 mx-3 mt-3"
                  >
                    <div>
                      <div className="d-flex w-5">
                        <Col md={6} className="mt-3">
                          <FormLabel
                            component="legend"
                            className="mt-1 font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">City</span>
                          </FormLabel>
                        </Col>
                      </div>
  
                      <div className="d-flex w-5 ">
                        <Col md={12} className="mt-1 px-2 ">
                          <input
                            id="city"
                            type="text"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                  
                      </div>
                      <div className="mx-3">
                        {" "}
                        {errors.city && touched.city && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.city}
                          </div>
                        )}
                      </div>
                      <Col>
                        <FormLabel
                          component="legend"
                          className="pt-2 font-weight-500 text-dark field-text"
                        >
                          <span className="field-texts  mx-3">State</span>
                        </FormLabel>
                      </Col>
  
                      <Col className="mt-1">
                        {" "}
                        <input
                          type="text"
                          id="state"
                          className="form-input mb-3 grey-bg-color"
                          placeholder="State"
                          value={values.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <div className="mx-3">
                        {" "}
                        {errors.state && touched.state && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.state}
                          </div>
                        )}
                      </div>
                      <Col>
                        <FormLabel
                          component="legend"
                          className="pt-2 font-weight-500 text-dark field-text"
                        >
                          <span className="field-texts  mx-3">Country</span>
                        </FormLabel>
                      </Col>
  
                      <Col className="mt-1">
                        {" "}
                        <input
                          type="text"
                          id="country"
                          className="form-input mb-3 grey-bg-color"
                          placeholder="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <div className="mx-3">
                        {" "}
                        {errors.country && touched.country && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.country}
                          </div>
                        )}
                      </div>
                    </div>
  
                    {/* </div> */}
                  </Col>
                </Col>
              </div>
  
              <div className="d-flex justify-content-center align-items-center mt-4">
                <Button className="form-btn" type="submit">
                  <span className="btn-text">Save and Update</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
  </form>)
  
            }}
  
        </Formik>
        {" "}
      
      </div>
    );
  }

};

export default EditUserDetailsPopUp;
