import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../assets/navbarLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import MyClinic from "../../assets/MyClinic.svg";
import logout from "../../assets/logout.svg";
import dashboard from "../../assets/backToDashboard.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FaMailBulk} from "react-icons/fa";
import {FaUserMd} from "react-icons/fa";

import {
  logoutUserService,
  getAppDetails
} from "../../Services/AuthService";
import { set } from "date-fns";
function Navbars() {
  const app = JSON.parse(localStorage.getItem("admin")).admin.app;
  const usa_admin=JSON.parse(localStorage.getItem("admin")).admin.is_usa_type_admin;
const [show,setshow]=useState(false);
const [appdata,setappdata]=useState()
const call=async()=>{
  await getAppDetails({app}).then(res=>{
    setappdata(res.data.data)
  setshow(true)
  }).catch(err=>{console.log(err)});
  
}

useEffect(()=>{
call()
},[])

  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
           {show &&
           <div className="mx-3">
           <img
               alt=""
              //  src="https://s3.ca-central-1.amazonaws.com/insurance-image-store/input/67191668708142864.jpeg"
src={`${appdata.app_logo}`}
// src="https://northlanedental.toothlens.com/static/media/logo.047a548f.png"
               //   width="0"
               //   height="30"
               width="70px"
               height="50px"
               className="d-inline-block align-top"
             />
         </div> } 
            <div className="navbar_text_dashbord">DASHBOARD</div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <div className="d-flex w-5">
                    <div>
                      {" "}
                      <img
                        alt=""
                        src={logo}
                        //   width="0"
                        //   height="30"
                        width="40px"
                        height="40px"
                      />
                    </div>
                    <div className="navbar_link_text_dashbord">Toothlens</div>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center w-5 h-80 align-items-center flex-grow-1 pe-3">
                  <Col md={12} className="px-3">
                {usa_admin?<Link
                      to="/dashboard-dental"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={dashboard}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dashboard
                        </Col>
                      </div>
                    </Link> : 
                    <Link
                      to="/dashboard"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={dashboard}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dashboard
                        </Col>
                      </div>
                    </Link> }    
                  </Col>
                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/dentist"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <FaUserMd size="40px" color='#0090FF'  />

                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Dentist Details
                        </Col>
                      </div>
                    </Link>
                  </Col>
                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/account-details"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={MyClinic}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Office Details
                        </Col>
                      </div>
                    </Link>
                  </Col>
                  <Col md={12} className="px-3 mt-3">
                   <a href="mailto:support@toothlens.com"                  
                        style={{ textDecoration: "none", color: "inherit" }}
>

                    
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center "
                        >
                          {" "}
                          {/* <img
                            alt=""
                            src={MyClinic}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          /> */}
                          {/* <i className="fas fa-envelope bg-primary" ></i> */}
                          {/* <i class="fa-lg fa-envelope" aria-hidden="true"></i> */}
                          <FaMailBulk size="50px" color='#0090FF'  />

                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support d-flex w-5 justify-content-start"
                        >
                        Support@toothlens.com
                        </Col>
                      </div>
                    </a>
                  </Col> 
                  <Col md={12} className="px-3 mt-3">
                    <Link
                      to="/login"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={()=>{
                        logoutUserService()
                      }}
                    >
                      <div className="d-flex w-5 mt-2">
                        <Col
                          md={3}
                          className="d-flex w-5 justify-content-center"
                        >
                          {" "}
                          <img
                            alt=""
                            src={logout}
                            //   width="0"
                            //   height="30"
                            width="40px"
                            height="40px"
                          />
                        </Col>
                        <Col
                          md={8}
                          className="navbar_link_text_dashbord_support  d-flex w-5 justify-content-start"
                        >
                          Logout
                        </Col>
                      </div>
                    </Link>
                  </Col>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Navbars;
