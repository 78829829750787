import React from 'react'
import { Link} from "react-router-dom";

const TrialLink = () => {
  return (
    <div>
        <div className='m-5'>
        <Link to="/form/63bd8648dfd2271f298b0566"> Matt/Adam</Link>

        </div>
        <div className='m-5'>
        <Link to="/form/63bd83c77483b336ac9ffe71"> Allison</Link>

        </div>
        <div className='m-5'>
        <Link to="/form/63bd842e67a66e97eb7e4af9"> Jai Testing</Link>

        </div>
    </div>
  )
}

export default TrialLink