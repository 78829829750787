import React, { useState, useEffect } from "react"
import Navbars from "./navBar"
import Analytics from "./analytics"
import SharedUsers from "./sharedUsers"
import Prospects from "./prospects"
import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import Subscribers from "./subscribers"
import NotFound from './notfound'
import {
    countOfMarkettingTypeUser,
    countOfAiCheckProspectUser, getAllClients,
} from "../../Services/AuthService";
import "./commonStyles.css"

const tabs = ["Shared Users", "Prospects", "Analytics"]
const constantTabs = {
    analytics: "Analytics",
    sharedUsers: "Shared Users",
    prospects: "Prospects"
}

const DashBoardInternal = () => {
    let app = JSON.parse(localStorage.getItem("admin")).admin.app;
    const verified =  JSON.parse(localStorage.getItem("flag"))
    const [activeTab, setActiveTab] = useState(constantTabs.sharedUsers)
    const [subscribers, toggleSubscribers] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(app)
    const [companyList, setCompanyList] = useState()
    const [areCompaniesGot, setAreCompaniesGot] = useState(false)
   

    //  getting total number of clients once when app starts
    useEffect(() => {
        if (verified) {
            setAreCompaniesGot(false)
            getAllClients({ app })
                .then((res) => {
                    setCompanyList(res.data.data)
                    setAreCompaniesGot(true)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }, [])

    const handleOnchange = event => {
        setSelectedCompany(event.target.value)
        const adminObject = JSON.parse(localStorage.getItem("admin"))
        const { admin, token } = adminObject
        const newApp = event.target.value
        const newAdmin = { admin: { ...admin, app:newApp }, token }
        localStorage.setItem("admin", JSON.stringify(newAdmin));
    }


    const Header = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Navbars app={selectedCompany} />
                </div>
            </div>
        )
    }

    // dropdown displays only when we get allclients appId's data from API
    const DropDown = () => {
        return (
            !areCompaniesGot ? <div className="col-6 col-lg-3 order-0">
                
                   <p className="pt-2">Loading...</p>
                 </div> :
                <div className="col-3 col-lg-3 order-0">
                    <select className="rounded-3 py-1 w-100" value={selectedCompany} onChange={handleOnchange} >
                        {
                            companyList.map(each => <option key={each.appId} value={each.appId} > {each.name} </option>)
                        }
                    </select>
                </div>
        )
    }

    const ShiftTabsContainer = () => {
        return (
            <ul className="col-12 col-lg-7 d-flex justify-content-sm-start justify-content-lg-center align-items-center tabs-list order-2 order-lg-1">
                {
                    tabs.map((eachTab) => {
                        const isActive = eachTab === activeTab
                        return (
                            <li key={eachTab} className="me-md-3 me-lg-4 align-self-center ">
                                <button type="button" className=" bg-white border-0" onClick={() => setActiveTab(eachTab)}>
                                    {isActive && <img src={dentalAnalysisimg} width="25px" height="25px" alt="filter" />}
                                    <span className={isActive ? "text-primary" : ""}> {eachTab} </span>
                                </button>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const TabsContainer = () => {
        return (
            <div className=" tbs-container row  border-primary pt-2 mx-2 mx-md-5   shadow rounded-3">
                <DropDown />
                <ShiftTabsContainer />
                <div className="col-6 col-lg-1 order-1 order-lg-2 text-end">
                   <button type="button" className="btn btn-primary" onClick={() => toggleSubscribers(true)} > Subscribers  </button>
                </div>
                
            </div>
        )
    }

    const Sections = () => {
        switch (activeTab) {
            case constantTabs.analytics:
                return <Analytics app={selectedCompany} />
                break;
            case constantTabs.sharedUsers:
                return <SharedUsers app={selectedCompany} />
                break;
            case constantTabs.prospects:
                return <Prospects app={selectedCompany} />
                break;
            default:
                return null
                break;
        }
    }

    return (
        <div className="container-fluid">
            {verified ?
                <div>
                    <Header />
                    {subscribers ? <Subscribers app={selectedCompany} close = {() => toggleSubscribers(false)} /> : 
                        <>
                            <TabsContainer />
                            <Sections />
                        </>
                    }
                    
                </div> : <NotFound />}

        </div>
    )
}

export default DashBoardInternal